import { ExportOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { HubspotIcon } from '../design/custom-icons.jsx';

const HubspotButton = ({ url, buttonText = "Link to Hubspot", ...otherProps }) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <>
            <Button icon={<HubspotIcon />} onClick={() => setIsOpen(true)} {...otherProps}>{buttonText}</Button>
            <Drawer
                open={isOpen}
                size='large'
                onClose={() => setIsOpen(false)}
                title='Hubspot'
                extra={(
                    <Button href={url} icon={<ExportOutlined />} type='primary' target="_blank">View in Hubspot</Button>
                )}
                destroyOnClose
            >
                <iframe src={url} width="100%" height="100%"></iframe>
            </Drawer>
        </>
    )
}

HubspotButton.propTypes = {
    url: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
}

export { HubspotButton };

import { Alert } from 'antd';
import React, { useEffect } from "react";
import { generatePath, useNavigate, useRouteLoaderData } from 'react-router-dom';
import style from './order-sent.module.scss';

const OrderSent = () => {
    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')
    const navigate = useNavigate()

    useEffect(() => {
        let timer = null
        timer = setTimeout(() => {
            navigate(generatePath('/order-manager/:itemId/:stepId', {
                itemId: orderDetails.id,
                stepId: orderDetails.order_status
            }), {
                replace: true
            })
        }, 10 * 1000)
        return () => {
            clearTimeout(timer)
        }
    }, [orderDetails.order_status])

    return (
        <div className={style.container}>
            <h3>Task To do for Customer</h3>
            {!orderDetails.reminder_artwork && <Alert message="Missing Artwork" description="Reminder sent to customer to share the artwork" type="info" showIcon />}
            {(orderDetails.request_for_address !== 2) && <Alert message="Missing delivery address" description="Reminder sent to customer to share the delivery address" type="info" showIcon />}
        </div>
    )
}

export default OrderSent;
import { Alert, Button } from 'antd';
import React from "react";
import { Link, useRouteLoaderData } from 'react-router-dom';
import { generateRouteUrl } from '../../../library/constants';
import style from './artwork-sent.module.scss';

const MakeArtworkRejected = () => {
    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')

    return <div className={style.container}>
        <h3>Task for you</h3>
        <Alert
            message="Made Artowrk has been rejected by the client"
            type="error"
            description={!!orderDetails.comments?.client_artwork_rejected && (
                <>
                    <strong>Reason</strong>: {orderDetails.comments.client_artwork_rejected}
                </>
            )}
            action={
                <Link to={generateRouteUrl('MakeCustomerArtwork', {
                    itemId: orderDetails.id,
                })}>
                    <Button className={style.greenButton}>Re-Make Artwork</Button>
                </Link>
            }
        />
    </div>
}

export default MakeArtworkRejected;
import { websiteUrls } from "../constants/website-urls.js";

export const productUrl = (url, language_code) => {
    let baseUrl = websiteUrls[language_code]
    if (['be_fr', 'lu_fr'].includes(language_code)) {
        baseUrl = baseUrl.endsWith('/fr') ? baseUrl.slice(0, -'/fr'.length) : baseUrl;
    }
    return `${baseUrl}/${url}`
}

export const getProductImageUrl = (path) => `${websiteUrls.nl}/files/photos/${path}`
export const getProductImageUrlResized = (path, width = 100) => `${websiteUrls.nl}/thumb.php?img=/files/photos/${path}&w=${width}`
import { Collapse, message } from 'antd';
import React from 'react';
import { redirect, useFetcher, useRouteLoaderData } from 'react-router-dom';
import { makeArtworkSendToClient } from '../../api/order/order-manager.js';
import FileTransfer from '../../components/inputs/FileTransfer.jsx';
import { generateRouteUrl } from '../../library/constants/routes.js';
const { Panel } = Collapse

const SendArtworkToCustomer = () => {
    const { orderDetails, orderFiles } = useRouteLoaderData('OrderManagerFileTransfer');
    const fetcher = useFetcher()

    return (
        <Collapse defaultActiveKey={['1']} expandIconPosition="end">
            <Panel header='Send Artwork to Customer' key="1">
                <FileTransfer
                    existingFiles={orderFiles?.make_artwork_from_studio ?? []}
                    orderDetails={orderDetails}
                    orderManagerStudioActionText="Sent to Customer"
                    loading={fetcher.state !== 'idle'}
                    onOrderManagerStudio={(data) => fetcher.submit({
                        ...data,
                        action: 'sendToCustomer'
                    }, {
                        method: 'post',
                        encType: 'application/json'
                    })}
                    onSendToCustomer={(data) => fetcher.submit({
                        ...data,
                        action: 'sendToCustomer'
                    }, {
                        method: 'post',
                        encType: 'application/json'
                    })}
                />
            </Panel>
        </Collapse>
    )
}
SendArtworkToCustomer.Actions = {
    sendToCustomer: async ({ params, data }) => {
        try {
            const { existingFiles = [], newFiles = [], comment } = data
            const msg = await makeArtworkSendToClient(params.itemId, existingFiles, newFiles, comment)
            message.success(msg)
            return redirect(generateRouteUrl('OrderManagerDetailsPage', { itemId: params.itemId }))
        }
        catch (error) {
            message.error(error.message)
            return false
        }
    }
}
export default SendArtworkToCustomer
import { Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import Currencies from '../../library/dynamics/currencies';
const { Option } = Select;
function CurrencySelectDropDown({ value = null, onChange, ...otherProps }) {
    return (
        <Select
            onChange={onChange}
            value={value}
            {...otherProps}
        >
            {Currencies.map((currency) => (
                <Option key={currency.id} value={currency.id} label={currency.name}>
                    {currency.code} ({currency.symbol})
                </Option>
            ))}
        </Select>
    );
}



CurrencySelectDropDown.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func
}

export default CurrencySelectDropDown;
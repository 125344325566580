import { Alert, Button } from 'antd';
import React from "react";
import { Link, useRouteLoaderData, useOutletContext } from 'react-router-dom';
import { generateRouteUrl } from '../../../library/constants';
import style from './request-template.module.scss';

const RequestTemplateFromVendor = () => {
    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')
    const { vendorRole } = useOutletContext()

    return <div className={style.container}>
        <h3>Task for you</h3>
        <Alert
            message={`Template has been requested, wait for ${vendorRole}'s reply`} type="info" action={<Link to={generateRouteUrl('UploadSampleTemplate', {
                itemId: orderDetails.id,
            })}>
                <Button className={style.greenButton}>Send Template to Customer</Button>
            </Link>}
        />

    </div>
}



export default RequestTemplateFromVendor;
// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/vendor-contact';
const ENDPOINT_SINGLE = '/vendor-contact/:id';
// const ENDPOINT_SINGLE_ACTION = '/project/:id/:action';
const ENDPOINT = '/vendor-contact/:contactId/:role/:action';

// export const getAllVendorContacts = (payload) => {
//     console.log(axiosInstance.get(ENDPOINT_GENERAL, { params: payload }));
//     return axiosInstance.get(ENDPOINT_GENERAL, { params: payload })
// }

export const createVendorContact = (payload) => {
    return axiosInstance.post(ENDPOINT_GENERAL, payload)
}

export const getVendorContactDetails = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE, {
        id
    })).then(({ data }) => data)
}

export const deleteVendorContact = (id) => {
    return axiosInstance.delete(generatePath(ENDPOINT_SINGLE, {
        id
    }))
}

export const updateVendorContactDetails = (id, payload) => {
    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE, {
        id
    }), payload)
}


export const removeContactEnquiry = (contactId) => {
    return axiosInstance.post(generatePath(ENDPOINT, {
        contactId,
        role: 'enquiry',
        action: 'remove'
    }), {})
}

export const setContactEnquiry = (contactId) => {
    return axiosInstance.post(generatePath(ENDPOINT, {
        contactId,
        role: 'enquiry'
    }), {})
}


export const removeContactOrder = (contactId) => {
    return axiosInstance.post(generatePath(ENDPOINT, {
        contactId,
        role: 'order',
        action: 'remove'
    }), {})
}

export const setContactOrder = (contactId) => {
    return axiosInstance.post(generatePath(ENDPOINT, {
        contactId,
        role: 'order'
    }), {})
}
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Card, Input, Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFetcher } from 'react-router-dom';
import { CustomerAddress } from '../../components/form';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { triggerInputChange } from '../../library/helpers/forms/index.js';
import { Box } from '../design/box.js';
import styles from './address-select.module.scss';

const renderItem = (item, selected, disabled, handleItemClick) => (
    <Card key={item.id} bordered={false} onClick={() => !disabled && handleItemClick(item)} className={`${styles.card} ${selected ? styles['card--selected'] : ''} ${disabled ? styles['card--disabled'] : ''}`} title={item.street}>
        <div className={styles.item}>
            <div className={styles.itemLabel}>Street</div>
            <div className={styles.itemValue}>{item.street}</div>
        </div>
        <div className={styles.item}>
            <div className={styles.itemLabel}>Street No.</div>
            <div className={styles.itemValue}>{item.street_number}</div>
        </div>
        <div className={styles.item}>
            <div className={styles.itemLabel}>City</div>
            <div className={styles.itemValue}>{item.city}</div>
        </div>
        <div className={styles.item}>
            <div className={styles.itemLabel}>County</div>
            <div className={styles.itemValue}>{item.county}</div>
        </div>
        <div className={styles.item}>
            <div className={styles.itemLabel}>Country</div>
            <div className={styles.itemValue}>{item.country.name}</div>
        </div>
        <div className={styles.item}>
            <div className={styles.itemLabel}>Zipcode</div>
            <div className={styles.itemValue}>{item.zipcode}</div>
        </div>
    </Card>
)

const AddModal = ({ customerId, onCancel, onSubmit }) => {
    const addressFormData = useRef({})
    const [isFormValid, setIsFormValid] = useState(false)
    const fetcher = useFetcher()
    const { errors = {} } = fetcher.data || {}

    const handleAddressFormChange = (_, values) => {
        addressFormData.current = { ...values, action: 'addAddress' }
    }

    useEffect(() => {
        if (fetcher.data) {
            onSubmit(fetcher.data)
        }
    }, [fetcher.data])

    return (
        <Modal
            title="Add New Address"
            open={true}
            onCancel={onCancel}
            width='50%'
            forceRender
            destroyOnClose
            footer={<>
                <Button type="primary" icon={<CheckOutlined />} onClick={() => {
                    fetcher.submit(addressFormData.current, {
                        action: generateRouteUrl('CustomerDetails', {
                            id: customerId,
                        }), method: 'post', encType: 'application/json'
                    })
                }} disabled={!isFormValid}>Add Contact</Button>
                <Button type="cancel" icon={<CloseOutlined />} onClick={onCancel}>Cancel</Button>
            </>}>
            <CustomerAddress
                index="1"
                onValuesChange={handleAddressFormChange}
                getValidationStatus={valid => setIsFormValid(valid)}
                errors={errors}
            />
        </Modal>
    )
}

AddModal.propTypes = {
    customerId: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

const AddressSelect = ({ name = null, defaultValue = null, customerId, onSelect, excludeAddressIds = [], renderSelected, disabled = false }) => {
    const [query, setQuery] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [selectedId, setSelectedId] = useState(defaultValue)
    const inputRef = useRef(null)
    const fetcher = useFetcher()
    const [addNewVisible, setAddNewVisible] = useState(false)
    const { addresses = [] } = fetcher.data ?? {}

    const filteredList = useMemo(() => {
        if (!query.length) {
            return addresses
        }
        return addresses.filter((address) => Object.values(address).some((v) => {
            return (typeof v === 'string') && v.toLowerCase().includes(query.toLowerCase())
        }))

    }, [addresses, query, excludeAddressIds])

    const handleItemClick = (address) => {
        setSelectedId(address.id)
        onSelect(address.id, address)
        if (name) {
            triggerInputChange(inputRef.current, address.id)
        }
        closePopup()
    }

    useEffect(() => {
        fetcher.load(generateRouteUrl('CustomerDetails', {
            id: customerId
        }))
    }, [customerId])

    const closePopup = () => setIsOpen(false)

    return (
        <>
            <div onClick={() => {
                if (!disabled) setIsOpen(true)
            }}>
                {renderSelected(addresses.find(({ id }) => id === selectedId))}
            </div>
            {!!name && (<input ref={inputRef} name={name} style={{ display: 'none' }} defaultValue={defaultValue} />)}
            <Modal title="Add Address" open={isOpen} onCancel={closePopup} destroyOnClose footer={<Button onClick={() => setAddNewVisible(true)}>Add New Address</Button>} width={1000} >
                <Box type={Box.BoxTypes.INNERSHADOW} >
                    <div className={styles.wrapper}>
                        <Input.Search placeholder="Search..." onChange={(e) => setQuery(e.target.value)} />
                        <div className={styles.cards}>
                            {filteredList.map((item) => renderItem(item, (item.id === selectedId), excludeAddressIds.includes(item.id), handleItemClick))}
                        </div>
                    </div>
                </Box>
                {addNewVisible && <AddModal customerId={customerId} onSubmit={(_address) => {
                    handleItemClick(_address)
                    setAddNewVisible(false)
                }} onCancel={() => setAddNewVisible(false)} />}
            </Modal >
        </>
    )
}

AddressSelect.propTypes = {
    name: PropTypes.string,
    defaultValue: PropTypes.number,
    customerId: PropTypes.number.isRequired,
    onSelect: PropTypes.func.isRequired,
    renderSelected: PropTypes.func.isRequired,
    excludeAddressIds: PropTypes.arrayOf(PropTypes.number),
    disabled: PropTypes.bool
}

export default AddressSelect
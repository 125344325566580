import { FileTextOutlined } from '@ant-design/icons';
import React from 'react';
import SupplierDelivered from '../../../routes/orderManager/supplier-tasks/supplier-delivered.jsx';
import { SupplierInProduction } from '../../../routes/orderManager/supplier-tasks/supplier-in-production.jsx';
import SupplierOrderConfirmation from '../../../routes/orderManager/supplier-tasks/supplier-order-confirmation.jsx';
import { SupplierProductDispatch } from '../../../routes/orderManager/supplier-tasks/supplier-product-dispatch.jsx';
import { SupplierDispatchDateConfirm } from '../../../routes/orderManager/tasks/supplier-dispatch-date-confirm.jsx';

export const OrderSupplierStatuses = {
    ORDER_CONFIRMATION: 1,
    IN_PRODUCTION: 2,
    EXPECTED_DISPATCHED_DATE: 3,
    IN_TRANSIT: 4,
    DELIVERED: 5,
}

export const SupplierOrderManagerSteps = [
    {
        id: 'OrderConfirmation',
        status: OrderSupplierStatuses.ORDER_CONFIRMATION,
        icon: <FileTextOutlined />,
        title: 'Order Confirmation',
        component: () => SupplierOrderConfirmation,
    },
    {
        id: 'InProduction',
        status: OrderSupplierStatuses.IN_PRODUCTION,
        icon: <FileTextOutlined />,
        title: 'In Production',
        component: () => SupplierInProduction,
    },
    {
        id: 'DispatchDateConfirm',
        status: OrderSupplierStatuses.EXPECTED_DISPATCHED_DATE,
        icon: <FileTextOutlined />,
        title: 'Expected Dispatch Date',
        component: () => SupplierDispatchDateConfirm,
    },
    {
        id: 'ProductDispatch',
        status: OrderSupplierStatuses.IN_TRANSIT,
        icon: <FileTextOutlined />,
        title: 'Product Dispatched',
        component: () => SupplierProductDispatch,
    },
    {
        id: 'Delivered',
        status: OrderSupplierStatuses.DELIVERED,
        icon: <FileTextOutlined />,
        title: 'Delivered',
        component: () => SupplierDelivered,
    },
    // {
    //     id: 'CancelRequest',
    //     status: OrderSupplierStatuses.C,
    //     icon: <FileTextOutlined />,
    //     title: 'Cancel Request',
    //     component: () => CancelRequest,
    // },
]
export const getSupplierOrderManagerSteps = () => SupplierOrderManagerSteps.map(({ icon, status, title }) => ({
    icon,
    status,
    title
}))

export const getSupplierOrderStatusName = (status) => SupplierOrderManagerSteps.find(step => step.status === status)?.title

// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { z } from "zod";
import { ValidationError } from "../../library/classes/exceptions";
import { axiosInstance } from '../common';


// Define all api endpoint urls at the top
const ENDPOINT = '/customer';
const ENDPOINT_CUSTOMER_UNQIUE = 'customer/unique/:field';

const AddressSchema = z.object({
    street: z.string({ required_error: 'Street is required' }).trim(),
    street_number: z.string({ required_error: 'Street Number is required' }).trim(),
    zipcode: z.string({ required_error: 'Zip is required' }).trim(),
    city: z.string({ required_error: 'City is required' }).trim(),
    country_id: z.number({ required_error: 'Country is required' }),
    id: z.number().int().optional(),
    county: z.string().trim().optional(),
    //id: z.number().int().optional(),
    type: z.number().int().optional(),
    context_id: z.number().int().optional(),
    context: z.string().trim().optional(),
    is_delivery: z.boolean().optional(),
    is_primary: z.boolean().optional(),
    is_billing: z.boolean().optional(),
})

const ContactSchema = z.object({
    first_name: z.string({ required_error: 'First Name is required' }).trim().min(1, { message: 'First Name cannot be empty' }).max(100),
    last_name: z.string({ required_error: 'Last Name is required' }).trim().min(1, { message: 'Last Name cannot be empty' }),
    email: z.string({ required_error: 'Email is Required' }).email({ message: 'Invalid email address' }),
    language_id: z.number({ required_error: 'Language is Required' }).int(),
    mobile_country_code: z.string({ required_error: 'Country code is Required', invalid_type_error: 'Country code must be a number follwed by +' }).min(2).max(4).optional(),
    phone_country_code: z.string({ required_error: 'Country code is Required', invalid_type_error: 'Country code must be a number follwed by +' }).min(2).max(4),
    phone_number: z.string({ required_error: 'Phone Number is Required' }),
    mobile_number: z.string({ required_error: 'Mobile Number is Required' }).optional(),
    gender_id: z.number({ required_error: 'Gender is Required' }).int(),
    company_role: z.number({ required_error: 'Role is Required' }).int(),
    country_id: z.number().int().optional(),
    is_authority: z.boolean().optional(),
    is_primary: z.boolean().optional(),
    is_billing: z.boolean().optional(),
})

export const CustomerSchema = z.object({
    email: z.string({ required_error: 'Email is Required' }).email({ message: 'Invalid email address' }).max(100),
    contact_country_code: z.string({ required_error: 'Country code is Required', invalid_type_error: 'Country code must be a number follwed by +' }).min(2).max(4),
    company: z.string({ required_error: 'Company is Required', invalid_type_error: 'Company must be string' }).trim().min(1, { message: 'Customer cannot be empty' }),
    payment_term_id: z.number({ required_error: 'Payment term is Required' }).int().optional(),
    invoice_type_id: z.string().optional(),
    industry_id: z.number().int().optional(),
    internal_notes: z.string().optional(),
    country_id: z.number({ required_error: 'Country is Required' }).int(),
    vat_number: z.string().optional(),
    contact_number: z.string({ required_error: 'Contact Number is Required' }).min(6),
    image_id: z.number().int().optional(),
    language_id: z.number({ required_error: 'Language is Required' }).int(),
    currency_id: z.number({ required_error: 'Currency is Required' }).int(),
    entity_id: z.number({ required_error: 'Entity is Required' }).int(),
    bad_payment_history: z.number().int().optional(),
    contacts: z.array(ContactSchema),
    addresses: z.array(AddressSchema),
    website: z.string().optional(),
})

export const createCustomer = (payload) => {
    try {
        CustomerSchema.parse(payload)
    }
    catch (error) {
        if (error instanceof z.ZodError) {
            throw new ValidationError("Invalid field values", error.issues.map(({ path, message }) => (
                {
                    path,
                    message
                }
            )))
        }
        throw error
    }
    return axiosInstance.post(ENDPOINT, payload)
}

export const checkCustomerDuplicacy = (field, value) => {
    return axiosInstance.get(generatePath(ENDPOINT_CUSTOMER_UNQIUE, {
        field
    }), {
        params: {
            value
        }
    }).then(({ data }) => data)
}
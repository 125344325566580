import { Alert } from 'antd';
import React from "react";
import style from './template-sent.module.scss';

const TemplateSenttoStudio = () => {
    return <div className={style.container}>
        <h3>Task for you</h3>
        {<Alert message="Template has been sent to studio. Waiting from studio departmen" type="info" />}
    </div>
}

export default TemplateSenttoStudio;
// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { convertDataTypes } from '../../library/helpers';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/finance/purchase-bill/';
const ENDPOINT_GENERAL_ACTION = '/finance/purchase-bill/:action';
const ENDPOINT_SINGLE = '/finance/purchase-bill/:purchaseBillId';
const ENDPOINT_SINGLE_ACTION = '/finance/purchase-bill/:purchaseBillId/:action';
const ENDPOINT_PURCHASEBILL_LINE = '/finance/purchase-bill/:purchaseBillId/line';
const ENDPOINT_PURCHASEBILL_LINE_SINGLE = '/finance/purchase-bill/:purchaseBillId/line/:lineId';
const ENDPOINT_PROJECT_DETAILS = '/finance/purchase-bill/project/:projectId';


export const getPurchaseBills = (payload) => {
    return axiosInstance.get(ENDPOINT_GENERAL, { params: payload })
}

export const getPurchaseBillDetails = (purchaseBillId) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE, {
        purchaseBillId
    })).then(({ data }) => data)
}

export const linkPurchaseBill = (purchaseBillId, vendorId = null, itemId = null) => {
    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE_ACTION, {
        purchaseBillId,
        action: 'link-project'
    }), {
        vendor_id: vendorId,
        item_id: itemId
    }).then(({ message }) => message)
}

export const updatePurchaseBillDetails = (purchaseBillId, data) => {
    const payload = convertDataTypes(data, {
        project_id: parseInt,
        project_item_id: parseInt,
        entity_id: parseInt,
        vendor_id: parseInt,
        item_id: parseInt,
        pass_on_entity_id: parseInt,
        quantity: parseInt,
        currency_id: parseInt,
        payment_method_id: parseInt,
        pass_on: parseInt,
    })
    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE, {
        purchaseBillId
    }), payload).then(({ message }) => message)
}

export const approvePurchaseBill = (purchaseBillId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        purchaseBillId,
        action: 'approved'
    }), {}).then(({ message }) => message)
}

export const revokePurchaseBill = (purchaseBillId) => {
    return axiosInstance.delete(generatePath(ENDPOINT_SINGLE_ACTION, {
        purchaseBillId,
        action: 'approved'
    }), {}).then(({ message }) => message)
}

export const onholdPurchaseBill = (purchaseBillId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        purchaseBillId,
        action: 'onhold'
    }), {}).then(({ message }) => message)
}

export const removeOnholdPurchaseBill = (purchaseBillId) => {
    return axiosInstance.delete(generatePath(ENDPOINT_SINGLE_ACTION, {
        purchaseBillId,
        action: 'onhold'
    }), {}).then(({ message }) => message)
}

export const recordManualPaymentPurchaseBill = (purchaseBillId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        purchaseBillId,
        action: 'manual-payment'
    })).then(({ message }) => message)
}
export const purchaseBillAuditTrail = async (purchaseBillId) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE_ACTION, {
        purchaseBillId,
        action: 'audit-trial'
    }), {}).then(({ data }) => data)
}

export const addPurchaseBillLine = (purchaseBillId, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_PURCHASEBILL_LINE, {
        purchaseBillId,
    }), payload).then(({ message }) => message)
}

export const updatePurchaseBillLine = (purchaseBillId, lineId, data) => {
    return axiosInstance.patch(generatePath(ENDPOINT_PURCHASEBILL_LINE_SINGLE, {
        purchaseBillId,
        lineId
    }), data).then(({ message }) => message)
}

export const deletePurchaseBillLine = (purchaseBillId, lineId) => {
    return axiosInstance.delete(generatePath(ENDPOINT_PURCHASEBILL_LINE_SINGLE, {
        purchaseBillId,
        lineId
    })).then(({ message }) => message)
}


export const getPurchaseBillProjectDetails = (projectId) => {
    return axiosInstance.get(generatePath(ENDPOINT_PROJECT_DETAILS, {
        projectId
    })).then(({ data }) => data)
}

export const updateCustomAmount = (purchaseBillId, discount, customAmountToPay) => {
    let payload = {}
    if (discount) {
        payload.discount = 1
    }
    else {
        payload.custom_amount_to_pay = customAmountToPay
    }
    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE_ACTION, {
        purchaseBillId,
        action: 'custom-amount'
    }), payload).then(({ message }) => message)
}

export const sendToTwinfield = (purchaseBillId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        purchaseBillId,
        action: 'send-to-twinfield'
    })).then(({ message }) => message)
}

export const twinfieldManualUpdate = (purchaseBillId, payload) => {
    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE_ACTION, {
        purchaseBillId,
        action: 'update-twinfield'
    }), payload).then(({ message }) => message)
}

export const generateSepaPaymentFile = (purchaseBilIds) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL_ACTION, {
        action: 'generate-sepa-payment'
    }), {
        ids: purchaseBilIds
    })
}

export const removePurchaseBill = (purchaseBillId) => {
    return axiosInstance.delete(generatePath(ENDPOINT_SINGLE, {
        purchaseBillId,
    }), {}).then(({ message }) => message)
}

//Search form project items , that are sent to vendor
export async function searchVendorSentProjectItems(query, filters) {
    const params = { q: query, ...filters }
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL_ACTION, {
        action: 'search-order-item'
    }), { params })
        .then(({ data }) => data)
}
import { Alert } from 'antd';
import React from "react";
import style from './artwork-rejected.module.scss';

const ArtWorkRejected = () => {
    return <div className={style.container}>
        <h3>Task for you</h3>
        <Alert message="Artwork has been rejected by Leopriting.." type="error" />

    </div>

}

export default ArtWorkRejected;
import { generatePath } from 'react-router-dom';
import { z } from "zod";
import { ValidationError } from '../../library/classes/exceptions/index.js';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/settings/general-ledger';
const ENDPOINT_SINGLE = '/settings/general-ledger/:id';

const GeneralLedgerSchema = z.object({
    name: z.string({
        required_error: "Name is required",
        invalid_type_error: "Name must be a string",
    }).min(1, { message: "Name cannot be empty" }), // For an empty string
    entity_id: z.number({
        required_error: "Entity ID is required",
        invalid_type_error: "Entity ID is invalid",
    }).nullable(),
    category: z.number({
        required_error: "Category is required",
        invalid_type_error: "Category is invalid",
    }),
    vat_category: z.number({
        required_error: "VAT category is required",
        invalid_type_error: "VAT category is invalid",
    }),
    vat_type_id: z.number({
        required_error: "VAT type ID is required",
        invalid_type_error: "VAT type ID is invalid",
    }),
    ledger_text: z.union([z.string(), z.literal(''), z.null()], {
        invalid_type_error: "Ledger text must be a string or null",
    }).optional(), // No need for required_error as it’s optional
    ledger_text_en: z.union([z.string(), z.literal(''), z.null()], {
        invalid_type_error: "Ledger text (EN) must be a string or null",
    }).optional(),
    language: z.union([z.object({}).passthrough(), z.literal(''), z.null()], {
        invalid_type_error: "Language must be an object, an empty string, or null",
    }).optional(),
});

const validateGeneralLedger = (data) => {
    try {
        return GeneralLedgerSchema.parse(Object.fromEntries(
            Object.entries(data).filter(([_, value]) => value !== null)
        ))
    }
    catch (error) {
        if (error instanceof z.ZodError) {
            throw new ValidationError("Invalid field values", error.issues.map(({ path, message }) => (
                {
                    path,
                    message
                }
            )))
        }
        throw error
    }
}

export const getAllGeneralLedgers = (payload) => {
    return axiosInstance.get(ENDPOINT_GENERAL, { params: payload })
}

export const getGeneralLedgerDetails = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE, {
        id
    })).then(({ data }) => data)
}
export const updateGeneralLedger = (id, payload) => {
    validateGeneralLedger(payload)
    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE, {
        id,
    }), payload).then(({ message }) => message)
}

export const createGeneralLedger = (payload) => {
    validateGeneralLedger(payload)
    return axiosInstance.post(ENDPOINT_GENERAL, payload)
}
import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import {
    Button, Form,
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useLoaderData } from 'react-router-dom';
import { convertDataTypes } from '../../../library/helpers/index.js';
import { AccountManagerSelect, CompanySelect, CountrySelect, LanguageSelect } from '../../inputs';

const ProjectFilterForm = ({ children, data, ...otherProps }) => {
    const { meta } = useLoaderData()
    const [form] = Form.useForm()

    const clearForm = () => {
        try {
            form.setFieldsValue(Object.fromEntries(
                Object.keys(form.getFieldsValue()).map(key => ([key, null]))
            ))
            form.submit()
        }
        catch (e) {
            console.log(e);
        }
    }

    return (
        <Form form={form} initialValues={convertDataTypes(data, {
            customer_id: parseInt,
            country_id: parseInt,
            language_id: parseInt,
            account_manager_id: parseInt,
        })} layout='vertical'  {...otherProps}  >
            <Form.Item name="customer_id" label="Customer  ">
                <CompanySelect currentSelection={meta?.filters?.customer_id?.id ? { value: meta.filters.customer_id.id, label: meta.filters.customer_id.name } : null} />
            </Form.Item>
            <Form.Item name="country_id" label="Country">
                <CountrySelect />
            </Form.Item>
            <Form.Item name="language_id" label="Language" >
                <LanguageSelect />
            </Form.Item>
            <Form.Item name="account_manager_id" label="Account Manager" >
                <AccountManagerSelect currentSelection={meta?.filters?.account_manager_id?.id ? { value: meta.filters.account_manager_id.id, label: meta.filters.account_manager_id.name } : null} />
            </Form.Item>
            {children}
            <div className="actions">
                <Button htmlType="submit" type="primary" icon={<FilterOutlined />}>Apply</Button>
                <Button onClick={clearForm} style={{ marginLeft: 20 }} icon={<ReloadOutlined />}>Reset</Button>
            </div>
        </Form>
    )
}

ProjectFilterForm.propTypes = {
    children: PropTypes.node,
    data: PropTypes.object,
}

export default ProjectFilterForm
import { Button, Form, Input, Modal, Space, Switch, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Link, Outlet, useActionData, useBlocker, useLoaderData, useSubmit } from 'react-router-dom';
import { getEmailTemplate, updateEmailTemplate } from '../../api/settings/email-template';
import GrapesJSEditor from '../../components/inputs/email-editor.jsx';
import Page from '../../components/page/page';
import { generateRouteUrl } from '../../library/constants/index.js';
import { cmsLanguageName, supportedLanguages } from '../../library/helpers/language.js';

const EmailTemplateDetails = ({ title }) => {
    const { emailTemplate } = useLoaderData()
    const { errors = null } = useActionData() || {}
    const submit = useSubmit()
    const [isActive, setIsActive] = useState(!!emailTemplate.active)
    const [subject, setSubject] = useState(emailTemplate.subject)
    const editorRef = useRef(null)

    // Block navigating elsewhere when data has been entered into the input
    let blocker = useBlocker(
        ({ currentLocation, nextLocation }) => {
            console.log({
                dirty: editorRef.current.isDirty()
            })
            return currentLocation.pathname !== nextLocation.pathname && editorRef.current.isDirty()
        }
    );


    useEffect(() => {
        if (blocker.state === "blocked") {
            Modal.confirm({
                title: 'Confirmation Required',
                content: 'Any unsaved changes will be lost if you proceed. Do you want to continue?',
                onOk() { blocker.proceed() },
                onCancel() { blocker.reset() },
            });
        }
    }, [blocker.state])

    useEffect(() => {
        setSubject(emailTemplate.subject)
        setIsActive(!!emailTemplate.active)
    }, [emailTemplate])

    const handleSubjectChange = (event) => {
        setSubject(event.target.value)
    }

    const handleActiveChagne = (checked) => {
        setIsActive(!!checked)
    }

    const handleSave = () => {
        const { html, css } = editorRef.current.getHtmlAndCss()
        submit({
            subject: subject,
            active: isActive,
            content: html,
            css: css,
            action: 'update'
        }, {
            replace: true,
            method: 'POST',
            encType: 'application/json'
        })
    }

    return (
        <Page title={title}>
            <h2>{emailTemplate.name}</h2>
            {!!emailTemplate.multilingual && (
                <Space size="small" wrap>
                    {supportedLanguages.map(language => (
                        language === emailTemplate.language ? (
                            <Button key={language} size="small" type={language === emailTemplate.language ? 'primary' : undefined}>{cmsLanguageName(language)}</Button>
                        ) : (
                            <Link key={language} to={generateRouteUrl('EmailTemplateDetails', {
                                id: emailTemplate.id,
                                language
                            })} replace><Button size="small">{cmsLanguageName(language)}</Button></Link>
                        )
                    )
                    )}
                </Space>
            )}
            <Form layout="vertical" errors={errors}>
                <Form.Item label="Active">
                    <Switch checked={isActive} onChange={handleActiveChagne} />
                </Form.Item>
                <Form.Item label="Subject">
                    <Input value={subject} onChange={handleSubjectChange} />
                </Form.Item>
                <Form.Item label="Content">
                    <GrapesJSEditor ref={editorRef} content={emailTemplate.content} cssStyles={emailTemplate.css} sample={emailTemplate.sample} header={!['header', 'footer'].includes(emailTemplate.name)} footer={!['header', 'footer'].includes(emailTemplate.name)} />
                </Form.Item>
                <div className="actions align--left">
                    <Button type="primary" onClick={handleSave}>Save</Button>
                    <Link to={-1}>
                        <Button>Cancel</Button>
                    </Link>
                </div>
            </Form>
            <Outlet />
        </Page>
    )
}

EmailTemplateDetails.Actions = {
    update: async ({ params, data }) => {
        const { id, language } = params
        try {
            const msg = await updateEmailTemplate(id, language, data)
            message.success(msg)
            return true
        }
        catch (error) {
            message.error(error.message)
            return { errors: error.error }
        }
    },
}

EmailTemplateDetails.Loader = async ({ params }) => {
    const { id, language } = params
    const emailTemplate = await getEmailTemplate(id, language)
    return { emailTemplate }
}

EmailTemplateDetails.propTypes = {
    title: PropTypes.string,
}

export default EmailTemplateDetails
import { formatDate } from "../../utilities/intl";

export  const SentCombiColumns = [
    {
        title: 'Combi Project Id',
        dataIndex: 'id',
        sorter: true,
        fixed: 'left',
        render: (id) => `CD_${id}`
    },
    {
        title: 'Item Name',
        dataIndex: 'product',
        sorter: true,
        fixed: 'left',
        render: (product) => product?.name
    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        sorter: true,
        fixed: 'left',
    },
    {
        title: 'Vendor Responded',
        dataIndex: 'vendor',
        sorter: true,
        fixed: 'left',
        render: (vendor) => vendor?.company
    },
    {
        title: 'Request Date',
        dataIndex: 'createdAt',
        sorter: true,
        fixed: 'left',
        render: (createdAt) => formatDate(createdAt)
    },
    {
        title: 'Delivery Deadline Date',
        dataIndex: 'delivery_deadline',
        sorter: true,
        fixed: 'left',
        render: (delivery_deadline) => formatDate(delivery_deadline)
    },
]
// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { formatBackendDate } from '../../library/utilities/intl.js';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/dashboard/:action';

export const getInsightToday = () => axiosInstance.get(generatePath(ENDPOINT_GENERAL, {
    action: 'insight-for-today'
})).then(({ data }) => data)

export const getInsights = (fromDate = null, toDate = null) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL, {
        action: 'insight'
    }), {
        params: {
            date1: fromDate,
            date2: toDate
        }
    }).then(({ data }) => data)
}

export const getTurnoverComparison = (year1, year2) => axiosInstance.get(generatePath(ENDPOINT_GENERAL, {
    action: 'turnover-comparison'
}), {
    params: {
        year1,
        year2
    }
}).then(({ data }) => data)

export const getProjectsComparison = (year1, year2) => axiosInstance.get(generatePath(ENDPOINT_GENERAL, {
    action: 'project-compare-with-previous-year'
}), {
    params: {
        year1,
        year2
    }
}).then(({ data }) => Object.values(data))

export const getSalesLeaderboard = (fromDate = formatBackendDate(new Date().toLocaleDateString()), toDate = formatBackendDate(new Date().toLocaleDateString())) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL, {
        action: 'sales-leader-board'
    }), {
        params: {
            date1: fromDate,
            date2: toDate
        }
    }).then(({ data }) => data)
}

export const getSalesOverYears = () => axiosInstance.get(generatePath(ENDPOINT_GENERAL, {
    action: 'sales-value-over-the-year'
})).then(({ data }) => data)

export const getMonthlyEnquiries = (year) => axiosInstance.get(generatePath(ENDPOINT_GENERAL, {
    action: 'enquiry'
}), {
    params: {
        year1: year
    }
}).then(({ data }) => data.map(v => ({
    ...v,
    total: v.email + v.website + v.phone
})))

export const getEnquiriesConversion = (year) => axiosInstance.get(generatePath(ENDPOINT_GENERAL, {
    action: 'enquiry-converted'
}), {
    params: {
        year1: year
    }
}).then(({ data }) => data)

import {ClockCircleOutlined } from '@ant-design/icons'
import { Alert } from 'antd';
import React from "react";
import style from './proof-sent-to-customer.module.scss';


const ProofSenttoCustomer = () => {
    return <div className={style.container}>
        <h3>Task for you</h3>
        {<Alert message="Proof has been sent to Customer. Waiting for customer's approval" type="info" icon={<ClockCircleOutlined />} />}
    </div>
}

export default ProofSenttoCustomer;
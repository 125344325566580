import { Collapse } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
const { Panel } = Collapse

const ActionCenter = ({ actions = [] }) => {
    const visibleActions = actions && actions.filter(({ isVisible }) => !!isVisible)
    if (!visibleActions || visibleActions.length === 0) {
        return
    }

    return (
        <Collapse defaultActiveKey={['1']} expandIconPosition="end">
            <Panel header='Actions' key="1">
                <div className="actions align--left" style={{ flexWrap: 'wrap' }}>
                    {visibleActions.map(({ action }, index) => (
                        <React.Fragment key={index}>{action}</React.Fragment>
                    ))}
                </div>
            </Panel>
        </Collapse>
    )
}

ActionCenter.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.shape({
        action: PropTypes.node.isRequired,
        isVisible: PropTypes.bool.isRequired
    }))
}

export { ActionCenter };

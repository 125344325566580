import { Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { arraysEqual } from '../../library/utilities/common.js';

function isInteger(value) {
    return /^\d+$/.test(value);
}

// function clamp(value, min, max) {
//     return Math.min(Math.max(value, min), max);
// }

const SelectQuantites = ({ values, max = Number.MAX_SAFE_INTEGER, min = 1, options = [], onChange, ...otherProps }) => {
    const [value, setValue] = useState(values?.map(Number) ?? [])

    useEffect(() => {
        setValue(values?.map(Number) ?? [])
    }, [values])

    return (
        <Select
            placeholder="Select Quantities"
            style={{
                width: '100%'
            }}
            max={max}
            min={min}
            options={options?.map(qty => ({
                label: String(qty),
                value: String(qty),
            }))}
            {...otherProps} //Placed at this location to prevent prop overrides below
            mode="tags"
            value={value?.map(v => v.toString())}
            tokenSeparators={[',', '.', ' ', '|']}
            key="selected_quantities"
            onInputKeyDown={e => {
                if (!isInteger(e.key) && !['Backspace', 'Enter', ',', 'Delete'].includes(e.key)) {
                    e.preventDefault()
                }
            }}
            onChange={selections => {
                /**
                 *Convert to numbers, remove empty, 
                 * less than min or greather than max, and duplicate values.
                 * And then sort the result 
                 */
                const selectedValues = [...new Set((selections ? selections.map(Number) : []).filter(v => (!!v && v >= min && v <= max)).sort((a, b) => a - b))]
                if (!arraysEqual(value, selectedValues)) {
                    setValue(selectedValues)
                    onChange?.(selectedValues)
                }
            }}
        />
    )
}
SelectQuantites.propTypes = {
    values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    min: PropTypes.number.isRequired,
    max: PropTypes.number,
    onChange: PropTypes.func,
}

export { SelectQuantites };


import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import { redirect, useLocation, useRouteLoaderData } from 'react-router-dom';
import { useAuth } from './providers/auth';

export const redirectToLogin = (referrer = '') => {
  let redirectUrl = '/login'

  if (referrer) {
    let params = new URLSearchParams();
    params.set("from", referrer);
    redirectUrl = redirectUrl + "?" + params.toString()
  }
  return redirect(redirectUrl)
}

export default function AuthManager(props) {
  const { children } = props;
  const { user } = useRouteLoaderData('Root');
  const { isPageLoading } = useAuth();
  const location = useLocation()

  let authenticated = !isEmpty(user);
  // // todo: need to remove the line below
  // // marked statically for now, use the presence of user to find authenticated status
  // authenticated = true;

  if (isPageLoading) {
    return (
      <div className="loading-screen">
        Loading...
      </div>
    );
  }


  if (!authenticated) return (
    // <Login onLogin={actions.onLogin} />
    redirectToLogin(location.pathname)
  )
  return children
}

AuthManager.propTypes = {
  children: PropTypes.element,
}

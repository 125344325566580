import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { searchFinishers } from '../../api/vendor/vendor.js';


function FinisherSelect({ defaultValue = null, onChange, currentSelection = {}, ...otherProps }) {
    const debounceTimeout = 800
    const selectedOption = currentSelection?.value && currentSelection.value === defaultValue ? [{ value: currentSelection?.value, label: currentSelection?.label }] : []
    const [fetching, setFetching] = useState(selectedOption);
    const [options, setOptions] = useState();
    const fetchRef = useRef(0);

    useEffect(() => {
        debounceFetcher()
    }, [])

    const debounceFetcher = useMemo(() => {
        const loadOptions = (query) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions(selectedOption);
            setFetching(true);
            searchFinishers(query).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                if (!selectedOption.some(({ value }) => value === currentSelection.value)) {
                    setOptions([...selectedOption, ...newOptions]);
                }
                else {
                    setOptions([...newOptions]);
                }
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [defaultValue, currentSelection, searchFinishers, debounceTimeout]);

    return (
        <Select
            {...otherProps}
            style={{ minWidth: '200px' }}
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            options={options}
            onChange={onChange}
            defaultValue={defaultValue}
            placeholder="Select Finisher"
            showSearch
        />
    );
}

FinisherSelect.propTypes = {
    defaultValue: PropTypes.number,
    onChange: PropTypes.func,
    currentSelection: PropTypes.object
}

export default FinisherSelect

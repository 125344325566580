// import { UserRoles } from "../dynamic.js";

const transformRolesArray = (rolesArray) => {
    const transformedRoles = rolesArray.map(role => {
        let words = role.split('_');
        words = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        return words.join(' ');
    });
    return transformedRoles.join(', ');
};

export const UserColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
    },
    // {
    //     title: 'username',
    //     dataIndex: 'username',
    // },
    {
        title: 'Email',
        dataIndex: 'email',
    },
    {
        title: 'Roles',
        dataIndex: 'roles',
        render: (roles) => transformRolesArray(roles)    }
]
import React from 'react';
import { Link } from 'react-router-dom';
import { findInvoiceStatusById, getFormattedId } from '../../helpers/index.js';
import { textToClassName } from '../../utilities/common.js';
import { convertFromERPCurrency, formatDate, formatDateTime, formatPrice, getTimeAgo } from '../../utilities/intl.js';
import { ERP_CURRENCY } from '../dynamic.js';
import { InvoicePaymentHistoyTypes } from '../invoice-payment-history-types.js';

export const InvoiceColumns = [
    {
        title: 'Project Id',
        dataIndex: 'project_id',
        sorter: true,
        fixed: 'left',
        render: id => getFormattedId(id)
    },
    {
        title: 'Invoice No',
        sorter: true,
        fixed: 'left',
        dataIndex: 'invoice_number'
    },
    {
        title: 'Company',
        dataIndex: 'company',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: (statusId) => {
            const status = findInvoiceStatusById(statusId)
            if (!status) return ''
            return <div className={`status-tag status-tag--${textToClassName(status)}`}>{status}</div>
        },
    },
    {
        title: 'Payment Info',
        dataIndex: 'payment_notes',
        ellipsis: true,
        textWrap: 'word-break',
        render: (val) => (
            <div style={{ display: 'grid', placeItems: 'stretch' }}>
                <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    {val}
                </div>
            </div>
        ),
        width: 200,
    },
    {
        title: 'Executive Entity',
        dataIndex: 'entity_name',
    },
    {
        title: 'Amount Excl. VAT',
        dataIndex: 'total_amount_excl',
        sorter: true,
        render: val => formatPrice(val, ERP_CURRENCY)
    },
    {
        title: 'VAT',
        dataIndex: 'total_amount',
        sorter: false,
        render: (val, { total_amount_excl }) => formatPrice((val - total_amount_excl), ERP_CURRENCY)
    },
    {
        title: 'Amount Incl. VAT',
        dataIndex: 'total_amount',
        sorter: true,
        render: val => formatPrice(val, ERP_CURRENCY)
    },
    {
        title: 'Total Amount Open',
        dataIndex: 'total_amount_open',
        render: val => formatPrice(val, ERP_CURRENCY)
    },
    {
        title: 'Amount Paid',
        dataIndex: 'amount_paid',
        render: val => formatPrice(val, ERP_CURRENCY)
    },
    {
        title: 'Invoice Date',
        dataIndex: 'invoice_date',
        render: date => formatDate(date),
    },
    {
        title: 'Due Date',
        dataIndex: 'expiry_date',
        render: date => formatDate(date),
    },
    {
        title: 'Twinfield Identifier',
        dataIndex: 'twinfield',
    },

]

export const InvoiceColumnsWithoutActions = [

    {
        title: 'Project Id',
        dataIndex: 'project_id',
        width: '200px',
    },
    {
        title: 'Invoice No',
        dataIndex: 'invoice_number',
        width: '10%',
    },
    {
        title: 'Company',
        dataIndex: 'customer',
        render: (customer) => customer?.company
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: findInvoiceStatusById,
    },
    {
        title: 'Payment Notes',
        dataIndex: 'payment_notes',
    },
    {
        title: 'Executive Entity',
        dataIndex: ['entity', 'name'],
    },
    {
        title: 'Amount Excl. VAT',
        dataIndex: 'total_amount_excl',
        render: price => formatPrice(price, ERP_CURRENCY)
    },
    {
        title: 'VAT',
        dataIndex: 'total_amount_excl',
        render: (total_amount_excl, invoice) => formatPrice(invoice.total_amount - total_amount_excl, ERP_CURRENCY)
    },
    {
        title: 'Amount Incl. VAT',
        dataIndex: 'total_amount',
        render: price => formatPrice(price, ERP_CURRENCY)
    },
    {
        title: 'Total Amount Open',
        dataIndex: 'total_amount_open',
        render: (total_amount_open) => formatPrice(total_amount_open, 'EUR')
    },
    {
        title: 'Amount Paid',
        dataIndex: 'amount_paid',
    },
    {
        title: 'Invoice Date',
        dataIndex: 'invoice_date',
        render: formatDate
    },
    {
        title: 'Due Date',
        dataIndex: 'expiry_date',
        render: formatDate
    },
    {
        title: 'Twinfield Identifier',
        dataIndex: 'twinfield_id',
    },

]

export const InvoiceDetailsPaymentLinksColumns = [
    {
        title: 'Expiration Date',
        dataIndex: 'expiration_date',
        render: formatDate
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        render: (val, { conversion_rate, currency }) => {
            if (ERP_CURRENCY === currency) {
                return formatPrice(val, currency)
            }
            return formatPrice(convertFromERPCurrency(val, conversion_rate?.[currency]) ?? 1, currency)
        }
    },
    {
        title: 'Link',
        dataIndex: 'payment_link',
        render: (payment_link) => <Link to={payment_link} target="_blank">Open</Link>,
    },
    {
        title: 'Created At',
        dataIndex: 'createdAt',
        render: getTimeAgo
    },
    {
        title: 'Status',
        dataIndex: 'status_text',
    }
]

export const InvoiceDetailsAuditTrailColumns = [
    {
        title: 'Date',
        dataIndex: 'createdAt',
        render: getTimeAgo
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: status => findInvoiceStatusById(status) ?? ''
    },
    {
        title: 'User',
        dataIndex: ['user', 'name'],
    },
    {
        title: 'Message',
        dataIndex: 'message',
    },
]


export const getInvoiceDetailsPaymentsColumns = (currency) => [
    {
        title: 'Amount',
        dataIndex: 'amount',
        render: (val, record) => {
            if (ERP_CURRENCY === currency) {
                return formatPrice(val, currency)
            }
            return formatPrice(convertFromERPCurrency(val, record.conversion_rate?.[currency]) ?? 1, currency)
        }
    },
    {
        title: 'type',
        dataIndex: 'type',
        render: (val) => InvoicePaymentHistoyTypes[val],
    },
    {
        title: 'Created At',
        dataIndex: 'createdAt',
        render: createdAt => formatDateTime(createdAt),
    },
]

import { GeneralLedgerCategories, VatCategories } from "../general-ledgers.js";


export const LedgersColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Category',
        dataIndex: 'category',
        render: cat => GeneralLedgerCategories[cat]
    },
    {
        title: 'VAT Category',
        dataIndex: 'vat_category',
        render: cat => VatCategories[cat]
    },
    {
        title: 'VAT Type',
        dataIndex: 'vat_type',
        render: vat_type => vat_type?.description
    },
    {
        title: 'Ledget Text',
        dataIndex: 'ledger_text',
    },
    {
        title: 'Ledget Text En',
        dataIndex: 'ledger_text_en',
    },
    {
        title: 'Entity',
        dataIndex: 'entity',
        render: entity => entity?.name
    },
]
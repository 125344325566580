import { Button, Drawer, Input, message } from 'antd';
import React, { useState } from 'react';
import { Form, redirect, useActionData, useNavigate } from 'react-router-dom';
import { createVAT } from '../../api/settings/vatType.js';
import ExecutiveEntitySelect from '../../components/inputs/ExecutiveEntitySelect.js';
import { ValidationError } from '../../library/classes/exceptions/index.js';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { getFieldErrors, getFieldStatus } from '../../library/helpers/forms/index.js';
import { convertDataTypes } from '../../library/helpers/index.js';
import Styles from '../users/user-details.module.scss';

const VATCreate = () => {
    const navigate = useNavigate()
    const { errors = null } = useActionData() || {}
    const [entity, setEntity] = useState()

    return (
        <Drawer open={true} mask={false} title="Create VAT" onClose={() => navigate('../', { replace: true, relative: 'route' })} >
            <Form method="post" className={Styles.formWrapper}>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Entity</label>
                        <ExecutiveEntitySelect
                            value={entity}
                            onChange={(val) => {
                                setEntity(val);
                            }}
                            status={getFieldStatus('entity_id', errors)}
                        />
                        <input type="hidden" name="entity_id" value={entity || ''} />
                        {getFieldErrors('entity_id', errors)}
                    </div>
                </div>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Percentage</label>
                        <Input type="number" name="percentage" status={getFieldStatus('percentage', errors)} />
                        {getFieldErrors('percentage', errors)}
                    </div>
                </div>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Description</label>
                        <Input type="text" name="description" status={getFieldStatus('description', errors)} />
                        {getFieldErrors('description', errors)}
                    </div>
                </div>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Btw Code</label>
                        <Input type="text" name="btw_code" status={getFieldStatus('btw_code', errors)} />
                        {getFieldErrors('btw_code', errors)}
                    </div>
                </div>
                <Button type='primary' htmlType='submit'>Create VAT</Button>
            </Form>
        </Drawer >
    )
}

VATCreate.Action = async ({ request }) => {
    try {
        const data = Object.fromEntries(await request.formData())
        const payload = convertDataTypes(data, {
            entity_id: parseInt,
            percentage: parseFloat,
        })

        const { message: msg, data: vatTypeId } = await createVAT(payload)
        message.success(msg)
        return redirect(generateRouteUrl('VAT', {
            id: vatTypeId
        }), {
            replace: true
        })
    }
    catch (error) {
        message.error(error.message)
        if (error instanceof ValidationError) {
            return {
                errors: error.errors
            }
        }
    }
    return false
}

export default VATCreate
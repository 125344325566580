// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { z } from "zod";
import { ValidationError } from "../../library/classes/exceptions";
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT = '/contact';
const ENDPOINT_SINGLE = '/contact/:id';
const ENDPOINT_CONTACT_UNQIUE = 'contact/unique/:field';

const ContactSchema = z.object({
    first_name: z.string({ required_error: 'First Name is required' }).trim().min(1).max(100),
    last_name: z.string({ required_error: 'Last Name is required' }).trim().min(1),
    email: z.string({ required_error: 'Email is Required' }).email(),
    language_id: z.number({ required_error: 'Language is Required' }),
    mobile_country_code: z.string({  invalid_type_error: 'Country code must be a number follwed by +' }).min(2).max(4).optional(),
    phone_country_code: z.string({ required_error: 'Country code is Required', invalid_type_error: 'Country code must be a number follwed by +' }).min(2).max(4),
    phone_number: z.string({ required_error: 'Phone Number is Required' }).optional(),
    mobile_number: z.string({ required_error: 'Mobile Number is Required' }).optional().nullable(),
    gender_id: z.number({ required_error: 'Gender is Required' }),
    company_role: z.number({ required_error: 'Role is Required' }).int(),
    // customer_id: z.number().int()

})

// Define all api functions here
export const getAlContacts = (payload) => {
    return axiosInstance.get(ENDPOINT, { params: payload })
}

// export const createContact = (payload) => {
//     payload = pick(payload, ["first_name", "last_name", "email", "customer_id", "language_id", "phone_country_code", "mobile_country_code", "phone_number", "mobile_number", "gender_id", "country_id", "role_id"])
//     return axiosInstance.post(ENDPOINT, payload)
// }

export const createContact = (payload) => {
    try {
        ContactSchema.parse(payload)
    }
    catch (error) {
        if (error instanceof z.ZodError) {
            throw new ValidationError('Validation Error', error.issues.map(({ path, message }) => (
                {
                    path,
                    message
                }
            )))
        }
        throw error
    }
    return axiosInstance.post(ENDPOINT, payload)
}

export const makeContact = (payload) => {
    console.log(payload);
    try {
        ContactSchema.parse(payload)
    }
    catch (error) {
        if (error instanceof z.ZodError) {
            throw new ValidationError(`Error`, error.issues.map(({ path, message }) => (
                {
                    path,
                    message
                }
            )))
        }
        throw error
    }
    return axiosInstance.post(ENDPOINT, payload)
}

export const updateContact = (id, payload) => {
    try {
        ContactSchema.parse(payload);
    }
    catch (error) {
        if (error instanceof z.ZodError) {
            throw new ValidationError(`Error`, error.issues.map(({ path, message }) => (
                {
                    path,
                    message
                }
            )))
        }
        throw error
    }
    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE, {
        id
    }), payload)
}

export const checkContactDuplicacy = (field, value) => {
    return axiosInstance.get(generatePath(ENDPOINT_CONTACT_UNQIUE, {
        field
    }), {
        params: {
            value
        }
    }).then(({ data }) => data)
}
import { Select } from 'antd';
// import { DownSquareOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { Server } from '../../library/constants/server.js';
import Countries from '../../library/dynamics/countries';
import styles from './CountrySelect.module.scss';
const { Option } = Select

function CountrySelect({ placeholder, value = null, onChange, ...otherProps }) {
    return (
        <Select 
            placeholder={placeholder}
            optionFilterProp="name"
            optionLabelProp="label"
            autoComplete="none"
            onChange={(val) => onChange(val, Countries.find(({ id }) => id === val))}
            value={value ?? undefined}
            showSearch
            {...otherProps}>
            {Countries.map((country) => {
                return (
                    <Option key={country.id} name={country.name} value={country.id} label={country.name}>
                        <div className={styles.option}>
                            <span role="img" aria-label={country.name}>
                                <img src={`${Server.BaseUrl}/${country.flag_url}`} alt={country.name} title={country.name} />
                            </span>
                            {country.name}
                        </div>
                    </Option>
                )
            })}
        </Select>
    );
}

CountrySelect.propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func
}

export default CountrySelect;
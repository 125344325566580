import { Alert, Button } from 'antd';
import React from "react";
import { Link, useOutletContext, useRouteLoaderData } from 'react-router-dom';
import { generateRouteUrl } from '../../../library/constants';
import style from './proof-received.module.scss';

const ProofReceived = () => {
    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')
    const { vendorRole } = useOutletContext()
    return (
        <div className={style.container}>
            <h3>Task for you</h3>
            <Alert message={`${vendorRole} has created printable final proof. Send it to customer for approval`} type="info"
                action={
                    <Link to={generateRouteUrl('SendProofToCustomer', {
                        itemId: orderDetails.id,
                    })}>
                        <Button className={style.greenButton}>View Final Proof</Button>
                    </Link>

                } />
        </div>
    )
}

export default ProofReceived;
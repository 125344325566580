import { Tabs as TabsAtnd } from 'antd';
import PropTypes from 'prop-types';
import React from "react";
import StickyBox from 'react-sticky-box';


const renderTabBar = (props, DefaultTabBar) => {
    if (!props.sticky) {
        <DefaultTabBar
            {...props}
            style={{
                background: 'transparent',
            }}
        />
    }
    return (
        <StickyBox
            offsetTop={0}
            offsetBottom={20}
            style={{
                zIndex: 1,
            }}
        >
            <DefaultTabBar
                {...props}
                style={{
                    background: 'transparent',
                }}
            />
        </StickyBox>
    )
}
const Tabs = ({ list }) => {
    return (<TabsAtnd defaultActiveKey="1" renderTabBar={renderTabBar} items={list} animated={{
        inkBar: false,
        tabPane: false
    }} />)

}

Tabs.propTypes = {
    list: PropTypes.array,
    // stateChanger :PropTypes.func
}

export default Tabs
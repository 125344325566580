import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/settings/vat-type';
const ENDPOINT_SINGLE = '/settings/vat-type/:id';

export const getVatList = (payload) => {
    return axiosInstance.get(ENDPOINT_GENERAL, { params: payload })
}
export const getVatDetails = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE, {
        id
    })).then(({ data }) => data)
}
export const updateVAT = (id, payload) => {
    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE, {
        id,
    }), payload).then(({ message }) => message)
}

export const createVAT = (payload) => {
    return axiosInstance.post(ENDPOINT_GENERAL, payload)
}
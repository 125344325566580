import React from "react";
import styles from './itemSpecifications.module.scss';
import PropTypes from 'prop-types';
import { Image } from "../../ui";
const StudioItemDetails = ({ photo, specs }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.left}>
                <div className={styles.image}>
                    <Image src={`https://leoprinting.nl/files/photos/${photo}`}
                    />
                </div>
                <p className={styles.imageTag}>Image is for illustration.Real product may vary</p>
            </div>
            <div className={styles.right}>

                <h3>Specifications :</h3>
                <div className={styles.specifications}>

                    {specs.map(({ name, value }) => (
                        <React.Fragment key={name}>
                            <div className={styles.name}>{name}</div>
                            <div className={styles.value}>{value}</div>
                        </React.Fragment>
                    ))}
                </div>

            </div>
        </div>
    );
}

StudioItemDetails.propTypes = {
    photo: PropTypes.string,
    specs: PropTypes.array
}
export default StudioItemDetails
import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    FormOutlined
} from '@ant-design/icons';
import { Button, Collapse, Input, InputNumber, Modal, Select } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Form, Link, Navigate, useFetcher, useNavigate, useRouteLoaderData } from 'react-router-dom';
import { useInvoiceLineTotals } from '../../../hooks/use-invoiceline-totals.js';
import { generateRouteUrl } from '../../../library/constants';
import { GeneralLedgers, VatTypes } from '../../../library/constants/dynamic.js';
import { getFieldErrors, getFieldStatus } from '../../../library/helpers/forms';
import { InvoiceStatuses } from '../../../library/helpers/invoice-status.js';
import { formatPrice } from '../../../library/utilities/intl.js';
import { SmallSection } from '../../design';
import { Currency } from '../../inputs';
import Confirm from '../../message/Confirm.jsx';
import Styles from './invoice-line.module.scss';
const { Panel } = Collapse
moment.locale('nl')

const InvoiceLines = ({ invoiceId, invoiceLines, currency, invoiceStatus, totals, entity, isCreditNote }) => {
    const [isEditable, setIsEditable] = useState(null)
    const fetcher = useFetcher()
    useEffect(() => {
        if (fetcher.data === true) {
            setIsEditable(false)
        }
    }, [fetcher])
    const isInvoiceSent = invoiceStatus.id >= InvoiceStatuses.SEND_TO_CLIENT

    return (
        <Collapse defaultActiveKey={['1']} expandIconPosition="end">
            <Panel header='Financial Information' key="1">
                {invoiceLines.map(line => (
                    <React.Fragment key={line.id}>
                        {isEditable && isEditable === line.id ? (
                            <EditInvoiceLine
                                entity={entity}
                                invoiceLine={line}
                                currency={currency}
                                isInvoiceSent={isInvoiceSent}
                                isCreditNote={isCreditNote}
                                errors={fetcher.data?.errors}
                                onSave={(form) => {
                                    fetcher.submit(form, {
                                        method: 'post',
                                        action: generateRouteUrl('InvoiceDetails', { invoiceId })
                                    })
                                }}
                                onCancel={() => setIsEditable(null)}
                            />
                        ) : (
                            <InvoiceLine
                                invoiceLine={line}
                                currency={currency}
                                isInvoiceSent={isInvoiceSent}
                                onEdit={() => setIsEditable(line.id)}
                            />

                        )}
                    </React.Fragment>
                ))}
                {invoiceLines?.length > 0 && (
                    <div className={`${Styles.fieldsWrapper} ${Styles.invoiceTotals}`}>
                        <div className={Styles.description} style={{ gridColumnStart: 4 }}>
                            <div className={Styles.DescriptionTitle} style={{ textWrap: 'initial' }}>Subtotal</div>
                            <div className={Styles.DescriptionValue}>{formatPrice(totals.total_amount_excl, currency.code)}</div>
                        </div>
                        {invoiceLines.map(line => (
                            <div key={line.id} className={Styles.description} style={{ gridColumnStart: 4 }}>
                                <div className={Styles.DescriptionTitle} style={{ textWrap: 'initial' }}>VAT {line.vat_type?.percentage}% ({line.vat_type?.description})</div>
                                <div className={Styles.DescriptionValue}>{formatPrice(line.total_vat, currency.code)}</div>
                            </div>
                        ))}
                        <div className={Styles.description} style={{ gridColumnStart: 4 }}>
                            <div className={Styles.DescriptionTitle} style={{ textWrap: 'initial' }}><strong>Total Incl. VAT</strong></div>
                            <div className={Styles.DescriptionValue}>{formatPrice(totals.total_amount, currency.code)}</div>
                        </div>
                    </div>
                )}
                {!isInvoiceSent && !isEditable && (
                    <div className={Styles.actionsWrapper}>
                        <Link type="button" to={generateRouteUrl('AddInvoiceLine', {
                            invoiceId
                        })} replace><Button type='primary' ghost>Add Line</Button></Link>
                    </div>
                )}
            </Panel>
        </Collapse>
    )
}
InvoiceLines.propTypes = {
    invoiceId: PropTypes.number.isRequired,
    invoiceLines: PropTypes.arrayOf(PropTypes.object).isRequired,
    currency: PropTypes.shape({
        code: PropTypes.oneOf(['EUR', 'GBP', 'USD']).isRequired
    }),
    invoiceStatus: PropTypes.object.isRequired,
    entity: PropTypes.object.isRequired,
    totals: PropTypes.shape({
        // total_amount_converted: PropTypes.number.isRequired,
        total_amount_excl: PropTypes.number.isRequired,
        // total_amount_excl_converted: PropTypes.number.isRequired,
        total_amount: PropTypes.number.isRequired,
    }).isRequired,
    isCreditNote: PropTypes.bool
}

const InvoiceLine = ({ currency, invoiceLine, isInvoiceSent, onEdit }) => {

    const fetcher = useFetcher()

    return (
        <div className={Styles.mainWrapper}>
            <div className={Styles['mainWrapper-Header']}>
                <div className="actions">
                    <Button icon={<FormOutlined />} onClick={onEdit} type='actionpanel' shape='square' danger ghost />
                    {!isInvoiceSent && <Confirm content="Are you sure to delete this line ?" icon={<DeleteOutlined />} onConfirm={() => {
                        fetcher.submit({
                            action: "deleteInvoiceLineItem",
                            lineId: invoiceLine.id
                        }, {
                            method: "POST",
                            encType: "application/json"
                        })
                    }} type='actionpanel' shape='square' danger ghost />}
                </div>
            </div>
            <SmallSection style={SmallSection.Styles.FINANCE}>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.description} style={{ gridColumn: 'span 2' }}>
                        <div className={Styles.DescriptionTitle}>Item Description</div>
                        <div className={Styles.DescriptionValue}>{invoiceLine.description}</div>
                    </div>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>Quantity</div>
                        <div className={Styles.DescriptionValue}>{invoiceLine.quantity}</div>
                    </div>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>Unit Price</div>
                        <div className={Styles.DescriptionValue}>{formatPrice(invoiceLine.unit_price, currency.code)}</div>
                    </div>
                    <div className={Styles.description} style={{ gridColumn: 'span 2' }}>
                        <div className={Styles.DescriptionTitle}>Add Description</div>
                        <div className={Styles.DescriptionValue}>{invoiceLine.additional_description}</div>
                    </div>
                    <div className={Styles.description} >
                        <div className={Styles.DescriptionTitle}>General Ledger</div>
                        <div className={Styles.DescriptionValue}>{invoiceLine?.general_ledger?.name || 'NA'}</div>
                    </div>
                </div>
            </SmallSection>
            <SmallSection style={SmallSection.Styles.FINANCE}>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.description} style={{ gridColumn: 'span 2' }}>
                        <div className={Styles.DescriptionTitle}>Total Price (Excl. VAT)</div>
                        <div className={Styles.DescriptionValue}>{formatPrice(invoiceLine.total_price_excl_vat, currency.code)}</div>
                    </div>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>Visible VAT</div>
                        <div className={Styles.DescriptionValue}>{invoiceLine?.vat_type?.description}</div>
                    </div>
                    <div className={Styles.description}>
                        <div className={Styles.DescriptionTitle}>Total VAT</div>
                        <div className={Styles.DescriptionValue}>{formatPrice(invoiceLine.total_vat, currency.code)}</div>
                    </div>
                    <div className={Styles.description} style={{ gridColumn: 'span 2' }}>
                        <div className={Styles.DescriptionTitle}>Total Price (Incl. VAT)</div>
                        <div className={Styles.DescriptionValue}>{formatPrice(invoiceLine.total_price_incl_vat, currency.code)}</div>
                    </div>
                </div>
            </SmallSection>
        </div>
    )
}
InvoiceLine.propTypes = {
    currency: PropTypes.shape({
        code: PropTypes.oneOf(['EUR', 'GBP', 'USD']).isRequired
    }).isRequired,
    invoiceLine: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    isInvoiceSent: PropTypes.bool.isRequired,
}

const EditInvoiceLine = ({ entity, invoiceLine, currency, isInvoiceSent, isCreditNote, errors = null, onSave, onCancel }) => {
    const {
        quantity,
        setQuantity,
        totalPriceExclVat,
        setTotalPriceExclVat,
        visibleVatId,
        setVisibleVatId,
        totalPriceInclVat,
        totalVat,
        unitPrice,
        generalLedger
    } = useInvoiceLineTotals(invoiceLine.quantity, invoiceLine.total_price_excl_vat, invoiceLine.visible_vat, invoiceLine.general_ledger_id)

    return (
        <Form method="post" className={Styles.formWrapper} onSubmit={(e) => {
            e.preventDefault()
            onSave(e.currentTarget)
        }}>
            <input type="hidden" name="action" value="updateInvoiceLine" />
            <input type="hidden" name="invoiceLineId" value={invoiceLine.id} />

            <div className={Styles.mainWrapper}>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField} style={{ gridColumn: 'span 2' }}>
                        <label>Item Description</label>
                        <Input name="description" defaultValue={invoiceLine.description} status={getFieldStatus('description', errors)} />
                        {getFieldErrors('description', errors)}
                    </div>
                    <div className={Styles.inputField}>
                        <label>Quantity</label>
                        <InputNumber name="quantity" className={Styles.wFull} defaultValue={quantity} controls={false} onChange={v => setQuantity(v)} status={getFieldStatus('quantity', errors)} disabled={isInvoiceSent} />
                        {getFieldErrors('quantity', errors)}
                    </div>
                    <div className={Styles.inputField}>
                        <label>Unit Price</label>
                        <Currency currency={currency.code} prefix={isCreditNote ? '-' : null} className={Styles.wFull} value={unitPrice} disabled />
                    </div>
                </div>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField} style={{ gridColumn: 'span 2' }}>
                        <label>Add Description</label>
                        <Input name="additional_description" defaultValue={invoiceLine.additional_description} status={getFieldStatus('additional_description', errors)} />
                        {getFieldErrors('additional_description', errors)}
                    </div>
                    <div className={Styles.inputField} style={{ gridColumn: 'span 2' }}>
                        <label>General Ledger</label>
                        <Select
                            options={GeneralLedgers}
                            fieldNames={{ value: 'id', label: 'name' }}
                            value={generalLedger}
                            className="always-visible"
                            disabled
                        />
                    </div>
                </div>
            </div>
            <div className={Styles.mainWrapper}>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Total Price (Excl. VAT)</label>
                        <Currency currency={currency.code} name="total_price_excl_vat" prefix={isCreditNote ? '-' : null} className={Styles.wFull} defaultValue={totalPriceExclVat} onChange={v => setTotalPriceExclVat(v)} status={getFieldStatus('total_price_excl_vat', errors)} disabled={isInvoiceSent} />
                        {getFieldErrors('total_price_excl_vat', errors)}
                    </div>
                    <div className={Styles.inputField}>
                        <label>Visible VAT</label>
                        <Select
                            options={VatTypes.filter(({ entity_id }) => entity_id === entity.id)}
                            fieldNames={{ value: 'id', label: 'description' }}
                            defaultValue={visibleVatId}
                            onChange={c => setVisibleVatId(c)}
                            status={getFieldStatus('visible_vat', errors)}
                            disabled={isInvoiceSent}
                        />
                        <input type="hidden" name="visible_vat" value={visibleVatId} disabled={isInvoiceSent} />
                        {getFieldErrors('visible_vat', errors)}
                    </div>
                    <div className={Styles.inputField}>
                        <label>Total VAT</label>
                        <Currency currency={currency.code} prefix={isCreditNote ? '-' : null} className={Styles.wFull} value={totalVat} disabled />
                    </div>
                    <div className={Styles.inputField} style={{ gridColumn: 'span 2' }}>
                        <label>Total Price (Inl. VAT)</label>
                        <Currency currency={currency.code} prefix={isCreditNote ? '-' : null} className={Styles.wFull} value={totalPriceInclVat} disabled />
                    </div>
                </div>
            </div>
            <div className={Styles.actionsWrapper}>
                <Button type="danger" icon={<CloseOutlined />} onClick={onCancel}>Cancel</Button>
                <Button type="primary" icon={<CheckOutlined />} htmlType="submit">Save</Button>
            </div>
        </Form>
    )
}
EditInvoiceLine.propTypes = {
    entity: PropTypes.object.isRequired,
    invoiceLine: PropTypes.object.isRequired,
    currency: PropTypes.shape({
        code: PropTypes.oneOf(['EUR', 'GBP', 'USD']).isRequired
    }).isRequired,
    isInvoiceSent: PropTypes.bool.isRequired,
    isCreditNote: PropTypes.bool,
    errors: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

const AddInvoiceLine = () => {
    const { invoiceId, invoiceDetails } = useRouteLoaderData('InvoiceDetails')
    const navigate = useNavigate()
    const fetcher = useFetcher();
    const { errors = null } = fetcher.data || {}
    const formRef = useRef()
    const {
        quantity,
        setQuantity,
        totalPriceExclVat,
        setTotalPriceExclVat,
        visibleVatId,
        setVisibleVatId,
        totalPriceInclVat,
        totalVat,
        unitPrice,
        generalLedger
    } = useInvoiceLineTotals()
    const isCreditNote = !!invoiceDetails.credit_note

    if (invoiceDetails.status?.id !== InvoiceStatuses.NEW) {
        return <Navigate to={'../'} replace={true} />
    }

    return (
        <Modal
            title="Add new Item"
            open={true}
            onCancel={() => navigate('../', { replace: true })}
            width='900px'
            forceRender
            destroyOnClose
            footer={
                <div className={Styles.actionsWrapper}>
                    <Button type="primary" icon={<CheckOutlined />} onClick={() => {
                        fetcher.submit(formRef.current)
                    }} >Save</Button>
                    <Button type="cancel" icon={<CloseOutlined />} onClick={() => navigate('../', { replace: true })}>Cancel</Button>
                </div>
            }>
            <Form method="post" className={Styles.formWrapper} ref={formRef} action={generateRouteUrl('InvoiceDetails', { invoiceId })} replace>
                <input type="hidden" name="action" value="addInvoiceLine" />
                <div className={Styles.mainWrapper}>
                    <div className={Styles.fieldsWrapper}>
                        <div className={Styles.inputField} style={{ gridColumn: 'span 2' }}>
                            <label>Item Description</label>
                            <Input name="description" status={getFieldStatus('description', errors)} />
                            {getFieldErrors('description', errors)}
                        </div>
                        <div className={Styles.inputField}>
                            <label>Quantity</label>
                            <InputNumber name="quantity" defaultValue={quantity} controls={false} onChange={v => setQuantity(v)} status={getFieldStatus('quantity', errors)} />
                            {getFieldErrors('quantity', errors)}
                        </div>
                        <div className={Styles.inputField}>
                            <label>Unit Price</label>
                            <Currency currency={invoiceDetails.currency.code} prefix={isCreditNote ? '-' : null} value={unitPrice} disabled />
                        </div>
                        <div className={Styles.inputField} style={{ gridColumn: 'span 2' }}>
                            <label>Add Description</label>
                            <Input name="additional_description" status={getFieldStatus('additional_description', errors)} />
                            {getFieldErrors('additional_description', errors)}
                        </div>
                        <div className={Styles.inputField}>
                            <label>General Ledger</label>
                            <Select
                                options={GeneralLedgers}
                                fieldNames={{ value: 'id', label: 'name' }}
                                value={generalLedger}
                                className="always-visible"
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className={Styles.mainWrapper}>
                    <div className={Styles.fieldsWrapper}>
                        <div className={Styles.inputField}>
                            <label>Total Price (Excl. VAT)</label>
                            <Currency currency={invoiceDetails.currency.code} name="total_price_excl_vat" prefix={isCreditNote ? '-' : null} defaultValue={totalPriceExclVat} onChange={v => setTotalPriceExclVat(v)} status={getFieldStatus('total_price_excl_vat', errors)} />
                            {getFieldErrors('total_price_excl_vat', errors)}
                        </div>
                        <div className={Styles.inputField}>
                            <label>Visible VAT</label>
                            <Select
                                options={VatTypes.filter(({ entity_id }) => entity_id === invoiceDetails.entity.id)}
                                fieldNames={{ value: 'id', label: 'description' }}
                                defaultValue={visibleVatId}
                                onChange={c => setVisibleVatId(c)}
                                status={getFieldStatus('visible_vat', errors)}
                            />
                            <input type="hidden" name="visible_vat" value={visibleVatId} />
                            {getFieldErrors('visible_vat', errors)}
                        </div>
                        <div className={Styles.inputField} style={{ gridColumn: 'span 2' }}>
                            <label>Total VAT</label>
                            <Currency currency={invoiceDetails.currency.code} prefix={isCreditNote ? '-' : null} value={totalVat} disabled />
                        </div>
                        <div className={Styles.inputField}>
                            <label>Total Price (Inl. VAT)</label>
                            <Currency currency={invoiceDetails.currency.code} prefix={isCreditNote ? '-' : null} value={totalPriceInclVat} disabled />
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    )
}

export { AddInvoiceLine, InvoiceLines };


export const VendorItemPriceStatuses = Object.freeze({
    ACTIVE: 1,
    VENDOR_QUOTATION_SEND: 2,
    VENDOR_QUOTATION_MANUALLY: 3,
    VENDOR_QUOTATION_RECEIVED: 4,
    USED_FOR_QUOTATION: 5,
    NOT_USED_FOR_QUOTATION: 6,
    APPROVED: 7,
    SELECTED_FOR_PRODUCTION: 8,
    UNSELECTED: 9,
})

export const ItemPriceVendorQuotationStatus = {
    VENDOR_QUOTATION_SENT: 1,
    VENDOR_QUOTATION_RECEIVED: 2,
    VENDOR_REQUESTED_VISUAL: 3,
    VISUAL_SENT: 4
}

export const getVendorQuotationStatus = (status) => {
    const texts = {
        [ItemPriceVendorQuotationStatus.VENDOR_QUOTATION_SENT]: "Quotation Request Sent",
        [ItemPriceVendorQuotationStatus.VENDOR_QUOTATION_RECEIVED]: "Quotation Recieved",
        [ItemPriceVendorQuotationStatus.VENDOR_REQUESTED_VISUAL]: "Vendor Requested Visual",
        [ItemPriceVendorQuotationStatus.VISUAL_SENT]: "Visual Sent to Vendor"
    }
    return texts?.[status] ?? ''
}
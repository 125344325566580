import { ClockCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { Alert, Button, Result, message } from 'antd';
import PropTypes from 'prop-types';
import React from "react";
import { useFetcher, useOutletContext, useRouteLoaderData } from 'react-router-dom';
import { requestVendorDispatchDate, updateOrderExpectedDispatchDates } from "../../../api/order";
import { Box } from '../../../components/design/box.js';
import { DatePicker, FieldWrapper, InputWrapper } from "../../../components/inputs";
import { ValidationError } from '../../../library/classes/exceptions/index.js';
import { generateRouteUrl } from '../../../library/constants/routes.js';
import { OrderSupplierStatuses } from '../../../library/constants/steps/supplier-order-manager-steps.js';
import { convertDataTypes } from '../../../library/helpers/index.js';
import { convertToNested } from '../../../library/utilities/formdata.js';
import { getTimeAgo } from '../../../library/utilities/intl.js';
import { getFilePath } from '../../../library/utilities/urls.js';
import styles from "./in-production.module.scss";

const ShipmentExpectedDispatchDate = ({ projectId, itemId, shipments, deliveryType = null, buttonText = "Save dates & Update Customer" }) => {
    const fetcher = useFetcher()
    const { errors = {} } = fetcher.data || {}
    const getAddress = (shipment) => {
        if (deliveryType === 3) {
            return <Button href={getFilePath(shipment.bulk_address_file.url, projectId)} icon={<DownloadOutlined />}>Download</Button>
        }
        else {
            return <>{shipment.street_number},{shipment.street},{shipment.city}</>
        }
    }

    return (
        <fetcher.Form method="post" action={generateRouteUrl('InProduction', {
            itemId
        })}>
            <Box header={
                <div className={deliveryType === 3 ? styles.deliveryRowHeadWithComment : styles.deliveryRowHead}>
                    <div>Quantity</div>
                    <div>Delivery Address</div>
                    <div>Expected Dispatch Date</div>
                    {deliveryType === 3 && (
                        <div>
                            Comment
                        </div>
                    )}
                    <div></div>
                </div>
            }>
                <input type="hidden" name="action" value="sendDispatchInfo" />
                {shipments && shipments.map((shipment, index) => (
                    <React.Fragment key={shipment.id}>
                        <div className={deliveryType === 3 ? styles.deliveryRowWithComment : styles.deliveryRow}>
                            <input type="hidden" name={`[${index}]shipping_id`} value={shipment.id} />
                            <div className="quantity">{shipment.quantity}</div>
                            <div className="address">{getAddress(shipment)}</div>
                            <div className="field-expected_dispatch_date">
                                <FieldWrapper name={`[${index}]dispatch_date`} errors={errors} >
                                    <DatePicker name={`[${index}]dispatch_date`} value={shipment.dispatch_date} useHidden />
                                </FieldWrapper>
                            </div>
                            {deliveryType === 3 && (
                                <div>
                                    <InputWrapper name={`[${index}]dispatch_date_comment`} defaultValue={shipment.dispatch_date_comment} textarea errors={errors} />
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                ))}
                <div className="actions align--right">
                    <Button type='primary' htmlType='submit'>{buttonText}</Button>
                </div>
            </Box>
        </fetcher.Form >
    )
}

ShipmentExpectedDispatchDate.propTypes = {
    projectId: PropTypes.number.isRequired,
    itemId: PropTypes.number.isRequired,
    shipments: PropTypes.arrayOf(PropTypes.object).isRequired,
    deliveryType: PropTypes.oneOf([1, 2, 3]),
    buttonText: PropTypes.string
}

const InProduction = () => {
    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')
    const fetcher = useFetcher()
    const { isFinisher, vendorRole } = useOutletContext()

    //If finisher time and product is not delivered to finisher
    if (isFinisher && orderDetails.order_delivery_status !== OrderSupplierStatuses.DELIVERED) {
        return (
            <div className={styles.container}>
                <Result
                    status="warning"
                    icon={<ClockCircleOutlined />}
                    title="Product not delivered to finisher"
                    subTitle="Finisher is waiting for the products to be delivered."
                />
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <h3>Task For You</h3>
            {isFinisher ? (
                <Alert message={`${vendorRole} has recieved the products and started the Production. Total Quantity Recieved by ${vendorRole} is ${orderDetails.item_finisher_address?.ship_quantity}`} type="info" showIcon />
            ) : (
                <Alert message={`${vendorRole} has started the Production`} type="info" showIcon />
            )}
            <Alert message={`Ask ${vendorRole}`} description={!orderDetails.notify_vendor_for_expected_time ? `Ask ${vendorRole} to provide an expected transit date of the order` : `${vendorRole} has been notified to share expected transit date ${getTimeAgo(orderDetails.notify_vendor_for_expected_time)}`} type="info" action={
                <Button onClick={() => {
                    fetcher.submit({
                        action: 'askVendorDispatchDate'
                    }, {
                        method: 'post'
                    })
                }} loading={fetcher.state !== 'idle'}>
                    {!orderDetails.delivery_notify_vendor_for_expected_time ? `Ask ${vendorRole}` : 'Send Reminder'}
                </Button>
            } showIcon />
            <ShipmentExpectedDispatchDate projectId={orderDetails.project_id} itemId={orderDetails.id} deliveryType={orderDetails.delivery_type} shipments={orderDetails.item_delivery_address} />
        </div>
    )
}

InProduction.Actions = {
    askVendorDispatchDate: async ({ params }) => {
        const { itemId } = params
        try {
            const msg = await requestVendorDispatchDate(itemId)
            message.success(msg);
            return true
        }
        catch (error) {
            message.error(error.message)
        }
        return false
    },
    sendDispatchInfo: async ({ params, data }) => {
        const { itemId } = params
        try {
            const shipmentDetails = convertToNested(data)?.map(shipment => convertDataTypes(shipment, {
                shipping_id: parseInt,
            })).filter(({ dispatch_date }) => dispatch_date.length)
            console.log({ shipmentDetails })
            const msg = await updateOrderExpectedDispatchDates(itemId, shipmentDetails)
            message.success(msg);
            return true
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
        }
        return false
    },

}

export { InProduction, ShipmentExpectedDispatchDate };

import {
    CheckOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import { Button, InputNumber, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFetcher } from 'react-router-dom';
import { SmallSection } from '../../components/design';
import { Currency, FieldWrapper, InputWrapper, SelectInput } from '../../components/inputs';
import Confirm from '../../components/message/Confirm.jsx';
import { usePurchasebillLineTotals } from '../../hooks/use-purchasebill-line-total.js';
import { GeneralLedgers, VatTypes } from '../../library/constants/dynamic.js';
import { getFieldStatus } from '../../library/helpers/forms/index.js';
import { formatPrice } from '../../library/utilities/intl.js';
import Styles from './purchasebills-lines.module.scss';

const LineTypes = {
    VENDOR: 1,
    FINISHER: 2,
    TRANSPORT: 3
}

const PurchaseBillLineEdit = ({ line, currency, entity_id, errors = {} }) => {
    const { totalPriceExclVat,
        setTotalPriceExclVat,
        visibleVatId,
        setVisibleVatId,
        totalPriceInclVat } = usePurchasebillLineTotals(line.price_excl_vat, line.vat_type_id)
    return (
        <div className={Styles.ledgerLine}>
            <input type="hidden" name={`[purchaseLine][${line.id}]id`} value={line.id} />
            <div></div>
            <InputWrapper name={`[purchaseLine][${line.id}]item_text`} defaultValue={line.item_text} errors={errors} errorKey="item_text" />
            <FieldWrapper name={`[purchaseLine][${line.id}]general_ledger_id`} errors={errors} errorKey="general_ledger_id">
                <SelectInput
                    name={`[purchaseLine][${line.id}]general_ledger_id`}
                    options={GeneralLedgers}
                    fieldNames={{ value: 'id', label: 'name' }}
                    defaultValue={line.general_ledger_id}
                    status={getFieldStatus('general_ledger_id', errors)}
                />
            </FieldWrapper>
            <FieldWrapper name={`[purchaseLine][${line.id}]quantity`} errors={errors} errorKey="quantity">
                <InputNumber name={`[purchaseLine][${line.id}]quantity`} defaultValue={line.quantity} />
            </FieldWrapper>
            <FieldWrapper name={`[purchaseLine][${line.id}]price_excl_vat`} errors={errors} errorKey="price_excl_vat">
                <Currency name={`[purchaseLine][${line.id}]price_excl_vat`} currency={currency.code} value={totalPriceExclVat} onChange={(val) => setTotalPriceExclVat(val)} />
            </FieldWrapper>
            <FieldWrapper name={`[purchaseLine][${line.id}]vat_type_id`} errors={errors} errorKey="vat_type_id">
                <Select
                    options={VatTypes.filter((vat) => vat.entity_id === entity_id)}
                    fieldNames={{ value: 'id', label: 'description' }}
                    defaultValue={visibleVatId}
                    onChange={val => setVisibleVatId(val)}
                    status={getFieldStatus('vat_type_id', errors)}
                />
                <input type="hidden" name={`[purchaseLine][${line.id}]vat_type_id`} value={visibleVatId} />
            </FieldWrapper>
            <div>{formatPrice(totalPriceInclVat, currency.code)}</div>
        </div>
    )
}
PurchaseBillLineEdit.propTypes = {
    line: PropTypes.object.isRequired,
    currency: PropTypes.object.isRequired,
    entity_id: PropTypes.number.isRequired,
    errors: PropTypes.object,
}

const PurchaseBillLineView = ({ line, currency }) => {
    const fetcher = useFetcher()
    const lineTypeText = () => {
        switch (line.type) {
            case LineTypes.FINISHER:
                return <img width="24" src="/icons/box-checked.svg" title="Finisher" />
            case LineTypes.TRANSPORT:
                return <img width="24" src="/icons/truck.svg" title="Transport" />
            case LineTypes.VENDOR:
                return <img width="24" src="/icons/box.svg" title="Vendor" />
            default:
                return <></>
        }
    }

    return (
        <div className={Styles.ledgerLine} >
            <div>{lineTypeText()}</div>
            <div>{line.item_text}</div>
            <div>{GeneralLedgers.find(({ id }) => id === line.general_ledger_id)?.name || line.general_ledger_id}</div>
            <div>{line.quantity}</div>
            <div>{formatPrice(line.price_excl_vat, currency.code)}</div>
            <div>{VatTypes.find(({ id }) => id === line.vat_type_id)?.description}</div>
            <div>{formatPrice(line.price_incl_vat, currency.code)}</div>
            <div className="actions">

                <Confirm
                    content="Are you sure to delete this line ?"
                    okText="Yes"
                    icon={<DeleteOutlined />}
                    shape='square'
                    type='actionpanel'
                    onConfirm={() => {
                        fetcher.submit({
                            action: "deleteLine",
                            lineId: line.id
                        }, {
                            method: "POST",
                            encType: "application/json"
                        })
                    }}
                    danger
                    ghost
                />
            </div>
        </div>
    )
}
PurchaseBillLineView.propTypes = {
    line: PropTypes.object.isRequired,
    currency: PropTypes.object.isRequired,
}

const PurchaseBillLineAdd = ({ currency, entity_id }) => {
    const { totalPriceExclVat,
        setTotalPriceExclVat,
        visibleVatId,
        setVisibleVatId,
        totalPriceInclVat } = usePurchasebillLineTotals(null, null)

    const fetcher = useFetcher()
    const { errors = null } = fetcher.data || {};
    const [showAddNew, setShowAddNew] = useState(false)

    useEffect(() => {
        if (fetcher.data === true) {
            //On succcess
            setShowAddNew(false)
        }
    }, [fetcher.data])

    if (!showAddNew) {
        return (
            <div>
                <Button onClick={() => setShowAddNew(true)}>Add new</Button>
            </div>
        )
    }

    return (
        <SmallSection>
            <fetcher.Form className={Styles.ledgerLine} method="post">
                <div></div>
                <InputWrapper name="item_text" defaultValue="" errors={errors} />
                <FieldWrapper name="general_ledger_id" errors={errors}>
                    <Select
                        name="general_ledger_id"
                        options={GeneralLedgers}
                        fieldNames={{ value: 'id', label: 'name' }}
                    />
                </FieldWrapper>
                <FieldWrapper name="quantity" errors={errors}>
                    <InputNumber name="quantity" />
                </FieldWrapper>
                <FieldWrapper name="price_excl_vat" errors={errors}>
                    <Currency name="price_excl_vat" currency={currency.code} value={totalPriceExclVat} onChange={val => setTotalPriceExclVat(val)} />
                </FieldWrapper>
                <FieldWrapper name="vat_type_id" errors={errors}>
                    <Select
                        options={VatTypes.filter((vat) => vat.entity_id === entity_id)}
                        fieldNames={{ value: 'id', label: 'description' }}
                        defaultValue={visibleVatId}
                        onChange={val => setVisibleVatId(val)}
                        status={getFieldStatus('vat_type_id', errors)}
                    />
                    <input type="hidden" name='vat_type_id' value={visibleVatId} />
                </FieldWrapper>
                <div>{formatPrice(totalPriceInclVat, currency.code)}</div>
                <div className="actions">
                    <Button
                        name='action'
                        value='addLine'
                        icon={<CheckOutlined />}
                        htmlType='submit'
                        type='actionpanel'
                        shape='square'
                        primary
                        ghost
                    />
                    <Button
                        name='action'
                        value='addLine'
                        icon={<DeleteOutlined />}
                        htmlType='submit'
                        type='actionpanel'
                        shape='square'
                        danger
                        ghost
                        onClick={() => setShowAddNew(false)}
                    />
                </div>

            </fetcher.Form>
        </SmallSection>
    )
}
PurchaseBillLineAdd.propTypes = {
    currency: PropTypes.object.isRequired,
    entity_id: PropTypes.number.isRequired,
}

const PurchaseBillLines = ({ lines, currency, entity_id, edit, errors }) => {
    return (
        <>
            <div className={Styles.ledgerLineHead}>
                <div></div>
                <div>Item</div>
                <div>General Ledger</div>
                <div>Quantity</div>
                <div>Price Excl. VAT</div>
                <div>VAT</div>
                <div>Price Incl. VAT</div>
                <div></div>
                <div></div>
            </div>
            {!!lines && lines.map((line, index) => (
                <SmallSection key={line.id} colorLight="#fff2e9" colorDark="#fe7b1c">
                    {edit ?
                        <PurchaseBillLineEdit line={line} currency={currency} entity_id={entity_id} errors={errors?.[index]} /> :
                        <PurchaseBillLineView line={line} currency={currency} />}
                </SmallSection>
            ))}
            {!edit && <PurchaseBillLineAdd line={{}} currency={currency} entity_id={entity_id} />}
        </>
    )
}

PurchaseBillLines.propTypes = {
    lines: PropTypes.arrayOf(PropTypes.object).isRequired,
    currency: PropTypes.object.isRequired,
    entity_id: PropTypes.number.isRequired,
    edit: PropTypes.bool.isRequired,
    errors: PropTypes.arrayOf(PropTypes.object)
}

export { PurchaseBillLines };


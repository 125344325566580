import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useLoaderData } from 'react-router-dom';
import { convertDataTypes } from '../../../library/helpers/index.js';
import { CompanySelect, CountrySelect, ExecutiveEntitySelect } from '../../inputs';

const InvoiceFilterStatuses = [
    {
        value: 1,
        label: "New",
    },
    {
        value: 4,
        label: "Invoice Sent",
    },
    {
        value: 7,
        label: "Paid",
    },
    {
        value: 8,
        label: "1st Reminder",
    },
    {
        value: 9,
        label: "2nd Reminder",
    },
    {
        value: 10,
        label: "3rd Reminder",
    },
    {
        value: 11,
        label: "Final Reminder",
    },
    {
        value: 6,
        label: "Euler",
    },
]

const InvoiceFilterForm = ({ children, data, ...otherProps }) => {
    const { meta } = useLoaderData()
    const [form] = Form.useForm()
    const { Option } = Select;

    const clearForm = () => {
        try {
            form.setFieldsValue(Object.fromEntries(
                Object.keys(form.getFieldsValue()).map(key => ([key, null]))
            ))
            form.submit()
        }
        catch (e) {
            console.log(e);
        }
    }


    return (
        <Form form={form} initialValues={convertDataTypes(data, {
            customer_id: parseInt,
            country_id: parseInt,
            entity_id: parseInt,
            status: parseInt,
            payment_status: parseInt,
        })} layout='vertical'  {...otherProps}  >
            <Form.Item name="customer_id" label="Customer">
                <CompanySelect currentSelection={meta?.filters?.customer_id?.id ? { value: meta.filters.customer_id.id, label: meta.filters.customer_id.name } : null} />
            </Form.Item>
            <Form.Item name="Invoice_no" label="Invoice No">
                <Input />
            </Form.Item>
            <Form.Item name='entity_id' label="Executive Entity">
                <ExecutiveEntitySelect allowClear />
            </Form.Item>
            <Form.Item name="country_id" label="Country" >
                <CountrySelect />
            </Form.Item>
            <Form.Item name="status" label="Invoice Status" >
                <Select options={InvoiceFilterStatuses} />
            </Form.Item>
            <Form.Item name="payment_status" label="Payment Status" >
                <Select>
                    <Option value="open">Open</Option>
                    <Option value="paid">Paid</Option>
                </Select>
            </Form.Item>
            {children}
            <div className="actions">
                <Button htmlType="submit" type="primary" icon={<FilterOutlined />}>Apply</Button>
                <Button onClick={clearForm} style={{ marginLeft: 20 }} icon={<ReloadOutlined />}>Reset</Button>
            </div>
        </Form>
    )
}

InvoiceFilterForm.propTypes = {
    children: PropTypes.node,
    data: PropTypes.object,
}

export default InvoiceFilterForm
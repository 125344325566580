import { AppstoreOutlined, BarsOutlined, DownOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, Drawer, Dropdown, Input, message, Modal, Segmented, Space, Spin } from 'antd';
import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { generatePath, useFetcher, useLoaderData, useNavigate, useSearchParams } from 'react-router-dom';
import { bulkInvoiceReminders, bulkInvoiceSendToClient, checkAllInvoicePayments, getInvoices, getInvoicesKanabanCount } from '../../api/finance';
import { SelectedFilters } from '../../components/data/selected-filters.jsx';
import { fetchSmallWebsiteLogo } from '../../components/design/getWebsiteLogo.js';
import KanabanBoard from '../../components/design/kanaban.jsx';
import InvoiceFilterForm from '../../components/form/invoice-filter/invoice-filter.jsx';
import Confirm from '../../components/message/Confirm.jsx';
import { Page } from '../../components/page';
import { Table } from '../../components/table';
import { useFetch } from '../../hooks';
import { useUpdateQueryStringValueWithoutNavigation } from '../../hooks/use-update-query-string-without-navigation.js';
import { getUserSettings, InvoiceColumns, setUserSettings } from '../../library/constants';
import { InvoiceKanabanColumns } from '../../library/constants/kanabanColumns/invoiceKanabanColumns.js';
import { findInvoiceStatusById, getFormattedId, searchParamsToObject } from '../../library/helpers/index.js';
import { textToClassName } from '../../library/utilities/common.js';
import { formatDate, formatPrice } from '../../library/utilities/intl.js';

const VIEW_MODES = Object.freeze({
  LIST: "list",
  KANABAN: "kanaban",
})

const FILTER_NAMES = {
  customer_id: "Customer",
  country_id: "Country",
  entity_id: "Executive Entity",
  Invoice_no: "Invoice No.",
  payment_status: "Payment Status",
  status: "Invoice Status",
}

const renderKanabanCard = ({ item, onClick }) => {
  const status = findInvoiceStatusById(item.status)
  return (
    <div className='kanaban__card' onClick={() => onClick(item)}>
      <div className="kanaban__card__header">
        <div className='projectID'>Project: {getFormattedId(item.project_id)}</div>
        <div className={`status-tag status  status-tag--${textToClassName(status)}`}>{status}</div>
      </div>
      <div className="kanaban__card__body">
        <div className="source">
          <div className={"upperBody"}>
            <div className="invoice-number">INV-{item.invoice_number}</div>
            <div className="exec">{(item?.entity_name)?.split(' ')?.pop()}</div>
          </div>
        </div>
        <span className="customer-logo">
          {fetchSmallWebsiteLogo(item?.website, item?.company)}
        </span>
      </div>
      <div className="kanaban__card__footer">
        <div className="details">
          <p className='head'>Invoice Due Date</p>
          <p className='leftHead'>Total Amount</p>
          <p className='value'>{formatDate(item?.expiry_date)}</p>
          <p className='leftValue'>{formatPrice(item.total_amount, item.currency_code)}</p>
        </div>
      </div>

    </div>
  )
}

const InvoiceListView = ({ data, isLoading = false, hasMore, onChangeSort, onLoadMore, onItemClick, onSelectionChanged }) => {
  const [selectedRowsString, setSelectedRows] = useUpdateQueryStringValueWithoutNavigation('selectedRows')
  const selectedRows = !selectedRowsString ? [] : selectedRowsString.split(',').map(Number)

  return (
    <div >
      <Table columns={InvoiceColumns}
        data={data}
        isLoading={isLoading}
        onChange={onChangeSort}
        onRowClick={(_, record) => onItemClick(record)}
        hasMore={hasMore}
        loadMore={onLoadMore}
        rowSelection={{
          type: "checkbox",
          fixed: true,
          selectedRowKeys: selectedRows,
          onChange: (selectedRowKeys) => {
            setSelectedRows(selectedRowKeys.join(','))
            onSelectionChanged(selectedRowKeys.join(','))
          },
        }}
        scroll={{
          x: 'max-content'
        }}
      />
    </div>
  )
}
InvoiceListView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  hasMore: PropTypes.bool.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onSelectionChanged: PropTypes.func.isRequired
}

const InvoicesKanabanView = ({ data, isLoading = false, hasMore, onLoadMore, onItemClick, kanabanCount }) => {
  const columns = InvoiceKanabanColumns.map(column => ({
    ...column,
    items: data.filter(({ status }) => status === column.key) ?? [],
    count: kanabanCount?.find(({ status }) => status === column.key)?.count
  }))

  if (isLoading) {
    return (
      <Spin />
    )
  }

  return (
    <KanabanBoard
      columns={columns}
      onItemClick={onItemClick}
      renderItem={renderKanabanCard}
      hasMore={hasMore}
      loadMore={onLoadMore}
    />
  )
}
InvoicesKanabanView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  hasMore: PropTypes.bool.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  kanabanCount: PropTypes.arrayOf(PropTypes.object).isRequired,
}



function Invoices({ title }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const initialData = useLoaderData()
  const navigate = useNavigate()
  const fetcher = useFetcher()
  const [selectedRowsString, setSelectedRows] = useUpdateQueryStringValueWithoutNavigation('selectedRows')
  const selectedRows = !selectedRowsString ? [] : selectedRowsString.split(',').map(Number)
  const [list, hasMore, isLoading, searchParamsParsed, {
    setSort,
    setFilters,
    loadMore
  }] = useFetch({ initialData })

  const debouncedChangeHandler = debounce(q => setFilters({ search: q }), 500)

  const viewMode = searchParams.get('view') ?? getUserSettings('invoiveListView') ?? VIEW_MODES.KANABAN
  const setViewMode = (mode) => setSearchParams(sp => {
    sp.set('view', mode)
    return sp
  })

  const handleRowClick = (record) => {
    navigate(generatePath('/finance/invoices/:id', {
      id: record.id
    }))
  }
  const [isFiltersVisible, setIsFiltersVisible] = useState(false)

  return (
    <Page className='Invoices' backLink={false} title={title} summary={initialData?.meta?.totalCount ? `${initialData?.meta?.totalCount} Records` : 'No Records'} header={(
      <>
        <Segmented
          value={viewMode}
          options={[
            {
              value: VIEW_MODES.LIST,
              icon: <BarsOutlined />,
            },
            {
              value: VIEW_MODES.KANABAN,
              icon: <AppstoreOutlined />,
            },
          ]}
          onChange={setViewMode}
        />
        <Space.Compact style={{ width: '100%', maxWidth: '400px' }}>
          <Input.Search placeholder="Search" onChange={(e) => debouncedChangeHandler(e.target.value)} defaultValue={searchParamsParsed?.filter?.search ?? ''} allowClear />
          <Button onClick={() => setIsFiltersVisible(v => !v)} icon={<FilterOutlined />}>Filters</Button>
        </Space.Compact>
        <div className="actions">
          {selectedRows.length > 0 && (
            <>
              <Confirm
                title="Send Invoices"
                content={`Send ${selectedRows.length} invoices ?`}
                onConfirm={() => {
                  fetcher.submit({
                    invoiceIds: selectedRows,
                    action: "bulkInvoiceSendToClient"
                  }, { method: "post", encType: 'application/json' })
                }}
                loading={fetcher.state !== 'idle' && fetcher.json?.action === 'bulkInvoiceSendToClient'}
              >Sent to Client</Confirm>
              <Dropdown
                menu={{
                  items: [
                    {
                      label: 'Send 1st Reminders',
                      key: 1,
                    },
                    {
                      label: 'Send 2nd Reminders',
                      key: 2,
                    },
                    {
                      label: 'Send 3rd Reminders',
                      key: 3,
                    },
                    {
                      label: 'Send Final Reminders',
                      key: 4,
                    },
                  ],
                  onClick: ({ key }) => {
                    let reminderTitle = ''
                    switch (key.toString()) {
                      case '1':
                        reminderTitle = 'First'
                        break
                      case '2':
                        reminderTitle = 'Second'
                        break
                      case '3':
                        reminderTitle = 'Third'
                        break
                      case '4':
                        reminderTitle = 'Final'
                        break
                    }
                    Modal.confirm({
                      content: `Send ${reminderTitle} Reminder to ${selectedRows.length} invoice(s) ?`,
                      title: 'Send Reminder',
                      okText: "Send",
                      cancelText: "Cancel",
                      onOk: () => {
                        fetcher.submit({
                          invoiceIds: selectedRows,
                          reminderType: parseInt(key),
                          action: "bulkReminders"
                        }, { method: "post", encType: 'application/json' })
                      },
                      closable: true,
                      maskClosable: true,
                    })
                  }
                }}
              >
                <Button loading={fetcher.state !== 'idle' && fetcher.json?.action === 'bulkReminders'}>
                  <Space>Send Reminders<DownOutlined /></Space>
                </Button>
              </Dropdown>
            </>
          )}
          <Button
            onClick={() => {
              fetcher.submit({
                invoiceIds: selectedRows,
                action: "checkInvoicePayments"
              }, { method: "post", encType: 'application/json' })
            }}
            loading={fetcher.state !== 'idle' && fetcher.json?.action === 'checkInvoicePayments'}
          >Check for new payments</Button>
        </div>
      </>
    )} subHeader={initialData.meta?.filters && <SelectedFilters filters={initialData.meta.filters} filterNames={FILTER_NAMES} updateFilters={setFilters} />}>
      {viewMode === VIEW_MODES.LIST
        ? <InvoiceListView data={list} isLoading={isLoading} hasMore={hasMore} onChangeSort={setSort} onLoadMore={loadMore} onItemClick={handleRowClick} onSelectionChanged={(selections) => setSelectedRows(selections)} />
        : <InvoicesKanabanView data={list} kanabanCount={initialData.kanabanCount} isLoading={isLoading} hasMore={hasMore} onLoadMore={loadMore} onItemClick={handleRowClick} />
      }
      <Drawer open={isFiltersVisible} onClose={() => setIsFiltersVisible(false)} >
        <InvoiceFilterForm key={JSON.stringify(searchParamsParsed.filter)} data={searchParamsParsed.filter} onFinish={(data) => setFilters(data)} />
      </Drawer>
    </Page>
  )
}

Invoices.Actions = {
  bulkReminders: async ({ data }) => {
    const { invoiceIds, reminderType } = data
    if (!invoiceIds?.length) {
      message.error('Please select invoices')
      return false
    }
    if (!reminderType || !parseInt(reminderType)) {
      message.error('Invalid Reminder Type')
      return false
    }
    try {
      const msg = await bulkInvoiceReminders(invoiceIds, reminderType)
      message.success(msg)
      return true
    }
    catch (error) {
      message.error(error.message)
    }
    return false
  },
  bulkInvoiceSendToClient: async ({ data }) => {
    const { invoiceIds = [] } = data
    if (!invoiceIds?.length) {
      message.error('Please select invoices')
      return false
    }
    try {
      const msg = await bulkInvoiceSendToClient(invoiceIds)
      message.success(msg)
      return true
    }
    catch (error) {
      message.error(error.message)
    }
    return false
  },
  checkInvoicePayments: async () => {
    try {
      const msg = await checkAllInvoicePayments()
      message.success(msg)
      return true
    }
    catch (error) {
      message.error(error.message)
    }
    return false
  }
}

Invoices.Loader = async ({ request }) => {
  const url = new URL(request.url)
  const { filter, page, sort, view = (getUserSettings('invoiveListView') ?? VIEW_MODES.KANABAN) } = searchParamsToObject(url.searchParams)
  setUserSettings('invoiveListView', view)
  const limit = view === VIEW_MODES.KANABAN ? 50 : 10
  const { data, meta } = await getInvoices({ filter, page, limit, ...sort })

  //Loads kanaban count only in list view mode and on first request
  let kanabanCount = null
  if (view === VIEW_MODES.KANABAN && !page) {
    kanabanCount = await getInvoicesKanabanCount({ filter })
  }

  return { data, meta, kanabanCount }
}

Invoices.propTypes = {
  title: PropTypes.string,
}

export default Invoices

// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/user';
const ENDPOINT_SINGLE = '/user/:userId';
const ENDPOINT_PASSWORD_CHANGE = 'user/password/change/:userId';
const ENDPOINT_SINGLE_ACTION = '/user/:userId/:action';

export const getUsers = (payload) => {
    return axiosInstance.get(ENDPOINT_GENERAL, { params: payload })
}

export const getUserDetails = (userId) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE, {
        userId
    })).then(({ data }) => data)
}

export const createUser = (payload) => {
    return axiosInstance.post(ENDPOINT_GENERAL, payload)
}

export const updateUser = (userId, payload) => {
    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE, {
        userId,
    }), payload)//.then(({ message }) => message)
}

export const changeUserPassword = (userId, newPassword) => {
    return axiosInstance.post(generatePath(ENDPOINT_PASSWORD_CHANGE, {
        userId,
    }), {
        change_password: newPassword
    }).then(({ message }) => message)
}

export const removeProfilePhoto = (userId) => {
    return axiosInstance.delete(generatePath(ENDPOINT_SINGLE_ACTION, {
        userId,
        action: 'remove-profile',
    })).then(({ message }) => message)
}
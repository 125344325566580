import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { searchProject } from '../../api/project-item';

function ProjectSelect({ value = null, onChange, vendorId = null, ...otherProps }) {
    const debounceTimeout = 200
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);

    useEffect(() => {
        debounceFetcher()
    }, [])

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            searchProject(value, vendorId).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                setOptions(newOptions);
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [vendorId, searchProject, debounceTimeout]);

    return (
        <Select
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            options={options}
            onChange={onChange}
            value={value}
            placeholder="Select Project"
            showSearch
            allowClear
            {...otherProps}
        />
    );
}

ProjectSelect.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    vendorId: PropTypes.number,
    currentSelection: PropTypes.object
    // currentSelection: PropTypes.shape({
    //     id: PropTypes.number.isRequired,
    //     company: PropTypes.string.isRequired
    // })
}

export default ProjectSelect;
import { Tag } from 'antd';
import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { searchVendorSentProjectItems } from '../../api/finance/purchase-bill.js';
import styles from './purchase-bill-project-item-select.module.scss';

function PurcahseBillProjectItemSelect({ filters = {}, value, onChange, ...otherProps }) {
    const debounceTimeout = 200
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);

    useEffect(() => {
        debounceFetcher()
    }, [])

    const debounceFetcher = useMemo(() => {
        const loadOptions = () => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            searchVendorSentProjectItems('', filters).then(data => {
                const _items = data.map(({ id, name, project_id }) => ({
                    value: id,
                    label: `#${id} - ${name}`,
                    project_id
                }))
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                setOptions(_items)
                setFetching(false);
            })
        };
        return debounce(loadOptions, debounceTimeout);
    }, [filters, searchVendorSentProjectItems, debounceTimeout]);

    const handleChange = (val, checked) => {
        const selectedValues = checked
            ? [...value, val]
            : value?.filter((t) => t !== val);
        const selectedItems = options.filter(({ value }) => selectedValues.includes(value))
        onChange(selectedValues, selectedItems)
    }

    if (otherProps.disabled) {
        return
    }

    if (fetching) {
        return <div className={styles.loader}>Loading...</div>
    }

    return (
        <div>{options.map(option => (
            <Tag.CheckableTag key={option.value} checked={value.indexOf(option.value) > -1} onChange={checked => handleChange(option.value, checked)}>
                {option.label}
            </Tag.CheckableTag>
        ))}</div>
    )
}

PurcahseBillProjectItemSelect.propTypes = {
    filters: PropTypes.object,
    value: PropTypes.arrayOf(PropTypes.number),
    onChange: PropTypes.func.isRequired,
    currentSelection: PropTypes.object
    // currentSelection: PropTypes.shape({
    //     id: PropTypes.number.isRequired,
    //     company: PropTypes.string.isRequired
    // })
}

export default PurcahseBillProjectItemSelect;
import { SendOutlined, UploadOutlined } from '@ant-design/icons';
import { Alert, Button, Modal, message } from 'antd';
import React, { useState } from "react";
import { useFetcher, useRouteLoaderData } from 'react-router-dom';
import { fetchArtworkDetails, updateOrderItem } from '../../../api/order/order-manager.js';
import { sendOrderToSupplier, uploadSupplierFiles } from '../../../api/order/supplier-finisher-flow.js';
import { ProjectItemSpecifications } from '../../../components/data/index.js';
import { Box } from '../../../components/design/box.js';
import FileManager from '../../../components/inputs/file-manager.jsx';
import { DatePicker, FieldWrapper, GeneralFieldWrapper, InputWrapper } from "../../../components/inputs/index.js";
import Confirm from '../../../components/message/Confirm.jsx';
import { ValidationError } from '../../../library/classes/exceptions/index.js';
import { generateRouteUrl } from '../../../library/constants/routes.js';
import style from './supplier-order-confirmation.module.scss';

const SupplierOrderConfirmation = () => {
    const { orderDetails, itemDetails, productDetails } = useRouteLoaderData('OrderManagerDetailsPage')
    const [isArtkworkUploadVisible, setIsArtkworkUploadVisible] = useState(false)
    const [artworkFiles, setArtworkFiles] = useState([])
    const fetcher = useFetcher()
    const { errors = null } = fetcher.data || {}

    const handleItemDetailsChange = (field, value) => {
        fetcher.submit({
            [field]: value,
            action: 'updateOrderItem'
        }, {
            method: 'post',
            encType: 'application/json',
        })
    }

    const getClientArtworks = async () => {
        try {
            setIsArtkworkUploadVisible(true)
            const { supplier_files = [] } = await fetchArtworkDetails(orderDetails.id)
            setArtworkFiles(supplier_files)
        }
        catch (e) {
            message.error(e.message)
        }
    }

    const handleUploadArtwork = async (imageData) => {
        try {
            console.log({ imageData })
            const msg = await uploadSupplierFiles(orderDetails.id, imageData)
            message.success(msg);
            getClientArtworks()
        }
        catch (error) {
            message.error(message.error);
        }
    }

    return (
        <div className={style.container}>
            <h3 style={{ 'textAlign': 'start' }}>Task For You</h3>
            <Alert message="Verify details" description="Verify all the order details before sending to vendor" type="info" showIcon />
            <Box type={Box.BoxTypes.BLUE}>
                <h3>Item Details</h3>
                <div style={{ display: 'flex', gap: '1rem', flexDirection: 'row' }}>
                    <div style={{ width: '50%', minWidth: '500px' }}>
                        <h4>Specifications</h4>
                        <ProjectItemSpecifications
                            itemId={orderDetails.id}
                            inbuiltSpecifications={productDetails.specifications}
                            max_print_area_size={itemDetails.max_print_area_size}
                            print_position={itemDetails.print_position}
                            customSpecifications={itemDetails.item_custom_specifications || []}
                            specificationValues={Object.values(itemDetails.specifications)}
                            readonly={true}
                        />
                    </div>
                    <fetcher.Form method="post">
                        <h4>Review</h4>
                        <div className="form-fields-wrapper--horizontal">
                            <FieldWrapper name="dead_line_for_supplier" label="Delivery Deadline" errors={errors} horizontal>
                                <DatePicker key={orderDetails.dead_line_for_supplier} name="dead_line_for_supplier" value={orderDetails.dead_line_for_supplier} onChange={val => handleItemDetailsChange('dead_line_for_supplier', val)} />
                            </FieldWrapper>
                            <InputWrapper name="vendor_comment" label="Comment for Vendor" defaultValue={orderDetails.vendor_comment} placeholder="Message for vendor..." onChange={e => handleItemDetailsChange('vendor_comment', e.target.value)} textarea allowClear delay errors={errors} horizontal />
                            <GeneralFieldWrapper label=" " horizontal>
                                <Button onClick={async () => {
                                    setIsArtkworkUploadVisible(true)
                                    getClientArtworks()
                                }} type='secondary' icon={<UploadOutlined />}>Upload Files</Button>
                            </GeneralFieldWrapper>
                        </div>
                    </fetcher.Form>
                    <FileManager
                        title="Upload Customer Artworks"
                        projectId={orderDetails.projectId}
                        files={artworkFiles}
                        isOpen={isArtkworkUploadVisible}
                        onClose={() => setIsArtkworkUploadVisible(false)}
                        onUpload={(imageData) => {
                            handleUploadArtwork(imageData)
                        }}
                    />
                </div>
            </Box>
            <div className="actions align--right">
                <Confirm
                    type='primary'
                    content='Order will be sent to vendor.Please make sure you have checked all the details.'
                    okText='Continue'
                    cancelText='Cancel'
                    onConfirm={() => {
                        fetcher.submit({
                            ...itemDetails.selected_vendor,
                            action: 'SupplierOrderConfirmation'
                        }, {
                            method: 'post',
                            action: generateRouteUrl('OrderManagerDetailsPage', {
                                itemId: orderDetails.id
                            })
                        })
                    }}
                    loading={fetcher.formData?.get('action') === 'SupplierOrderConfirmation'}
                    icon={<SendOutlined />}

                >Send Order to Vendor</Confirm>
            </div>
        </div>
    )
}

/**
 * !!IMPORTANT!!
 * These actions will not work automatically
 * These needs to be adeed to orderDetails Actions 
 */
export const SupplierOrderConfirmationActions = {
    updateOrderItem: async ({ params, data }) => {
        const { itemId } = params
        try {
            //todo: Validate only specific fields can be updated by order manager
            await updateOrderItem(itemId, data)
            return true
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
            return false
        }
    },
    SupplierOrderConfirmation: async ({ params, data }) => {
        const { itemId } = params
        try {
            const msg = await sendOrderToSupplier(itemId)
            Modal.success({
                title: msg,
                content: `${data.company} has been notified to start working on the project`,
            })
            return true
        }
        catch (error) {
            message.error(error.message)
            return false
        }
    },
}

export default SupplierOrderConfirmation;
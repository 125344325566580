// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/project/:projectId/contact/:contactId';
const ENDPOINT_SINGLE = '/project/:projectId/contact/:contactId/:action';

export const addProjectContact = (projectId, contactId) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL, {
        projectId,
        contactId
    }))
}

export const removeProjectContact = (projectId, contactId) => {
    return axiosInstance.delete(generatePath(ENDPOINT_GENERAL, {
        projectId,
        contactId
    }))
}

export const setProjectContactPrimary = (projectId, contactId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE, {
        projectId,
        contactId,
        action: 'primary'
    }))
}

export const setProjectContactAuthority = (projectId, contactId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE, {
        projectId,
        contactId,
        action: 'authority'
    }))
}

export const removeProjectContactAuthority = (projectId, contactId) => {
    return axiosInstance.delete(generatePath(ENDPOINT_SINGLE, {
        projectId,
        contactId,
        action: 'authority'
    }))
}

export const setProjectInvoiceContact = (projectId, contactId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE, {
        projectId,
        contactId,
        action: 'invoice'
    }))
}

export const removeProjectInvoiceContact = (projectId, contactId) => {
    return axiosInstance.delete(generatePath(ENDPOINT_SINGLE, {
        projectId,
        contactId,
        action: 'invoice'
    }))
}
import { Alert, Button, notification } from "antd";
import { getToken, onMessage } from "firebase/messaging";
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { subscribe } from '../../api/auth/notifications.js';
import { getUserSessionData, setUserSessionData } from "../../library/constants/auth.js";
import { messaging } from "../../library/firebaseConfig.js";
import { getEnvVariable } from "../../library/utilities/common.js";

const fcpApiKey = getEnvVariable('FIREBASE_PUSH_KEY')

async function checkSubscription() {
  try {
    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration.pushManager.getSubscription();
    return !!subscription; // Returns true if subscribed, false if not subscribed
  } catch (error) {
    console.error('Error checking subscription:', error);
    return false; // Handle errors and return false if something goes wrong
  }
}

const PushNotifications = ({ onNewNotifaction }) => {
  const [isUserSubscribed, setIsUserSubscribed] = useState(!!getUserSessionData('usersubscribed'))
  const messageChannelRef = useRef(null)
  const firebaseListener = useRef(null)
  const navigate = useNavigate()

  useEffect(() => {

    if (!isUserSubscribed) {
      handleSubscribe()
    }
    checkSubscription().then(isSubscribed => setIsUserSubscribed(isSubscribed))

    messageChannelRef.current = new BroadcastChannel('sw-messages')
    messageChannelRef.current.addEventListener('message', handlePushMessage);

    firebaseListener.current = onMessage(messaging, (payload) => {
      handlePushMessage(payload)
    });

    return () => {
      messageChannelRef.current.close()
      firebaseListener.current()
    }
  }, [])

  const handlePushMessage = (payload) => {
    if (payload instanceof MessageEvent) {
      notification.success({
        message: payload.data?.data.title,
        description: payload.data?.data.body,
        style: { cursor: 'pointer' },
        duration: 10,
        onClick: () => {
          if (payload.data?.data?.redirect) {
            navigate(payload.data?.data?.redirect)
          }
        }
      })
      onNewNotifaction({
        message: payload.data?.data.title,
        description: payload.data?.data.body
      })
    }
    else {
      notification.success({
        message: payload.data.title,
        description: payload.data.body,
        style: { cursor: 'pointer' },
        duration: 10,
        onClick: () => {
          if (payload.data?.redirect) {
            navigate(payload.data?.redirect)
          }
        }
      })
      onNewNotifaction({
        message: payload.data.title,
        description: payload.data.body
      })
    }
  }

  async function handleSubscribe() {
    if (!isUserSubscribed) {
      const permission = await Notification.requestPermission()
      if (permission === 'granted') {
        // return navigator.serviceWorker.ready;
        const token = await getToken(messaging, {
          vapidKey: fcpApiKey,
        });

        // Send the subscription object to your server for storage
        try {
          await subscribe(token)
          setIsUserSubscribed(true)
          setUserSessionData('usersubscribed', true)
        }
        catch (error) {
          setIsUserSubscribed(false)
          setUserSessionData('usersubscribed', false)
          console.error('Server error subscribing to push notifications:', error)
        }
      } else if (permission === "denied") {
        setIsUserSubscribed(false)
        setUserSessionData('usersubscribed', false)
        //notifications are blocked
        throw new Error('Notification permission denied');
      }
    }
  }

  if (isUserSubscribed) return (<></>)

  return (
    <Alert
      type="warning"
      message="Please subscribe to recive instant updates"
      action={
        <Button onClick={handleSubscribe} >Subscribe</Button>
      }
      style={{ zIndex: 99 }}
      showIcon
      closable
    />
  )
}

PushNotifications.propTypes = {
  onNewNotifaction: PropTypes.func.isRequired
}
export default PushNotifications
const GrapeJsImageLink = (editor, /* opts = {} */) => {
  // Define the custom table component
  editor.BlockManager.add('image-link', {
    label: 'Image with Link',
    content: {
      type: 'image',
      attributes: { src: 'https://via.placeholder.com/150' },
      traits: [
        'alt',
        'src',
        {
          type: 'text',
          label: 'Link (href)',
          name: 'href',
        }
      ],
      components: [
        {
          tagName: 'a',
          attributes: { href: '#' },
          components: [
            {
              tagName: 'img',
              attributes: { src: 'https://via.placeholder.com/150' },
            }
          ]
        }
      ]
    }
  });

  editor.BlockManager.add('image-with-link-block', {
    label: 'Image with Link',
    content: {
      type: 'image-link',
      attributes: { src: 'https://via.placeholder.com/150', href: 'https://example.com' },
    },
    category: 'Basic',
  });
};

export default GrapeJsImageLink
import { DownloadOutlined } from '@ant-design/icons';
import { Alert, Button, Modal, message } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from "react";
import { useFetcher, useOutletContext, useRouteLoaderData } from 'react-router-dom';
import { uploadFileFromObject } from "../../../api/common";
import { confirmOrderDelivery } from "../../../api/order";
import { Box } from "../../../components/design/box.js";
import { InputWrapper } from "../../../components/inputs/input-wrappers.jsx";
import { useUpdateQueryStringValueWithoutNavigation } from "../../../hooks/use-update-query-string-without-navigation.js";
import { ValidationError } from "../../../library/classes/exceptions/index.js";
import { formats } from '../../../library/constants/formats.js';
import { OrderShippingStatuses } from "../../../library/constants/shipping-statuses.js";
import { formatDate } from "../../../library/utilities/intl.js";
import { getFilePath } from "../../../library/utilities/urls.js";
import MultifileUpload from "../../../ui/multifile-upload.jsx";
import { ShipmentConfirmDispatchDate, ShipmentExpectedDispatchDate } from "../index.js";
import styles from './product-dispatch.module.scss';

const DeliveryConfirm = ({ projectId, item, onConfirm, deliveryType = null }) => {
    const address = (deliveryType === 3) ?
        <Button href={getFilePath(item.bulk_address_file.url, projectId)} icon={<DownloadOutlined />}>Download</Button> :
        <>{item.street_number},{item.street},{item.city}</>

    return (
        <div className={deliveryType === 3 ? styles.deliveryRowWithComment : styles.deliveryRow}>
            <div className="quantity">{item.quantity}</div>
            <div className="address">{address}</div>
            <div className="field-quantity">{item.ship_quantity}</div>
            <div className="field-expected_date">
                {formatDate(item.dispatch_date)}
            </div>
            {deliveryType === 3 ? (
                <>
                    <div className="field-expected_date">{<Button href={getFilePath(item.delivery_file.url, projectId)} icon={<DownloadOutlined />}>Download</Button>}</div>
                    <div className="field-trackingurl">{item.delivery_comment}</div>
                </>
            ) : (
                <>
                    <div className="field-trackingurl"><a href={item.tracking_url} target='_blank' rel="noreferrer noopener">View</a></div>
                    <div className="field-expected_date">{formatDate(item.expected_date)}</div>
                </>
            )}
            {item.status === 4 ? (
                <Button
                    type="primary"
                    name="action"
                    disabled
                >Proof Sent</Button>
            ) : (
                <Button
                    type="primary"
                    name="action"
                    value="confirmDelivery"
                    onClick={onConfirm}
                >Confirm</Button>
            )}

        </div>
    )
}
DeliveryConfirm.propTypes = {
    projectId: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    deliveryType: PropTypes.oneOf([1, 2, 3]),
}

const ProductDispatch = () => {
    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')
    const fetcher = useFetcher();
    const { errors = {} } = fetcher.data || {}
    const fileUploaderRef = useRef([])
    const inputComment = useRef('')
    const [confirmDeliveryId, setConfirmDeliveryId] = useUpdateQueryStringValueWithoutNavigation('confirmdelivery')
    const { vendorRole } = useOutletContext()
    const [isUploading, setIsuploading] = useState(false)
    const missingExpectedDateShipments = orderDetails.item_delivery_address.filter(({ status }) => status === OrderShippingStatuses.NEW)
    const dispachableShipments = orderDetails.item_delivery_address.filter(({ status }) => status === OrderShippingStatuses.DISPATCH_DATE_ADDED)
    const deliverableShipments = orderDetails.item_delivery_address.filter(({ status }) => [OrderShippingStatuses.DETAILS_ADDED, OrderShippingStatuses.CONFIRM].includes(status))
    const expectedDeliveryDays = moment(deliverableShipments?.[0]?.expected_date, formats.backendDateFormat).days()

    const closeConfirmDeliveryPopup = () => {
        setConfirmDeliveryId(null)
        inputComment.current = ''
        fileUploaderRef.current = []
    };

    useEffect(() => {
        setIsuploading(fetcher.state === 'submitting')
        if (fetcher.state !== 'submitting' && fetcher.data === true) {
            closeConfirmDeliveryPopup()
        }
    }, [fetcher])

    const handleFilesUpload = async (deliveryId) => {
        if (fileUploaderRef.current.length === 0) {
            alert('Please select atleast one file')
            return
        }
        setIsuploading(true)
        const promises = fileUploaderRef.current.map(file => uploadFileFromObject(file))
        try {
            const fileIds = await Promise.all(promises)
            fetcher.submit({
                deliveryId,
                image_ids: fileIds,
                comment: inputComment.current,
                action: 'confirmDelivery'
            }, {
                method: 'post',
                encType: 'application/json'
            })
            setIsuploading(false)
        }
        catch (error) {
            setIsuploading(false)
            message.error(error.message)
            return
        }
    }

    return (
        <div className={styles.container}>
            <h3>Task For You</h3>
            <Alert description={`The ${vendorRole} has shipped the order, ${expectedDeliveryDays > 0 ? `the delivery will take around ${expectedDeliveryDays} days` : 'the delivery is running late'} . Client is already notified.`} type="info" showIcon />
            <Box header={
                <div className={orderDetails.delivery_type === 3 ? styles.deliveryRowHeadWithComment : styles.deliveryRowHead}>
                    <div>Quantity</div>
                    <div>Delivery Address</div>
                    <div>Ship Quantity</div>
                    <div>Expected Dispatch Date</div>
                    {orderDetails.delivery_type === 3 ? (
                        <>
                            <div>Delivery File</div>
                            <div>Comment</div>
                        </>
                    ) : (
                        <>
                            <div>Tracking URL</div>
                            <div>Expected Delivery Date</div>
                        </>
                    )}
                    <div></div>
                </div>
            }>
                {!!deliverableShipments && deliverableShipments.length > 0 && deliverableShipments.map((shipment) => (
                    <DeliveryConfirm
                        key={shipment.id}
                        projectId={orderDetails.project_id}
                        item={shipment}
                        deliveryType={orderDetails.delivery_type}
                        onConfirm={() => {
                            setConfirmDeliveryId(shipment.id)
                        }}
                    />
                ))}
            </Box>
            {dispachableShipments.length > 0 && (
                <>
                    <Alert description="Please provide an expected shipment details for following items" type="error" showIcon />
                    <Box header={
                        <div className={styles.deliveryRowHead}>
                            <div>Quantity</div>
                            <div>Delivery Address</div>
                            <div>Expected Dispatch Date</div>
                            <div>Ship Quantity</div>
                            <div>Tracking URL</div>
                            <div>Expected Delivery Date</div>
                            <div></div>
                        </div>
                    }>
                        {dispachableShipments.map((shipment) => <ShipmentConfirmDispatchDate key={shipment.id} projectId={orderDetails.project_id} itemId={orderDetails.id} item={shipment} />)}
                    </Box>
                </>
            )}
            {missingExpectedDateShipments.length > 0 && (
                <>
                    <Alert description="Please provide shipment details for following items" type="error" showIcon />
                    <ShipmentExpectedDispatchDate projectId={orderDetails.project_id} itemId={orderDetails.id} deliveryType={orderDetails.delivery_type} shipments={missingExpectedDateShipments} buttonText="Save Dates" />
                </>
            )}
            <Modal
                title="Upload Proof of Delivery"
                open={confirmDeliveryId}
                onOk={() => handleFilesUpload(confirmDeliveryId)}
                okButtonProps={{ loading: isUploading }}
                okText="Continue"
                onCancel={closeConfirmDeliveryPopup}
            >
                <div className={styles.uploadBox}>
                    <p>The uploaded printscreen should not contain track and trace code, country of origin and sender information</p>
                    <div className="main">
                        <div className="box--white">
                            <MultifileUpload ref={fileUploaderRef} />
                        </div>
                    </div>
                    <br />
                    <InputWrapper name="comment" label="comment" defaultValue="" textarea allowClear errors={errors} onChange={e => inputComment.current = e.target.value} />
                </div>
            </Modal>
        </div>
    )
}

ProductDispatch.Actions = {
    confirmDelivery: async ({ params, data }) => {
        const { itemId } = params
        try {
            const { deliveryId, image_ids, delivery_proof_comment } = data
            const { message: msg } = await confirmOrderDelivery(itemId, deliveryId, {
                image_ids,
                comment: delivery_proof_comment
            })
            message.success(msg);
            return true
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
        }
        return false
    }
}

export default ProductDispatch;

import { Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

function SelectInput({ name, ...otherProps }) {
    const [val, setVal] = useState(otherProps.defaultValue ?? '')

    return (
        <>
            <Select
                onChange={(v) => {
                    setVal(v)
                    console.log({ v })
                    if (otherProps.onChange) {
                        otherProps.onChange(v)
                    }
                }}
                defaultValue={val ?? undefined}
                {...otherProps}
            />
            <input type="hidden" name={name} value={val} />
        </>
    );
}

SelectInput.propTypes = {
    name: PropTypes.string.isRequired
}

export default SelectInput;
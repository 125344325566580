// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/settings/field';
const ENDPOINT_SINGLE = '/settings/field/:templateId';

export const getTemplates = (payload) => {
    return axiosInstance.get(ENDPOINT_GENERAL, { params: payload }).then(({ data }) => data)
}

export const getTemplateDetails = (templateId) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE, {
        templateId
    })).then(({ data }) => data)
}

export const createTemplate = (payload) => {
    return axiosInstance.post(ENDPOINT_GENERAL, payload)
}

export const updateTemplate = (templateId, payload) => {
    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE, {
        templateId,
    }), payload).then(({ message }) => message)
}


export const deleteTemplate = (/* templateId */) => {
    throw new Error('Not implemented Yet')
}


import { Alert, message } from "antd";
import React from "react";
import { useFetcher, useRouteLoaderData } from 'react-router-dom';
import { resumeOrder } from "../../../api/order/order-manager.js";
import styles from '../../../components/data/quotation/quotation-item.module.scss';
import { InputWrapper } from "../../../components/inputs/input-wrappers.jsx";
import { Prompt } from "../../../components/message/prompt.jsx";
const OnHold = () => {
    const { commentData } = useRouteLoaderData('OrderManagerDetailsPage')
    const fetcher = useFetcher()
    const reason = commentData?.['2']?.['28']
    return <div className={styles.container}>
        <h3>Task For You</h3>
        <Alert message="Order on Hold" description={"Reason : " + reason} type="error" showIcon action={(
            <Prompt
                title="Resume Order"
                buttonText="Resume Order"
                disabled={fetcher.state !== 'idle'}
                loading={fetcher.state === 'submitting' && fetcher.json?.action === 'resume'}
                onConfirm={({ comment = '' }) => {
                    fetcher.submit({
                        comment,
                        action: 'resume'
                    }, {
                        method: 'post',
                        encType: 'application/json'
                    })
                }}
            >
                <InputWrapper name="comment" label="Comment" placeholder="Reason for resuming" textarea />
            </Prompt>
        )} />
    </div>
}

OnHold.Actions = {
    resume: async ({ params, data }) => {
        try {
            const { itemId } = params
            const { comment } = data
            const msg = await resumeOrder(itemId, { comment })
            message.success(msg)
            return true
        }
        catch (e) {
            message.error(e.message)
        }
        return false
    },
}

export default OnHold;
import { Button, Drawer, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { Form, useActionData, useLoaderData, useNavigate } from 'react-router-dom';
import { getVatDetails, updateVAT } from '../../api/settings/vatType.js';
import ExecutiveEntitySelect from '../../components/inputs/ExecutiveEntitySelect.js';
import { ValidationError } from '../../library/classes/exceptions/index.js';
import { getFieldErrors, getFieldStatus } from '../../library/helpers/forms/index.js';
import { convertDataTypes } from '../../library/helpers/index.js';
import Styles from '../users/user-details.module.scss';

const VatDetails = () => {
    const { vatDetails } = useLoaderData()
    const navigate = useNavigate()
    const { errors = null } = useActionData() || {}
    const [entity, setEntity] = useState()
    useEffect(() => {
        setEntity(vatDetails.entity_id)
    }, [vatDetails])
    return (
        <Drawer open={true} mask={false} title={`VAT: ${vatDetails.id}`} onClose={() => navigate('../', { replace: true, relative: 'route' })} >
            <Form method="post" className={Styles.formWrapper}>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Entity</label>
                        <ExecutiveEntitySelect
                            onChange={(val) => {
                                console.log(val, 'val')
                                setEntity(val);
                            }}
                            value={entity}
                            status={getFieldStatus('entity_id', errors)}
                        />
                        <input type="hidden" name="entity_id" value={entity || ''} />
                        {getFieldErrors('entity_id', errors)}
                    </div>
                </div>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Percentage</label>
                        <Input type="number" name="percentage" key={vatDetails.percentage} defaultValue={vatDetails.percentage} status={getFieldStatus('percentage', errors)} />
                        {getFieldErrors('percentage', errors)}
                    </div>
                </div>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Description</label>
                        <Input type="text" name="description" key={vatDetails.description} defaultValue={vatDetails.description} status={getFieldStatus('description', errors)} />
                        {getFieldErrors('description', errors)}
                    </div>
                </div>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Btw Code</label>
                        <Input type="text" name="btw_code" key={vatDetails.btw_code} defaultValue={vatDetails.btw_code} status={getFieldStatus('btw_code', errors)} />
                        {getFieldErrors('btw_code', errors)}
                    </div>
                </div>



                <Button type='primary' name="action" value="updateVat" htmlType='submit'>Update</Button>
            </Form>

        </Drawer >
    )
}

VatDetails.Actions = {
    "updateVat": async ({ params, data }) => {
        const { id } = params
        try {
            const payload = convertDataTypes(data, {
                entity_id: parseInt,
                percentage: parseFloat,
            })
            // Basic validation
            const msg = await updateVAT(id, payload)
            message.success(msg)
            return true
        } catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
        }
        return false
    },
}


VatDetails.Loader = async ({ params }) => {
    const { id } = params
    const vatDetails = await getVatDetails(id)
    return { vatDetails }
}

export default VatDetails








import { FileTextOutlined, LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import {
    ArtWorkRejected,
    ArtworkReady, ArtworkReceived,
    ArtworkSentToStudio,
    CancelRequest,
    DelayInDelivery, Delivered, DispatchDateConfirm, InProduction,
    OnHold,
    OrderConfirmation,
    OrderSent,
    ProductDispatch,
    ProofReady,
    ProofRecieved, ProofRejected, ProofRejectedbyCustomer,
    ProofRequested,
    ProofSentToStudio,
    ProofSenttoCustomer, ReadyForProduction, RequestTemplatefromVendor, SendTemplate,
    TemplateReady,
    TemplateRecievedFromVendor,
    TemplateSenttoStudio,
    WaitingForFile
} from "../../../routes/orderManager";
import ArtworkSentToCustomer from '../../../routes/orderManager/tasks/artwork-sent-to-customer.jsx';
import MakeArtworkRejected from '../../../routes/orderManager/tasks/make-artowrk-rejected.jsx';
import MakeArtworkReady from '../../../routes/orderManager/tasks/make-artwork-ready.jsx';
import MakeArtworkSenttoStudio from '../../../routes/orderManager/tasks/make-artwork-sent-to-studio.jsx';

export const OrderManagerSteps = [
    {
        id: 'OrderConfirmation',
        status: 1,
        icon: <FileTextOutlined />,
        title: 'Order Confirmation',
        component: () => OrderConfirmation,
    },
    {
        id: 'OrderSent',
        status: 2,
        icon: <FileTextOutlined />,
        activeIcon: <LoadingOutlined />,
        title: 'Order Sent',
        component: () => OrderSent,
    },
    {
        id: 'WaitingForFile',
        status: 3,
        icon: <FileTextOutlined />,
        title: 'Waiting for Files',
        component: () => WaitingForFile,
    },
    {
        id: 'RequestTemplatefromVendor',
        status: 4,
        icon: <FileTextOutlined />,
        title: 'Request Template from Vendor',
        component: () => RequestTemplatefromVendor,
    },
    {
        id: 'TemplateRecievedFromVendor',
        status: 5,
        icon: <FileTextOutlined />,
        title: 'Template Recieved from Vendor',
        component: () => TemplateRecievedFromVendor,
    },
    {
        id: 'TemplateSenttoStudio',
        status: 6,
        icon: <FileTextOutlined />,
        title: 'Template Sent to Studio',
        component: () => TemplateSenttoStudio,
    },
    {
        id: 'TemplateReady',
        status: 7,
        icon: <FileTextOutlined />,
        title: 'Template Ready',
        component: () => TemplateReady,
    },
    {
        id: 'SendTemplate',
        status: 8,
        icon: <FileTextOutlined />,
        title: 'Template Sent',
        component: () => SendTemplate,
    },
    {
        id: 'MakeArtworkSenttoStudio',
        status: 101,
        icon: <FileTextOutlined />,
        title: 'Make Artwork Sent to Studio',
        component: () => MakeArtworkSenttoStudio,
    },
    {
        id: 'MakeArtworkReady',
        status: 102,
        icon: <FileTextOutlined />,
        title: 'Make Artwork Ready',
        component: () => MakeArtworkReady,
    },
    {
        id: 'ArtworkSentToCustomer',
        status: 9,
        icon: <FileTextOutlined />,
        title: 'Artwork Sent To Customer',
        component: () => ArtworkSentToCustomer,
    },
    {
        id: 'MakeArtworkRejected',
        status: 10,
        icon: <FileTextOutlined />,
        title: 'Make Artwork Rejected',
        component: () => MakeArtworkRejected,
    },
    {
        id: 'ArtworkReceived',
        status: 11,
        icon: <FileTextOutlined />,
        title: 'ArtWork Received',
        component: () => ArtworkReceived,
    },
    {
        id: 'ArtWorkRejected',
        status: 12,
        icon: <FileTextOutlined />,
        title: 'ArtWork Rejected',
        component: () => ArtWorkRejected,
    },
    {
        id: 'ArtworkSentToStudio',
        status: 13,
        icon: <FileTextOutlined />,
        title: 'ArtWork Sent to Studio',
        component: () => ArtworkSentToStudio,
    },
    {
        id: 'ArtworkReady',
        status: 14,
        icon: <FileTextOutlined />,
        title: 'ArtWork Ready',
        component: () => ArtworkReady,
    },
    {
        id: 'ProofRequested',
        status: 15,
        icon: <FileTextOutlined />,
        title: 'Proof Requested',
        component: () => ProofRequested,
    },
    {
        id: 'ProofRecieved',
        status: 16,
        icon: <FileTextOutlined />,
        title: 'Proof Received',
        component: () => ProofRecieved,
    },
    {
        id: 'ProofRejected',
        status: 17,
        icon: <FileTextOutlined />,
        title: 'Proof Rejected',
        component: () => ProofRejected,
    },
    {
        id: 'ProofSentToStudio',
        status: 18,
        icon: <FileTextOutlined />,
        title: 'Proof Sent to Studio',
        component: () => ProofSentToStudio,
    },
    {
        id: 'ProofReady',
        status: 19,
        icon: <FileTextOutlined />,
        title: 'Proof Ready',
        component: () => ProofReady,
    },
    {
        id: 'ProofSenttoCustomer',
        status: 20,
        icon: <FileTextOutlined />,
        title: 'Proof Sent to Client',
        component: () => ProofSenttoCustomer,
    },
    {
        id: 'ProofRejectedbyCustomer',
        status: 21,
        icon: <FileTextOutlined />,
        title: 'Proof Rejected by Client',
        component: () => ProofRejectedbyCustomer,
    }, {
        id: 'ReadyForProduction',
        status: 22,
        icon: <FileTextOutlined />,
        title: 'Ready for Production',
        component: () => ReadyForProduction,
    }, {
        id: 'InProduction',
        status: 23,
        icon: <FileTextOutlined />,
        title: 'In Production',
        component: () => InProduction,
    },
    {
        id: 'DispatchDateConfirm',
        status: 24,
        icon: <FileTextOutlined />,
        title: 'Expected Dispatch Date',
        component: () => DispatchDateConfirm,
    },
    {
        id: 'OnHold',
        status: 28,
        icon: <FileTextOutlined />,
        title: 'On Hold',
        component: () => OnHold,
    },
    {
        id: 'ProductDispatch',
        status: 25,
        icon: <FileTextOutlined />,
        title: 'Product Dispatched',
        component: () => ProductDispatch,
    },
    {
        id: 'DelayInDelivery',
        status: 27,
        icon: <FileTextOutlined />,
        title: 'Delay in Delivery',
        component: () => DelayInDelivery,
    },
    {
        id: 'CancelRequest',
        status: 29,
        icon: <FileTextOutlined />,
        title: 'Cancel Request',
        component: () => CancelRequest,
    },
    {
        id: 'Delivered',
        status: 26,
        icon: <FileTextOutlined />,
        title: 'Delivered',
        component: () => Delivered,
    },
]

export const getOrderManagerSteps = () => OrderManagerSteps.map(({ icon, activeIcon = null, status, title }) => ({
    icon,
    status,
    title,
    activeIcon
}))

export const getOrderStatusName = (status) => OrderManagerSteps.find(step => step.status === status)?.title
// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/finance/euler';
// const ENDPOINT_GENERAL_ACTION = '/finance/euler/:action';
const ENDPOINT_SINGLE = '/finance/euler/:id';
// const ENDPOINT_SINGLE_ACTION = '/finance/euler/:id/:action';

export const getEulerProjects = (payload) => {
    return axiosInstance.get(ENDPOINT_GENERAL, { params: payload })
}

export const getEulerProjectDetails = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE, {
        id
    })).then(({ data }) => data)
}

export const updateEulerDetails = (id, payload) => {

    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE, {
        id
    }), payload).then(({ message }) => message)
}
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Checkbox, Row, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { forwardRef, useState } from 'react';
import style from '../../../components/general.module.scss';
import { InputWrapper } from '../../../components/inputs/input-wrappers.jsx';
import { getFilePath, getFilePathForView, getPreviewUrl } from '../../../library/constants';
import { formDataToJson } from '../../../library/utilities/formdata.js';
import { Image } from '../../../ui';

const ArtworkSelector = forwardRef(function ArtworkSelector({ artworks, project_id }, ref) {
    const [selections, setSelections] = useState(new Set())
    return (
        <form onSubmit={(e) => e.preventDefault()} onChange={(e) => {
            const data = formDataToJson(Object.fromEntries((new FormData(e.currentTarget)).entries()))
            ref.current = Object.values(data).filter(a => a.use).map(({ comment, url, name }) => ({
                url, comment, name
            }))
        }} className={style.itemList}>
            {artworks.map((artwork, index) => (
                <div key={index} className={selections.has(index) ? style.itemDetail : style.itemDetailDisabled} >
                    <Checkbox name={`[${index}]use`} defaultChecked={false} value="1" onChange={e => {
                        setSelections(val => {
                            const data = new Set(val)
                            if (e.target.checked === true) {
                                data.add(index)
                            }
                            else { data.delete(index) }
                            return data
                        })
                    }} />
                    <div className={style.imageName}>
                        <Image src={getPreviewUrl(artwork.url, project_id)} width="100" className={style.imageBox} />
                        <input type="hidden" name={`[${index}]url`} value={artwork.url} />
                        <input type="hidden" name={`[${index}]name`} value={artwork.name} />
                        <div className={style.title}>
                            <h4>{artwork.name}</h4>
                        </div>
                        <div className={style.title}>
                            <b>Comment</b> : {artwork?.comment}
                        </div>
                    </div>
                    <div style={{ 'width': '20%' }}>
                        <InputWrapper name={`[${index}]comment`} label='' disabled={!selections.has(index)} textarea placeholder="Add a comment" />
                    </div>
                    <Row>
                        <Space.Compact block>
                            <Button href={getFilePath(artwork.url, project_id)} download="artwork.png" icon={<DownloadOutlined />} >Download</Button>
                            <Button href={getFilePathForView(artwork.url, project_id)} icon={<EyeOutlined />} target='_blank'>View</Button>
                        </Space.Compact>
                    </Row>
                </div>
            ))}
        </form>
    )
})
ArtworkSelector.propTypes = {
    project_id: PropTypes.number,
    artworks: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string.isRequired,
        comment: PropTypes.string,
    }))
}

export default ArtworkSelector
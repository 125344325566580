import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { ProjectItemVendorPrices } from '../';
import styles from './item-overview.module.scss';
import './item-overview.scss';

const ProjectItemOverview = ({ children, data, includeVat, actions, onHeaderClick }) => {
    const itemData = useMemo(() => {
        const res = { ...data }
        res.selected_vendors = res.selected_vendors.map(v => ({
            ...v,
            ['prices']: data.vendors_prices[v.id]
        }))
        return res
    }, [data])

    return (
        <div className={`project-item-overview ${styles.wrapper}`} style={{ opacity: data.active === 2 ? 0.6 : 1 }}>
            <div className={styles.header}>
                <span className="project-item-index" onClick={onHeaderClick ?? undefined}>
                    {itemData.id}
                </span>
                <span className="project-item-name" onClick={onHeaderClick ?? undefined}>
                    {itemData.product.name}{data.active === 2 && " - (Not Approved)"}
                </span>
                <span className="project-item-actions">
                    {!!actions && Object.entries(actions).map(([key, action]) => (
                        <React.Fragment key={key}>{action}</React.Fragment>
                    ))}
                </span>
            </div>
            <div className={styles.body}>
                {itemData.selected_vendors.map(vendor => <ProjectItemVendorPrices key={vendor.id} data={vendor} includeVat={includeVat} active={data.active} />)}
            </div>
            {children}
        </div>
    )
}

ProjectItemOverview.propTypes = {
    children: PropTypes.node,
    data: PropTypes.shape({
        id: PropTypes.number.isRequired,
        selected_vendors: PropTypes.array,
        vendors_prices: PropTypes.object,
        quantities: PropTypes.array,
        active: PropTypes.number,
        product: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
    }),
    includeVat: PropTypes.bool,
    selectable: PropTypes.bool,
    actions: PropTypes.object,
    onHeaderClick: PropTypes.func,
}

export default ProjectItemOverview
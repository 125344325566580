import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import useElementReachedBottomListner from '../../hooks/use-element-reached-bottom-listener.js';
import styles from './kanaban.module.scss';
import './kanaban.scss';

const KanabanBoard = ({ columns, renderItem, onItemClick, hasMore, loadMore }) => {
    const endElementRef = useRef([])
    const kanabanRef = useRef(null)
    const location = useLocation()

    useEffect(() => {
        endElementRef.current = endElementRef.current.slice(0, columns.length);
    }, [columns])

    useElementReachedBottomListner({
        hasMore,
        loadMore,
        element: endElementRef.current
    })

    useEffect(() => {
        kanabanRef.current?.scrollTo(0, 0)
    }, [location])

    const itemRender = (data) => {
        try {
            return renderItem(data)
        }
        catch (error) {
            console.error(error, data)
            return <div className="">Error Rendering Item</div>
        }
    }


    return (
        <div className='kanaban-wrapper'>
            <div className={styles.kanabanWrapper} ref={kanabanRef}>
                <div className={styles.kanaban}>
                    {columns.map(({ key, title, items, count }, index) => (
                        <div key={key} className={items.length != 0 ? styles.kanabanColumn : styles.kanabanVanish} >
                            <div className={styles.kanabanColumnHeader} title={title}>
                                <span className={styles.kanabanColumnTitle}>{title}</span>
                                <span className={styles.kanabanColumnCount}>({count ?? items.length})</span>
                            </div>
                            {items.map(item => (
                                <React.Fragment key={item.id}>
                                    {
                                        itemRender({
                                            item,
                                            onClick: onItemClick
                                        })
                                    }
                                </React.Fragment>
                            ))}
                            <div ref={el => endElementRef.current[index] = el} className={styles.scrollToEnd}>
                                {hasMore ? <LoadingOutlined /> : <>No more items</>}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )

}

KanabanBoard.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    renderItem: PropTypes.func,
    onItemClick: PropTypes.func,
    hasMore: PropTypes.bool,
    loadMore: PropTypes.func,
    // stateChanger :PropTypes.func
}

export default KanabanBoard
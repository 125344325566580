import {
    CheckOutlined,
    ClockCircleOutlined
} from '@ant-design/icons';
import { DatePicker, Tooltip } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { formats } from '../../../library/constants';
import { formatDate, formatDateTime } from '../../../library/utilities/intl.js';

const InvoiceReminder = ({ status, onScheduleChange, scheduledAt, sentAt, minDate, onHold = false }) => {
    const startDate = minDate ? moment(minDate) : moment().startOf('day')
    switch (status) {
        case 1:
            return (
                <Tooltip title={`Sent as scheduled: ${formatDate(scheduledAt)}`}>
                    <ClockCircleOutlined /> <span>{formatDate(scheduledAt)}</span>
                </Tooltip>
            )

        case 2:
            return (
                <Tooltip title={`Sent manually at: ${formatDateTime(sentAt)}`}>
                    <CheckOutlined /> <span>{formatDate(sentAt)}</span>
                </Tooltip>
            )

        default: {
            if (onHold) {
                return 'On Hold'
            }
            return (
                <Tooltip title={`Scheduled at: ${formatDate(scheduledAt)}`}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                        <ClockCircleOutlined />
                        <DatePicker
                            defaultValue={scheduledAt ? moment(scheduledAt, formats.backendDateFormat) : null}
                            format={formats.frontendDateFormat}
                            onChange={(val) => onScheduleChange(val.format(formats.backendDateFormat))}
                            disabledDate={(current) => {
                                return current && current <= moment(startDate)
                            }}
                            disabled={onHold}
                        />
                    </div>
                </Tooltip>
            )
        }
    }
}

InvoiceReminder.propTypes = {
    status: PropTypes.oneOf([0, 1, 2]).isRequired,
    onScheduleChange: PropTypes.func.isRequired,
    scheduledAt: PropTypes.string,
    sentAt: PropTypes.string,
    minDate: PropTypes.string,
    onHold: PropTypes.bool,
}


export { InvoiceReminder };


export const OrderKanabanColumn = [

    {
        key: 1,
        title: 'Order Confirmation'
    },
    {
        key: 2,
        title: 'Order Sent'
    },
    {
        key: 3,
        title: 'Waiting for Files'
    },
    {
        key: 4,
        title: 'Request Template from Vendor'
    },

    {
        key: 5,
        title: 'Template Recieved from Vendor'
    },
    {
        key: 6,

        title: 'Template Sent to Studio'
    },
    {
        key: 7,

        title: 'Template Ready'
    },
    {
        key: 8,

        title: 'Send Template'
    },
    {
        key: 9,

        title: 'Make ArtWork'
    },
    {
        key: 101,
        title: 'Make Artwork Sent to Studio',

    },
    {
        key: 102,
        title: 'Make Artwork Ready',

    },
    {
        key: 10,
        title: 'Make Artwork Rejected'
    },

    {
        key: 11,

        title: 'ArtWork Received'
    },
    {
        key: 12,

        title: 'ArtWork Rejected'
    },
    {
        key: 13,

        title: 'ArtWork Sent to Studio'
    },
    {
        key: 14,

        title: 'ArtWork Ready'
    },

    {
        key: 15,

        title: 'Reuquest Vendor for Proof'
    },

    {
        key: 16,

        title: 'Proof Received'
    },
    {
        key: 17,

        title: 'Proof Rejected'
    },

    {
        key: 18,

        title: 'Proof Sent to Studio'
    },

    {
        key: 19,

        title: 'Proof Ready'
    },

    {
        key: 20,

        title: 'Proof Sent to Client'
    },

    {
        key: 21,

        title: 'Proof Rejected by Client'
    }, {

        key: 22,

        title: 'Ready for Production'
    },
    {
        key: 23,


        title: 'In Production'
    },

    {
        key: 24,

        title: 'Expected Dispatch Date'
    },
    {
        key: 25,

        title: 'Product Dispatched'
    },
    {
        key: 26,

        title: 'Delivered'
    },
    {
        key: 27,

        title: 'Delay in Delivery'
    },
    {
        key: 28,

        title: 'On Hold'
    },
    {
        key: 29,

        title: 'Cancel Request'
    },
   



]

export const ArtworkStatus = [
    {
        value: 2,
        label: 'Order Sent'
    },
    {
        value: 3,
        label: 'Waiting for Files'
    },
    {
        value: 4,
        label: 'Request Template from Vendor'
    },

    {
        value: 5,
        label: 'Template Recieved from Vendor'
    },
    {
        value: 6,

        label: 'Template Sent to Studio'
    },
    {
        value: 7,

        label: 'Template Ready'
    },
    {
        value: 8,

        label: 'Send Template'
    },
    {
        value: 9,

        label: 'Make ArtWork'
    },
    {
        value: 10,
        label: 'Artwork Sent to Vendor'
    },

    {
        value: 11,

        label: 'ArtWork Received'
    },
    {
        value: 12,

        label: 'ArtWork Rejected'
    },
    {
        value: 13,

        label: 'ArtWork Sent to Studio'
    },
    {
        value: 14,

        label: 'ArtWork Ready'
    },

    {
        value: 15,

        label: 'Reuquest Vendor for Proof'
    },

    {
        value: 16,

        label: 'Proof Received'
    },
    {
        value: 17,

        label: 'Proof Rejected'
    },

    {
        value: 18,

        label: 'Proof Sent to Studio'
    },

    {
        value: 19,

        label: 'Proof Ready'
    },

    {
        value: 20,

        label: 'Proof Sent to Client'
    },

    {
        value: 21,

        label: 'Proof Rejected by Client'
    }, {

        value: 22,

        label: 'Ready for Production'
    },
    {
        value: 23,


        label: 'In Production'
    },

    {
        value: 24,

        label: 'Expected Dispatch Date'
    },
    {
        value: 25,

        label: 'Product Dispatched'
    },
    {
        value: 26,

        label: 'Delivered'
    },
    {
        value: 27,

        label: 'Delay in Delivery'
    },
    {
        value: 28,

        label: 'On Hold'
    },
    {
        value: 29,

        label: 'Cancel Request'
    },


]
/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/studio/item';
const ENDPOINT_GENERAL_ACTION = '/studio/item/:action';


export const getStudioList = (payload) => {
    return axiosInstance.get(ENDPOINT_GENERAL, { params: payload })
}

export const getStudioOrdersKanabanCount = (payload) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL_ACTION, {
        action: 'kanban-count'
    }), { params: payload }).then(({ data }) => data)
}

export const getStudioDetails = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL + '/:id', {
        id
    })).then(({ data }) => data)
}

export const sendProoftoOrder = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/:id' + '/send-proof', {
        id
    }), payload)
}

export const sendMakeArtworktoOrder = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/:id' + '/send-make-artwork', {
        id
    }), payload)
}

export const sendArtworktoOrder = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/:id' + '/send-artwork', {
        id
    }), payload)
}

export const sendTemplateToOrder = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/:id' + '/send-template', {
        id
    }), payload)
}


import { DeleteOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Collapse, Modal, Rate, Row, Statistic, Switch, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Await, Link, Outlet, defer, generatePath, matchPath, redirect, useFetcher, useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { approveSuggestion, declineSuggestion, disableClientRating, enableClientRating, getActivityLogs, getComment, getOrderDetails, getOrderDocumentsNew, getOrderProjectDetails, getOrderVendorCommentHistory, getStatusHistory, sendCommentToVendor, sendOrderToVendor, updateOrderItem, wishToCancel } from '../../api/order';
import { getOrderSalesCommentHistory, sendCommentToSales } from '../../api/order/sales-comments.js';
import { getItemSupplierRating } from '../../api/order/supplier-finisher-flow.js';
import { getProductDetails } from '../../api/product';
import { addItemCustomSpecification, addItemHiddenSpecification, deleteItemCustomSpecification, deleteItemHiddenSpecification, getProjectItem, sortItemCustomSpecifications, updateItemCustomSpecification, updateProjectItemSpecifications } from '../../api/project-item';
import { CommunicationPanel } from '../../components/communication-panel';
import { AuditTrail, ContactDescription, ItemDetailsSection } from '../../components/data';
import { OrderDocumentsNew } from '../../components/data/OrdeManager/order-documents.jsx';
import { Box, SmallSection } from '../../components/design';
import { ProjectDetails } from '../../components/form/index.js';
import { InputWrapper } from '../../components/inputs/input-wrappers.jsx';
import { Prompt } from '../../components/message/prompt.jsx';
import { Page } from '../../components/page';
import { ValidationError } from '../../library/classes/exceptions/index.js';
import { ERP_CURRENCY } from '../../library/constants/dynamic.js';
import { OrderStatuses } from '../../library/constants/OrderStatuses.js';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { getOrderManagerSteps, getOrderStatusName } from '../../library/constants/steps';
import { OrderSupplierStatuses, SupplierOrderManagerSteps, getSupplierOrderManagerSteps } from '../../library/constants/steps/supplier-order-manager-steps.js';
import { getCurrencyById, getEntityById, getFormattedId } from '../../library/helpers';
import { formatDate, formatPrice } from '../../library/utilities/intl.js';
import { Steps, StepsAdvance } from '../../ui';
import style from './orderManager.module.scss';
import { SupplierDeliveredActions } from './supplier-tasks/supplier-delivered.jsx';
import { SupplierInProductionsActions } from './supplier-tasks/supplier-in-production.jsx';
import { SupplierOrderConfirmationActions } from './supplier-tasks/supplier-order-confirmation.jsx';
import { SupplierProductDispatchActions } from './supplier-tasks/supplier-product-dispatch.jsx';
import CancelRequest from './tasks/CancelRequest.jsx';
import { SupplierDispatchDateConfirmActions } from './tasks/supplier-dispatch-date-confirm.jsx';

const { Panel } = Collapse

const getSupplierStepIndexByStatus = (stepStatus) => {
	const statusStep = getSupplierOrderManagerSteps().findIndex(({ status }) => status === stepStatus)
	if (statusStep < 0) {
		return 0
	}
	return statusStep
}

const parseLocationStatus = (url) => {
	const { ['*']: currentStep } = matchPath('order-manager/:itemId/*', url)?.params ?? {}
	if (!parseInt(currentStep)) {
		return 0
	}
	return parseInt(currentStep)
}


const OrderDetails = ({ title }) => {
	const { orderDetails, projectDetails, basicProjectDdetails, contacts, auditTrail, productDetails, itemDetails, vendorCommunicationHistory, orderDocuments, salesCommunicationHistory, statusHistory } = useLoaderData();
	const location = useLocation()
	const urlStatus = parseLocationStatus(location.pathname)
	const vendorCurrentStep = getSupplierStepIndexByStatus(orderDetails.order_delivery_status)
	const finisherTimelineVisible = !!(orderDetails.finisher && orderDetails.finisher_id && orderDetails.finisher_id !== orderDetails.vendor_selected_id)
	const ActiveVendorStepComponent = finisherTimelineVisible ? SupplierOrderManagerSteps[vendorCurrentStep]?.component() ?? React.Fragment : React.Fragment
	const fetcher = useFetcher()
	const navigate = useNavigate()

	useEffect(() => {
		if (urlStatus !== orderDetails.order_status) {
			//Get ordersent from url search param
			const ordersent = (new URLSearchParams(location.search))?.get('ordersent')
			//Don't Redirect if ordersent is in url
			if (!ordersent) {
				navigate(generatePath('/order-manager/:itemId/:stepId', {
					itemId: orderDetails.id,
					stepId: orderDetails.order_status
				}), {
					replace: true
				})
			}
		}
	}, [orderDetails.order_status, location]) //Watch location to update data when clicking on notification

	// useEffect(() => {
	// 	if (!currentStep && currentStep !== 0) {
	// 		navigate(generatePath('/order-manager/:itemId/:stepId', {
	// 			itemId: orderDetails.id,
	// 			stepId: orderDetails.order_status
	// 		}))
	// 	}
	// }, [location])

	// const onStepChange = (stepIndex) => {
	// 	const stepStatus = getStepStatusByIndex(stepIndex)
	// 	if (stepStatus !== currentStep) {
	// 		navigate(generatePath('/order-manager/:itemId/:stepId', {
	// 			itemId: orderDetails.id,
	// 			stepId: stepStatus
	// 		}))
	// 	}
	// };

	// function convertDate(timestamp) {
	// 	return new Date(timestamp).toLocaleDateString('de-DE')
	// }
	const getCurrency = () => {
		const currencyId = getEntityById(projectDetails.entity_id)?.currency_id
		return getCurrencyById(currencyId)
	}

	return (
		<Page title={title} header={(
			<div className='headerStats'>
				<Statistic
					title="Project ID"
					value={getFormattedId(projectDetails.id)}
					formatter={v => v}
					valueStyle={{
						color: '#5A5A5A',
						backgroundColor: '#E6E6E6'
					}}
				/>
				<Statistic
					title="Item ID"
					value={getFormattedId(orderDetails.id)}
					formatter={v => v}
					valueStyle={{
						color: '#5A5A5A',
						backgroundColor: '#E6E6E6'
					}}
				/>
				{/* <StatisticfinisherTimelineVisible
					title="Item Cost"
					value={formatPrice((itemDetails.approved_unit_price * itemDetails.approved_quantity), ERP_CURRENCY)}
				/> */}
				<Statistic
					title="Payment term"
					value={projectDetails.payment_condition?.name}
				/>

				<Statistic
					title="Status"
					value={getOrderStatusName(orderDetails.order_status)}
				/>
				<Statistic
					title="Item Cost (Excl. VAT)"
					value={formatPrice(orderDetails?.item_vendor_prices[0]?.total_price, ERP_CURRENCY)}
					valueStyle={{
						color: '#32b785',
						backgroundColor: '#e8f8ee'
					}}
				/>
				{getCurrency()?.code !== ERP_CURRENCY && (
					<Statistic
						title={`Item Cost (Excl. VAT) ${getCurrency()?.code}`}
						value={formatPrice(orderDetails.project?.total_price_converted?.[getCurrency()?.code], getCurrency()?.code)}
						valueStyle={{
							color: '#32b785',
							backgroundColor: '#e8f8ee'
						}}
					/>
				)}
				<Statistic
					title="Project Total"
					value={formatPrice(projectDetails?.total_price, ERP_CURRENCY)}
					valueStyle={{
						color: '#32b785',
						backgroundColor: '#e8f8ee'
					}}
				/>
				<Statistic
					title="Total Project Amount Recieved"
					value={formatPrice(projectDetails?.total_price_received, ERP_CURRENCY)}
					valueStyle={{
						color: '#32b785',
						backgroundColor: '#e8f8ee'
					}}
				/>
			</div>
		)} >
			{orderDetails.active_suggestions?.map(suggestion => (
				<Alert
					key={suggestion.id}
					type="info"
					message={suggestion.title}
					banner
					action={
						<Button.Group>
							<Prompt
								title="Notify Account Manager"
								buttonText="Notify Account Manager"
								onConfirm={({ comment = '' }) => {
									fetcher.submit({
										suggestionId: suggestion.id,
										comment,
										action: 'approveSuggestion'
									}, {
										method: 'post',
										encType: 'application/json'
									})
								}}
							>
								<InputWrapper name="comment" label="Comment" textarea required />
							</Prompt>
							<Prompt
								title="Ignore Suggestion"
								buttonText="Ignore"
								onConfirm={({ comment = '' }) => {
									if (comment.length === 0) {
										message.error('Please enter a comment')
										return false
									}
									fetcher.submit({
										suggestionId: suggestion.id,
										comment,
										action: 'declineSuggestion'
									}, {
										method: 'post',
										encType: 'application/json'
									})
								}}
							>
								<InputWrapper name="comment" label="Comment" textarea required />
							</Prompt>
						</Button.Group>
					}
					showIcon
				/>
			))}
			<Collapse expandIconPosition="end" >
				<Panel header='Project Information' key="1">
					<div style={{ cursor: 'not-allowed' }}>
						<div style={{ pointerEvents: 'none' }}>
							<ProjectDetails
								initialValues={basicProjectDdetails}
								onValuesChange={() => { }}
								companyName={projectDetails.customer.company}
								disabled
							/>
						</div>
					</div>
				</Panel>
			</Collapse>
			<Collapse expandIconPosition="end">
				<Panel header='Contact Information' key="1">
					{contacts.map((contact) => {
						return (
							<SmallSection key={contact?.id} primary={projectDetails.primary_contact_id === contact.id} authority={contact.is_authority} ribbon={(projectDetails.primary_contact_id !== contact.id && projectDetails.billing_contact_id === contact.id) ? 'Invoice' : undefined}>
								<ContactDescription contact={contact} />
							</SmallSection>
						)
					})}
				</Panel>
			</Collapse>
			<Box>
				<Row>
					<Col span={18}>
						<ItemDetailsSection orderDetails={orderDetails} productDetails={productDetails} itemDetails={itemDetails} />
					</Col>
					<Col span={6}>
						<div className={style.projectItem}>
							<div className={style.infoBox}>
								<div className={style.title}>Order Manager</div>
								<div className={style.value}>{orderDetails.project.order_manager?.name}</div>
							</div>
							<div className={style.infoBox}>
								<div className={style.title}>Sales Manager</div>
								<div className={style.value}>{orderDetails.project.sales_manager?.name}</div>
							</div>
							<div className={style.infoBox}>
								<div className={style.title}>Delivery Deadline Date</div>
								<div className={style.value}>{formatDate(orderDetails.client_expected_delivery_date)}</div>
							</div>
							<div className={style.infoBox}>
								<div className={style.title}>Customer Artwork Deadline</div>
								<div className={style.value}>{formatDate(orderDetails.deadline_artwork_customer)}</div>
							</div>
							<div className={style.infoBox}>
								<div className={style.title}>Deadline Approval Proof Client</div>
								<div className={style.value}>{formatDate(orderDetails?.deadline_approval_proof_customer)}</div>
							</div>
							<div className={style.infoBox}>
								<div className={style.title}>Deadline Approval Proof Vendor</div>
								<div className={style.value}>{formatDate(orderDetails?.deadline_approval_proof_vendor)}</div>
							</div>
							<div className={style.infoBox}>
								<div className={style.title}>Disable Client Rating Link</div>
								<div className={style.value}>
									<Switch checked={fetcher.json?.action === 'disableClientRating' ? fetcher.formMethod === 'delete' : orderDetails.disable_rating_link} onChange={checked => {
										fetcher.submit({
											action: 'disableClientRating'
										}, {
											method: checked ? 'delete' : 'post',
											encType: 'application/json'
										})
									}} />
								</div>
							</div>
						</div>
					</Col>
				</Row>

			</Box>
			{finisherTimelineVisible && (
				<Box>
					<div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'baseline', marginBottom: '1rem' }}>
						<h2>Vendor</h2>
						<Link className={style.vendorTitle} to={generateRouteUrl('VendorUpdate', {
							id: orderDetails.selected_vendor.id
						})}>{orderDetails.selected_vendor.company}</Link>
						( <Rate value={orderDetails.selected_vendor.rating} disabled /> )
					</div>
					<Steps
						current={vendorCurrentStep}
						labelPlacement="vertical"
						items={getSupplierOrderManagerSteps()}
					/>
					<div className={style.stepBox}>
						{(orderDetails.order_status === OrderStatuses.CANCELED || orderDetails.order_status === OrderStatuses.CANCEL_REQUEST) ? (
							<CancelRequest />
						) : <ActiveVendorStepComponent />}
					</div>
				</Box>
			)}
			<Box>
				<div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'baseline', marginBottom: '1rem' }}>
					{finisherTimelineVisible && (
						<>
							<h2>Finisher</h2>
							<Link className={style.vendorTitle} to={generateRouteUrl('VendorUpdate', {
								id: orderDetails.selected_finisher.id
							})}>{orderDetails.selected_finisher.company}</Link>
							( <Rate value={orderDetails.selected_finisher.rating} disabled /> )
						</>
					)}
				</div>
				<StepsAdvance
					// currentStatus={itemDetails.order_status}
					currentStatus={urlStatus}
					history={statusHistory} //Always include 1 and 2 steps
					labelPlacement="vertical"
					// onChange={onStepChange}
					mandatorySteps={[OrderStatuses.ORDER_CONFIRMATION, OrderStatuses.ORDER_SEND, OrderStatuses.IN_PRODUCTION, OrderStatuses.DELIVERED]}
					optionalSteps={[OrderStatuses.ON_HOLD, OrderStatuses.CANCELED, OrderStatuses.CANCEL_REQUEST, OrderStatuses.DELAY_IN_DELIVERY]}
					items={getOrderManagerSteps()}
				/>
				<div className={style.stepBox}>
					<Outlet context={{
						isFinisher: finisherTimelineVisible,
						vendorRole: finisherTimelineVisible ? 'Finisher' : 'Vendor'
					}} />
				</div>
			</Box>
			<div className="actions align--left">
				{!orderDetails.isInProduction && (
					<Prompt
						type="critical"
						onConfirm={({ comment }) => {
							fetcher.submit({
								comment,
								action: 'wishToCancel'
							}, {
								method: 'post',
								encType: 'application/json'
							})
						}}
						buttonText="Wish to Cancel"
						icon={<DeleteOutlined />}
					>
						<InputWrapper name="comment" label="Comment for Sales Manager" textarea allowClear />
					</Prompt>
				)}
			</div>
			<br />
			<Collapse defaultActiveKey={['1']} expandIconPosition="end">
				<Collapse.Panel header='Documents' key="1">
					<React.Suspense fallback={<p>Loading Documents...</p>}>
						<Await
							resolve={orderDocuments}
							errorElement={
								<p>Error loading documents!</p>
							}
						>
							{(orderDocuments) => <OrderDocumentsNew projectId={orderDetails.project_id} documents={orderDocuments} />}
						</Await>
					</React.Suspense>
				</Collapse.Panel>
			</Collapse>
			<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem', alignItems: 'start' }}>
				<Collapse defaultActiveKey={['1']} expandIconPosition="end">
					<Collapse.Panel header='Vendor Communication' key="1">
						<React.Suspense fallback={<p>Loading vendor communication...</p>}>
							<Await
								resolve={vendorCommunicationHistory}
								errorElement={
									<p>Error loading vendor communication history!</p>
								}
							>
								{(messages) => <CommunicationPanel messages={messages} onSubmit={comment => {
									if (!comment || comment.length === 0) {
										message.error('Please enter a comment')
										return false
									}
									fetcher.submit({
										action: 'sendMessageToVendor',
										comment
									}, {
										method: 'post'
									})
								}} placeholder='Send message to vendor' />}
							</Await>
						</React.Suspense>
					</Collapse.Panel>
				</Collapse>
				<Collapse defaultActiveKey={['1']} expandIconPosition="end">
					<Collapse.Panel header='Internal Communication' key="1">
						<React.Suspense fallback={<p>Loading internal communication...</p>}>
							<Await
								resolve={salesCommunicationHistory}
								errorElement={
									<p>Error loading internal communication history!</p>
								}
							>
								{(messages) => <CommunicationPanel messages={messages} onSubmit={comment => {
									if (!comment || comment.length === 0) {
										message.error('Please enter a comment')
										return false
									}
									fetcher.submit({
										action: 'sendMessageToSales',
										comment
									}, {
										method: 'post'
									})
								}} placeholder='Send Message to Sales Manager' />}
							</Await>
						</React.Suspense>
					</Collapse.Panel>
				</Collapse>
			</div>
			<React.Suspense>
				<Await
					resolve={auditTrail}
					errorElement={
						<p>Error loading audit trail!</p>
					}
				>
					{(logs) => (
						<Collapse defaultActiveKey={['1']} expandIconPosition="end">
							<Panel header='Audit Trail' key="1">
								<AuditTrail logs={logs} />
							</Panel>
						</Collapse>
					)}
				</Await>
			</React.Suspense>
			{/* <Box>
				<ActivityLogs logs={auditTrail} />
			</Box> */}
		</Page>
	)
}

OrderDetails.Actions = {
	updateOrderDetails: async ({ params, data }) => {
		const { itemId } = params
		try {
			//todo: Validate only specific fields can be updated by order manager
			const msg = await updateOrderItem(itemId, data)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
			if (error instanceof ValidationError) {
				return {
					errors: error.errors
				}
			}
			return false
		}
	},
	sendOrderToVendor: async ({ params, data }) => {
		const { itemId } = params
		try {
			const msg = await sendOrderToVendor(itemId)
			const success = (vendorName, msg) => {
				Modal.success({
					title: `${msg}`,
					content: `${vendorName} has been notified to start working on the project`,
				});
			};


			success(data?.company, msg)
			return redirect(generatePath('/order-manager/:itemId/:stepId', {
				itemId,
				stepId: OrderStatuses.ORDER_SEND
			}) + '?' + (new URLSearchParams({ ordersent: true })).toString())
			// return redirect(generateRouteUrl('OrderManagerDetailsPage', {
			// 	itemId
			// }, {
			// 	ordersent: true
			// }))
		}
		catch (e) {
			message.error(e.message)
			throw e
		}
	},
	updateSelectedSpecs: async ({ params, data }) => {
		const { itemId } = params
		try {
			const { specs } = data
			const payload = Object.entries(specs).map(([key, { valueId, sort_order }]) => ({
				spec_id: Number(key),
				spec_value_id: Number(valueId),
				sort_order: Number(sort_order),
			}))
			await updateProjectItemSpecifications(itemId, payload)
			return true
		}
		catch (error) {
			console.log(error)
			message.error(error.message)
		}
		return false
	},
	customSpecification: async ({ params, data, method }) => {
		const { itemId } = params
		try {
			if (method.toUpperCase() === 'PATCH') {
				const { specId, ...specData } = data
				await updateItemCustomSpecification(specId, specData)
			}
			else if (method.toUpperCase() === 'DELETE') {
				const { specId } = data
				await deleteItemCustomSpecification(specId)
			}
			else {
				await addItemCustomSpecification(itemId, data)
			}
			return true
		}
		catch (error) {
			message.error(error.message)
			if (error instanceof ValidationError) {
				return {
					error: error.errors
				}
			}
		}
		return false
	},
	sortSpecifications: async ({ params, data }) => {
		const { itemId } = params
		try {
			const { customSpecs = [] } = data
			await sortItemCustomSpecifications(itemId, customSpecs.map(({ id, sort_order }) => ({ id, sort_order })))
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	hiddenSpecification: async ({ params, data, method }) => {
		const { itemId } = params
		try {
			const { specId } = data
			if (method.toUpperCase() === 'POST') {
				await addItemHiddenSpecification(itemId, specId)
			}
			else if (method.toUpperCase() === 'DELETE') {
				await deleteItemHiddenSpecification(itemId, specId)
			}
			return true
		}
		catch (error) {
			message.error(error.message)
			if (error instanceof ValidationError) {
				return {
					error: error.errors
				}
			}
		}
		return false
	},
	wishToCancel: async ({ params, data }) => {
		const { itemId } = params
		try {
			const { comment = '' } = data
			const msg = await wishToCancel(itemId, comment)
			Modal.info({
				title: msg,
				content: "Account manager will review the request",
			})
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	sendMessageToVendor: async ({ params, data }) => {
		const { itemId } = params
		try {
			const { comment } = data
			const msg = await sendCommentToVendor(itemId, comment)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
			if (error instanceof ValidationError) {
				return {
					errors: error.errors
				}
			}
			return false
		}
	},
	sendMessageToSales: async ({ params, data }) => {
		const { itemId } = params
		try {
			const { comment } = data
			const msg = await sendCommentToSales(itemId, comment)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
			if (error instanceof ValidationError) {
				return {
					errors: error.errors
				}
			}
			return false
		}
	},
	approveSuggestion: async ({ params, data }) => {
		const { itemId } = params
		try {
			const { suggestionId, comment = '' } = data
			const msg = await approveSuggestion(itemId, suggestionId, comment)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
			return false
		}
	},
	declineSuggestion: async ({ params, data }) => {
		const { itemId } = params
		try {
			const { suggestionId, comment = '' } = data
			const msg = await declineSuggestion(itemId, suggestionId, comment)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
			return false
		}
	},
	disableClientRating: async ({ params, method }) => {
		const { itemId } = params
		try {
			let msg;
			if (method.toLowerCase() === 'delete') {
				msg = await disableClientRating(itemId)
			}
			else {
				msg = await enableClientRating(itemId)
			}
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	...SupplierOrderConfirmationActions,
	...SupplierInProductionsActions,
	...SupplierDispatchDateConfirmActions,
	...SupplierProductDispatchActions,
	...SupplierDeliveredActions,
}


OrderDetails.Loader = async ({ params }) => {
	const { itemId } = params

	const orderDetails = await getOrderDetails(itemId)
	if (!orderDetails) {
		throw new Error('Invalid Order')
	}
	const projectDetails = await getOrderProjectDetails(itemId)
	if (!orderDetails) {
		throw new Error('Invalid Order')
	}
	const { contacts } = projectDetails

	const commentData = await getComment(itemId)
	if (!commentData) {
		throw new Error('Invalid Project Item')
	}

	const itemDetails = await getProjectItem(itemId)
	if (!itemDetails) {
		throw new Error('Invalid Project Item')
	}

	itemDetails.selected_vendor = itemDetails.selected_vendors.find(({ id }) => id === itemDetails.vendor_selected_id)
	delete itemDetails.selected_vendors

	itemDetails.vendors_prices = itemDetails.vendors_prices[itemDetails.vendor_selected_id]

	const productDetails = await getProductDetails(orderDetails.product.id, itemDetails.project.language.cms_language_code)
	if (!productDetails) {
		throw new Error('Invalid Product')
	}

	const auditTrail = getActivityLogs(itemId)
	if (!auditTrail) {
		throw new Error('invalid id')
	}
	const basicProjectDdetails = (({
		source_id, customer_id, customer, entity_id, account_manager_id, client_order_number, payment_condition_id, payment_term_id
	}) => ({
		source_id, customer_id, customer, entity_id, account_manager_id, client_order_number, payment_condition_id, payment_term_id
	}))(projectDetails)

	const orderDocuments = getOrderDocumentsNew(itemId)
	const vendorCommunicationHistory = getOrderVendorCommentHistory(itemId)
	const salesCommunicationHistory = getOrderSalesCommentHistory(itemId)

	orderDetails.isInProduction = orderDetails.order_status >= OrderStatuses.IN_PRODUCTION && ![OrderStatuses.MAKE_ARTWORK_READY, OrderStatuses.MAKE_ARTWORK_READY].includes(orderDetails.order_status)

	//Since loader are not available in supplier timeline, we need to add rating here manually
	if (orderDetails.order_delivery_status === OrderSupplierStatuses.DELIVERED) {
		try {
			orderDetails.itemSupplierRating = await getItemSupplierRating(orderDetails.id)
		}
		catch {
			orderDetails.itemSupplierRating = null
		}
	}

	const statusHistory = await getStatusHistory(itemId)

	return defer({
		orderDetails,
		statusHistory,
		auditTrail,
		projectDetails,
		contacts,
		productDetails,
		itemDetails,
		basicProjectDdetails,
		commentData,
		orderDocuments,
		vendorCommunicationHistory,
		salesCommunicationHistory
	})
}

OrderDetails.propTypes = {
	title: PropTypes.string
}
export default OrderDetails;
import PropTypes from 'prop-types';
import React from 'react';
import { Outlet, useLoaderData } from 'react-router-dom';
import { getOrderDetails, getOrderDocuments } from '../../api/order/index.js';
import Page from '../../components/page/page.jsx';

const OrderManagerFileTransfer = ({ title }) => {
    const { orderDetails } = useLoaderData()
    return (
        <Page title={title}>
            <Outlet context={{
                isFinisher: !!orderDetails.finisher,
                vendorRole: orderDetails.finisher ? 'Finisher' : 'Vendor'
            }} />
        </Page>
    )

}
OrderManagerFileTransfer.Loader = async ({ params }) => {
    if (isNaN(params.itemId)) {
        throw new Error('Invalid Order ID')
    }

    const [orderDetails, orderFiles] = await Promise.all([
        getOrderDetails(params.itemId),
        getOrderDocuments(params.itemId)
    ]);

    return {
        orderDetails,
        orderFiles,
    }
}

OrderManagerFileTransfer.propTypes = {
    title: PropTypes.string.isRequired
}

export default OrderManagerFileTransfer
export const normalizeAlphaNumeric = (value) => {
    const filteredValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
    return filteredValue;
};

export const normalizeEmail = (value) => {
    const filteredValue = value.replace(/[^a-zA-Z0-9-@.\s]/g, '');
    return filteredValue;
};

export const normalizeWebsite = (value) => {
    const filteredValue = value.replace(/[^a-zA-Z0-9-.\s]/g, '');
    return filteredValue;
};
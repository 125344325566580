import { Collapse, message } from 'antd';
import React from 'react';
import { redirect, useFetcher, useOutletContext, useRouteLoaderData } from 'react-router-dom';
import { proofRequested } from '../../api/order';
import FileTransfer from '../../components/inputs/FileTransfer';
import { generateRouteUrl } from '../../library/constants';
const { Panel } = Collapse


const SendReadyArtwork = () => {
    const { orderDetails, orderFiles } = useRouteLoaderData('OrderManagerFileTransfer');
    const fetcher = useFetcher()
    const { vendorRole } = useOutletContext() || {}
    const existingFiles = orderFiles?.make_artwork_send_to_client.length == 0 ? orderFiles.studio_artwork : orderFiles?.make_artwork_send_to_client
    return (
        <Collapse defaultActiveKey={['1']} expandIconPosition="end">
            <Panel header={`Send Artwork to ${vendorRole}`} key="1">
                <FileTransfer
                    existingFiles={existingFiles ?? []}
                    orderDetails={orderDetails}
                    loading={fetcher.state !== 'idle'}
                    onSendToVendor={(data) => fetcher.submit({
                        ...data,
                        action: 'sendToVendor'
                    }, {
                        method: 'post',
                        encType: 'application/json'
                    })} />
            </Panel>
        </Collapse>

    )

}
SendReadyArtwork.Actions = {
    sendToVendor: async ({ params, data }) => {

        try {
            const { existingFiles = [], newFiles = [], comment } = data
            const payload = {
                images: existingFiles,
                image_ids: newFiles,
                comment
            }
            const response = await proofRequested(params.itemId, payload)
            const { message: msg } = response
            message.success(msg)
            return redirect(generateRouteUrl('OrderManagerDetailsPage', { itemId: params.itemId }))
        }
        catch (e) {
            message.error(e.message)
            throw e
        }
    }
}


export default SendReadyArtwork
import { ArrowLeftOutlined, BellOutlined, ExclamationCircleFilled, HomeOutlined, LogoutOutlined } from "@ant-design/icons";
import { Badge, Button, Drawer, Empty, Layout } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useNavigate, useOutletContext, useSubmit } from 'react-router-dom';
// import { useApp } from '../../providers/app/app-context.js';
import { readNotification } from "../../api/auth/notifications.js";
import { generateRouteUrl } from "../../library/constants/routes.js";
import { getTimeAgo } from "../../library/utilities/intl.js";
import Confirm from "../message/Confirm.jsx";
import styles from './header.module.scss';
import notificationStyles from './notifications.module.scss';
const { Header: AntdHeader } = Layout;

const NotificationItem = ({ /* id, */ title, body, read, createdAt, onClick }) => {
  return (
    <div className={`${read ? notificationStyles.wrapperRead : notificationStyles.wrapper}`} onClick={onClick}>
      <div className={notificationStyles.title}>{title}</div>
      <div className={notificationStyles.content}>{body}</div>
      <div className={notificationStyles.footer}>{getTimeAgo(createdAt)}</div>
    </div>
  )
}

NotificationItem.propTypes = {
  // id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  read: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
  createdAt: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

function Header({ title, summary, backLink = true }) {
  const [showNotifications, setShowNotifications] = useState(false);
  const { notifications, unreadCount, updateNotifications } = useOutletContext() ?? {};
  const navigate = useNavigate()
  const submit = useSubmit()

  const handleLogout = () => {
    submit({
      action: 'logout',
      index: false
    }, {
      method: 'post',
      action: '/'
    })
  }


  // const enterLoading = (index) => {
  //   setLoadings((prevLoadings) => {
  //     const newLoadings = [...prevLoadings];
  //     newLoadings[index] = true;
  //     return newLoadings;
  //   });
  //   setTimeout(() => {
  //     setLoadings((prevLoadings) => {
  //       const newLoadings = [...prevLoadings];
  //       newLoadings[index] = false;
  //       return newLoadings;
  //     });
  //   }, 2000);
  // };

  return (
    <div className="site-page-header-ghost-wrapper">
      <AntdHeader className={`header ${styles.header}`}>
        <div className="main-header">
          <div className="title-area">
            <HomeOutlined style={{ fontSize: '1.2em' }} onClick={() => { navigate(generateRouteUrl('Dashboard')) }} />  / {title && <h1>{title}</h1>}
            {!!summary && <div className="summary">{summary}</div>}
          </div>
          <div className="action-area">
            {!!backLink && <Button type="primary" icon={<ArrowLeftOutlined />} onClick={() => navigate(backLink === true ? -1 : backLink)}>Back</Button>}
            <Badge count={unreadCount} title={`${unreadCount} unread notifications`} onClick={() => setShowNotifications(v => !v)} style={{ cursor: 'pointer' }}>
              <BellOutlined className="notifications" style={{ cursor: 'pointer' }} />
            </Badge>
            <h4>Logout</h4>
            <Confirm
              type="primary"
              icon={<LogoutOutlined />}
              content='Are you sure you want to Logout ?'
              modalIcon={<ExclamationCircleFilled />}
              onConfirm={handleLogout}
            />
            {/* <Button
              className="filters"
              icon={<ControlOutlined />}
              loading={loadings[1]}
              onClick={() => enterLoading(1)}
            >
              Filters
            </Button> */}
          </div>
        </div>
      </AntdHeader>
      <Drawer title="Notifications" width={450} placement="right" onClose={() => setShowNotifications(false)} open={showNotifications}>
        {(Array.isArray(notifications) && notifications.length > 0) ? notifications.map(n => (
          <NotificationItem key={n.id} onClick={async () => {
            await readNotification(n.id)
            if (n.redirect) {
              const redirectUrl = n.redirect.startsWith('/') ? n.redirect : `/${n.redirect}`;
              navigate(redirectUrl)
            }
            updateNotifications()
          }} {...n} />
        )) : <Empty description="No notifications" />}
      </Drawer>
    </div>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.string,
  backLink: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  user: PropTypes.shape({}),
};

export default Header;

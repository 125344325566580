export const VendorQuotationStatuses = {
    ACTIVE: 1,
    SENT: 2,
    VISUAL_REQUESTED: 3,
    VISUAL_SENT: 4,
    CANCELED: 5,
    APPROVED: 6,
}

export const VendorQuotationStatusText = {
    [VendorQuotationStatuses.ACTIVE]: 'Quotation request sent',
    [VendorQuotationStatuses.SENT]: 'Vendor Sent Quotation',
    [VendorQuotationStatuses.VISUAL_REQUESTED]: 'Vendor Requested Visual',
    [VendorQuotationStatuses.VISUAL_SENT]: 'Visual Sent to Vendor',
    [VendorQuotationStatuses.CANCELED]: 'Quotation Request Cancelled',
    [VendorQuotationStatuses.APPROVED]: 'Approved',
}
import PropTypes from 'prop-types';
import React from 'react';
import { FormContext } from './form-context.js';

function FormProvider(props) {
  const { children, validationRules, errors = {} } = props;

  return <FormContext.Provider value={{ validationRules, errors }}>{children}</FormContext.Provider>;
}

FormProvider.propTypes = {
  children: PropTypes.node,
  validationRules: PropTypes.object.isRequired,
  errors: PropTypes.object,
}

export default FormProvider

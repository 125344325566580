import { EditOutlined, SwapOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';
import React from "react";
import { Link, useRouteLoaderData } from 'react-router-dom';
import { generateRouteUrl } from '../../../library/constants';
import style from './proof-rejected.module.scss';


const ProofRejectedByCustomer = () => {
    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')
    return (
        <div className={style.container}>
            <Result
                status="warning"
                title="Proof has been rejected by customer."
                subTitle={orderDetails.comments?.client_proof_rejected}
                extra={[
                    <Link key="CustomerArtworkToVendor" to={generateRouteUrl('CustomerArtworkToVendor', {
                        itemId: orderDetails.id,
                    })}>
                        <Button className={style.greenButton} icon={<SwapOutlined />}>Re - Request Proof from Vendor</Button>
                    </Link>,
                    <Link key="UpdateProof" to={generateRouteUrl('UpdateProof', {
                        itemId: orderDetails.id,
                    })}>
                        <Button className={style.greenButton} icon={<EditOutlined />}>Update Existing Proof</Button>
                    </Link>
                ]}
            />
        </div>
    )
}


export default ProofRejectedByCustomer;
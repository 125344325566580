import grapesjs from 'grapesjs';
import pluginNewsletter from 'grapesjs-preset-newsletter';
import 'grapesjs/dist/css/grapes.min.css';
import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { EmailTemplateEditorCSS, EmailTemplateFooter, EmailTemplateHeader } from '../../library/constants/email-template.js';
import GrapeJsImageLink from '../../library/grapejs-image-link.js';
import GrapeJsMainTable from '../../library/grapesjs-main-table.js';

grapesjs.plugins.add('gjs-plugin-show-json', (editor, options) => {
  editor.Panels.addButton('options', {
    id: 'show-json',
    className: 'fa fa-clipboard',
    command: 'show-json',
    attributes: {
      title: 'Show JSON',
    },
  });

  editor.Commands.add('show-json', {
    run(editor) {
      const json = JSON.parse(options?.sample);
      const formattedJson = JSON.stringify(json, null, 2);
      editor.Modal.open({
        title: 'Placeholders available',
        content: `<pre>${formattedJson}</pre>`,
      });
      // alert(JSON.stringify(json, null, 2)); // Display JSON in an alert

    },
  });
});

grapesjs.plugins.add('grapesjs-header-footer', (editor, opts = {}) => {
  const options = {
    headerHtml: `
        <div id="static-header" style="background-color:#f6f6f6; padding: 30px; text-align: center;">
          <img src="https://via.placeholder.com/150" alt="logo" width="150" height="50" />
        </div>
      `,
    footerHtml: `
        <div id="static-footer" style="background-color:#f6f6f6; padding: 30px; text-align: center;">
          <p>&copy; 2023 Company. All rights reserved.</p>
        </div>
      `,
    ...opts,
  };

  editor.on('load', () => {
    const canvas = editor.Canvas.getElement();
    const iframe = canvas.querySelector('iframe');
    const iframeDoc = iframe?.contentDocument;

    const editorCss = ` [data-gjs-type="wrapper"] {
        min-height: 100px!important
    }
    .main-container{
      min-height: 100px!important
    }`
    editor.addStyle(editorCss)


    // Add header if not already present
    if (!iframeDoc.querySelector('#static-header')) {
      const header = document.createElement('div');
      header.innerHTML = options.headerHtml;
      iframeDoc.body.insertBefore(header, iframeDoc.body.firstChild);
    }

    // Add footer if not already present
    if (!iframeDoc.querySelector('#static-footer')) {
      const footer = document.createElement('div');
      footer.innerHTML = options.footerHtml;
      iframeDoc.body.appendChild(footer);
    }

    const style = document.createElement('style');
    style.innerHTML = EmailTemplateEditorCSS
    iframeDoc.head.appendChild(style);
  });
})

grapesjs.plugins.add('gjs-plugin-heading', (editor) => {
  editor.BlockManager.add('heading', {
    label: 'Heading',
    content: {
      type: 'heading',
      content: 'Heading',
    },
    attributes: { class: 'fa fa-header' },
  });

  editor.DomComponents.addType('heading', {
    isComponent: el => ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'].includes(el.tagName),
    extend: 'text',
    model: {
      defaults: {
        tagName: 'h1',
        droppable: false,
        editable: true,
        traits: [
          {
            type: 'select',
            label: 'Heading Type',
            name: 'tagName',
            options: [
              { value: 'h1', name: 'H1' },
              { value: 'h2', name: 'H2' },
              { value: 'h3', name: 'H3' },
              { value: 'h4', name: 'H4' },
              { value: 'h5', name: 'H5' },
              { value: 'h6', name: 'H6' },
            ],
            changeProp: 1
          },
        ],
      },
      init() {
        this.listenTo(this, 'change:tagName', this.handleTagChange);
      },
      handleTagChange() {
        const newTagName = this.get('tagName');
        const content = this.view.el.innerHTML; // Preserve the current content

        // Create new element with the updated tag name
        const newEl = document.createElement(newTagName);
        newEl.innerHTML = content;

        // Replace the current element with the new one
        this.view.el.parentNode.replaceChild(newEl, this.view.el);

        // Update the view and model with the new element and content
        this.view.setElement(newEl);
        this.set({ tagName: newTagName });
        this.trigger('change:content', this); // Trigger content change

        // Re-render the view to reflect the changes
        this.view.render();
      },
    },
    view: {
      onRender() {
        const el = this.el;
        el.contentEditable = true
        const tagName = this.model.get('tagName');
        if (el.tagName.toLowerCase() !== tagName) {
          const newEl = document.createElement(tagName);
          newEl.innerHTML = el.innerHTML;
          el.parentNode.replaceChild(newEl, el);
          this.setElement(newEl);
        }
      }
    }
  });
});

const GrapesJSEditor = forwardRef(function GrapesJSEditor({ content, cssStyles, sample, header = true, footer = true, onChange }, ref) {
  const editorRef = useRef(null);
  const grapesJSEditorInstance = useRef(null); // Store the initialized instance

  const loseFocus = (editor) => {
    // Get the selected component
    const selectedComponent = editor.getSelected();

    // If there's a selected component, deselect it
    if (selectedComponent) {
      editor.select(null);
    }

    // Get the canvas element and blur it
    const iframe = editor.Canvas.getFrameEl();
    if (iframe) {
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      const activeElement = iframeDoc.activeElement;
      if (activeElement && typeof activeElement.blur === 'function') {
        activeElement.blur();
      }
    }
  };

  useEffect(() => {
    if (!grapesJSEditorInstance.current) {
      const editor = grapesjs.init({
        container: editorRef.current,
        plugins: [pluginNewsletter, GrapeJsMainTable, GrapeJsImageLink, 'grapesjs-header-footer', 'gjs-plugin-heading', 'gjs-plugin-show-json'],
        pluginsOpts: {
          'grapesjs-preset-newsletter': {
            // options for the newsletter preset
          },
          'grapesjs-header-footer': {
            headerHtml: header ? EmailTemplateHeader : null,
            footerHtml: footer ? EmailTemplateFooter : null,
          },
          'gjs-plugin-show-json': {
            sample
          },
          'grapesjsCustomTable': {}
        },
        canvas: {
          styles: [
            'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap',
          ]
        },
        fromElement: true,
        styleManager: {
          clearProperties: true,
        },
        // Setting global width
        minHeight: '50vh',
        width: '100%',
        storageManager: { type: 0 },
      });

      // Listen for the load event before setting content and style
      editor.on('load', () => {
        // Load the initial content into the editor
        editor.setComponents(content);
        editor.setStyle(cssStyles);

        // Debounce handleChange to prevent too many calls
        const debouncedHandleChange = debounce(() => {
          if (onChange) {
            console.log('changing...')
            const html = editor.runCommand('gjs-get-inlined-html');
            const css = editor.getCss();
            onChange({ html, css });
          }
        }, 100); // 100ms debounce time

        // Listen to meaningful events that indicate actual changes
        editor.on('update', debouncedHandleChange);
        editor.on('component:add', debouncedHandleChange);
        editor.on('component:remove', debouncedHandleChange);
        editor.on('styleManager:change', debouncedHandleChange);
        editor.on('component:styleUpdate', debouncedHandleChange);
        editor.on('component:update', debouncedHandleChange); // Detects inline text edits and more general updates
        editor.on('change:content', debouncedHandleChange); // Detects content changes such as typing
        editor.on('component:change', debouncedHandleChange); // Detects changes made to any component

      })

      grapesJSEditorInstance.current = editor;

    } else {
      // If the editor already exists, update the content without reinitializing
      const editor = grapesJSEditorInstance.current;
      editor.on('load', () => {
        editor.setComponents(content);
        editor.setStyle(cssStyles);
      });
    }

    // Clean up function if needed
    return () => {
      if (grapesJSEditorInstance.current) {
        grapesJSEditorInstance.current.destroy();
        grapesJSEditorInstance.current = null;
      }
    };
  }, [content, cssStyles, header, footer]);

  // UseImperativeHandle allows us to expose some methods to the parent component via the ref
  useImperativeHandle(ref, () => ({
    getHtmlAndCss: () => {
      if (grapesJSEditorInstance.current) {
        loseFocus(grapesJSEditorInstance.current); // Make sure all changes are committed
        const html = grapesJSEditorInstance.current.runCommand('gjs-get-inlined-html');
        const css = grapesJSEditorInstance.current.getCss();
        return { html, css };
      }
      return null;
    },
    isDirty: () => {
      if (grapesJSEditorInstance.current) {
        loseFocus(grapesJSEditorInstance.current); // Make sure all changes are committed
        const html = grapesJSEditorInstance.current.runCommand('gjs-get-inlined-html');
        console.log({ content, html })
        return content !== html;
      }
      return false; // If the editor isn't loaded yet, it's not dirty
    }
  }), []);

  return <div ref={editorRef} />;
})

GrapesJSEditor.propTypes = {
  content: PropTypes.string,
  cssStyles: PropTypes.string,
  sample: PropTypes.string,
  header: PropTypes.bool,
  footer: PropTypes.bool,
  onChange: PropTypes.func,
}

export default GrapesJSEditor;

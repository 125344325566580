/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from "../common";

const ENDPOINT = `/user/:action`

export const emailLogin = (headers, payload) => axiosInstance.post(generatePath(ENDPOINT, {
    action: 'login'
}), payload, { headers });

export const resetPassword = (email) => axiosInstance.post(generatePath(ENDPOINT, {
    action: 'reset-password'
}), {
    email
}).then(({ message }) => message);

export const changePassword = (password, token) => axiosInstance.post(generatePath(ENDPOINT, {
    action: 'change-password'
}), {
    token,
    change_password: password
}).then(({ message }) => message);

export const logout = () => axiosInstance.post(generatePath(ENDPOINT, {
    action: 'logout'
})).then(({ message }) => message)
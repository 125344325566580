import { Collapse, message } from 'antd';
import React from 'react';
import { redirect, useFetcher, useRouteLoaderData } from 'react-router-dom';
import { sendProoftoClient } from '../../api/order';
import FileTransfer from '../../components/inputs/FileTransfer';
import { generateRouteUrl } from '../../library/constants';
const { Panel } = Collapse




const SendReadyProof = () => {
    const { orderDetails, orderFiles } = useRouteLoaderData('OrderManagerFileTransfer');
    const fetcher = useFetcher()
    return (
        <Collapse defaultActiveKey={['1']} expandIconPosition="end">
            <Panel header='Send Ready Proof to Customer' key="1">
                <FileTransfer
                    existingFiles={orderFiles?.studio_proof ?? []}
                    orderDetails={orderDetails}
                    loading={fetcher.state !== 'idle'}
                    onSendToCustomer={(data) => fetcher.submit({
                        ...data,
                        action: 'sendToCustomer'
                    }, {
                        method: 'post',
                        encType: 'application/json'
                    })} />
            </Panel>
        </Collapse>

    )
}

SendReadyProof.Actions = {
    sendToCustomer: async ({ params, data }) => {

        try {
            const { existingFiles = [], newFiles = [], comment } = data
            const payload = {
                images: existingFiles,
                image_ids: newFiles,
                comment
            }
            const response = await sendProoftoClient(params.itemId, payload)
            const { message: msg } = response
            message.success(msg)
            return redirect(generateRouteUrl('OrderManagerDetailsPage', { itemId: params.itemId }))
        }
        catch (e) {
            message.error(e.message)
            throw e
        }
    }
}

export default SendReadyProof
import { Alert, Button, message } from 'antd';
import PropTypes from 'prop-types';
import React from "react";
import { useFetcher, useRouteLoaderData } from 'react-router-dom';
import { sendSupplierDispatchDateNotification, updateSupplierExpectedDispatchDate } from '../../../api/order/supplier-finisher-flow.js';
import { Box } from '../../../components/design/box.js';
import { DatePicker, FieldWrapper } from "../../../components/inputs";
import { ValidationError } from '../../../library/classes/exceptions/index.js';
import { generateRouteUrl } from '../../../library/constants/routes.js';
import { getTimeAgo } from '../../../library/utilities/intl.js';
import styles from "./supplier-in-production.module.scss";

const getFormattedAddress = (address) => <>{address.street_number},{address.street},{address.city}</>
const ShipmentExpectedDispatchDate = ({ itemId, delivery }) => {
    const fetcher = useFetcher()
    const { errors = {} } = fetcher.data || {}

    return (
        <Box header={
            <div className={styles.deliveryRowHead}>
                <div>Quantity</div>
                <div>Delivery Address</div>
                <div>Expected Dispatch Date</div>
                <div></div>
            </div>
        }>
            <fetcher.Form method="post" action={generateRouteUrl('OrderManagerDetailsPage', {
                itemId
            })}>
                <div className={styles.deliveryRow}>
                    <div className="quantity">{delivery.quantity}</div>
                    <div className="address">{getFormattedAddress(delivery)}</div>
                    <div className="field-expected_expected_date">
                        <FieldWrapper name="expected_dispatch_date" errors={errors} >
                            <DatePicker name="expected_dispatch_date" value={delivery.expected_dispatch_date} useHidden />
                        </FieldWrapper>
                    </div>
                </div>
                <div className="actions align--right">
                    <Button type='primary' htmlType='submit' name="action" value="updateSupplierDispatchDate" loading={fetcher.state !== 'idle'}>Save & Update Finisher</Button>
                </div>
            </fetcher.Form >
        </Box>
    )
}

ShipmentExpectedDispatchDate.propTypes = {
    itemId: PropTypes.number.isRequired,
    delivery: PropTypes.object.isRequired,
}

export const SupplierInProduction = () => {
    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')
    const fetcher = useFetcher()
    return (
        <div className={styles.container}>
            <h3>Task For You</h3>
            <Alert message="Vendor has started the Production" type="info" showIcon />
            <Alert message="Ask Vendor" description={!orderDetails.delivery_notify_vendor_for_expected_time ? "Ask Vendor to provide an expected transit date of the order" : `Vendor has been notified to share expected transit date ${getTimeAgo(orderDetails.delivery_notify_vendor_for_expected_time)} `} type="info" action={
                <Button onClick={() => {
                    fetcher.submit({
                        action: 'supplierAskDispatchDate'
                    }, {
                        method: 'post'
                    })
                }} loading={fetcher.state !== 'idle'}>{!orderDetails.delivery_notify_vendor_for_expected_time ? 'Ask Vendor' : 'Send Reminder'}</Button>
            } showIcon />
            <ShipmentExpectedDispatchDate itemId={orderDetails.id} delivery={orderDetails.item_finisher_address ?? {}} />
        </div>
    )
}

/**
 * !!IMPORTANT!!
 * These actions will not work automatically
 * These needs to be adeed to orderDetails Actions 
 */
export const SupplierInProductionsActions = {
    supplierAskDispatchDate: async ({ params }) => {
        const { itemId } = params
        try {
            const msg = await sendSupplierDispatchDateNotification(itemId)
            message.success(msg);
            return true
        }
        catch (error) {
            message.error(error.message)
        }
        return false
    },
    updateSupplierDispatchDate: async ({ params, data }) => {
        const { itemId } = params
        try {
            const { expected_dispatch_date } = data
            const msg = await updateSupplierExpectedDispatchDate(itemId, expected_dispatch_date)
            message.success(msg);
            return true
        }
        catch (error) {

            console.log({ error })
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
        }
        return false
    },

}

export const UserRoleTypes = {
  DASHBOARD: '101',
  Supervisor: 102,
  Vendor: 201,
  Admin: 901,
  SuperAdmin: 999,
}

export const UserRoles = {
  SUPPER_USER: "supper_user",
  SALES_MANAGER: "sales_manager",
  SALES_SENIOR: "sales_senior",
  SALES_EMPLOYEE: "sales_employee",
  ORDER_MANAGER: "order_manager",
  ADMINISTRATION_MANAGER: "administration_manager",
  ADMINISTRATION: "administration",
  ITEM_EMPLOYEE: "item_employee",
  STUDIO: "studio",
  VENDOR_ADMIN: "vendor_admin",
  VENDOR_LIGHT_USER: "vendor_light_user",
  VENDOR_QUOTATION: "vendor_quotation",
}
import { Button, Modal } from "antd";
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { parseFormData } from "../../library/utilities/formdata.js";

const getParsedFormData = (form) => {
    const formData = new FormData(form)
    return parseFormData(formData)
}

const CustomPrompt = ({ children, buttonText, onConfirm, additionalActions = {}, buttonProps, title, okText = 'Continue', cancelText = 'Cancel', onCancel = null, modalIcon, width, ...otherProps }) => {
    const [visible, setVisible] = useState(false)

    const showPrompt = () => setVisible(true)
    const closePrompt = () => setVisible(false)

    const handleOk = () => {
        //Prevent close only if onConfirm return false
        if (onConfirm(getParsedFormData(formRef.current)) === false) {
            return
        }
        closePrompt()
    }

    const handleCancel = () => {
        if (onCancel) {
            onCancel(closePrompt)
        }
        closePrompt()
    }

    const formRef = useRef(null)

    return (
        <>
            <Button onClick={showPrompt} {...buttonProps}>{buttonText}</Button>
            <Modal
                {...otherProps}
                open={visible}
                title={title}
                okText={okText}
                cancelText={cancelText}
                closable={true}
                maskClosable={true}
                width={width}
                onCancel={handleCancel}
                icon={modalIcon}
                footer={[
                    !!additionalActions && Object.entries(additionalActions).map(([key, { onClick, text, ...otherProps }]) => (
                        <Button key={key} onClick={onClick ? () => {
                            //Prevent close only if onConfirm return false
                            if (onClick(getParsedFormData(formRef.current)) === false) {
                                return
                            }
                            closePrompt()
                        } : undefined} {...otherProps}>{text}</Button>
                    )),
                    <Button key="cancel" onClick={handleCancel}>{cancelText}</Button>,
                    <Button key="ok" type="primary" onClick={handleOk}>{okText}</Button>
                ]}
            >
                <form ref={formRef}>
                    {children}
                </form>
            </Modal>
        </>
    )
}

CustomPrompt.propTypes = {
    children: PropTypes.node,
    buttonText: PropTypes.node,
    buttonProps: PropTypes.object,
    additionalActions: PropTypes.object,
    title: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    okText: PropTypes.node,
    cancelText: PropTypes.node,
    modalIcon: PropTypes.node,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
}

export { CustomPrompt };


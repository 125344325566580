import { SendOutlined } from '@ant-design/icons';
import { Avatar, Button, Comment, List } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useRouteLoaderData } from 'react-router-dom';
import { getTimeAgo } from '../../library/utilities/intl.js';
import { InputWrapper } from '../inputs';

const CommunicationPanel = ({ messages, placeholder = "", onSubmit }) => {
    // const { user } = useAuth()
    const { user } = useRouteLoaderData('Root')
    const [inputMessage, setInputMessage] = useState('')
    return (
        <>
            <List
                // header={
                //     <div>
                //         <InputWrapper name="comment" label="Comment" placeholder="Send message to vendor" rows={3} defaultValue={inputMessage} onChange={e => setInputMessage(e.target.value)} delay textarea required />
                //         <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                //             <Button type='primary' icon={<SendOutlined />} onClick={() => {
                //                 onSubmit(inputMessage)
                //                 setInputMessage('')
                //             }}>Send</Button>
                //         </div>
                //     </div>
                // }
                style={{ maxHeight: '250px', overflow: 'auto' }}
                dataSource={messages}
                renderItem={(item) => (
                    <li>
                        <Comment
                            className={item.source === 1 && 'ant-comment-right'}
                            author={item.user.name}
                            avatar={<Avatar alt={item.user?.name}>{item.user?.name?.[0]?.toUpperCase()}</Avatar>}
                            content={item.message}
                            datetime={getTimeAgo(item.createdAt)}
                        />
                    </li>
                    // <List.Item className={item.source === 1 && 'ant-list-item-right'}>
                    //     <List.Item.Meta
                    //         avatar={
                    //             item.source === 1 ?
                    //                 <Avatar src='/images/logo_100.png' /> :
                    //                 <Avatar>{item.user.name[0]}</Avatar>
                    //         }
                    //         title={
                    //             <>
                    //                 {item.user.name}
                    //                 <span className="time">{getTimeAgo(item.createdAt)}</span>
                    //             </>
                    //         }
                    //         description={item.message}
                    //     />
                    // </List.Item>
                )}
            />
            <Comment
                className="ant-comment-form"
                avatar={<Avatar alt={user.name}>{user.name?.[0]?.toUpperCase()}</Avatar>}
                content={
                    <>
                        <InputWrapper name="comment" label="Comment" placeholder={placeholder} rows={3} value={inputMessage} onChange={e => setInputMessage(e.target.value)} textarea required />
                        <div className="actions align--right" style={{ marginTop: '0.5rem' }}>
                            <Button type='primary' icon={<SendOutlined />} htmlType='submit' onClick={() => {
                                const comment = inputMessage.trim()
                                if (comment?.length > 0) {
                                    onSubmit(comment)
                                    setInputMessage('')
                                }
                            }} disabled={inputMessage.trim().length === 0}>Send</Button>
                        </div>
                    </>
                }
            />
        </>
    )
}
CommunicationPanel.propTypes = {
    messages: PropTypes.arrayOf(PropTypes.shape({
        message: PropTypes.string.isRequired,
        user: PropTypes.object.isRequired,
        source: PropTypes.number.isRequired,
    })),
    onSubmit: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
}

export { CommunicationPanel };

export const StudioKanabanColumn =
    [
        {
            key: 1,
            title: 'Template Request',
        },
        {
            key: 2,
            title: 'Sent Template',
        },
        {
            key: 3,
            title: 'Vendor Artwork Request',
        },
        {
            key: 4,
            title: 'Sent Vendor Artwork',
        },
        {
            key: 5,
            title: 'Client Proof Request',
        },
        {
            key: 6,
            title: 'Client Proof Sent',
        },
        {
            key: 7,
            title: 'Make Artwork Request',
        },
        {
            key: 8,
            title: 'Make Artwork Sent',
        },
    ]
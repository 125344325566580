// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT = '/contact/:id';
const ENDPOINT_SINGLE_ACTION = '/contact/:id/:action';

// Define all api functions here
export const getContact = async (id) => {
    const data = await axiosInstance.get(generatePath(ENDPOINT, {
        id
    }), {})
    return data
}

// export const updateContact = (id, payload) => {
//     payload = pick(payload, ["first_name", "last_name", "email", 'customer_id', "language_id", "phone_country_code", "mobile_country_code", "phone_number", "mobile_number", "gender_id", "country_id", "role_id"])
//     return axiosInstance.patch(generatePath(ENDPOINT, {
//         id
//     }), payload)
// }

export const deleteContact = (id) => {
    return axiosInstance.delete(generatePath(ENDPOINT, {
        id
    }))
}

export const getContactOrders = async (id) => axiosInstance.get(generatePath(ENDPOINT_SINGLE_ACTION, {
    id,
    action: 'orders'
}), {}).then(({ data }) => data)
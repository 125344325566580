import { Button, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Form } from 'react-router-dom';
import OrderManagerStudio from '../../components/data/studio/orderMangerStudio';
import SendToStudio from '../../components/data/studio/sendTOstudio';
import { Tabs } from '../../components/design';
import { ArtworkSelector } from '../../components/form';
import { Languages } from '../../library/constants/dynamic.js';
import { GeneralFieldWrapper, InputWrapper } from './input-wrappers.jsx';

const FileTransfer = ({ existingFiles, orderDetails, orderManagerStudioActionText, manualUploadActionText, loading, onSendToStudio, onOrderManagerStudio, onSendToCustomer, onSendToVendor, onManualUpload, onReject }) => {
    const selectedFiles = useRef()
    const projectLanuage = Languages.find(({ id }) => id === orderDetails.project.language_id)?.cms_language_code

    const tabList = [
        {
            label: 'Send to Studio',
            key: 'sendToStudio',
            isVisible: !!onSendToStudio,
            children:
                <SendToStudio orderDetails={orderDetails} onSubmit={(formData) => {
                    if (existingFiles.length !== 0 && (selectedFiles.current?.length === 0)) {
                        message.error('Please Select at least one file')
                        return
                    }
                    const _data = { ...Object.fromEntries(formData.entries()), existingFiles: selectedFiles.current }
                    onSendToStudio(_data)
                }} languageCode={projectLanuage} loading={loading} />
        },
        {
            label: 'Order Manager (Studio)',
            key: 'orderManager',
            isVisible: !!onOrderManagerStudio,
            children:
                <OrderManagerStudio
                    orderDetails={orderDetails}
                    onSubmit={imageData => (
                        onOrderManagerStudio({
                            existingFiles: selectedFiles.current ?? [],
                            newFiles: imageData
                        })
                    )}
                    actionText={orderManagerStudioActionText}
                    languageCode={projectLanuage}
                    loading={loading}
                />
        },
        {
            label: 'Send to Vendor',
            key: 'sendToVendor',
            isVisible: !!onSendToVendor,
            children: (
                <Form className='box--light-gray' onSubmit={async (e) => {
                    e.preventDefault()
                    if (!selectedFiles.current || selectedFiles.current.length === 0) {
                        message.error('Please Select at least one file')
                        return
                    }
                    const formData = new FormData(e.currentTarget)
                    const { comment } = Object.fromEntries(formData.entries())
                    onSendToVendor({
                        existingFiles: selectedFiles.current,
                        comment
                    })
                }}>
                    <div className="form-fields-wrapper--vertical">
                        <InputWrapper name="comment" label="Comment for Vendor" placeholder="Message for Vendor" allowClear />
                        <GeneralFieldWrapper style={{ alignItems: 'flex-start' }} horizontal>
                            <Button htmlType='submit' type="primary" loading={loading}>Send to Vendor</Button>
                        </GeneralFieldWrapper>
                    </div>
                </Form>
            )
        },
        {
            label: 'Send to Customer',
            key: 'sendToCustomer',
            isVisible: !!onSendToCustomer,
            children: (
                <Form className='box--light-gray' onSubmit={async (e) => {
                    e.preventDefault()
                    if (!selectedFiles.current || selectedFiles.current.length === 0) {
                        message.error('Please Select at least one file')
                        return
                    }
                    const formData = new FormData(e.currentTarget)
                    const { comment } = Object.fromEntries(formData.entries())
                    onSendToCustomer({
                        existingFiles: selectedFiles.current,
                        comment
                    })
                }}>
                    <div className="form-fields-wrapper--vertical">
                        <InputWrapper name="comment" label="Comment for Customer" placeholder="Message for Customer" allowClear />
                        <GeneralFieldWrapper style={{ alignItems: 'flex-start' }} horizontal>
                            <Button htmlType='submit' type="primary" loading={loading}>Send to customer</Button>
                        </GeneralFieldWrapper>
                    </div>
                </Form>
            )
        },
        {
            label: manualUploadActionText,
            key: 'manualUpload',
            isVisible: !!onManualUpload,
            children:
                <OrderManagerStudio
                    orderDetails={orderDetails}
                    onSubmit={imageData => (
                        onManualUpload({
                            existingFiles: selectedFiles.current ?? [],
                            newFiles: imageData
                        })
                    )}
                    actionText={manualUploadActionText}
                    languageCode={projectLanuage}
                    loading={loading}
                />
        },
        {
            label: 'Reject',
            key: 'reject',
            isVisible: !!onReject,
            children: (
                <Form className='box--light-gray' onSubmit={async (e) => {
                    e.preventDefault()
                    const formData = new FormData(e.currentTarget)
                    const { comment } = Object.fromEntries(formData.entries())
                    onReject({
                        comment
                    })
                }}>
                    <div className="form-fields-wrapper--vertical">
                        <InputWrapper name="comment" label="Reason for Rejection" placeholder="Specify reason for rejection" allowClear />
                        <GeneralFieldWrapper style={{ alignItems: 'flex-start' }} horizontal>
                            <Button htmlType='submit' type="primary" loading={loading}>Reject</Button>
                        </GeneralFieldWrapper>
                    </div>
                </Form>
            )
        },
    ]

    const activeTabs = tabList.filter(({ isVisible }) => !!isVisible)

    return (
        <>
            {!!existingFiles && existingFiles.length > 0 && <ArtworkSelector ref={selectedFiles} artworks={existingFiles} project_id={orderDetails.project_id} />}
            <Tabs list={activeTabs}></Tabs>
        </>
    )
}

FileTransfer.propTypes = {
    existingFiles: PropTypes.arrayOf(PropTypes.object),
    orderDetails: PropTypes.object.isRequired,
    orderManagerStudioActionText: PropTypes.string,
    manualUploadActionText: PropTypes.string,
    loading: PropTypes.bool,
    onSendToStudio: PropTypes.func,
    onOrderManagerStudio: PropTypes.func,
    onSendToCustomer: PropTypes.func,
    onSendToVendor: PropTypes.func,
    onManualUpload: PropTypes.func,
    onReject: PropTypes.func
    // stateChanger :PropTypes.func
}
export default FileTransfer
import { getEnvVariable } from "../utilities/common.js"

export const Server = {
    BaseUrl: process.env.REACT_APP_SERVER_BASE_URL,
    getFileUrl: (path) => {
        return `${process.env.REACT_APP_BASE_API_URL}/${process.env.REACT_APP_VERSION_V1}/${path}`
    },
    getPublicFileUrl: (path) => {
        return `${getEnvVariable('SERVER_BASE_URL')}/data/${process.env.REACT_APP_VERSION_V1}/${path}`
    }
}
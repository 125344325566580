import { Collapse, message } from 'antd';
import React from 'react';
import { redirect, useFetcher, useRouteLoaderData } from 'react-router-dom';
import { sendTemplatetoCustomer } from '../../api/order';
import FileTransfer from '../../components/inputs/FileTransfer';
import { generateRouteUrl } from '../../library/constants';
const { Panel } = Collapse

const SendReadyTemplate = () => {

    const { orderDetails, orderFiles } = useRouteLoaderData('OrderManagerFileTransfer');
    const fetcher = useFetcher()

    return (
        (
            <Collapse defaultActiveKey={['1']} expandIconPosition="end">
                <Panel header='Template Received from Studio' key="1">
                    <FileTransfer
                        orderManagerStudioActionText={"Send to Customer"}
                        existingFiles={orderFiles?.studio_template ?? []}
                        orderDetails={orderDetails}
                        loading={fetcher.state !== 'idle'}
                        onSendToCustomer={(data) => {
                            const { existingFiles, comment } = data
                            fetcher.submit({
                                existingImages: existingFiles,
                                action: 'sendToCustomer',
                                comment
                            }, {
                                method: 'post',
                                encType: 'application/json'
                            })
                        }}
                    />
                </Panel>
            </Collapse>

        )

    )
}
SendReadyTemplate.Actions = {

    sendToCustomer: async ({ params, data }) => {
        try {
            const { existingImages = [], newFiles = [], comment } = data
            const payload = {
                images: existingImages,
                image_ids: newFiles,
                comment
            }
            const { message: msg } = await sendTemplatetoCustomer(params.itemId, payload)
            message.success(msg)
            return redirect(generateRouteUrl('OrderManagerDetailsPage', { itemId: params.itemId }))
        }
        catch (error) {
            message.error(error.message)
            return false
        }
    },

}


export default SendReadyTemplate
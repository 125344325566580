import PropTypes from 'prop-types';
import React from 'react';

const PdfPreview = ({ url }) => {
    const parsedUrl = new URL(url, window.location.origin);
    parsedUrl.searchParams.set('v', Date.now());
    console.log({ url, parsedUrl: parsedUrl.toString() })

    return (
        <object data={parsedUrl.toString()} type="application/pdf" width="100%" height="100%">
            <p>Unable to load file.Click on the link below to view the file</p>
            <p><a target="_blank" rel="noreferrer" href={parsedUrl.toString()}>View File</a></p>
        </object>
    )
}

PdfPreview.propTypes = {
    url: PropTypes.string.isRequired
}

export { PdfPreview };

import { throttle } from 'lodash-es'
import { useEffect, useRef } from 'react'

const useElementReachedBottomListner = ({ loadMore, hasMore, element }) => {
    const scrollListener = useRef(loadMore)
    const observer = useRef(null)
    const debouncedLoadMore = useRef(throttle(() => {
        scrollListener.current()
    }, 200)).current;

    // Function to handle IntersectionObserver entries
    const handleIntersection = (entries, obs) => {
        const anyIntersecting = entries.some(entry => entry.isIntersecting);

        if (anyIntersecting) {
            debouncedLoadMore();
            // Optionally unobserve the elements that are now visible
            if (!hasMore) {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        obs.unobserve(entry.target);
                    }
                });
            }
        }
    }

    useEffect(() => {
        scrollListener.current = loadMore
        debouncedLoadMore.cancel()
        debouncedLoadMore.current = throttle(() => {
            scrollListener.current()
        }, 200)
    }, [loadMore])

    useEffect(() => {
        if (element && hasMore) {
            observer.current = new IntersectionObserver(handleIntersection, {
                root: null,
                rootMargin: '0px',
                threshold: 0.1
            });


            if (Array.isArray(element)) {
                // element.forEach(observeElement);
                element.forEach(el => observer.current.observe(el));
            } else {
                // observeElement(element);
                observer.current.observe(element)
            }

            return () => {
                if (observer.current) {
                    observer.current.disconnect();
                }
            }
        }
    }, [hasMore, debouncedLoadMore, element])

}

export default useElementReachedBottomListner
import { ApartmentOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Select, Spin, TreeSelect } from 'antd';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getAllCategories, getProductDetails, searchProducts } from '../../api/product';
import Languages from '../../library/dynamics/languages';
import { productUrl } from '../../library/helpers/product_url';

function ProductSelect({ language, value = null, onChange, currentSelection = {}, disabled = false, ...otherProps }) {
    const debounceTimeout = 800
    const [fetching, setFetching] = useState(false)
    const [product, setProduct] = useState({})
    const [categories,
        setCategories
    ] = useState([])
    const [selectedCategory, setSelectedCategory] = useState()
    const [options, setOptions] = useState([{ value: currentSelection?.id, label: currentSelection?.name }]);
    const fetchRef = useRef(0);
    const searchQueryRef = useRef('')

    useEffect(() => {
        if (currentSelection?.id) {
            setProduct(currentSelection)
            setSelectedCategory(currentSelection.category_id)
            // getProductDetails(currentSelection.id, language)
            //     .then((data) => {
            //         // setSelectedCategory(data?.category_id)
            //         // onChange(data)
            //         // console.log(data);
            //         setProduct(data);
            //     })
        }

        debounceFetcher(null, currentSelection?.category_id)
        getAllCategories(language)
            .then(data => {
                setCategories(data)
                // searchProducts(data)
            })
    }, [])

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value = null, categoryId = null) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            if (!categoryId && (!value || value.length < 3)) {
                return []
            }
            setFetching(true);
            searchProducts(value, categoryId, language).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                setOptions(newOptions);
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [selectedCategory, language, searchProducts, debounceTimeout]);

    return (
        <>
            <Input.Group compact>
                <TreeSelect
                    showSearch
                    value={selectedCategory}
                    style={{ width: '30%' }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeData={categories}
                    fieldNames={{
                        label: 'name',
                        value: 'id',
                        children: 'children',
                    }}
                    onChange={(category_id) => {
                        setSelectedCategory(category_id)
                        debounceFetcher(searchQueryRef.current, category_id)
                    }}
                    placeholder="Category"
                    /* onChange={onCategoryChange} */
                    suffixIcon={<ApartmentOutlined />}
                    allowClear
                    disabled={disabled}
                    treeNodeFilterProp='name'
                />
                <Select
                    filterOption={false}
                    onSearch={(v) => {
                        searchQueryRef.current = v
                        debounceFetcher(v, selectedCategory)
                    }}
                    notFoundContent={fetching ? <Spin size="small" /> : null}
                    options={options}
                    onChange={(id) => {
                        if (!id) {
                            return
                        }
                        getProductDetails(id, language)
                            .then((data) => {
                                setSelectedCategory(data?.category_id)
                                onChange(data)
                                setProduct(data);

                            })
                    }}
                    value={value ?? currentSelection?.id}
                    placeholder="Select a Product"
                    suffixIcon={<SearchOutlined />}
                    style={{ width: '70%' }}
                    showSearch
                    allowClear
                    {...otherProps}
                    disabled={disabled}
                />
            </Input.Group>

            <span>
                {Object.keys(product).length > 0 && (
                    <>
                        Product URL: <Button target='blank' href={productUrl(product.url2, language)} type='link'>{productUrl(product.url2, language)}</Button>
                    </>
                )}
            </span>
        </>
    )
}

ProductSelect.propTypes = {
    language: PropTypes.oneOf(Languages.map(({ cms_language_code }) => cms_language_code)).isRequired,
    value: PropTypes.number,
    onChange: PropTypes.func,
    currentSelection: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    }),
    disabled: PropTypes.bool,
}

export default ProductSelect;
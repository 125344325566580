// import { UserRoles } from "../dynamic.js";

export const TemplateColumns = [
    {
        title: 'id',
        dataIndex: 'id',
    },
    {
        title: 'name',
        dataIndex: 'name',
    },
    {
        title: 'value',
        dataIndex: 'value',
    },
    // {
    //     title: 'type',
    //     dataIndex: 'type',
    //     render: (type) => UserRoles.find(role => role.id === type)?.name
    // }
]
import React from 'react';
import { textToClassName } from '../../utilities/common.js';
import { formatDate, formatPrice, roundOfNumber } from '../../utilities/intl.js';
import { ERP_CURRENCY } from '../dynamic.js';
import { PurchaseBillStatusDetails } from '../purchase-bill-statuses.js';

export const PurchaseBillColumns = [
    {
        title: 'Project Id',
        dataIndex: 'project_id',
        sorter: true,
        fixed: 'left',
    },
    {
        title: 'Vendor',
        sorter: true,
        fixed: 'left',
        dataIndex: ['vendor', 'company']
    },
    {
        title: 'Amount',
        dataIndex: 'total_price_incl_vat',
        render: amount => formatPrice(amount, ERP_CURRENCY)
    },
    {
        title: 'Margin',
        dataIndex: 'margin',
        render: val => roundOfNumber(val)
    },
    {
        title: 'Invoice No',
        sorter: true,
        dataIndex: 'invoice_number'
    },
    {
        title: 'Purchase Bill Notes',
        dataIndex: 'notes',
    },
    {
        title: 'Subject Line',
        dataIndex: ['email_read', 'subject'],
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: (statusId) => {
            const status = PurchaseBillStatusDetails[statusId]
            return status ? <div className={`status-tag status-tag--${textToClassName(status)}`}>{status}</div> : ''
        },
    },
    // {
    //     title: 'Payment Status',
    //     dataIndex: 'payment_status',
    // },
    {
        title: 'Invoice Date',
        dataIndex: 'invoice_date',
        render: date => formatDate(date)
    },
    {
        title: 'Due Date',
        dataIndex: 'due_date',
        render: date => formatDate(date)
    },
    {
        title: 'Executive Entity',
        dataIndex: ['entity', 'name'],
    },
]
export const PurchaseBillAuditTrailColumns = [
    {
        title: 'Date',
        dataIndex: 'createdAt'
    },
    {
        title: 'Status',
        dataIndex: 'status',
    },
    {
        title: 'User',
        dataIndex: 'user',
        render: ({ name }) => name,
    },
    {
        title: 'Message',
        dataIndex: 'message',
    },
]
// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_SEARCH = '/product/search';
const ENDPOINT_PRODUCT = '/product/:id';
const ENDPOINT_PRODUCT_ACTION = '/product/:id/:action';


// Define all api functions here
export async function searchProducts(query, category_id, language_code) {
    return axiosInstance.get(ENDPOINT_SEARCH, { params: { q: query, category_id, lang: language_code } })
        .then(({ data }) =>
            data.map(({ id, name }) => ({
                label: name,
                value: id,
            })),
        )
}

export const getProductDetails = async (id, language, content_language = null) => {
    return axiosInstance.get(generatePath(ENDPOINT_PRODUCT, {
        id
    }), {
        params: {
            lang: language,
            ...(content_language && { content_lang: content_language })
        }
    }).then(({ data }) => data)
}

export const getProductVendors = (productId) => {
    return axiosInstance.get(generatePath(ENDPOINT_PRODUCT_ACTION, {
        id: productId,
        action: 'vendor'
    })).then(({ data }) => data)
}
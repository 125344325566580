export const ItemClientStatuses = Object.freeze({
    ORDER_CONFIRMATION: 1,
    UPLOAD_ARTWORK: 2,
    FINAL_APPROVAL: 3,
    IN_PRODUCTION: 4,
    IN_TRANSIT: 5,
    DELIVERED: 6,
})

export const AdditionalQuotationStatus = {
    4: 'Approved',
    3: "Sent",
    2: 'Cancel',
    1: 'New'

}
export const AdditionalQuotationStatusText = {
    Approved: 4,
    Sent: 3,
    Cancel: 2,
    New: 1

}
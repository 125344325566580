import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Modal, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { redirect, useActionData, useFetcher, useLoaderData, useLocation, useNavigate, useParams, useSubmit } from 'react-router-dom';
import { createContact } from '../../api/contact';
import { getCustomerDetails } from '../../api/customer/customer-details.js';
import { CustomerContact, ValidationForm as ValidForm } from '../../components/form';
import { ValidationError } from '../../library/classes/exceptions/index.js';
import { Genders, Languages, Roles } from '../../library/constants/dynamic.js';
import { generateRouteUrl } from '../../library/constants/routes.js';
import styles from './contact-select.module.scss';

const renderItem = (item, selected, handleItemClick) => (
	<Card key={item.id} onClick={() => handleItemClick(item)} className={`${styles.card} ${selected ? styles['card--selected'] : ''}`} title={`${item.first_name} ${item.last_name}`}>
		<div className={styles.item}>
			<div className={styles.itemLabel}>Phone No.</div>
			<div className={styles.itemValue}>{`+${item.phone_country_code} ${item.phone_number}`}</div>
		</div>
		<div className={styles.item}>
			<div className={styles.itemLabel}>Mobile No.</div>
			<div className={styles.itemValue}>{`+${item.mobile_country_code} ${item.mobile_number}`}</div>
		</div>
		<div className={styles.item}>
			<div className={styles.itemLabel}>Email</div>
			<div className={styles.itemValue}>{item.email}</div>
		</div>
		<div className={styles.item}>
			<div className={styles.itemLabel}>Language</div>
			<div className={styles.itemValue}>{Languages.find(({ id }) => id === item.language_id)?.name}</div>
		</div>
		<div className={styles.item}>
			<div className={styles.itemLabel}>Role</div>
			<div className={styles.itemValue}>{Roles.find(({ id }) => id === item.role_id)?.name}</div>
		</div>
		<div className={styles.item}>
			<div className={styles.itemLabel}>Gender</div>
			<div className={styles.itemValue}>{Genders.find(({ id }) => id === item.gender_id)?.name}</div>
		</div>
	</Card>
)

const AddContactModal = ({ children, handleClose }) => {
	// const [contactFormIsValid] = useState(false)
	const contactFormData = useRef({})
	const [form] = ValidForm.useForm()
	const fetcher = useFetcher()

	const handleContactFormChange = (name, { forms }) => {
		contactFormData.current = forms[name].getFieldsValue()
	}

	const handleSave = (contact) => {
		fetcher.submit({
			action: 'createContact',
			...contact,

		}, {
			method: 'post',
			encType: 'application/json'
		})
	}

	return (
		<Modal
			title="Add New Contact"
			open={true}
			onCancel={handleClose}
			width='50%'
			forceRender
			destroyOnClose
			footer={<>
				<Button type="primary" icon={<CheckOutlined />} onClick={() => {
					handleSave(contactFormData.current)
					// console.log(contactFormData.current)
					// submit(contactFormData.current, { method: 'post', encType: 'application/json' })
				}}>Save</Button>
				<Button type="cancel" icon={<CloseOutlined />} onClick={handleClose}>Cancel</Button>
			</>}>
			<Form.Provider onFormChange={handleContactFormChange}>
				<CustomerContact initialValues={{ mobile_country_code: '+49', phone_country_code: '+49' }} errors={fetcher.data?.errors} form={form}
					index="1" />
				{children}
			</Form.Provider>
		</Modal>
	)
}

AddContactModal.propTypes = {
	children: PropTypes.node,
	handleClose: PropTypes.func.isRequired,
	customerId: PropTypes.number.isRequired,
}

const ContactSelect = ({ onSubmit }) => {
	const [query, setQuery] = useState('')
	const [selectedId, setSelectedId] = useState()
	const { customerId } = useParams()
	const { contacts } = useLoaderData()
	const navigate = useNavigate()
	const location = useLocation()
	const { showAddNew = null } = location.state || {}
	const actionData = useActionData()
	const submit = useSubmit()

	const filteredList = useMemo(() => (
		contacts.filter((contact) => (query.length === 0 || Object.values(contact).some((v) => {
			return (typeof v === 'string') && v.toLowerCase().includes(query.toLowerCase())
		})))
	), [contacts, query])

	const handleItemClick = (contact) => {
		setSelectedId(contact.id)
		if (onSubmit) {
			onSubmit(submit, {
				contactId: contact.id
			})
		}
		navigate(-1, {
			replace: true
		})
	}

	useEffect(() => {
		const { contact = null } = actionData || {}
		if (contact) {
			navigate(-1, {
				replace: true
			})
			handleItemClick(contact)
		}
	}, [actionData])

	return (
		<Modal title="Add Contact" open={true} onCancel={() => navigate(-1, { replace: true })} destroyOnClose footer={<Button onClick={() => {
			navigate('.', {
				replace: true,
				state: {
					showAddNew: 1
				}
			})
		}}>Add New Contact</Button>} width={1000} >
			<div className={styles.wrapper}>
				<Input.Search placeholder="Search..." onChange={(e) => setQuery(e.target.value)} allowClear />
				<div className={styles.cards}>
					{filteredList.map((item) => renderItem(item, (item.id === selectedId), handleItemClick))}
				</div>
			</div>
			{showAddNew && <AddContactModal
				customerId={parseInt(customerId)} handleClose={() => navigate(-1, { replace: true })} />}
		</Modal >
	)
}

ContactSelect.propTypes = {
	onSubmit: PropTypes.func
}
ContactSelect.Loader = async ({ params }) => {
	const { customerId } = params
	if (isNaN(customerId)) {
		throw new Error('Invalid Customer')
	}
	const customerDetails = await getCustomerDetails(customerId)
	if (!customerDetails) {
		throw new Error('Invalid Customer')
	}

	return { contacts: customerDetails.contacts }
}

ContactSelect.Actions = {

	createContact: async ({ params, data }) => {
		const { customerId, projectId } = params
		const payload = data
		try {
			const contact = await createContact({ ...payload, ['customer_id']: customerId })
			console.log(contact);
			message.success("Contact Added Successfully")
			return redirect(generateRouteUrl('FinanceProjectDetailsSelectContact', {
				projectId,
				customerId
			}))
			//return { contact }
		}
		catch (error) {
			message.error(error.message)
			if (error instanceof ValidationError) {
				const formattedErrors = {
				}
				Object.entries(error.errors).forEach(([k, v]) => {
					formattedErrors[k] = v
				})

				return {
					errors: formattedErrors
				}

			}
		}
		return false
	}
}


export default ContactSelect
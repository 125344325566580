import PropTypes from 'prop-types';
import React from 'react';
import { Countries } from '../../library/constants';
import styles from './PhoneDisplay.module.scss';
// import Icon from '@ant-design/icons';

function PhoneDisplay({ code, number }) {
    const selectedCountry = () => {
        const country = Countries.find(({ dial_code }) => dial_code === code)
        if (!country) {
            return
        }
        return [country.emoji, '+' + country.dial_code].join(' ')
    }

    if (!number)
        return <></>

    return (
        <span className={styles.wrapper}>
            {selectedCountry()} {number}
        </span>
    )
}

PhoneDisplay.propTypes = {
    code: PropTypes.string,
    number: PropTypes.string
}

export default PhoneDisplay;
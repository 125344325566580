import { EditOutlined, HolderOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message, Modal, Select } from 'antd';
import { sortBy } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CustomSpecificationForm, ValidationForm as Form } from '../../form';

import styles from '../project-item/specifciations.module.scss';

const SPECTYPES = Object.freeze({
    INBUILT: 'inbuilt',
    CUSTOM: 'custom',
})

const EditSpecification = ({ children, inbuiltSpecifications, specificationValues, customSpecifications, onSpecificationsChange, onCustomSpecificationAdd, onCustomSpecificationUpdate, onCustomSpecificationDelete, onOrderChange }) => {
    const [addSpecificationModalVisible, SetAddSpecificationModalVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedCustomSpecification, setSelectedCustomSpecification] = useState(null)
    const selectedSpecs = useRef({})
    const [form] = Form.useForm()
    const dragSourceIndex = useRef(null)
    const dragTargetIndex = useRef(null)

    useEffect(() => {
        const _mergedSpecs = mergedSpecs()
        selectedSpecs.current = inbuiltSpecifications.reduce((prev, { id }) => {
            const _selectedSpec = specificationValues.find(({ id: _id }) => _id === id)
            return {
                ...prev,
                [id]: {
                    valueId: _selectedSpec?.value?.id,
                    sort_order: _selectedSpec?.value?.sort_order ?? _mergedSpecs.findIndex(({ id: _id }) => _id === id)
                }
            }
        }, {})
    }, [inbuiltSpecifications, specificationValues])


    const mergedSpecs = useCallback(() => {
        return sortBy([
            ...inbuiltSpecifications.map((spec, index) => ({
                ...spec,
                valueId: specificationValues.find(({ id }) => id === spec.id)?.value?.id,
                ['type']: SPECTYPES.INBUILT,
                ['sort_order']: specificationValues.find(({ id }) => id === spec.id)?.sort_order ?? index
            })),
            ...Object.values(customSpecifications).map((spec, index) => ({ ...spec, ['type']: SPECTYPES.CUSTOM, ['sort_order']: spec.sort_order ?? index }))
        ], 'sort_order')
    }, [inbuiltSpecifications, specificationValues, customSpecifications])

    const onDragEnd = () => {
        if (onOrderChange) {
            const _mergedSpecs = mergedSpecs()
            const [sourceItem] = _mergedSpecs.splice(dragSourceIndex.current, 1)
            _mergedSpecs.splice(dragTargetIndex.current, 0, sourceItem)
            let _customSpecifications = []
            //console.log({ _mergedSpecsTest, _mergedSpecs });
            _mergedSpecs.forEach((spec, index) => {
                const specType = spec.type
                delete spec.type
                spec.sort_order = index
                switch (specType) {
                    case SPECTYPES.INBUILT: {
                        selectedSpecs.current[spec.id].sort_order = index
                        break
                    }

                    case SPECTYPES.CUSTOM: {
                        _customSpecifications.push(spec)
                        break
                    }
                    default:
                        throw new Error('Invalid Specification Type')
                }
            })

            onOrderChange(selectedSpecs.current, _customSpecifications)
        }
        dragSourceIndex.current = null
        dragTargetIndex.current = null
    }

    const handleSpecificationChange = (spec, sort_order, value) => {
        selectedSpecs.current[spec] = {
            valueId: value,
            sort_order
        }
        if (onSpecificationsChange) {
            onSpecificationsChange(selectedSpecs.current)
        }
    }

    const handleAddCustomeSpecificationClose = () => {
        SetAddSpecificationModalVisible(false)
        setSelectedCustomSpecification(null)
        form.resetFields()
    }

    const handleCustomSpecificationSubmit = (data, specId, errorHandler) => {
        console.log('form data', data);
        console.log('specid', specId);
        console.log(errorHandler);
        setIsLoading(true)
        const promise = specId ?
            onCustomSpecificationUpdate(specId, { ...data }) :
            onCustomSpecificationAdd({ ...data, ['sort_order']: mergedSpecs().length })

        promise.then(() => {
            SetAddSpecificationModalVisible(false)
        })
            .catch(e => {
                if (!errorHandler(e)) {
                    message.error('Unable to save specification')
                    message.error(e.response.data.message)
                }
            })
            .finally(() => {
                setIsLoading(false);
                addSpecificationModalVisible(false)
            }


            )
    }

    const handleCustomSpecificationDelete = (_specId) => {
        setIsLoading(true)
        onCustomSpecificationDelete(_specId)
            .then(() => {
                SetAddSpecificationModalVisible(false)
            })
            .catch(() => {
                message.error('Unable to delete specification')
            })
            .finally(setIsLoading(false))
    }

    return (
        <div className={styles.wrapper}>
            {mergedSpecs().length > 0 && mergedSpecs().map((spec, index) => {
                console.log(spec);
                switch (spec.type) {
                    case SPECTYPES.INBUILT: {
                        return (
                            <div className={styles.item} key={spec.id} draggable onDragStart={() => dragSourceIndex.current = index} onDragEnter={() => dragTargetIndex.current = index} onDragEnd={onDragEnd}>
                                <div className={styles.itemLabel}>
                                    <HolderOutlined style={{ 'color': '#8f8f8f', margin: '0 10px 0 0' }} /> {spec.text}
                                </div>
                                <div className={styles.itemValue}>
                                    <Select options={spec.values} defaultValue={spec.valueId ?? null} fieldNames={{ label: 'text', value: 'id' }} bordered={false} style={{ width: '100%' }} placeholder="Select" onChange={(value) => handleSpecificationChange(spec.id, spec.sort_order, value)} allowClear />
                                </div>
                            </div>
                        )
                    }

                    case SPECTYPES.CUSTOM:
                        return (
                            <div className={styles.item} key={spec.id} draggable onDragStart={() => dragSourceIndex.current = index} onDragEnter={() => dragTargetIndex.current = index} onDragEnd={onDragEnd}>
                                <div className={styles.itemLabel}>
                                    <HolderOutlined style={{ 'color': '#8f8f8f', margin: '0 10px 0 0' }} /> {spec.name}
                                </div>
                                <div className={styles.itemValue} onClick={() => { }}>
                                    {spec.value}
                                    <EditOutlined className={styles.editAction} onClick={() => {
                                        setSelectedCustomSpecification(spec.id)
                                        SetAddSpecificationModalVisible(true)
                                    }} />
                                </div>
                            </div>
                        )
                    default:
                        return ''

                }
            })}
            <Button style={{ 'marginTop': '10px', 'marginRight': '1%', 'width': '59%' }}
                icon={<PlusOutlined />}
                onClick={() => SetAddSpecificationModalVisible(true)}>Add Specification</Button>
            {children}
            <Modal
                title={!selectedCustomSpecification ? "Add New Specification" : 'Update Specification'}
                open={addSpecificationModalVisible}
                width={600}
                confirmLoading={isLoading}
                onCancel={handleAddCustomeSpecificationClose}
                footer={[
                    ...[!!selectedCustomSpecification && <Button key="delete" type="danger" onClick={() => handleCustomSpecificationDelete(selectedCustomSpecification)}>Delete</Button>],
                    <Button key="submit" type="primary" onClick={() => form.submit()}>Save</Button>,
                    <Button key="cancel" onClick={handleAddCustomeSpecificationClose}>Cancel</Button>,
                ]}
                destroyOnClose
            >
                <CustomSpecificationForm form={form} entityId={selectedCustomSpecification} initialValues={selectedCustomSpecification ? customSpecifications[selectedCustomSpecification] : {}} onFinish={handleCustomSpecificationSubmit} />
            </Modal>
        </div >
    )
}

EditSpecification.propTypes = {
    children: PropTypes.node,
    inbuiltSpecifications: PropTypes.arrayOf(PropTypes.object),
    specificationValues: PropTypes.arrayOf(PropTypes.object),
    customSpecifications: PropTypes.object,
    onSpecificationsChange: PropTypes.func,
    onCustomSpecificationAdd: PropTypes.func.isRequired,
    onCustomSpecificationUpdate: PropTypes.func.isRequired,
    onCustomSpecificationDelete: PropTypes.func.isRequired,
    onOrderChange: PropTypes.func,
}

export default EditSpecification
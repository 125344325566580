// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT = '/contact/:contactId/:role/:action';
const ENDPOINT_BILLING = 'customer/:customerId/param/billing_contact/:contactId';

export const setContactAuthority = (contactId) => {
    return axiosInstance.post(generatePath(ENDPOINT, {
        contactId,
        role: 'authority',
        action: 'set'
    }), {})
}

export const removeContactAuthority = (contactId) => {
    return axiosInstance.post(generatePath(ENDPOINT, {
        contactId,
        role: 'authority',
        action: 'remove'
    }), {})
}

export const setContactInvoice = (customerId, contactId) => {
    return axiosInstance.post(generatePath(ENDPOINT_BILLING, {
        customerId,
        contactId,
    }), {})
}

export const removeContactInvoice = (customerId, contactId) => {
    return axiosInstance.delete(generatePath(ENDPOINT_BILLING, {
        customerId,
        contactId,
    }), {})
}
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { InputWrapper } from '../../components/inputs/input-wrappers.jsx';
import styles from './project-cancellation-form.module.scss';

const ProjectCancellationForm = ({ items }) => {
    const [selectedItems, setSelectedItems] = useState(items.filter(({ selected }) => !!selected).map(({ id }) => id))

    return (
        <div className={styles.containerStyles}>
            <Checkbox
                name='entire_project'
                onChange={e => {
                    if (e.target.checked) {
                        setSelectedItems(items.map(({ id }) => id))
                    }
                    else {
                        setSelectedItems([])
                    }
                }}
                checked={selectedItems.length === items.length}
            >
                <strong>Entire Project</strong>
            </Checkbox>
            <Checkbox.Group
                name="items"
                options={items.map(({ id, name }) => ({
                    label: name,
                    value: id
                }))}
                value={selectedItems}
                onChange={v => setSelectedItems(v)}
            />
            <br />
            <InputWrapper name="comment" label="Reason" textarea allowClear />
            <i>Order manager will be updated</i>
        </div>
    )
}
ProjectCancellationForm.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired
}

export { ProjectCancellationForm };

import { LockOutlined } from '@ant-design/icons';
import { Button, Input, message } from 'antd';
import React from 'react';
import { Form, Link, redirect } from 'react-router-dom';
//import { emailLogin } from '../../api/auth';
import { changePassword } from '../../api/auth/login.js';
import { FieldWrapper } from '../../components/inputs/input-wrappers.jsx';
import { ValidationError } from '../../library/classes/exceptions/index.js';
import styles from './login.module.scss';
import { Home } from './login.jsx';

const ChangePassword = () => {
    return (
        <Home>
            <Form style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }} method="post">
                <FieldWrapper name="password">
                    <Input.Password name="password" prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" style={{ borderRadius: 0, fontSize: '1rem' }} required />
                </FieldWrapper>
                <FieldWrapper name="password">
                    <Input.Password name="confirm_password" prefix={<LockOutlined className="site-form-item-icon" />} type="confirm_password" placeholder="Confirm Password" style={{ borderRadius: 0, fontSize: '1rem' }} visibilityToggle={false} required />
                </FieldWrapper>
                <div className={styles.loginButtonWrapper}>
                    <Link to={'/login'}><Button type="link" htmlType="submit" className="login-form-button">Cancel</Button></Link>
                    <Button type="primary" htmlType="submit" className="login-form-button">Reset Password</Button>
                </div>
            </Form>
        </Home>
    )
}
ChangePassword.Action = async ({ params, request }) => {
    const { password = '', confirm_password = '' } = Object.fromEntries(await request.formData())
    const { token } = params
    try {
        if (password.length < 6 || confirm_password.length < 6) {
            throw new ValidationError('Password should be greater then 6 characters', [])
        }
        else if (password !== confirm_password) {
            throw new ValidationError('Password and confirm password doesn\'t match', [])
        }
        // const msg = await resetPassword(email)
        const msg = await changePassword(password, token)
        message.success(msg)
        return redirect('/')
    }
    catch (error) {
        message.error(error.message)
    }
    return false
}

export default ChangePassword
// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { z } from "zod";
import { ValidationError } from "../../library/classes/exceptions";
import { axiosInstance } from '../common';
// Define all api endpoint urls at the top
const ENDPOINT = '/vendor';

const AddressSchema = z.object({
    street: z.string({required_error:'Street is required'}).trim(),
    street_number: z.string({required_error:'Street Number is required'}).trim(),
    zipcode: z.string({required_error:'Zip is required'}).trim(),
    city: z.string({required_error:'City is required'}).trim(),
    country_id: z.number({required_error:'Country is required'}),
    id: z.number().int().optional(),
    county: z.string().trim().optional(),

   
})

const ContactSchema = z.object({
    first_name: z.string({required_error:'First Name is required'}).trim().min(1).max(100),
    last_name: z.string({required_error:'Last Name is required'}).trim().min(1),
    email: z.string({required_error :'Email is Required'}).email(),
    language_id: z.number({required_error :'Language is Required'}).int(),
    mobile_country_code: z.string({required_error :'Country code is Required' , invalid_type_error:'Country code must be a number follwed by +'}).min(2).max(4),
    phone_country_code: z.string({required_error :'Country code is Required' , invalid_type_error:'Country code must be a number follwed by +'}).min(2).max(4),
    phone_number: z.string({required_error :'Phone Number is Required'}).optional(),
    mobile_number: z.string({required_error :'Mobile Number is Required'}).optional(),
    gender_id: z.number({required_error :'Gender is Required'}).int(),
    role_id: z.string({required_error:'Role is required'}).trim().min(1),
})

export const VendorSchema = z.object({
    company: z.string({required_error :'Company is Required' , invalid_type_error:'Company must be string'}).trim().min(1),
    phone_number: z.string({required_error :'Phone Number is Required' , invalid_type_error:'Phone Number is Required'}).min(6),
    mobile_number: z.string({required_error :'Mobile is Required','message':"less than 6"}).min(6),
    email: z.string({required_error :'Email is Required'}).email().max(100),
    website: z.string().optional(),
    mobile_country_code: z.string({required_error :'Country code is Required' , invalid_type_error:'Country code must be a number follwed by +'}).min(2).max(4),
    phone_country_code: z.string({required_error :'Country code is Required' , invalid_type_error:'Country code must be a number follwed by +'}).min(2).max(4),
    country_id: z.number({required_error :'Country is Required'}).int(),
    language_id: z.number({required_error :'Language is Required'}).int(),
    currency_id: z.number({required_error :'Currency is Required'}).int(),
    payment_term: z.number({required_error :'Payment term is Required'}).int(),
    payment_method_id: z.number({required_error :'Payment Method is Required'}).int(),
    vat_number: z.string().optional(),
   // vendor_type : z.array(),
    using_portal : z.boolean().optional(),
    vendor_contacts: z.array(ContactSchema),
    addresses: z.array(AddressSchema),
})

// export const VendorDetailsSchema = z.object({
//     company: z.string({required_error :'Company is Required' , invalid_type_error:'Company must be string'}).trim().min(1),
//     phone_number: z.string({required_error :'Phone Number is Required'}).min(6),
//     mobile_number: z.string({required_error :'Mobile is Required'}).min(6),
//     email: z.string({required_error :'Email is Required'}).email().max(100),
//     website: z.string().optional(),
//     mobile_country_code: z.string({required_error :'Country code is Required' , invalid_type_error:'Country code must be a number follwed by +'}).min(2).max(4),
//     phone_country_code: z.string({required_error :'Country code is Required' , invalid_type_error:'Country code must be a number follwed by +'}).min(2).max(4),
//     country_id: z.number({required_error :'Country is Required'}).int(),
//     language_id: z.number({required_error :'Labguage is Required'}).int(),
//     currency_id: z.number({required_error :'Currency is Required'}).int(),
//     payment_term: z.number({required_error :'Payment term is Required'}).int(),
//     payment_method_id: z.number({required_error :'Payment Method is Required'}).int(),

//     vat_number: z.string().optional(),
//    // vendor_type : z.array(),
//     using_portal : z.boolean().optional(),
// })

// export const updateVendorDetails = (id,payload) => {
 
//     try {
//         VendorDetailsSchema.parse(payload)
//     }

//     catch (error) {
//         console.log(error);
//         if (error instanceof z.ZodError) {
            
//             throw new ValidationError("Invalid field values", error.issues.map(({ path, message }) => (
//                 {
//                     path,
//                     message
//                 }
//             )))
//         }
//         throw error
//     }
//     return axiosInstance.patch(generatePath(ENDPOINT_SINGLE, {
//         id
//     }), payload)
// }


export const createVendor = (payload) => {
 
    try {
        VendorSchema.parse(payload)
    }

    catch (error) {
        console.log(error);
        if (error instanceof z.ZodError) {
            throw new ValidationError("Invalid field values", error.issues.map(({ path, message }) => (
                {
                    path,
                    message
                }
            )))
        }
        throw error
    }
    return axiosInstance.post(ENDPOINT, payload)
}
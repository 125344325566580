import { Alert, Button } from 'antd';
import PropTypes from 'prop-types';
import React from "react";
import { useFetcher, useRouteLoaderData } from 'react-router-dom';
import { Box } from '../../../components/design/box.js';
import styles from "./in-production.module.scss";
const DeliveryTrackingRow = ({ item }) => {
    const fetcher = useFetcher()

    return (
        <fetcher.Form method="post">
            <input type="hidden" name="shipment_id" value={item.id} />
            <div className={styles.deliveryRow}>
                <div className="quantity">{item.quantity}</div>
                <div className="address">{item.street_number},{item.street},{item.city}</div>
                <div className="field-quantity">
                    {item.ship_quantity}
                </div>
                <div className="field-trackingurl">
                    {item.tracking_url}
                </div>
                <div className="field-expecteddeliverydate">
                    {item.expected_date}
                </div>
            </div>
        </fetcher.Form>
    )
}
DeliveryTrackingRow.propTypes = {
    item: PropTypes.object.isRequired
}

const DelayInDelivery = () => {
    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')
    const fetcher = useFetcher()
    return (
        <div className={styles.container}>
            <h3>Task For You</h3>
           
            <Alert message="Order Delayed" description="Vendor has shared that the order has been delayed by 1-2 days" type="error" action={
                <Button onClick={() => {
                    fetcher.submit({
                        action: 'notifyCustomer'
                    }, {
                        method: 'post'
                    })
                }} loading={fetcher.state !== 'idle'}>Notify Customer</Button>
            } showIcon />
            <Box>
                <div className={styles.deliveryRow}>
                    <div>Quantity</div>
                    <div>Delivery Address</div>
                    <div>Ship Quantity</div>
                    <div>Tracking URL</div>
                    <div>Expected Delivery Date</div>
                    <div></div>
                </div>
                {orderDetails.item_delivery_address && orderDetails.item_delivery_address.map((shipment) => <DeliveryTrackingRow key={shipment.id} item={shipment} />)}
            </Box>
        </div>
    )
}
DelayInDelivery.Actions = {
    notifyCustomer: async ({ params }) => {
        console.log(params);
    }
}
export default DelayInDelivery;

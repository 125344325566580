import { Alert, Button, Result, message } from 'antd';
import React from "react";
import { useFetcher, useOutletContext, useRouteLoaderData } from 'react-router-dom';
import { clientAddressReminder, inProduction, onHold } from '../../../api/order';
import styles from '../../../components/data/quotation/quotation-item.module.scss';
import { InputWrapper } from '../../../components/inputs/input-wrappers.jsx';
import Confirm from '../../../components/message/Confirm.jsx';
import { Prompt } from '../../../components/message/prompt.jsx';
import { OrderSupplierStatuses } from '../../../library/constants/steps/supplier-order-manager-steps.js';

const getProudctionTitle = (isFinisher, delivered) => {
    if (!isFinisher) {
        return 'Customer has approved the printable final proof and the delivery address is also confirmed.Notify vendor to start the production'
    }
    return (
        <>
            Customer has approved the printable final proof and the delivery address is also confirmed.Notify finisher to start the production<br />
            {!delivered && (
                <i>Finisher has not yet recieved the products. Production will only start once products are delivered to the Finisher</i>
            )}
        </>
    )

}

const ReadyForProduction = () => {
    const fetcher = useFetcher()
    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')
    const { isFinisher, vendorRole } = useOutletContext()
    const isAddressConfirmed = (orderDetails.request_for_address === 2)

    return <div className={styles.container}>
        <h3>Task For You</h3>
        {!isAddressConfirmed ? (
            <Result
                status="warning"
                title="Customer has not yet confirmed the address"
                subTitle="Customer has not yet comfirmed the delivery address. Remind customer to confirm the delivery address so that order can be sent to production"
                extra={
                    <Button
                        className={styles.greenButton}
                        onClick={() => {
                            fetcher.submit({
                                action: 'remindCustomer'
                            }, {
                                method: 'post'
                            })
                        }}
                        disabled={fetcher.state !== 'idle'}
                        loading={fetcher.state === 'submitting' && fetcher.formData?.get('action') === 'remindCustomer'}
                    >Remind Customer</Button>
                }
            />
        ) : (
            <Result
                status="success"
                title="Order is ready to be sent to production"
                subTitle={getProudctionTitle(isFinisher, orderDetails.order_delivery_status === OrderSupplierStatuses.DELIVERED)}
                extra={
                    <Confirm
                        content={`This will send a confirmation to ${vendorRole} to start the production. Please check all the details carefully before proceeding.`}
                        okText='Continue'
                        cancelText='Cancel'
                        onConfirm={() => {
                            fetcher.submit({
                                action: 'sendToProduction'
                            }, {
                                method: 'post'
                            })
                        }}
                        disabled={fetcher.state !== 'idle'}
                        loading={fetcher.state === 'submitting' && fetcher.formData?.get('action') === 'sendToProduction'}
                        type="primary"
                    >Send to Production</Confirm>
                }
            />
        )}
        <br />
        <Alert message="Put order on hold" description="Ask vendor to put order on hold" type="warning" action={
            <Prompt
                title="Put Order On Hold"
                buttonText="Hold Order"
                disabled={fetcher.state !== 'idle'}
                loading={fetcher.state === 'submitting' && fetcher.json?.action === 'onhold'}
                onConfirm={({ comment = '' }) => {
                    fetcher.submit({
                        comment,
                        action: 'onhold'
                    }, {
                        method: 'post',
                        encType: 'application/json'
                    })
                }}
            >
                <InputWrapper name="comment" label="Comment" placeholder="Reason for hold" textarea />
            </Prompt>
        } showIcon />
    </div>
}


ReadyForProduction.Actions = {
    onhold: async ({ params, data }) => {
        try {
            const { itemId } = params
            const { comment } = data
            const msg = await onHold(itemId, { comment })
            message.success(msg)
            //showReasonModal(false)
            return true
        }
        catch (e) {
            message.error(e.message)
        }
        return false
    },
    remindCustomer: async ({ params }) => {
        const { itemId } = params
        try {
            const msg = await clientAddressReminder(itemId)
            message.success(msg);
            return true
        }
        catch (error) {
            message.error(error.message)
            return false
        }
    },
    sendToProduction: async ({ params }) => {
        const { itemId } = params
        try {
            const { message: msg } = await inProduction(itemId, [])
            message.success(msg)
            return true
        }
        catch (e) {
            message.error(e.message)
            return false
        }
    }
}
export default ReadyForProduction;
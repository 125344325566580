export const LoginProvider = {
  EMAIL: 'email',
}

export const isAuthenticated = () => {
  console.log('checking auth');
  try {
    if (!localStorage.getItem('USER') || !JSON.parse(localStorage.getItem('USER'))) {
      throw new Error('Not logged in')
    }
    console.log('auth passed');
    return true
  }
  catch {
    console.log('show login');
    return false
  }
}

export const getStoredToken = () => {
  if (!isAuthenticated()) {
    return
  }
  const { token = null } = JSON.parse(localStorage.getItem('USER'))
  if (!token) {
    throw new Response("Not logged in", { status: 200 })
  }
  return token
}

export const getLoggedInUserDetails = () => {
  const user = JSON.parse(localStorage.getItem('USER'))
  if (!user)
    return null

  return {
    userId: user.id,
    name: user.name,
    email: user.email
  }
}

export const setUserSessionData = (key, value) => {
  if (!key)
    return
  //Check if user is logged in 
  const { userId } = getLoggedInUserDetails()
  if (!userId)
    return

  sessionStorage.setItem(`${userId}-${key}`, value)
}

export const getUserSessionData = (key) => {
  if (!key)
    return
  //Check if user is logged in 
  const { userId } = getLoggedInUserDetails()
  if (!userId)
    return

  return sessionStorage.getItem(`${userId}-${key}`) && JSON.parse(sessionStorage.getItem(`${userId}-${key}`))
}

export const getUserSettings = (key) => {
  if (!key) return; // Early return if no key provided

  // Check if user is logged in
  const { userId } = getLoggedInUserDetails();
  if (!userId) return; // Early return if user is not logged in

  try {
    const userSettings = JSON.parse(localStorage.getItem(`userSettings-${userId}`) || '{}');
    return userSettings?.[key];
  } catch (error) {
    console.error('Error parsing user settings:', error);
    return; // Return undefined in case of error
  }
}
// return localStorage.getItem(`${userId}-${key}`) && JSON.parse(localStorage.getItem(`${userId}-${key}`))

export const setUserSettings = (key, value) => {
  if (!key) return; // Early return if no key provided

  // Check if user is logged in
  const { userId } = getLoggedInUserDetails();
  if (!userId) return; // Early return if user is not logged in

  try {
    // Get current settings from localStorage
    const existingSettings = JSON.parse(localStorage.getItem(`userSettings-${userId}`) || '{}');

    // Update the specific key with the new value
    existingSettings[key] = value;

    // Save the updated settings back to localStorage
    localStorage.setItem(`userSettings-${userId}`, JSON.stringify(existingSettings));
  } catch (error) {
    console.error('Error setting user settings:', error);
  }
};
// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/finance/project';
const ENDPOINT_GENERAL_ACTION = '/finance/project/:action';
const ENDPOINT_SINGLE = '/finance/project/:id';
const ENDPOINT_SINGLE_ACTION = '/finance/project/:id/:action';

export const getFinanceProjects = (payload) => {
    return axiosInstance.get(ENDPOINT_GENERAL, { params: payload })
}

export const getProjectDetails = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE, {
        id
    })).then(({ data }) => data)
}

export const createProjectInvoice = (projectId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: projectId,
        action: 'invoice'
    })).then(({ data }) => data)
}
export const createProjectCreditNote = (projectId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: projectId,
        action: 'credit-note'
    })).then(({ data }) => data)
}

export const updateProjectBillingContact = (projectId, contactId) => {
    return axiosInstance.post(generatePath('finance/project/:projectId/param/billing_contact/:contactId', {
        projectId,
        contactId: parseInt(contactId)
    })).then(({ message }) => message)
}

export const updateProjectBillingAddress = (projectId, addressId) => {
    return axiosInstance.post(generatePath('finance/project/:projectId/param/billing_address/:addressId', {
        projectId,
        addressId: parseInt(addressId)
    })).then(({ message }) => message)
}

export const createBulkProjectsInvoices = (projectIds) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL_ACTION, {
        action: 'invoice-bulk'
    }), {
        project_ids: projectIds
    }).then(({ message }) => message)
}

export const projectAuditTrail = async (projectId) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: projectId,
        action: 'audit-trial'
    })).then(({ data }) => data)
}
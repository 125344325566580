import { DownloadOutlined } from '@ant-design/icons';
import { Alert, Button, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from "react";
import { useFetcher, useOutletContext, useRouteLoaderData } from 'react-router-dom';
import { uploadFileFromObject } from '../../../api/common/image-upload.js';
import { sendOrderShippingDetails } from "../../../api/order";
import { Box } from '../../../components/design/box.js';
import { DatePicker, FieldWrapper, InputWrapper } from "../../../components/inputs";
import { ValidationError } from '../../../library/classes/exceptions/index.js';
import { ItemClientStatuses } from '../../../library/constants/ItemClientStatuses.js';
import { generateRouteUrl } from '../../../library/constants/routes.js';
import { OrderShippingStatuses } from '../../../library/constants/shipping-statuses.js';
import { convertDataTypes } from '../../../library/helpers/index.js';
import { formatDate } from '../../../library/utilities/intl.js';
import { getFilePath } from '../../../library/utilities/urls.js';
import { ShipmentExpectedDispatchDate } from '../index.js';
import styles from "./dispatchdateconfirm.module.scss";

const ShipmentConfirmDispatchDate = ({ projectId, itemId, item, deliveryType = null }) => {
    const fetcher = useFetcher()
    const { errors = {} } = fetcher.data || {}
    const [selectedQuantity, setSelectedQuantity] = useState(null)
    const quantityValidationMessage = useMemo(() => {
        if (!selectedQuantity) {
            return
        }
        if (selectedQuantity < (item.quantity * 0.9)) {
            return 'Dispatch quantity is less than 10% of confirmed quantity'
        }
        if (selectedQuantity > (item.quantity * 1.1)) {
            return 'Dispatch quantity cannot be greater than 10% of confirmed quantity'
        }
    }, [item.quantity, selectedQuantity])

    const address = (deliveryType === 3) ?
        <Button href={getFilePath(item.bulk_address_file.url, projectId)} icon={<DownloadOutlined />}>Download</Button> :
        <>{item.street_number},{item.street},{item.city}</>

    return (
        <fetcher.Form method="post" action={generateRouteUrl('DispatchDateConfirm', {
            itemId
        })} encType={deliveryType === 3 ? 'multipart/form-data' : 'application/x-www-form-urlencoded'}>
            <input type="hidden" name="shipment_id" value={item.id} />
            <div className={deliveryType === 3 ? styles.deliveryRowWithComment : styles.deliveryRow}>
                <div className="quantity">{item.quantity}</div>
                <div className="address">{address}</div>
                <div className="field-expected_date">
                    {formatDate(item.dispatch_date)}
                </div>
                <div className="field-quantity">
                    <InputWrapper type='Number' min={0} name="ship_quantity" defaultValue={item.ship_quantity} errors={errors} onChange={(e) => {
                        setSelectedQuantity(e.target.value)
                    }} />
                    <span className="error">{quantityValidationMessage}</span>
                </div>
                {deliveryType === 3 ? (
                    <>
                        <div className="field">
                            <FieldWrapper name="delivery_file" errors={errors} >
                                <input type="file" name="delivery_file" />
                            </FieldWrapper>
                        </div>
                        <div className="field">
                            <InputWrapper name="delivery_comment" defaultValue={item.delivery_comment} errors={errors} textarea />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="field-trackingurl">
                            <InputWrapper name="tracking_url" defaultValue={item.tracking_url} errors={errors} />
                        </div>
                        <div className="field-expected_date">
                            <FieldWrapper name="expected_date" errors={errors} >
                                <DatePicker name="expected_date" useHidden value={item.expected_date} />
                            </FieldWrapper>
                        </div>
                    </>
                )}
                <Button
                    type="primary"
                    htmlType='submit'
                    name="action"
                    value={deliveryType === 3 ? 'updateDeliveryInformationBulk' : 'updateDeliveryInformation'}
                    loading={fetcher.state !== 'idle'}
                >Submit</Button>
            </div>
        </fetcher.Form>
    )
}
ShipmentConfirmDispatchDate.propTypes = {
    projectId: PropTypes.number.isRequired,
    itemId: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
    deliveryType: PropTypes.oneOf([1, 2, 3]),
}


const DispatchDateConfirm = () => {
    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')
    const { vendorRole } = useOutletContext()
    const missingExpectedDateShipments = orderDetails.item_delivery_address.filter(({ status }) => status === OrderShippingStatuses.NEW)
    const dispachableShipments = orderDetails.item_delivery_address.filter(({ status }) => status === OrderShippingStatuses.DISPATCH_DATE_ADDED)

    return <div className={styles.container}>
        <h3>Task For You</h3>
        {![ItemClientStatuses.IN_TRANSIT, ItemClientStatuses.DELIVERED].includes(orderDetails.client_status) ? (
            <Alert description={`${vendorRole} has shared the expected dispatch date`} type="info" showIcon />
        ) : (
            <Alert description={`${vendorRole} has shared the expected dispatch date.Customer is already notified.`} type="info" showIcon />
        )}
        <Box header={
            <div className={orderDetails.delivery_type === 3 ? styles.deliveryRowHeadWithComment : styles.deliveryRowHead}>
                <div>Quantity</div>
                <div>Delivery Address</div>
                <div>Expected Dispatch Date</div>
                <div>Ship Quantity</div>
                {orderDetails.delivery_type === 3 ? (
                    <>
                        <div>Comment</div>
                        <div>Delivery File</div>
                    </>
                ) : (
                    <>
                        <div>Tracking URL</div>
                        <div>Expected Delivery Date</div>
                    </>
                )}
                <div></div>
            </div>
        }>
            {dispachableShipments.length > 0 && dispachableShipments.map((shipment) => <ShipmentConfirmDispatchDate key={shipment.id} projectId={orderDetails.project_id} itemId={orderDetails.id} deliveryType={orderDetails.delivery_type} item={shipment} />)}
        </Box>
        {missingExpectedDateShipments.length > 0 && (
            <>
                <Alert description="Please provide an expected shipment details for following items" type="error" showIcon />
                <ShipmentExpectedDispatchDate projectId={orderDetails.project_id} itemId={orderDetails.id} deliveryType={orderDetails.delivery_type} shipments={missingExpectedDateShipments} buttonText='Save Dates' />
            </>
        )}
    </div>
}
DispatchDateConfirm.Actions = {
    updateDeliveryInformationBulk: async ({ params, data }) => {
        const { itemId } = params
        try {
            const { shipment_id, ship_quantity, delivery_file, delivery_comment } = convertDataTypes(data, {
                shipment_id: parseInt,
                ship_quantity: parseInt
            })
            if (!ship_quantity) {
                throw new ValidationError('Quantity is required', [
                    {
                        path: 'ship_quantity',
                        message: 'Quantity is required'
                    }
                ])
            }
            if (!delivery_file || !(delivery_file instanceof File)) {
                throw new ValidationError('Delivery file is required', [
                    {
                        path: 'delivery_file',
                        message: 'Delivery file is required'
                    }
                ])
            }

            //Upload file
            const fileId = await uploadFileFromObject(delivery_file)

            const msg = await sendOrderShippingDetails(itemId, shipment_id, {
                ship_quantity,
                delivery_file_id: fileId,
                delivery_comment
            })
            message.success(msg);
            return true
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
        }
        return false
    },
    updateDeliveryInformation: async ({ params, data }) => {
        const { itemId } = params
        try {
            const ship_quantity = parseInt(data.ship_quantity)
            if (!ship_quantity) {
                throw new ValidationError('Quantity is required', [
                    {
                        path: 'ship_quantity',
                        message: 'Quantity is required'
                    }
                ])
            }

            const shipment_id = parseInt(data.shipment_id)
            const msg = await sendOrderShippingDetails(itemId, shipment_id, {
                ship_quantity,
                tracking_url: data.tracking_url,
                expected_date: data.expected_date,
            })
            message.success(msg);
            return true
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
        }
        return false
    }
    // updateDeliveryDetails: async ({ params, data }) => {
    //     const { itemId } = params
    //     try {
    //         const shipment_id = parseInt(data.shipment_id)
    //         const { message: msg } = await sendOrderDispatchDate(itemId, shipment_id, {
    //             dispatch_date: data.expected_dispatch_date
    //         })
    //         message.success(msg);
    //         return true
    //     }
    //     catch (error) {
    //         message.error(error.message)
    //         if (error instanceof ValidationError) {
    //             return {
    //                 errors: error.errors
    //             }
    //         }
    //     }
    //     return false
    // }
}

export { DispatchDateConfirm, ShipmentConfirmDispatchDate };



import React, { useMemo } from "react";
import { useLoaderData } from 'react-router-dom';
import { ERP_CURRENCY } from "../../../library/constants/dynamic.js";
import { formatPrice } from '../../../library/utilities/intl';
import styles from './projectdetails.module.scss';

const ProjectSummary = () => {
    const { items } = useLoaderData()
    const approvedItems = items.filter(({ active }) => (active === 1)).map(({ id, product, approved_quantity, vendors_prices, approved_unit_price, approved_price_converted }) => {
        return ({
            id,
            product,
            approved_unit_price,
            approved_quantity,
            approved_price_converted,
            vendor_price: Object.values(vendors_prices)?.[0]?.find(({ quantity }) => quantity === approved_quantity)
        })
    })

    const totalAmount = useMemo(() => approvedItems.reduce((total, item) => (total + item.vendor_price?.total_price), 0), [items])

    return (
        <div className={styles.summaryBox}>
            <h3>Items and Services</h3>
            <table className={styles.tableSpacing}>
                <tbody>
                    <tr>
                        <td className={styles.tableRow}>Item Name</td>
                        <td className={styles.tableRow}>Quantity</td>
                        <td className={styles.tableRow}>Sales Prices/Piece</td>
                        <td className={styles.tableRow}>Margin</td>
                        <td className={styles.tableRow}>Total Price</td>
                    </tr>
                    {approvedItems.map((item) => {
                        return (
                            <tr key={item.id}>
                                <td className={styles.tableValues}>{item.product.name}</td>
                                <td className={styles.tableValues}>{item.approved_quantity}</td>
                                <td className={styles.tableValues}>{formatPrice(item.approved_unit_price, ERP_CURRENCY)}</td>
                                <td className={styles.tableValues}>{item.vendor_price?.margin} %</td>
                                <td className={styles.tableValues}>{formatPrice((item.approved_unit_price * item.approved_quantity), ERP_CURRENCY)}</td>
                            </tr>
                        )
                    })}

                    <tr>
                        <td className={styles.netAmount}>Net Amount</td>
                        <td className={styles.netAmount}></td>
                        <td className={styles.netAmount}></td>
                        <td className={styles.netAmount}></td>
                        <td className={styles.netAmount}>{formatPrice(totalAmount, ERP_CURRENCY)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ProjectSummary
import { FilterOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { generatePath, useLoaderData, useNavigate } from 'react-router-dom';
import { getAlContacts } from '../../api/contact';
import { SelectedFilters } from '../../components/data/selected-filters.jsx';
import ContactFilterForm from '../../components/form/contact-filter/contact-filter.jsx';
import { Page } from '../../components/page';
import { Table } from '../../components/table';
import { useFetch } from '../../hooks';
import { ContactColumns } from '../../library/constants/tableColumns/contactColumns';
import { searchParamsToObject } from '../../library/helpers/index.js';

const FILTER_NAMES = {
  first_name: "First Name",
  last_name: "Last Name",
  customer_id: "Customer",
  email: "Email",
  phone_number: "Contact Number",
  country_id: "Country",
  language_id: "Language",
}

function Contacts(props) {
  const initialData = useLoaderData()
  const navigate = useNavigate()
  const [filtersVisible, setFiltersVisible] = useState(false)
  const [list, hasMore, isLoading, searchParamsParsed, {
    setSort,
    setFilters,
    loadMore
  }] = useFetch({ initialData })

  const handleRowClick = (rowIndex, record) => {
    navigate(generatePath('/customers/contacts/:id', {
      id: record.id
    }))
  }

  return (
    <Page className='contacts-list' title={props.title} summary={initialData?.meta?.totalCount ? `${initialData?.meta?.totalCount} Records` : 'No Records'} header={(
      <>
        <div></div>
        <Space.Compact style={{ width: '100%', maxWidth: '400px' }}>
          <Input.Search placeholder="Search" prefix={<SearchOutlined />} onChange={(e) => setFilters({ 'search': e.target.value })} allowClear />
          <Button onClick={() => setFiltersVisible(v => !v)} icon={<FilterOutlined />}>Filters</Button>
        </Space.Compact>
        <Button type="primary" onClick={() => { navigate('/customers/contacts/create') }} icon={<PlusOutlined />}>Add new Contact</Button>
      </>
    )} subHeader={initialData.meta?.filters && <SelectedFilters filters={initialData.meta.filters} filterNames={FILTER_NAMES} updateFilters={setFilters} />}>

      <Drawer open={filtersVisible} onClose={() => setFiltersVisible(false)} >
        <ContactFilterForm key={JSON.stringify(searchParamsParsed.filter)} data={searchParamsParsed.filter} onFinish={setFilters} />
      </Drawer>
      <Table
        columns={ContactColumns}
        data={list}
        isLoading={isLoading}
        onChange={setSort}
        onRowClick={handleRowClick}
        hasMore={hasMore}
        loadMore={loadMore}
      />
    </Page>
  )
}

Contacts.Loader = async ({ request }) => {
  const url = new URL(request.url)
  const { filter, page, sort } = searchParamsToObject(url.searchParams)
  const { data, meta } = await getAlContacts({ filter, page, ...sort })
  //console.log({ Loading: data.map(r => r.id) })
  return { data, meta }
}

Contacts.propTypes = {
  title: PropTypes.string
}

export default Contacts

// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT = '/customer/:id';


// Define all api functions here
export const getCustomerDetails = async (id) => {
    return await axiosInstance.get(generatePath(ENDPOINT, {
        id
    })).then(({ data }) => data)
}

export const updateCustomerDetails = (id, payload) => {
    return axiosInstance.patch(generatePath(ENDPOINT, {
        id
    }), payload)
}
export const EmailTemplateHeader = `
<div class="container" id="static-header">
    <table cellpadding="0" cellspacing="0" border="0" align="center" style="width:100%">
        <tr><td height="43"></td></tr>
        <tr>
            <td>
                <table cellpadding="0" cellspacing="0" border="0" align="right" style="width:100%">
                    <tr>
                        <td align="left" valign="middle">
                            <img src="https://www.leoprinting.nl/testemails/logo.png" alt="logo" width="246" height="91" border="0" style="display: block;">
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr><td height="40"></td></tr>
    </table>
</div>
`

export const EmailTemplateFooter = `
<div class="container" id="static-footer">
                        <div style="width: 100%; margin: auto; background: #ffffff;">
                            <table cellpadding="0" cellspacing="0" border="0" align="center" style="width:100%">
                                <tr>
                                  <td valign="middle">
                                      <table cellpadding="0" cellspacing="0" border="0" align="center" style="width:100%">
                                          <tr><td style="font-size: 15px; font-weight: 400; color: #000000; line-height: 1.5; letter-spacing: 0.03em;font-family: 'Roboto', sans-serif;">Best regards,</td></tr>
                                          <tr><td height="15"></td></tr>
                                          <tr>
                                              <td>
                                                  <a href="#"><img src="https://www.leoprinting.nl/testemails/footer-logo.png" alt="Footer Logo" border="0" style="display: block;" width="94" height="35"></a>
                                              </td>
                                          </tr>
                                          <tr><td height="15"></td></tr>
                                          <tr><td style="font-size: 15px; font-weight: 400; color: #000000; line-height: 1.5; letter-spacing: 0.03em;font-family: 'Roboto', sans-serif;">{{other_info.signature_name}}</td></tr>
                                          <tr><td height="5"></td></tr>
                                          <tr><td style="font-size: 15px; font-weight: 400; color: #5a5a5a; line-height: 1.5; letter-spacing: 0.03em;font-family: 'Roboto', sans-serif;">T: <a href="tel:+31(0)727112998" style="color: #5a5a5a; text-decoration: none;">+ 31 (0)72 711 2998</a></td></tr>
                                          <tr><td height="2"></td></tr>
                                          <tr><td style="font-size: 15px; font-weight: 400; color: #5a5a5a; line-height: 1.5; letter-spacing: 0.03em;font-family: 'Roboto', sans-serif;">E: <a href="mailto:{{other_info.signature_email}}" style="color: #5a5a5a; text-decoration: none;">{{other_info.signature_email}}</a><a href="www.leoprinting.com" style="color: #5a5a5a; text-decoration: none;"> | www.leoprinting.com</a></td></tr>
                                          <tr><td height="2"></td></tr>
                                          <tr><td style="font-size: 15px; font-weight: 400; color: #5a5a5a; line-height: 1.5; letter-spacing: 0.03em;font-family: 'Roboto', sans-serif;">Robijnstraat 38 | 1812 RB | Alkmaar | Nederland</td></tr>
                                          <tr><td height="15"></td></tr>
                                                    <tr>
                                              <td>
                                                  <table cellpadding="0" cellspacing="0" border="0" align="left" style="width:132px">
                                                      <tr>
                                                          <td><a href="#"><img src="https://www.leoprinting.nl/testemails/facebook.png" border="0" style="display: block;" width="24" height="25" alt="Facebook"></a></td>
                                                          <td width="10"></td>
                                                          <td><a href="#"><img src="https://www.leoprinting.nl/testemails/linkedin.png" border="0" style="display: block;" width="24" height="25" alt="linkedin"></a></td>
                                                          <td width="10"></td>
                                                          <td><a href="#"><img src="https://www.leoprinting.nl/testemails/youtube.png" border="0" style="display: block;" width="30" height="25" alt="youtube"></a></td>
                                                          <td width="10"></td>
                                                          <td><a href="#"><img src="https://www.leoprinting.nl/testemails/instagram.png" border="0" style="display: block;" width="24" height="25" alt="Facebook"></a></td>
                                                      </tr>
                                                  </table>
                                              </td>
                                          </tr>
                                      </table>
                                  </td>
                                  <td valign="middle" align="right">
                                      <img src="https://www.leoprinting.nl/testemails/footer-image.gif" style="display: block;" border="0" width="449" height="195" alt="footer Image">
                                  </td>
                                </tr>
                            </table>
                        </div>
                      </div>
                            `

export const EmailTemplateEditorCSS = `
body {
            margin: 0;
            padding: 0;
            font-family: 'Roboto', sans-serif;
        }
        .main-container {
            max-width: 800px; /* Set your desired max-width */
            margin: 0 auto; /* Center align the container */
            padding: 20px; /* Add some padding if needed */
            box-sizing: border-box; /* Ensure padding is included in the width */
            width:100%;
            background-color: #FAFAFA;
        }
        .container {
            max-width: 800px; /* Set your desired max-width */
            margin: 0 auto; /* Center align the container */
            padding: 20px; /* Add some padding if needed */
            box-sizing: border-box; /* Ensure padding is included in the width */
        }
        .button {
            font-size: 13px; 
            font-weight: 800; 
            color: #ffffff; 
            line-height: 1.5; 
            letter-spacing: 0.03em; 
            font-family: 'Roboto', 
            sans-serif; 
            background: #23B27C; 
            border-radius: 10px; 
            text-decoration: none; 
            text-transform: uppercase; 
            display: inline-block; 
            padding: 10px 35px;
        }
        [data-gjs-type="wrapper"] {
            min-height: initial!important;
        }
`
import React from 'react';

const renderErrors = (errors) => {
    return errors.map((error, index) =>
        React.isValidElement(error) ? error : <span key={index} className='error'>{error}</span>
    )
}

export const getFieldErrors = (field, errors) => (errors && errors?.[field]) ? renderErrors(errors[field]) : undefined
export const getFieldStatus = (field, errors) => (errors && errors?.[field]) ? "error" : undefined

export const triggerInputChange = (input, value) => {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value').set;
    nativeInputValueSetter.call(input, value);
    const event = new Event('input', { bubbles: true });
    input.dispatchEvent(event);
}

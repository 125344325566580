import { InputNumber } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { defaultProductMargin } from '../../library/constants/defaults.js';

const Percent = ({ value = null, onChange, ...otherProps }) => {
    return (
        <InputNumber
            defaultValue={defaultProductMargin}
            value={value}
            controls={false}
            onChange={onChange}
            step="0.01"
            precision="2"
            className="inputfield--percentage"
            id="percent-field"
            min={-10000}
            max={10000}
            onFocus={event => event.target.select()}
            {...otherProps}
            type="number"
        />
    )
}

Percent.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
}

export default Percent;
import React from "react"


const renderSpecialWebsites = (website) => {
    if (website == 'gmail.com') {
        return 'google.com'
    }
    else return website
}

export const fetchWebsiteLogo = (website) => {

    return website == undefined ? <img src="/images/placeholder.svg" height={150}
        width={150} /> : <img height={150}
            width={150}
            src={'https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://' + renderSpecialWebsites(website) + '&size=256'}
            alt="new"
    />
}

export const fetchSmallWebsiteLogo = (website, company) => {
    return website == undefined ? <div><img src="/images/placeholder.svg" height={30}
        width={30} style={{marginRight : '8px'}}/> <span style={{fontWeight:700, color:'gray'}}>{company}</span></div> : <div style={{width:'100%', display:'flex', alignItems:'center'}}><img height={30}
            width={30} style={{marginRight : '8px'}}
            src={'https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://' + renderSpecialWebsites(website) + '&size=32'}
            alt="new"
        /> {'  '} <span style={{  fontWeight: 700,
            color: 'gray',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            WebkitLineClamp: 2  }}>{company}</span></div>
}


import PropTypes from 'prop-types';
import React from 'react';
import styles from './box.module.scss';

const BoxTypes = {
    BLUE: 'blueBox',
    GRAY: 'grayBox',
    GRAY2: 'grayBox2',
    INNERSHADOW: 'innerShadow'
}

const BoxSizes = {
    SMALL: 'smallBox',
    MINI: 'miniBox'
}

const Box = ({ icon, footer, header, children, size = null, type = null, containerStyles = {} }) => {
    const getClasses = () => {
        const classes = [styles.container]
        if (type) {
            classes.push(styles[type])
        }

        if (size) {
            classes.push(styles[size])
        }
        return classes.join(' ')
    }
    return (
        <div className={getClasses()} style={containerStyles}>
            {(!!header || !!icon) && (
                <div className={icon ? styles.headerWithIcon : styles.header}>
                    {icon}
                    {header}
                </div>
            )}
            <div className={styles.content}>{children}</div>
            {!!footer && <div className={styles.footer}>{footer}</div>}
        </div>
    )
}

Box.propTypes = {
    header: PropTypes.node,
    footer: PropTypes.node,
    children: PropTypes.node,
    icon: PropTypes.node,
    type: PropTypes.oneOf(Object.values(BoxTypes)),
    size: PropTypes.oneOf(Object.values(BoxSizes)),
    containerStyles: PropTypes.object,
}

Box.BoxTypes = BoxTypes
Box.BoxSizes = BoxSizes

export { Box };

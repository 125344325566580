// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT = '/user';


// Define all api functions here
export const getAllUsers = (payload) => {
    return axiosInstance.get(ENDPOINT, { params: payload })
}

export async function searchUsers(query) {
    return axiosInstance.get('/user/search', { params: { q: query } })
        .then(({ data }) =>
            data.map(({ id, username, name }) => ({
                label: name ?? username,
                value: id,
            })),
        )
}

export async function searchUsersByRole(query, role) {
    return axiosInstance.get('/user/search/auth', {
        params: {
            q: query,
            item_name: role
        }
    })
        .then(({ data }) =>
            data.map(({ id, name }) => ({
                label: name,
                value: id,
            })),
        )
}
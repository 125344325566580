const GrapeJsMainTable = (editor, /* opts = {} */) => {
    // const config = {
    //     // default configurations can be extended by user
    //     ...opts
    // };

    // Define the custom table component
    editor.Components.addType('custom-table', {
        model: {
            defaults: {
                tagName: 'table',
                classes: ['container', 'main-container'],
                components: [
                    {
                        tagName: 'tbody',
                        components: [{
                            tagName: 'tr',
                            components: [{
                                tagName: 'td',
                                components: [],
                            }]
                        }]
                    }
                ]
            }
        },
        view: {
            onRender() {
                this.el.classList.add('container', 'main-container');
            }
        }
    });

    // Add the custom table component to the editor's block manager
    editor.BlockManager.add('custom-table', {
        label: 'Main Section',
        content: { type: 'custom-table' },
        category: 'Basic',
    });
};

export default GrapeJsMainTable
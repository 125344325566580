import PropTypes from 'prop-types';
import React from 'react';
import { getFilePathForView, getPreviewUrl } from './urls';

export const PDFViewer = ({ path, type, project_id }) => {
  if (type == "pdf") {
    return <iframe
      src={getFilePathForView(path, project_id)} width="100%"
      height="500px">
    </iframe>
  }
  else if (type == "svg") {
    return (
      <img src={getPreviewUrl(path, project_id)} alt="preview" width="100%"
        height="500px" />
    )
  }
  else {
    return (
      <object
        //type='application/pdf'
        data={getFilePathForView(path, project_id)} // Replace with the path to your PDF file
        width="100%"
        height="500px">
      </object>

    );
  }

}

PDFViewer.propTypes = {
  path: PropTypes.string,
  type: PropTypes.string,
  project_id: PropTypes.number,

}


import { Select } from 'antd';
// import { DownSquareOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';
import Industries from '../../library/dynamics/industries' ;
import styles from './CountrySelect.module.scss';
const { Option } = Select

function IndustrySelect({ placeholder, value = null, onChange, ...otherProps }) {
    return (
        <Select 
          
            placeholder={placeholder}
            optionFilterProp="name"
            optionLabelProp="label"
            autoComplete="none"
            onChange={(val) => onChange(val, Industries.find(({ id }) => id === val))}
            value={value ?? undefined}
            showSearch
            {...otherProps}
        >
            {Industries.map((industry) => {
                return (
                    <Option key={industry.id} name={industry.name} value={industry.id} label={industry.name}>
                        <div className={styles.option}>
                            {industry.name}
                        </div>
                    </Option>
                )
            })}
        </Select>
    );
}

IndustrySelect.propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func
}

export default IndustrySelect;
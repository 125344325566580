// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCRwbjMQdtxJEXnJgOojcThC_3k4QpSx_M",
  authDomain: "leoprinting-erp-demo.firebaseapp.com",
  projectId: "leoprinting-erp-demo",
  storageBucket: "leoprinting-erp-demo.appspot.com",
  messagingSenderId: "1044790751951",
  appId: "1:1044790751951:web:293283f29c228f8dfa3dba",
  measurementId: "G-RD7BCD0S6H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);
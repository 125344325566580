import { MailOutlined } from '@ant-design/icons';
import { Alert, Button, message } from 'antd';
import React from 'react';
import { Form, Link, useActionData } from 'react-router-dom';
//import { emailLogin } from '../../api/auth';
import { resetPassword } from '../../api/auth/login.js';
import { InputWrapper } from '../../components/inputs/input-wrappers.jsx';
import { Home } from './login.jsx';
import styles from './login.module.scss';

const ResetPassword = () => {
    const actionData = useActionData()
    return (
        <Home>
            <Form style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }} method="post">
                {actionData === true ? (
                    <Alert
                        description="Password reset instructions are sent to your registered email address.Please check your email for further instructions."
                        type="success"
                        action={
                            <Link to={'/login'}>
                                <Button>Login</Button>
                            </Link>
                        }
                        showIcon
                    />
                ) : (
                    <>
                        <InputWrapper name="email" prefix={<MailOutlined />} placeholder="Email" style={{ borderRadius: 0, fontSize: '1rem' }} required />
                        <div className={styles.loginButtonWrapper}>
                            <Link to={-1}><Button type="link" htmlType="submit" className="login-form-button">Cancel</Button></Link>
                            <Button type="primary" htmlType="submit" className="login-form-button">Reset Password</Button>
                        </div>
                    </>
                )}
            </Form>
        </Home>
    )
}
ResetPassword.Action = async ({ request }) => {
    const { email } = Object.fromEntries(await request.formData())
    try {
        const msg = await resetPassword(email)
        message.success(msg)
        return true
    }
    catch (error) {
        message.error(error.message)
    }
    return false
}

export default ResetPassword
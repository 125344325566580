export const formDataToJson = (formData) => {
  return Object.entries(formData).reduce((json, [key, value]) => {
    const matches = key.match(/(\w+)\[(\d+)\]/);
    if (matches) {
      const [, parentKey, childKey] = matches;
      json[parentKey] = json[parentKey] || {};
      json[parentKey][childKey] = value;
    } else {
      const matches2 = key.match(/\[(\d+)\](\w+)/);
      if (matches2) {
        const [, parentKey, childKey] = matches2;
        json[parentKey] = json[parentKey] || {};
        json[parentKey][childKey] = value;
      }
      else {
        json[key] = value;
      }
    }
    return json;
  }, {});
}
function deepNest(obj, path, value) {
  if (Object(obj) !== obj) return obj; // When obj is not an object
  // If not yet an array, get the keys from the string-path
  if (!Array.isArray(path)) path = path.toString().match(/[^.[\]]+/g) || [];
  path.slice(0, -1).reduce((a, c, i) => // Iterate all of them except the last one
    Object(a[c]) === a[c] // Does the key exist and is its value an object?
      // Yes: then follow that path
      ? a[c]
      // No: create the key. Is the next key a potential array-index?
      : a[c] = Math.abs(path[i + 1]) >> 0 === +path[i + 1]
        ? [] // Yes: assign a new array object
        : {}, // No: assign a new plain object
    obj)[path[path.length - 1]] = value; // Finally assign the value to the last key
  return obj; // Return the top-level object to allow chaining
}

export const formDataToJsonV2 = (formData) => Object.entries(formData).reduce((acc, [path, value]) => deepNest(acc, path, value), {})

export function formDataToNestedJson(formData) {
  const result = {};
  formData.forEach((value, key) => {
    const keys = key.split('[').map((k) => k.replace(']', ''));

    let currentObj = result;

    keys.forEach((k, index) => {
      if (!currentObj[k]) {
        if (index === keys.length - 1) {
          currentObj[k] = value;
        } else {
          currentObj[k] = {};
        }
      }
      currentObj = currentObj[k];
    });
  });

  return result;
}

export const convertToNested = (inputObject) => {
  const nestedArray = [];

  Object.entries(inputObject).forEach(([key, value]) => {
    const [, index, nestedKey] = key.match(/\[(\d+)\](.+)/);

    if (!nestedArray[index]) {
      nestedArray[index] = {};
    }

    nestedArray[index][nestedKey] = value;
  });

  return nestedArray.filter(Boolean); // Filter out undefined entries
};


export const parseFormData = (formData) => {
  let parsedData = {}
  formData.forEach((value, key) => {
    // Check if the key already exists
    if (Object.prototype.hasOwnProperty.call(parsedData, key)) {
      // If it's an array, push the new value
      if (Array.isArray(parsedData[key])) {
        parsedData[key].push(value);
      } else {
        // If it's not an array, convert it to an array and add the new value
        parsedData[key] = [parsedData[key], value];
      }
    } else {
      // If the key doesn't exist, create a new key-value pair
      parsedData[key] = value;
    }
  });
  return parsedData
}
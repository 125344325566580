import { Steps as AntdSteps } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styles from './step.module.scss';

function insertMandatoryStep(steps, newStep) {
    // Find the index where the newStep should be placed
    const index = steps.findIndex(({ itemstatus }) => itemstatus >= newStep.status);

    if (index === -1) {
        // If no greater or equal item is found, replace the last newStep with the new newStep
        steps[steps.length - 1] = newStep;
        steps[steps.length - 2].dotted = 'yes'
    } else {
        // Replace the found item with the new newStep
        steps[index] = newStep;
        steps[index - 1].dotted = 'yes'
    }

    // Keep the steps sorted after the replacement
    steps.sort((a, b) => a.itemstatus - b.itemstatus);

    return steps;
}

const StepsAdvance = ({ items, currentStatus = 0, history, optionalSteps = [], mandatorySteps = [], onChange, debug = false, ...otherProps }) => {
    const current = items.findIndex(({ status }) => status === currentStatus)
    const indexeditems = useMemo(() => items.filter(({ status }, index) => {
        if (index > current) return !optionalSteps?.includes(status)//Always include future (except optional step)
        if (status === currentStatus) return true //Always include current step
        if (mandatorySteps?.includes(status)) return true //Always include mandatory steps
        if (!history?.length) return true //Show remaining if history is not specified
        return history.includes(status) //For past steps show only if in history
    }).map((item) => ({
        title: item.title,
        icon: (
            <div className={currentStatus === item.status ? styles.stepIconActive : styles.stepIcon}>
                {(item.activeIcon && item.status === currentStatus) ? item.activeIcon : item.icon}
            </div>
        ),
        itemstatus: item.status
    })), [items, currentStatus, history, optionalSteps, mandatorySteps])

    const currentIndex = indexeditems.findIndex(({ itemstatus }) => itemstatus === currentStatus)
    // const mandatoryIndices = useMemo(() => mandatorySteps.map(a => indexeditems.findIndex(({ itemstatus }) => a === itemstatus)), [items, mandatorySteps])

    const visibleItems = useMemo(() => {
        const maxVisible = 9;
        if (indexeditems.length <= maxVisible) {
            return indexeditems
        }

        if (mandatorySteps.length > maxVisible) {
            const _splicedMandatorySpliced = mandatorySteps.splice(0, maxVisible)
            return indexeditems.filter(({ itemstatus }) => _splicedMandatorySpliced.includes(itemstatus))
        }

        let vi;
        if (currentIndex <= 4) {
            vi = [...indexeditems.slice(0, 6), ...indexeditems.slice(-3)];
            vi[5].dotted = 'yes';
        } else if (currentIndex > (indexeditems.length - 4)) {
            vi = [...indexeditems.slice(0, 3), ...indexeditems.slice(-6)];
            vi[2].dotted = 'yes';
        } else {
            vi = [
                ...indexeditems.slice(0, 2),
                ...indexeditems.slice(currentIndex - 2, currentIndex + 3),
                ...indexeditems.slice(-2)
            ];
            vi[1].dotted = 'yes';
            vi[6].dotted = 'yes';
        }
        mandatorySteps.forEach(a => {
            const mi = vi.findIndex(({ itemstatus }) => a === itemstatus)
            if (mi >= 0) {
                return
            }
            vi = insertMandatoryStep(vi, indexeditems.find(({ itemstatus }) => itemstatus === a))
        })
        return vi
    }, [indexeditems])

    const selectedStep = useMemo(() => visibleItems.findIndex(({ itemstatus }) => itemstatus === currentStatus), [visibleItems, currentStatus])

    return (
        <>
            {debug && indexeditems.map(({ title, itemstatus }) => (
                <span key={title} style={{
                    background: currentStatus === itemstatus ? 'red' : 'white',
                    border: '1px black solid',
                    padding: '3px',
                    margin: '3px',
                    borderRadius: '3px'
                }}>{title}</span>
            ))}
            <AntdSteps
                {...otherProps}
                onChange={onChange ? (i) => onChange(visibleItems[i].index, i) : undefined}
                items={visibleItems}
                current={selectedStep}
            />
            <div className={styles.stepsWrapperActiveMarker} />
        </>
    )
}
StepsAdvance.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentStatus: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(PropTypes.number),
    optionalSteps: PropTypes.arrayOf(PropTypes.number),
    mandatorySteps: PropTypes.arrayOf(PropTypes.number),
    onChange: PropTypes.func,
    debug: PropTypes.bool
}


export default StepsAdvance
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { searchVendors } from '../../api/vendor/vendor.js';

function VendorSelect({ value = null, onChange, currentSelection = {}, ...otherProps }) {
    const debounceTimeout = 800
    const selectedOption = currentSelection?.value && currentSelection.value === value ? [{ value: currentSelection?.value, label: currentSelection?.label }] : []
    const [fetching, setFetching] = useState(selectedOption);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);

    useEffect(() => {
        debounceFetcher()
    }, [])

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions(selectedOption);
            setFetching(true);
            searchVendors(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                if (!selectedOption.some(({ value }) => value === currentSelection.value)) {
                    setOptions([...selectedOption, ...newOptions]);
                }
                else {
                    setOptions([...newOptions]);
                }
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [value, currentSelection, searchVendors, debounceTimeout]);

    return (
        <Select
            {...otherProps}
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            options={options}
            onChange={onChange}
            value={value}
            placeholder="Select Vendor"
            showSearch
            allowClear
        />
    );
}

VendorSelect.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    currentSelection: PropTypes.shape({
        id: PropTypes.number,
        company: PropTypes.string,
    })
}

export default VendorSelect;
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Modal, Space, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { getFilePath, getPreviewUrl, isShowable } from "../../../library/constants";
import { PDFViewer } from '../../../library/utilities/PDFviewer';
import { Image } from "../../../ui";
import style from './received-artwork.module.scss';

const DocumentsDownloader = ({ image, name, comment, project_id, containerStyles }) => {
    const [isModalOpen, setisOpen] = useState(false)
    return (<div className={style.itemDetail} style={containerStyles}>
        <div className={style.bottomButton}>
            <Image src={getPreviewUrl(image, project_id)} width="100" className={style.imageBox} />
            <div className={style.imageName}>
                <h4>{name}</h4>
                {comment != undefined && <p><b>Comment</b> : {comment}</p>}
                <Space.Compact>
                    <Link reloadDocument to={getFilePath(image, project_id)} download="image.png"> <Button icon={<DownloadOutlined />} >Download</Button> </Link>
                    <Button icon={<EyeOutlined />} onClick={() => { isShowable(image) ? setisOpen(true) : message.warning('Not Previewable') }}>View</Button>
                </Space.Compact>
            </div>
        </div>
        <Modal title="Preview" open={isModalOpen} onCancel={() => { setisOpen(false) }} footer={<Button onClick={() => { setisOpen(false) }} >Close</Button>}>
            <PDFViewer path={image} project_id={project_id} type={image.split('.').pop()} />
        </Modal>
    </div>)
}

DocumentsDownloader.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    comment: PropTypes.string,
    project_id: PropTypes.number,
    containerStyles: PropTypes.object,
}

export default DocumentsDownloader
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_SINGLE_ACTION = '/order/item/:id/:action';


//APIS for vendor when finish is involved

export const sendOrderToSupplier = (itemId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: itemId,
        action: 'order-send-to-vendor'
    })).then(({ message }) => message)
}

export const sendSupplierDispatchDateNotification = (itemId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: itemId,
        action: 'vendor-ask-expected-date'
    })).then(({ message }) => message)
}

export const updateSupplierExpectedDispatchDate = (itemId, expected_dispatch_date) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: itemId,
        action: 'add-delivery-expected-date'
    }), {
        expected_dispatch_date
    }).then(({ message }) => message)
}

export const sendSupplierOrderShippingDetails = (itemId, ship_quantity, expected_delivery_date, tracking_url = '') => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: itemId,
        action: 'add-delivery-shipping-details'
    }), {
        ship_quantity,
        tracking_url,
        expected_delivery_date
    }).then(({ message }) => message)
}

export const supplierConfirmOrderDelivery = (itemId, image_ids, delivery_proof_comment = '') => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: itemId,
        action: 'delivery-shipping-confirm'
    }), {
        image_ids,
        delivery_proof_comment
    }).then(({ message }) => message)
}

export const uploadSupplierFiles = (itemId, files) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: itemId,
        action: 'supplier-files'
    }), {
        image_ids: files
    }).then(({ message }) => message)

}

export const addItemSupplierrRating = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id,
        action: 'supplier-rating'
    }), payload).then(({ message }) => message)
}


export const getItemSupplierRating = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE_ACTION, {
        id,
        action: 'supplier-rating'
    })).then(({ data }) => data)
}

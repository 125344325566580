import { convertCaseToText } from "../helpers/index.js"

export const OrderStatuses = {
    // convert this to normal status in DB 
    ORDER_CONFIRMATION: 1,
    ORDER_SEND: 2,
    WAITING_FOR_FILES: 3,
    //TEMPLATE
    TEMPLATE_REQUEST_FROM_VENDOR: 4,
    TEMPLATE_RECEIVED_FROM_VENDOR: 5,
    TEMPLATE_SENT_TO_STUDIO: 6,
    TEMPLATE_READY: 7,
    TEMPLATE_SEND_TO_CLIENT: 8,
    //ARTWORK
    MAKE_ARTWORK: 9,
    MAKE_ARTWORK_REJECTED: 10,
    ARTWORK_RECEIVED: 11,
    ARTWORK_REJECTED: 12,
    ARTWORK_SEND_TO_STUDIO: 13,
    ARTWORK_READY: 14,
    //PROOF
    REQUEST_VENDOR_FOR_PROOF: 15,
    PROOF_RECEIVED: 16,
    PROOF_REJECTED: 17, // status change api
    PROOF_SENT_TO_STUDIO: 18,
    PROOF_READY: 19,
    PROOF_SENT_TO_CLIENT: 20,
    PROOF_REJECTED_BY_CLIENT: 21,
    //PRODUCTION
    READY_FOR_PRODUCTION: 22,
    IN_PRODUCTION: 23,
    EXPECTED_DISPATCHED_DATE: 24,
    PRODUCT_DISPATCHED: 25,
    DELIVERED: 26,
    DELAY_IN_DELIVERY: 27,
    ON_HOLD: 28,
    CANCEL_REQUEST: 29,
    //CLIENT requested template
    CLIENT_REQUEST_TEMPLATE: 30,

    MAKE_ARTWORK_SENT_TO_STUDIO: 101,
    MAKE_ARTWORK_READY: 102,
    CANCELED: 110,
}

export const OrderStatusNameById = (id) => convertCaseToText(Object.keys(OrderStatuses).find(k => OrderStatuses[k] === id))

export const ItemEditableOrderStatuses = [
    OrderStatuses.ORDER_CONFIRMATION,
    OrderStatuses.ORDER_SEND,
    OrderStatuses.WAITING_FOR_FILES,
    OrderStatuses.TEMPLATE_REQUEST_FROM_VENDOR,
    OrderStatuses.TEMPLATE_RECEIVED_FROM_VENDOR,
    OrderStatuses.TEMPLATE_SENT_TO_STUDIO,
    OrderStatuses.TEMPLATE_READY,
    OrderStatuses.TEMPLATE_SEND_TO_CLIENT,
    OrderStatuses.MAKE_ARTWORK,
    OrderStatuses.MAKE_ARTWORK_REJECTED,
    OrderStatuses.ARTWORK_RECEIVED,
    OrderStatuses.ARTWORK_REJECTED,
    OrderStatuses.ARTWORK_SEND_TO_STUDIO,
    OrderStatuses.ARTWORK_READY,
    OrderStatuses.REQUEST_VENDOR_FOR_PROOF,
    OrderStatuses.PROOF_RECEIVED,
    OrderStatuses.PROOF_REJECTED,
    OrderStatuses.PROOF_SENT_TO_STUDIO,
    OrderStatuses.PROOF_READY,
    OrderStatuses.PROOF_SENT_TO_CLIENT,
    OrderStatuses.PROOF_REJECTED_BY_CLIENT,
    OrderStatuses.READY_FOR_PRODUCTION,
    OrderStatuses.CLIENT_REQUEST_TEMPLATE,
    OrderStatuses.MAKE_ARTWORK_SENT_TO_STUDIO,
    OrderStatuses.MAKE_ARTWORK_READY
]
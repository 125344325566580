export const InvoiceStatuses = {
    NEW: 1,
    REMINDER: 2,
    FINALIZE: 3,
    SEND_TO_CLIENT: 4,
    CLOSED_LOST: 5,
    SENT_TO_EULER: 6,
    PAID: 7,
    FIRST_REMINDER: 8,
    SECOND_REMINDER: 9,
    THIRD_REMINDER: 10,
    FOURTH_REMINDER: 11,
    CANCEL: 12
}

const invoiceStatusTitles = {
    [InvoiceStatuses.NEW]: "New",
    [InvoiceStatuses.REMINDER]: "Reminder",
    [InvoiceStatuses.FINALIZE]: "Finalize",
    [InvoiceStatuses.SEND_TO_CLIENT]: "Sent To Client",
    [InvoiceStatuses.CLOSED_LOST]: "Closed Lost",
    [InvoiceStatuses.SENT_TO_EULER]: "Sent to Euler",
    [InvoiceStatuses.PAID]: "Paid",
    [InvoiceStatuses.FIRST_REMINDER]: "First Reminder",
    [InvoiceStatuses.SECOND_REMINDER]: "Second Reminder",
    [InvoiceStatuses.THIRD_REMINDER]: "Third Reminder",
    [InvoiceStatuses.FOURTH_REMINDER]: "Final Reminder",
    [InvoiceStatuses.CANCEL]: "Canceled"
}

export const findInvoiceStatusById = (status) => invoiceStatusTitles[status]

export const ReminderTypes = {
    FIRST: "first",
    SECOND: "second",
    THIRD: "third",
    FINAL: "fourth",
}
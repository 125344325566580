import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { capitalize } from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './DropdownSelect.module.scss';

const AddressRoles = Object.freeze({
	PRIMARY: "primary",
	Delivery: "delivery",
	INVOICE: "invoice",
})

const actions = [
	{
		label: "Primary",
		type: AddressRoles.PRIMARY,
		action: "add",
		visible: (currentRoles) => !currentRoles.includes(AddressRoles.PRIMARY)
	},
	{
		label: "Delivery",
		type: AddressRoles.Delivery,
		action: "add",
		visible: (currentRoles) => !currentRoles.includes(AddressRoles.PRIMARY) && !currentRoles.includes(AddressRoles.Delivery)
	},
	{
		label: "Invoice",
		type: AddressRoles.INVOICE,
		action: "add",
		visible: (currentRoles) => !currentRoles.includes(AddressRoles.PRIMARY) && !currentRoles.includes(AddressRoles.INVOICE)
	},
	{
		label: "Remove Delivery",
		type: AddressRoles.Delivery,
		action: "remove",
		visible: (currentRoles) => currentRoles.includes(AddressRoles.Delivery)
	},
	{
		label: "Remove Invoice",
		type: AddressRoles.INVOICE,
		action: "remove",
		visible: (currentRoles) => currentRoles.includes(AddressRoles.INVOICE)
	}
]

const CustomerAddressRoleSelect = ({ addressId, isPrimary, isDelivery, isInvoice, onChange }) => {
	const getRoles = () => {
		if (isPrimary) {
			return [AddressRoles.PRIMARY]
		}
		const roles = []
		if (isDelivery) {
			roles.push(AddressRoles.Delivery)
		}
		if (isInvoice) {
			roles.push(AddressRoles.INVOICE)
		}
		return roles;
	};

	const getActions = () => actions.filter(action => action.visible(getRoles()))

	const getValue = () => {
		const roles = getRoles()
		if (!roles || roles.length === 0) {
			return 'Set role';
		}
		return roles.map(role => capitalize(role)).join(' / ')
	};

	const onDropdownChange = ({ key }) => {
		if (onChange) {
			const [type, action] = key.split(':')
			onChange({ addressId, type, action })
			return
		}
	};

	if (getActions().length === 0) {
		return <div className={styles.selectedValue}>
			<span className={styles.selectedText}>{getValue()}</span>
		</div>
	}

	return (
		<Dropdown
			menu={{
				items: getActions().map(action => ({
					label: action.label,
					key: [action.type, action.action].join(':')
				})),
				onClick: onDropdownChange
			}}
		>
			<div className={styles.selectedValue}>
				<span className={styles.selectedText}>{getValue()}</span>
				<DownOutlined />
			</div>
		</Dropdown>
	);
};
CustomerAddressRoleSelect.propTypes = {
	addressId: PropTypes.number.isRequired,
	isPrimary: PropTypes.bool.isRequired,
	isDelivery: PropTypes.bool.isRequired,
	isInvoice: PropTypes.bool.isRequired,
	onChange: PropTypes.func,
};

export { AddressRoles, CustomerAddressRoleSelect };


// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = 'order/item/:itemId/vendor-comment'

export const sendCommentToVendor = (itemId, comment) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL, {
        itemId
    }), {
        comment
    }).then(({ message }) => message)
}

export const getOrderVendorCommentHistory = (itemId) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL, {
        itemId
    })).then(({ data }) => data)
}
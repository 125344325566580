import { SendOutlined } from '@ant-design/icons';
import { Button, Drawer, message } from 'antd';
import React from 'react';
import { redirect, useFetcher, useNavigate, useParams, useRouteLoaderData } from 'react-router-dom';
import { sendAddtionalQuotation } from '../../../api/project-item/project-item.js';
import { PdfPreview } from '../../../components/data/pdfpreview.jsx';
import { ValidationError } from '../../../library/classes/exceptions/index.js';
import { generateRouteUrl, getAdditionalQuotationFile } from '../../../library/constants';
``

const AdditionalQuotationPreview = () => {
    const { projectId, itemId, id } = useParams()
    const navigate = useNavigate()
    const { additionalQuotation } = useRouteLoaderData('AdditionalQuotation')
    const fetcher = useFetcher()
    const quotation = additionalQuotation?.some(q => q.id === Number(id))

    const handleSendAdditionalQuotation = (quotationId) => {
        fetcher.submit({
            quotationId,
            action: 'sendAdditonalQuotation'
        }, {
            action: generateRouteUrl('AdditionalQuotationPreview', {
                id,
                projectId,
                itemId
            }),
            method: 'post',
            encType: 'application/json',
            replace: true,
        })
    }

    return (
        <Drawer
            open={true}
            width="50%"
            onClose={() => {
                navigate(generateRouteUrl('AdditionalQuotation', {
                    projectId,
                    itemId
                }), { replace: true })
            }}
            title="Additional Quotation Preview"
            destroyOnClose
            forceRender={true}
            getContainer='#sidebar'
            rootStyle={{ position: 'absolute' }}
            extra={!quotation && (
                <Button
                    icon={<SendOutlined />}
                    type="primary"
                    onClick={() => { handleSendAdditionalQuotation(id) }}
                    loading={fetcher.state === 'submitting' && fetcher.json?.action === 'sendAdditonalQuotation'}
                >Send to Customer</Button>
            )}
        >
            <PdfPreview url={getAdditionalQuotationFile(id)} />
        </Drawer>
    )
}

AdditionalQuotationPreview.Actions = {
    sendAdditonalQuotation: async ({ params, data }) => {
        const { projectId, itemId } = params
        const { quotationId } = data
        try {
            const { message: msg } = await sendAddtionalQuotation(quotationId)
            message.success(msg)
            const redirectUrl = generateRouteUrl('AdditionalQuotation', {
                projectId,
                itemId
            })
            let params = new URLSearchParams(location.search);
            //Set submitted to identify if user has submitted the request, to clear parent form
            params.set("submitted", 1);
            return redirect(`${redirectUrl}?${params.toString()}`)
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errros
                }
            }
        }
        return false
    },
}
export default AdditionalQuotationPreview
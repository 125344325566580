// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/order/item';

// const ENDPOINT_SINGLE_ACTION = '/project/:id/:action';

export const getCombiSuggestions = () => {
    return axiosInstance.get(`${ENDPOINT_GENERAL}/combi-suggest`).then(({ data }) => data)
}
export const getCombiSuggestionDetails = (productId) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL + '/combi-suggest-details/:id', {
        id: productId
    })).then(({ data }) => data)
}

export const sendCombiRequest = (item_ids, vendor_ids, expected_delivery_date, comment) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/combi/request-quotations'), {
        item_ids,
        vendor_ids,
        expected_delivery_date,
        comment
    })
}

export const acceptCombiRequest = (id, vendorId) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/combi/:id/quotations/:vendorId/approved', {
        id,
        vendorId
    })).then(({ message }) => message)
}
export const getSentCombiSuggest = (payload) => {
    return axiosInstance.get(`${ENDPOINT_GENERAL}/combi` , { params: payload })
}

export const getSentCombiDetails = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL + '/combi/:id', {
        id
    })).then(({ data }) => data)
}

export const reviseCombiRequest = (id, vendorId, comment) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/combi/:id/vendor/:vendorId/revised', {
        id,
        vendorId
    }), {
        comment
    }).then(({ message }) => message)
}
export const updateCombiQuotationPrices = (id, vendorId, price_unit, transport_price, expected_artwork_received_date, comment) => {
    const expectedArtworkDate = (expected_artwork_received_date && expected_artwork_received_date.length > 0) ? expected_artwork_received_date : null
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/combi/:id/vendor/:vendorId/update-quotation-price', {
        id,
        vendorId,
    }), {
        price_unit,
        transport_price,
        expected_artwork_received_date: expectedArtworkDate,
        comment
    }).then(({ message }) => message)
}
import { FileSearchOutlined } from '@ant-design/icons';
import { DatePicker, Space, Table } from 'antd';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  // LinearScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Await, defer, useLoaderData, useSearchParams } from 'react-router-dom';
import { getEnquiriesConversion, getInsightToday, getInsights, getMonthlyEnquiries, getProjectsComparison, getSalesLeaderboard, getSalesOverYears, getTurnoverComparison } from '../../api/reporting/dashboard.js';
import { StatCard, StatCardSkeleton } from '../../components/design';
import { Box } from '../../components/design/box.js';
import { Page } from '../../components/page';
import { ERP_CURRENCY } from '../../library/constants/dynamic.js';
import { formats } from '../../library/constants/formats.js';
import { formatPrice, roundOfNumber } from '../../library/utilities/intl.js';

ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const dateFormat = formats.frontendDateFormat
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const ranges = {
  Today: [moment(), moment()],
  Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'This Week': [moment().startOf('week'), moment().endOf('week')],
  'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  'This Year': [moment().startOf('year'), moment().endOf('year')],
  'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
}

function TodayInsight({ dataPromise }) {
  return (
    <div style={{ display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(4, 1fr)' }}>
      <React.Suspense fallback={<>
        <StatCardSkeleton title="New Enquiry" icon={<FileSearchOutlined />} />
        <StatCardSkeleton title="Due Date" />
        <StatCardSkeleton title="Outstanding" icon={<FileSearchOutlined />} />
        <StatCardSkeleton title="Waiting For Invoice" icon={<FileSearchOutlined />} />
      </>}>
        <Await resolve={dataPromise} errorElement={<></>} >
          {(data = {}) => (
            <>
              <StatCard title="New Enquiry" icon={<FileSearchOutlined />} data={data.new_enquiries} />
              <StatCard title="Due Date" data={data.due_invoices} />
              <StatCard title="Outstanding" icon={<FileSearchOutlined />} data={formatPrice(data.outstanding_amount, ERP_CURRENCY)} />
              <StatCard title="Waiting For Invoice" icon={<FileSearchOutlined />} data={formatPrice(data.waiting_for_invoice_amount, ERP_CURRENCY)} />
            </>
          )}
        </Await>
      </React.Suspense>
    </div>
  )
}
TodayInsight.propTypes = {
  dataPromise: PropTypes.shape({
    then: PropTypes.func.isRequired,
    catch: PropTypes.func.isRequired
  }).isRequired
}

function Insights({ dataPromise }) {
  return (
    <div style={{ display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(4, 1fr)' }}>
      <React.Suspense fallback={<>
        <StatCardSkeleton type={StatCard.Types.GRAY} title="Total Created Projects" icon={<FileSearchOutlined />} />
        <StatCardSkeleton type={StatCard.Types.GRAY} title="Quotation Sent" icon={<FileSearchOutlined />} />
        <StatCardSkeleton type={StatCard.Types.GRAY} title="Confirmed Projects" icon={<FileSearchOutlined />} />
        <StatCardSkeleton type={StatCard.Types.GRAY} title="Total Turnover" icon={<FileSearchOutlined />} />
        <StatCardSkeleton type={StatCard.Types.GRAY} title="Avg margin %" icon={<FileSearchOutlined />} />
        <StatCardSkeleton type={StatCard.Types.GRAY} title="Web Order" icon={<FileSearchOutlined />} />
        <StatCardSkeleton type={StatCard.Types.GRAY} title="Web Order Margin" icon={<FileSearchOutlined />} />
        <StatCardSkeleton type={StatCard.Types.GRAY} title="Potential Enquiry Value" icon={<FileSearchOutlined />} />
        <StatCardSkeleton type={StatCard.Types.GRAY} title="Unique Client" icon={<FileSearchOutlined />} />
        <StatCardSkeleton type={StatCard.Types.GRAY} title="Lost Projects" icon={<FileSearchOutlined />} />
        <StatCardSkeleton type={StatCard.Types.GRAY} title="Close Lost Value" icon={<FileSearchOutlined />} />
        <StatCardSkeleton type={StatCard.Types.GRAY} title="Received Amount" icon={<FileSearchOutlined />} />
      </>}>
        <Await resolve={dataPromise} errorElement={<></>} >
          {(data = {}) => (
            <>
              <StatCard type={StatCard.Types.GRAY} title="Total Created Projects" icon={<FileSearchOutlined />} data={data.projects_created} />
              <StatCard type={StatCard.Types.GRAY} title="Quotation Sent" icon={<FileSearchOutlined />} data={data.quotations_sent} />
              <StatCard type={StatCard.Types.GRAY} title="Confirmed Projects" icon={<FileSearchOutlined />} data={data.projects_confirmed} />
              <StatCard type={StatCard.Types.GRAY} title="Total Turnover" icon={<FileSearchOutlined />} data={formatPrice(data.turnover, ERP_CURRENCY)} />
              <StatCard type={StatCard.Types.GRAY} title="Avg margin %" icon={<FileSearchOutlined />} data={`${roundOfNumber(data.average_margin)} %`} />
              <StatCard type={StatCard.Types.GRAY} title="Web Order" icon={<FileSearchOutlined />} data={formatPrice(data.web_orders_value, ERP_CURRENCY)} />
              <StatCard type={StatCard.Types.GRAY} title="Web Order Margin" icon={<FileSearchOutlined />} data={`${roundOfNumber(data.web_orders_margin)} %`} />
              <StatCard type={StatCard.Types.GRAY} title="Potential Enquiry Value" icon={<FileSearchOutlined />} data={formatPrice(data.potential_enquiry_value, ERP_CURRENCY)} />
              <StatCard type={StatCard.Types.GRAY} title="Unique Client" icon={<FileSearchOutlined />} data={data.unique_clients} />
              <StatCard type={StatCard.Types.GRAY} title="Lost Projects" icon={<FileSearchOutlined />} data={data.projects_lost} />
              <StatCard type={StatCard.Types.GRAY} title="Close Lost Value" icon={<FileSearchOutlined />} data={formatPrice(data.close_lose_value, ERP_CURRENCY)} />
              <StatCard type={StatCard.Types.GRAY} title="Received Amount" icon={<FileSearchOutlined />} data={formatPrice(data.amount_recieved, ERP_CURRENCY)} />
            </>
          )}
        </Await>
      </React.Suspense>
    </div>
  )
}
Insights.propTypes = {
  dataPromise: PropTypes.shape({
    then: PropTypes.func.isRequired,
    catch: PropTypes.func.isRequired
  }).isRequired
}


function TurnOverComparison({ dataPromise, year1, year2 }) {
  const colors = {
    year1: '#4178d1',
    year2: '#a5c7ff',
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        // align: 'start',
      },
    },
  }

  return (
    <React.Suspense>
      <Await resolve={dataPromise} errorElement={<></>} >
        {(data = []) => {
          return (
            <Bar options={options} data={{
              labels: months,
              datasets: [
                {
                  label: year1,
                  data: months.map((_, monthIndex) => data.find(v => v.month === (monthIndex + 1))?.data1 ?? 0),
                  backgroundColor: colors.year1,
                },
                {
                  label: year2,
                  data: months.map((_, monthIndex) => data.find(v => v.month === (monthIndex + 1))?.data2 ?? 0),
                  backgroundColor: colors.year2,
                },
              ]
            }} />
          )
        }}
      </Await>
    </React.Suspense>
  )
}
TurnOverComparison.propTypes = {
  dataPromise: PropTypes.shape({
    then: PropTypes.func.isRequired,
    catch: PropTypes.func.isRequired
  }).isRequired,
  year1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  year2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

function ProjectsComparison({ dataPromise, year1, year2 }) {
  const colors = {
    year1: '#E8AA4D',
    year2: '#F6D97C',
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        // align: 'start',
      },
    },
  }

  return (
    <React.Suspense>
      <Await resolve={dataPromise} errorElement={<></>} >
        {(data = []) => {
          return (
            <Bar options={options} data={{
              labels: months,
              datasets: [
                {
                  label: year1,
                  data: months.map((_, monthIndex) => data.find(v => v.month === (monthIndex + 1))?.data1 ?? 0),
                  backgroundColor: colors.year1,
                },
                {
                  label: year2,
                  data: months.map((_, monthIndex) => data.find(v => v.month === (monthIndex + 1))?.data2 ?? 0),
                  backgroundColor: colors.year2,
                },
              ]
            }} />
          )
        }}
      </Await>
    </React.Suspense>
  )
}
ProjectsComparison.propTypes = {
  dataPromise: PropTypes.shape({
    then: PropTypes.func.isRequired,
    catch: PropTypes.func.isRequired
  }).isRequired,
  year1: PropTypes.string.isRequired,
  year2: PropTypes.string.isRequired,
}

function SalesOverYears({ dataPromise }) {
  const colors = ['#4178D1', '#23B27C', '#FFA590', '#F6D97C', '#FFEEB9', '#A1E1B9', '#B7D1FB']

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        // align: 'start',
      },
    },
  }

  return (
    <React.Suspense>
      <Await resolve={dataPromise} errorElement={<></>} >
        {(data = []) => {
          return (
            <Line options={options} data={{
              labels: months,
              datasets: Object.entries(data).map(([year, turnover], i) => (
                {
                  label: year,
                  data: months.map((_, monthIndex) => turnover.find(v => parseInt(v.month) === (monthIndex + 1))?.sum ?? 0),
                  backgroundColor: colors[i] ?? '#4178D1',
                  borderColor: colors[i] ?? '#4178D1',
                }
              ))
            }} />
          )
        }}
      </Await>
    </React.Suspense>
  )
}
SalesOverYears.propTypes = {
  dataPromise: PropTypes.shape({
    then: PropTypes.func.isRequired,
    catch: PropTypes.func.isRequired
  }).isRequired,
}

const LeaderboardProgress = ({ val, maxVal }) => (
  <div style={{
    width: `${(val / maxVal) * 100}%`,
    background: '#23b17c',
    height: '25px',
    borderRadius: '5px',
    color: 'white',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '5px',
    minWidth: 'fit-content',
  }}>{formatPrice(val, ERP_CURRENCY)}</div>
)
LeaderboardProgress.propTypes = {
  val: PropTypes.number.isRequired,
  maxVal: PropTypes.number.isRequired,
}

const Leaderboard = ({ data }) => {
  const maxTurnover = data.reduce((prev, current) => (prev && prev > current.total_price) ? prev : current.total_price, 0)
  // console.log({ maxTurnover })

  return (
    <Table
      columns={[
        {
          title: 'Account Manager',
          dataIndex: 'name',
        },
        {
          title: 'Turnover',
          dataIndex: 'total_price',
          render: val => <LeaderboardProgress val={val} maxVal={maxTurnover} />
        },
        {
          title: 'Margin',
          dataIndex: 'total_margin',
          render: val => `${roundOfNumber(val, 2)} %`
        },
        {
          title: 'Adjusted Result',
          dataIndex: 'total_price_with_margin',
          render: val => formatPrice(val, ERP_CURRENCY)
        },
      ]}
      dataSource={data}
      rowKey='id'
      pagination={false}
    // onChange={(...[, , { field, order }]) => {
    //   onChange({
    //     sortBy: field,
    //     sortType: sortOrders[order] ?? undefined,
    //   })
    // }}
    // onRow={(record, rowIndex) => ({
    //   onClick: event => {
    //     if (event.target.querySelector('input[type="checkbox"]')) {
    //       return
    //     }
    //     event.preventDefault()
    //     onRowClick(rowIndex, record)
    //   }
    // })}
    />
  )
}
Leaderboard.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
}

function EnqiriesCount({ dataPromise }) {
  const colors = {
    website: '#B7D1FB',
    call: '#FFEEB9',
    email: '#A1E1B9',
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        // align: 'start',
      },
      datalabels: {
        display: true,
        formatter: (v) => (v && v > 0) ? v : '',
        color: 'black',
        font: {
          weight: 'bold',
        },
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  }

  return (
    <React.Suspense>
      <Await resolve={dataPromise} errorElement={<></>} >
        {(data = []) => {
          return (
            <Bar options={options} data={{
              labels: months,
              datasets: [
                {
                  label: 'Website',
                  data: months.map((_, monthIndex) => data.find(v => v.month === (monthIndex + 1))?.website ?? 0),
                  backgroundColor: colors.website,
                },
                {
                  label: 'E-mail',
                  data: months.map((_, monthIndex) => data.find(v => v.month === (monthIndex + 1))?.email ?? 0),
                  backgroundColor: colors.email,
                },
                {
                  label: 'Call',
                  data: months.map((_, monthIndex) => data.find(v => v.month === (monthIndex + 1))?.phone ?? 0),
                  backgroundColor: colors.call,
                },
              ]
            }} plugins={[ChartDataLabels]} />
          )
        }}
      </Await>
    </React.Suspense>
  )
}
EnqiriesCount.propTypes = {
  dataPromise: PropTypes.shape({
    then: PropTypes.func.isRequired,
    catch: PropTypes.func.isRequired
  }).isRequired
}

function EnquiriesConverion({ dataPromise }) {
  const colors = {
    total: '#FFDAD1',
    converted: '#FFA590',
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        // align: 'start',
      },
      datalabels: {
        display: true,
        formatter: (v) => (v && v > 0) ? v : '',
        color: 'black',
        font: {
          weight: 'bold',
        },
      },
    },
  }

  return (
    <React.Suspense>
      <Await resolve={dataPromise} errorElement={<></>} >
        {(data = []) => {
          return (
            <Bar options={options} data={{
              labels: months,
              datasets: [
                {
                  label: 'New Enquiries',
                  data: months.map((_, monthIndex) => data.find(v => v.month === (monthIndex + 1))?.data1 ?? 0),
                  backgroundColor: colors.total,
                },
                {
                  label: 'Converted',
                  data: months.map((_, monthIndex) => data.find(v => v.month === (monthIndex + 1))?.data2 ?? 0),
                  backgroundColor: colors.converted,
                },
              ]
            }} plugins={[ChartDataLabels]} />
          )
        }}
      </Await>
    </React.Suspense>
  )
}
EnquiriesConverion.propTypes = {
  dataPromise: PropTypes.shape({
    then: PropTypes.func.isRequired,
    catch: PropTypes.func.isRequired
  }).isRequired,
}

function Dashboard({ title }) {
  const { insightToday, insights, turnoverComparison, salesLeaderboard, salesOverYears, projectsComparison, enquiriesCount, enquiriesConversion } = useLoaderData()
  const [searchParams, setSearchParams] = useSearchParams()
  const dateRange = searchParams.get('daterange')?.split(',').map(v => v ? moment(v, formats.backendDateFormat) : null)
  const [year1, year2] = [
    searchParams.has('year1') ? moment(searchParams.get('year1'), 'YYYY') : moment(),
    searchParams.has('year2') ? moment(searchParams.get('year2'), 'YYYY') : moment().subtract(1, 'year'),
  ]
  const enquiriesYear = searchParams.has('enquiriesyear') ? moment(searchParams.get('enquiriesyear'), 'YYYY') : moment()

  return (
    <Page className='dashboard' title={title} backLink={false}>
      <Box type={Box.BoxTypes.BLUE} containerStyles={{ border: '#97B9EF 1px solid' }}>
        <h3>Insight for today</h3>
        <TodayInsight dataPromise={insightToday} />
      </Box>
      <Box header={
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <h3>Insights</h3>
          <DatePicker.RangePicker
            value={dateRange}
            ranges={ranges}
            disabledDate={(current) => current.isAfter(moment())}
            format={dateFormat}
            onChange={range => {
              const params = new URLSearchParams(searchParams)
              if (!range) {
                params.delete('daterange');
                setSearchParams(params);
                return
              }
              params.set('daterange', range.map(d => d.format(formats.backendDateFormat)).join(','))
              console.log(range, params)
              setSearchParams(params)
            }}
          />
        </div>
      }>
        <Insights dataPromise={insights} />
      </Box>
      <Box header={
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <h3>Turnover vs Target</h3>
          <div>
            <DatePicker
              picker="year"
              value={year1}
              onChange={(_, v) => {
                const params = new URLSearchParams(searchParams)
                if (!v) {
                  params.delete('year1')
                  setSearchParams(params)
                  return;
                }
                params.set('year1', v)
                setSearchParams(params)
              }}
            />
            <DatePicker
              picker="year"
              value={year2}
              onChange={(_, v) => {
                const params = new URLSearchParams(searchParams)
                if (!v) {
                  params.delete('year2')
                  params.delete('year1')
                  setSearchParams(params)
                  return;
                }
                params.set('year2', v)
                setSearchParams(params)
              }}
            />
          </div>
        </div>
      }>
        <TurnOverComparison dataPromise={turnoverComparison} year1={year1.format('YYYY')} year2={year2.format('YYYY')} />
      </Box>
      <Box header={
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <h3>Sales Leaderboard</h3>
          <DatePicker.RangePicker
            value={dateRange}
            ranges={ranges}
            disabledDate={(current) => current.isAfter(moment())}
            format={dateFormat}
            onChange={range => {
              const params = new URLSearchParams(searchParams)
              if (!range) {
                params.delete('daterange')
                setSearchParams(params)
                return
              }
              params.set('daterange', range.map(d => d.format(formats.backendDateFormat)).join(','))
              setSearchParams(params)
            }}
          />
        </div>
      }>
        <React.Suspense fallback={<p>Loading Leaderboard...</p>}>
          <Await resolve={salesLeaderboard}>
            {(salesLeaderboard) => (
              <Leaderboard data={salesLeaderboard} />
            )}
          </Await>
        </React.Suspense>
      </Box>
      <Box header={
        <h3>Sales over the Years</h3>
      }>
        <SalesOverYears dataPromise={salesOverYears} />
      </Box>
      <Box header={
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <h3>Projects Compared With Previous Year</h3>
          <div>
            <DatePicker
              picker="year"
              value={year1}
              onChange={(_, v) => {
                const params = new URLSearchParams(searchParams)
                if (!v) {
                  params.delete('year1')
                  setSearchParams(params)
                  return;
                }
                params.set('year1', v)
                setSearchParams(params)
              }}
            />
            <DatePicker
              picker="year"
              value={year2}
              onChange={(_, v) => {
                const params = new URLSearchParams(searchParams)
                if (!v) {
                  params.delete('year2')
                  setSearchParams(params)
                  return;
                }
                params.set('year2', v)
                setSearchParams(params)
              }}
            />
          </div>
        </div>
      }>
        <ProjectsComparison dataPromise={projectsComparison} year1={year1.format('YYYY')} year2={year2.format('YYYY')} />
      </Box>
      <Box header={
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <h3>Enquiries</h3>
          <div>
            <DatePicker
              picker="year"
              value={enquiriesYear}
              onChange={(_, v) => {
                const params = new URLSearchParams(searchParams)
                if (!v) {
                  params.delete('enquiriesyear')
                  setSearchParams(params)
                  return;
                }
                params.set('enquiriesyear', v)
                setSearchParams(params)
              }}
            />
          </div>
        </div>
      }>
        <EnqiriesCount dataPromise={enquiriesCount} />
      </Box>
      <Box header={
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <h3>Enquiry Conversion</h3>
          <div>
            <DatePicker
              picker="year"
              value={enquiriesYear}
              onChange={(_, v) => {
                const params = new URLSearchParams(searchParams)
                if (!v) {
                  params.delete('enquiriesyear')
                  setSearchParams(params)
                  return;
                }
                params.set('enquiriesyear', v)
                setSearchParams(params)
              }}
            />
          </div>
        </div>
      }>
        <EnquiriesConverion dataPromise={enquiriesConversion} />
      </Box>
      <Space>
      </Space>
    </Page>
  )
}
Dashboard.Loader = async ({ request }) => {
  const searchParams = new URL(request.url).searchParams
  let hasInvalidParams = false
  // const normalizedParams = new URLSearchParams(searchParams)
  const dateRange = searchParams.get('daterange')?.split(',').map(v => v && moment(v, formats.backendDateFormat, true).isValid() ? v : null)
  const [year1, year2] = [
    searchParams.has('year1') && moment(searchParams.get('year1'), 'YYYY', true).isValid() ? searchParams.get('year1') : null,
    searchParams.has('year2') && moment(searchParams.get('year2'), 'YYYY', true).isValid() ? searchParams.get('year2') : null,
  ]
  const enquiriesYear = searchParams.has('enquiriesyear') && moment(searchParams.get('enquiriesyear'), 'YYYY', true).isValid() ? searchParams.get('enquiriesyear') : null

  if (searchParams.has('daterange') && dateRange.filter(v => v !== null).length < 2) {
    searchParams.delete('daterange')
    hasInvalidParams = true
  }

  if (searchParams.has('year1') && !year1) {
    searchParams.delete('year1')
    hasInvalidParams = true
  }

  if (searchParams.has('year2') && !year2) {
    searchParams.delete('year2')
    hasInvalidParams = true
  }

  if (searchParams.has('enquiriesyear') && !enquiriesYear) {
    searchParams.delete('enquiriesyear')
    hasInvalidParams = true
  }

  if (hasInvalidParams) {
    const newUrl = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`
    // Redirect to the new URL
    window.location.href = newUrl
  }

  // Ensure dateRange has valid values and only pass valid dates
  const dateRangeStart = dateRange?.[0] && moment(dateRange[0], formats.backendDateFormat, true).isValid() ? dateRange[0] : null;
  const dateRangeEnd = dateRange?.[1] && moment(dateRange[1], formats.backendDateFormat, true).isValid() ? dateRange[1] : null;

  return defer({
    insightToday: getInsightToday(),
    insights: getInsights(dateRangeStart),
    turnoverComparison: getTurnoverComparison(year1, year2),
    salesLeaderboard: getSalesLeaderboard(dateRangeStart, dateRangeEnd),
    salesOverYears: getSalesOverYears(),
    projectsComparison: getProjectsComparison(year2, year2),
    enquiriesCount: getMonthlyEnquiries(enquiriesYear),
    enquiriesConversion: getEnquiriesConversion(enquiriesYear),
  })
}

Dashboard.propTypes = { ...Page.propTypes }

export default Dashboard

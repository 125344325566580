import { Alert, Button, message } from 'antd';
import React from "react";
import { Link, useFetcher, useOutletContext, useRouteLoaderData } from 'react-router-dom';
import { templateRequest } from '../../../api/order';
import { generateRouteUrl } from '../../../library/constants';
import style from './waiting-for-files.module.scss';

const WaitingforFile = () => {
    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')
    const fetcher = useFetcher()
    const { vendorRole } = useOutletContext()

    return (
        <div className={style.container}>
            <h3>Task for you</h3>

            {(orderDetails.request_for_address !== 2) && <Alert message="Missing delivery address" description="Reminder sent to customer to share the delivery address" type="info" showIcon />}
            {orderDetails.request_for_template === 1 && (
                <Alert message="Customer has requested for product template. Please share it" type="info"
                    action={<Link to={generateRouteUrl('UploadSampleTemplate', {
                        itemId: orderDetails.id,
                    })}>
                        <Button className={style.greenButton}>Send Template to Customer</Button>
                    </Link>}
                />
            )}
            <Alert message="Make artwork for customer" type="info"
                action={<Link to={generateRouteUrl('MakeCustomerArtwork', {
                    itemId: orderDetails.id,
                })}>
                    <Button className={style.greenButton}>Make Artwork</Button>
                </Link>}
            />
            <h3>Task to do with {vendorRole}</h3>
            <Alert
                message={
                    orderDetails.request_for_template == 1 ?
                        "Customer has requested for product template please , Request sample template from vendor and send it" :
                        "Request sample template from vendor"}
                type="info"
                action={
                    <Button className={style.greenButton} onClick={() => {
                        fetcher.submit({
                            action: 'resuestTemplateFromVendor'
                        }, {
                            method: 'post'
                        })
                    }} loading={fetcher.state !== 'idle'}>Request Template</Button>
                }
            />
        </div>
    )
}
WaitingforFile.Actions = {
    resuestTemplateFromVendor: async ({ params }) => {
        const { itemId } = params
        try {
            const { message: msg } = await templateRequest(itemId, [])
            message.success(msg);
            return true
        }
        catch (error) {
            message.error(error.message)
        }
        return false
    },
}
export default WaitingforFile;
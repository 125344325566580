import { Alert, Button } from 'antd';
import React from "react";
import { Link, useRouteLoaderData } from 'react-router-dom';
import { generateRouteUrl } from '../../../library/constants';
import style from './artwork-received.module.scss';


const ArtWorkReceived = () => {
    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')

    return (
        <div className={style.container}>
            <h3>Task for you</h3>
            <Alert message="The customer has shared the artwork. Please upload the file and send to vendor for comfirmation" type="info"
                action={
                    <Link to={generateRouteUrl('CustomerArtworkToVendor', {
                        itemId: orderDetails.id,
                    })}>
                        <Button className={style.greenButton}>View Artwork</Button>
                    </Link>

                }
            />
            <Alert message="Make artwork for customer" type="info"
                action={<Link to={generateRouteUrl('MakeCustomerArtwork', {
                    itemId: orderDetails.id,
                })}>
                    <Button className={style.greenButton}>Make Artwork</Button>
                </Link>}
            />
        </div>
    )

}
export default ArtWorkReceived;
import {
    FilePdfOutlined
} from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { getFilePath } from '../../../library/constants';

const QuotationsDownload = ({ quotations, projectID }) => {
    if(!quotations || quotations.length === 0){
        return <></>
    }
    if (quotations.length > 1) {
        return (
            <Dropdown.Button
                type="primary"
                menu={{
                    items: quotations.map(({ id, pdf }) => ({
                        label: <a target="_blank" rel="noopener noreferrer" href={getFilePath(pdf.approved_quotation)} download>Quotation {id}</a>,
                        key: id,
                    }))
                }}
            >Order Confirmation PDF</Dropdown.Button>
        )
    }
    return (
        <Link target='blank' to={getFilePath(quotations[0].pdf.approved_quotation, projectID)} reloadDocument>
            <Button
                icon={<FilePdfOutlined />}
                type="primary"
            >Order Confirmation PDF</Button>
        </Link>
    )
}

QuotationsDownload.propTypes = {
    quotations: PropTypes.arrayOf(PropTypes.object).isRequired,
    projectID: PropTypes.number
}

export default QuotationsDownload
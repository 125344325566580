import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import { Alert, Button, Radio, Rate, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef, useState } from "react";
import { Link, useFetcher } from 'react-router-dom';
import { VendorItemPriceStatuses } from '../../../library/constants/VendorItemPriceStatuses.js';
import { ERP_CURRENCY } from '../../../library/constants/dynamic.js';
import { generateRouteUrl } from '../../../library/constants/routes.js';
import { formatDate, formatPrice } from '../../../library/utilities/intl.js';
import { Box } from '../../design/box.js';
import SmallSection from '../../design/small-section.jsx';
import Confirm from '../../message/Confirm.jsx';
import styles from './select-vendors.module.scss';

const VendorSelectionRow = ({ itemId, isSelected = false, vendor, price = {}, onChange }) => {
    const ribbon = (vendor.status === VendorItemPriceStatuses.SELECTED_FOR_PRODUCTION ? 'Selected for Production' :
        vendor.status === VendorItemPriceStatuses.USED_FOR_QUOTATION ? 'Selected for Quotation' :
            null)

    return (
        <div className={isSelected ? styles.vendorSelected : styles.vendor} onClick={() => onChange(vendor.id, !isSelected)}>
            <SmallSection colorLight="#fff2e9" colorDark="#fe7b1c" ribbon={ribbon}>
                <div className={styles.vendorDetails}>
                    <div>
                        <Radio name={`selectedVendor[${itemId}]`}
                            className={styles.radioButtons}
                            value={vendor.id}
                            checked={isSelected}
                            onChange={e => {
                                e.stopPropagation()
                                onChange(vendor.id, e.target.checked)
                            }}
                        />
                    </div>
                    <div>{vendor.company}</div>
                    <div>
                        <Rate value={vendor?.rating} disabled />
                    </div>
                    <div>{price.quantity}</div>
                    <div>{formatPrice(price.total_price, ERP_CURRENCY)}</div>
                </div>
            </SmallSection>
        </div>
    )
}
VendorSelectionRow.propTypes = {
    itemId: PropTypes.number.isRequired,
    vendor: PropTypes.object.isRequired,
    price: PropTypes.object.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
}

const ProjectItem = ({ item }) => {
    const [selectedVendor, setSelectedVendor] = useState(item.vendor_selected_id)
    return (
        <Box type={Box.BoxTypes.GRAY}>
            <div className="project-item-overview">
                <div className="project-item-header">
                    <h3>
                        <span className="project-item-index">
                            {item.id}
                        </span>
                        <span className="project-item-name">
                            {item.product.name}
                        </span>
                    </h3>
                    <div className="project-item-actions">
                        <Tooltip title="Requested Deadline Date By Client"><Button type='actionpanel' shape='square' ghost>{formatDate(item.client_expected_delivery_date)}</Button></Tooltip>
                        <Link to={generateRouteUrl('ProjectItemUpdate', { projectId: item.project_id, itemId: item.id })}><Button icon={<EditOutlined />} shape="square" type="primary" ghost /></Link>
                    </div>
                </div>
                <div className={styles.vendors}>
                    <div className={styles.vendorsHeader}>
                        <div></div>
                        <div>Vendor</div>
                        <div>Rating</div>
                        <div>Quantity</div>
                        <div>Total Price</div>
                    </div>
                    {item.selected_vendors.map(vendor => {
                        const selectedPrice = item.vendors_prices[vendor.id]?.find((a => a.quantity === item.approved_quantity))
                        if (!selectedPrice) {
                            return
                        }
                        return <VendorSelectionRow key={vendor.id} itemId={item.id} vendor={vendor} price={selectedPrice} isSelected={(item.selected_vendors.length === 1 || (!!selectedVendor && vendor.id === selectedVendor))} onChange={(_vendorId, checked) => {
                            console.log({ _vendorId, checked })
                            checked ? setSelectedVendor(_vendorId) : setSelectedVendor(null)
                        }} />
                    })}
                </div>
            </div>
        </Box>
    )
}
ProjectItem.propTypes = {
    item: PropTypes.object.isRequired,
}

const SelectVendorforProduction = ({ items, orderManagerId }) => {
    const fetcher = useFetcher()
    const _formRef = useRef()
    const vendorAlreadySelected = !(items.some(item => !item.vendor_selected_id)) //Check if vendors are selected for all items

    return <div style={{ display: 'flex', flexDirection: 'column' }}>
        {!orderManagerId && <Alert message="Select an Order Manager before selecting vendor" type="warning" showIcon />}
        <br />
        <fetcher.Form method="post" ref={_formRef}>
            {items.map((item) => <ProjectItem key={item.id} item={item} />)}
            <input type="hidden" name="orderManagerId" value={orderManagerId || ''} />
            <input type="hidden" name="action" value="selectProductionVendor" />
            <div className="ant-collapse-footer">
                <Confirm
                    onConfirm={() => {
                        fetcher.submit(_formRef.current, {
                            method: 'post'
                        })
                    }}
                    title={'Project confirmation'}
                    content='You have selected the vendor for this project. Continue to notify the Order manager'
                    okText="Continue"
                    icon={<CheckOutlined />}
                    type="primary"
                    disabled={!orderManagerId}
                    loading={fetcher.state === 'submitting'}
                    ghost
                >
                    {vendorAlreadySelected ? 'Reselect vendor and notify order manager' : 'Select Vendors and Notify Order Manager'}
                </Confirm>
            </div>
        </fetcher.Form>
    </div >
}
SelectVendorforProduction.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    orderManagerId: PropTypes.number
}
export default SelectVendorforProduction
import { fetchSmallWebsiteLogo } from "../../../components/design";
import { formatDate, formatPrice } from "../../utilities/intl";
import { ERP_CURRENCY } from "../dynamic.js";

export const EulerCaseColumn = [
    {
        title: 'Case Number',
        dataIndex: 'case_number',
        sorter: true,
        fixed: 'left',
    },
    {
        title: 'Relation',
        dataIndex: 'customer',
        render: (company) => {
            return fetchSmallWebsiteLogo(company?.website, company.company)
        }
        //sorter: true,
    },
    {
        title: 'Total Price Inc. VAT',
        dataIndex: 'euler_inc_amount',
        //sorter: true,
        render: val => formatPrice(val, ERP_CURRENCY),

    },
    {
        title: 'Total Euler Invoice Inc. VAT',
        dataIndex: 'inc_amount',
        render: val => formatPrice(val, ERP_CURRENCY),
        //sorter: true,
    },
    {
        title: 'Create Date',
        dataIndex: 'createdAt',
        render: createdAt => formatDate(createdAt)
        //sorter: true,
    },
]

import { Result } from "antd";
import React from "react";
import styles from "./canceled.module.scss";
const CancelRequest = () => {
    return <div className={styles.container}>
        <Result
            title="Order cancellation request sent"
            subTitle="Order cancellation request is sent to the Account manager."
            type="error"
        />
    </div>
}
export default CancelRequest;
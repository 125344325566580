// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = 'finance/sepa-payment/';
const ENDPOINT_SINGLE = '/finance/sepa-payment/:id';
const ENDPOINT_SINGLE_ACTION = '/finance/sepa-payment/:id/:action';


export const getSepaPaymentFiles = (payload) => {
    return axiosInstance.get(ENDPOINT_GENERAL, { params: payload })
}

export const getSepaPayment = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE, {
        id
    })).then(({ data }) => data)
}

export const updateSepaPayment = (id, notes) => {
    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE, {
        id
    }), {
        notes
    }).then(({ message }) => message)
}

export const paySepaPayment = (id) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id,
        action: 'paid'
    })).then(({ message }) => message)
}

export const deleteSepayPayment = (id) => {
    return axiosInstance.delete(generatePath(ENDPOINT_SINGLE, {
        id,
    })).then(({ message }) => message)
}
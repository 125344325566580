import { useEffect, useMemo, useRef, useState } from "react";
import { useFetcher, useSearchParams } from "react-router-dom";
import { objectToSearchParams, removeEmpty, searchParamsToObject } from '../library/helpers';

function useFetch({ initialData }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const fetcher = useFetcher()
  const [hasMore, setHasMore] = useState(true)
  const searchParamsParsed = searchParamsToObject(searchParams)
  const [list, setList] = useState(initialData?.data || [])
  const currentPage = useRef(1)

  useEffect(() => {
    setHasMore(initialData.meta.totalPages > parseInt(initialData.meta.page))
    currentPage.current = 1
  }, [searchParams])

  useEffect(() => {
    setList(initialData.data)
    setHasMore(initialData.meta.totalPages > parseInt(initialData.meta.page))
    currentPage.current = 1
  }, [initialData])

  useEffect(() => {
    if (!fetcher.data || fetcher.state === "loading") {
      return;
    }

    updateList(fetcher.data.data)

    /**
     * todo: Pagination not working once hasMore is false and then filter/sort is applied
     */

    currentPage.current = fetcher?.data?.meta?.page ?? 1
    setHasMore(fetcher.data?.meta && (fetcher.data.meta.totalPages > parseInt(fetcher.data.meta.page)))
  }, [fetcher.data])

  const setFilters = (f) => {
    // setSearchParams(params => objectToSearchParams({
    //   ...searchParamsToObject(params),
    //   filter: removeEmpty(f),
    // }))
    let _f = f
    if (typeof f === 'function') {
      _f = f(searchParamsParsed.filter)
    }
    setSearchParams(objectToSearchParams({
      ...searchParamsParsed,
      filter: removeEmpty(_f),
    }))
  }

  const setSort = (s) => {
    setSearchParams(objectToSearchParams({
      ...searchParamsParsed,
      sort: removeEmpty(s),
    }))
  }

  const loadMore = () => {
    if (fetcher.state === 'loading') return;
    //const page = fetcher?.data?.meta?.page ? parseInt(fetcher.data.meta.page) + 1 : parseInt(initialData.meta?.page) + 1
    const params = new URLSearchParams(objectToSearchParams(searchParamsParsed))
    currentPage.current++;
    params.set('page', currentPage.current)
    params.set('index', '')
    fetcher.load('.?' + params.toString())
  }


  const isLoading = useMemo(() => {
    fetcher.state === 'loading'
  }, [fetcher.state])

  const updateList = (items) => {
    setList((prev) => [...prev, ...items])
  }

  return [list, hasMore, isLoading, searchParamsParsed, {
    setSort,
    setFilters,
    loadMore
  }];
}

export default useFetch;

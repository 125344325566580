export const FinanceProjectStatuses = Object.freeze({
    WAITING_FOR_INVOICE: 1,
    WAITING_FOR_PURCHASE_BILL: 2,
    CLOSED_WON: 3,
})

export const FinanceProjectStatusDetails = {
    [FinanceProjectStatuses.WAITING_FOR_INVOICE]: "Waiting for Invoice",
    [FinanceProjectStatuses.WAITING_FOR_PURCHASE_BILL]: "Waiting for Purchase Bill",
    [FinanceProjectStatuses.CLOSED_WON]: "Closed",
}
import { Alert, Button, message } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useFetcher, useRouteLoaderData } from 'react-router-dom';
import { sendSupplierOrderShippingDetails } from '../../../api/order/supplier-finisher-flow.js';
import { Box } from '../../../components/design/box.js';
import { DatePicker, FieldWrapper, InputWrapper } from "../../../components/inputs";
import { ValidationError } from '../../../library/classes/exceptions/index.js';
import { generateRouteUrl } from '../../../library/constants/routes.js';
import { formatDate } from '../../../library/utilities/intl.js';
import styles from "./supplier-dispatch-date-confirm.module.scss";

const getFormattedAddress = (address) => <>{address.street_number},{address.street},{address.city}</>

const ShipmentDeliveryDate = ({ itemId, delivery }) => {
    const fetcher = useFetcher()
    const { errors = {} } = fetcher.data || {}

    return (
        <fetcher.Form method="post" action={generateRouteUrl('OrderManagerDetailsPage', { itemId })}>
            <div className={styles.deliveryRow}>
                <div className="quantity">{delivery.quantity}</div>
                <div className="address">{getFormattedAddress(delivery)}</div>
                <div className="field">
                    {formatDate(delivery.expected_dispatch_date)}
                </div>
                <div className="field-quantity">
                    <InputWrapper type='Number' min={0} name="ship_quantity" defaultValue={delivery.ship_quantity} errors={errors} />
                </div>
                <div className="field-trackingurl">
                    <InputWrapper name="tracking_url" defaultValue={delivery.tracking_url} errors={errors} />
                </div>
                <div className="field">
                    <FieldWrapper name="" errors={errors} >
                        <DatePicker name="expected_delivery_date" useHidden value={delivery.expected_delivery_date} />
                    </FieldWrapper>
                </div>
                <Button
                    type="primary"
                    htmlType='submit'
                    name="action"
                    value='supplierUpdateDeliveryInformation'
                    loading={fetcher.state !== 'idle'}
                >Submit</Button>
            </div>
        </fetcher.Form>
    )
}
ShipmentDeliveryDate.propTypes = {
    itemId: PropTypes.number.isRequired,
    delivery: PropTypes.object.isRequired,
}

export const SupplierDispatchDateConfirm = () => {
    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')

    return <div className={styles.container}>
        <h3>Task For You</h3>
        <Alert description="Vendor has shared the expected dispatch date" type="info" showIcon />
        <Box header={
            <div className={styles.deliveryRowHead}>
                <div>Quantity</div>
                <div>Delivery Address</div>
                <div>Expected Dispatch Date</div>
                <div>Ship Quantity</div>
                <div>Tracking URL</div>
                <div>Expected Delivery Date</div>
                <div></div>
            </div>
        }>
            <ShipmentDeliveryDate
                itemId={orderDetails.id}
                delivery={orderDetails.item_finisher_address ?? {}}
            />
        </Box>
    </div>
}

/**
 * !!IMPORTANT!!
 * These actions will not work automatically
 * These needs to be adeed to orderDetails Actions 
 */
export const SupplierDispatchDateConfirmActions = {
    supplierUpdateDeliveryInformation: async ({ params, data }) => {
        const { itemId } = params
        try {
            const ship_quantity = parseInt(data.ship_quantity)
            if (!ship_quantity) {
                throw new ValidationError('Quantity is required', [
                    {
                        path: 'ship_quantity',
                        message: 'Quantity is required'
                    }
                ])
            }

            const msg = await sendSupplierOrderShippingDetails(itemId, ship_quantity, data.expected_delivery_date, data.tracking_url)
            message.success(msg);
            return true
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
        }
        return false
    }
}
import { GlobalOutlined, MailOutlined, MobileOutlined } from '@ant-design/icons';
import React from 'react';
import { fetchSmallWebsiteLogo } from '../../../components/design';
import { Server } from '../../constants/server';
import { formatDateTime, formatPrice } from '../../utilities/intl.js';
import { getProjectStatusByID } from '../project-statuses.js';
// import styles from '../../../styles/page.scss'
const source = {
    display: 'flex',
    flexFlow: 'row',
    gap: '17px',
    width: '60px',
    justifyContent: 'flex-end'
}

function getSourceIcon(status_id = 'eamil', projectID) {
    if (status_id == 'email') {
        return <div style={source}>
            <h4>{projectID}</h4>
            <MailOutlined style={{ fontSize: '20px', color: '#EAB935' }} />
        </div>

    }
    if (status_id == 'phone') {
        return <div style={source}>
            <h4>{projectID}</h4>
            <MobileOutlined style={{ fontSize: '20px', color: '#4178D1' }} />
        </div>
    }
    else {
        return <div style={source}>
            <h4>{projectID}</h4>
            <GlobalOutlined style={{ fontSize: '20px', color: '#6E589D' }} />
        </div>

    }
}


export const ProjectColumns = [
    {
        title: 'Project Id',
        dataIndex: 'id',
        sorter: true,
        fixed: 'left',
        render: (id, data) => getSourceIcon(data.source_id, id)
    },
    {
        title: 'Company',
        dataIndex: 'customer',
        render: (company) => {
            return fetchSmallWebsiteLogo(company.website, company.company)
        }
        //sorter: true,
    },
    {
        title: 'Account Manager',
        dataIndex: 'user',
        render: user => user?.name
        //sorter: true,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        //sorter: true,
        render: status => getProjectStatusByID(status)?.name,

    },
    {
        title: 'Amount',
        dataIndex: 'total_price',
        render: price => formatPrice(price, 'EUR')
        //sorter: true,
    },
    {
        title: 'Country',
        dataIndex: ['customer', 'country'],
        ///sorter: true,
        render: (country) => {
            return <img src={`${Server.BaseUrl}/${country.flag_url}`} alt={country.name} title={country.name} />
        }
    },
    {
        title: 'Language',
        dataIndex: ['language', 'name'],
    },
    {
        title: 'Order Manager',
        dataIndex: ['order_manager', 'name'],
    },
    {
        title: 'Contact',
        dataIndex: ['contact', 'email'],
    },
    {
        title: 'Created At',
        dataIndex: 'createdAt',
        render: formatDateTime,
    }
    // {
    //     title: 'Language',
    //     dataIndex: 'language',
    //     //sorter: true,
    //     render: language => language.name
    // },
    // {
    //     title: 'Order Manager',
    //     dataIndex: 'order_manager',
    //     //sorter: true,
    //     render: user => user?.name
    // },
    // {
    //     title: 'Contact',
    //     dataIndex: 'contact',
    //     render: contact => contact?.email
    //     //sorter: true,
    // },

]
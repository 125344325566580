import { Layout } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useNavigation, useParams } from 'react-router-dom';
import { Header, SecondaryHeader } from "../header";
import Styles from './page.module.scss';
const { Content } = Layout;

function Page({ title, className, children, summary, header, subHeader, backLink, footer, loading = false }) {
  const navigation = useNavigation()
  const urlParams = useParams()
  //Generate dynamic title
  const parsedTitle = title.interpolate(urlParams)

  return (
    <>
      <div className={Styles.header}>
        <Header title={parsedTitle} backLink={backLink} summary={summary} />
        <div className={Styles.secondaryHeaderWrapper}>
          {!!header && <SecondaryHeader content={header} backLink={false} />}
          {!!subHeader && (<div className={Styles.subHeaderWrapper}>{subHeader}</div>)}
        </div>
      </div>
      <Content className={`page ${className}`} style={{ opacity: loading || navigation.state === 'loading' ? 0.6 : 1 }}>
        <div className={Styles.innerWrapper}>
          <div className={Styles.contentWrapper}>
            {children}
          </div>
          {footer && (
            <div className={Styles.footer} style={{ display: 'flex', justifyContent: 'space-between' }}>
              {footer}
            </div>
          )}
        </div>
      </Content>
    </>
  );
}


Page.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  summary: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  header: PropTypes.node,
  subHeader: PropTypes.node,
  footer: PropTypes.node,
  loading: PropTypes.bool,
  backLink: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default Page;

export const studioStatus = Object.freeze({
    SEND_TEMPLATE: 1,
    TEMPLATE_SENT: 2,
    SEND_ARTWORK: 3,
    ARTWORK_SENT: 4,
    SEND_PROOF: 5,
    PROOF_SENT: 6,
    SEND_MAKE_ARTWORK: 7,
    MAKE_ARTWORK_SENT: 8

})

export const InvoiceKanabanColumns = [
    { key: 1, title: 'New' },
    { key: 4, title: 'Invoice Sent' },
    { key: 8, title: '1st Reminder' },
    { key: 9, title: '2nd Reminder' },
    { key: 10, title: '3rd Reminder' },
    { key: 11, title: 'Final Reminder' },
    { key: 7, title: 'Paid' },
    { key: 6, title: 'Sent to Euler' },
    { key: 5, title: 'Closed Lost' },
    { key: 12, title: 'Cancelled' },
]
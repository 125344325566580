import { Button } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { parseFormData } from '../../library/utilities/formdata.js';
import Confirm from './Confirm.jsx';

const Prompt = ({ children, buttonText, onConfirm, additionalActions = {}, ...otherProps }) => {
    const formRef = useRef(null)

    return (
        <>
            <Confirm
                okText='Continue'
                cancelText='Cancel'
                {...otherProps}
                content={(
                    <form ref={formRef}>
                        {children}
                        {!!additionalActions && Object.entries(additionalActions).map(([key, action]) => (
                            <Button key={key} onClick={action.onClick ? () => {
                                const formData = new FormData(formRef.current)
                                action.onClick(parseFormData(formData))
                            } : undefined}>{action.text}</Button>
                        ))}
                    </form>
                )}
                onConfirm={(close) => {
                    const formData = new FormData(formRef.current)
                    const response = onConfirm(parseFormData(formData))
                    //Prevent close only if onConfirm return false
                    if (response === false) {
                        return response
                    }
                    close()
                }}
            >
                {buttonText}
            </Confirm>
        </>
    )
}

Prompt.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    children: PropTypes.node,
    buttonText: PropTypes.node,
    additionalActions: PropTypes.object,
}

export { Prompt };

import { Table as AntdTable } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';

const sortOrders = {
    ascend: 'ASC',
    descend: 'DESC'
}

const PaginationTable = ({ columns, data = [], currentPage = 1, pageSize = 20, total, isLoading, onChange, onPageChange, onRowClick, ...otherProps }) => {
    const tableRef = useRef()
    console.log({ pageSize, total })
    const paginationConfig = useMemo(() => {
        if (pageSize >= total || !total) {
            return false
        }
        return {
            current: parseInt(currentPage),
            pageSize,
            total,
            // showTotal: true,
            showSizeChanger: false,
            hideOnSinglePage: true,
        }
    }, [currentPage, pageSize, total])

    return (
        <AntdTable
            columns={columns}
            dataSource={data}
            ref={tableRef}
            pagination={paginationConfig}
            onChange={(...[pagination, , { field, order }, { action }]) => {
                if (action === 'paginate') {
                    console.log({ pagination })
                    onPageChange(pagination.current)
                }
                else if (action === 'sort' && typeof onChange === 'function') {
                    onChange({
                        sortBy: Array.isArray(field) ? field.join('.') : field,
                        sortType: sortOrders[order] ?? undefined,
                    })
                }
            }}
            // style={{ cursor: 'pointer' }}
            loading={isLoading}
            onRow={(record, rowIndex) => ({
                onClick: event => {
                    if (event.target.querySelector('input[type="checkbox"]')) {
                        return
                    }
                    event.preventDefault()
                    onRowClick(rowIndex, record)
                }
            })}
            scroll={{ x: 'max-content' }}
            {...otherProps}
        />
    )
}

PaginationTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.arrayOf(PropTypes.object),
    currentPage: PropTypes.number,
    pageSize: PropTypes.number,
    total: PropTypes.number.isRequired,
    isLoading: PropTypes.bool,
    onChange: PropTypes.func,
    onRowClick: PropTypes.func,
    onPageChange: PropTypes.func.isRequired
}

export default PaginationTable
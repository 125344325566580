import { Button, Collapse, Table, message } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Await, Form, Link, defer, redirect, useFetcher, useLoaderData } from 'react-router-dom';
import { getPurchaseBills } from '../../api/finance';
import { deleteSepayPayment, getSepaPayment, paySepaPayment, updateSepaPayment } from '../../api/finance/sepa-payment.js';
import { InputWrapper } from '../../components/inputs/input-wrappers.jsx';
import Confirm from '../../components/message/Confirm.jsx';
import { Page } from '../../components/page';
import { ERP_CURRENCY } from '../../library/constants/dynamic.js';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { Server } from '../../library/constants/server.js';
import { getPurchaseBillColumns } from '../../library/constants/tableColumns/SepaPaymentColumns.js';
import { formatDate, formatPrice } from '../../library/utilities/intl.js';
import Styles from './sepapayment-details.module.scss';

const { Panel } = Collapse

// const DetailsEdit = () => {
//     // const { sepaPaymentDetails } = useLoaderData()
//     // const fetcher = useFetcher()

//     // const { errors = null } = fetcher.data || {}

//     return (
//         // <fetcher.Form method="post" className={Styles.fieldsWrapper}>
//         //     <input type="hidden" name="action" value="twinfieldUpdate" />
//         //     <InputWrapper defaultValue={sepaPaymentDetails.twinfield_id} name="twinfield_id" label="Twinfield Number" errors={errors} horizontal />
//         //     <FieldWrapper name="send_to_twinfield" label="Send To Twinfield" errors={errors} horizontal>
//         //         <input type="hidden" name="send_to_twinfield" value={0} />
//         //         <Checkbox name="send_to_twinfield" defaultChecked={sepaPaymentDetails.send_to_twinfield} value={1} />
//         //     </FieldWrapper>
//         //     <GeneralFieldWrapper horizontal>
//         //         <Button type="primary" htmlType='submit' loading={fetcher.state === 'submitting'}>Save</Button>
//         //     </GeneralFieldWrapper>
//         // </fetcher.Form>
//         <></>
//     )
// }

const Details = () => {
    const { sepaPaymentDetails } = useLoaderData()

    return (
        <Collapse defaultActiveKey={['1']} expandIconPosition="end">
            <Panel header='XML File' key="1">
                <Form method="post" replace>
                    <div className={Styles.mainWrapper}>
                        <div className={Styles.fieldsWrapper}>
                            <div className={Styles.description}>
                                <div className={Styles.DescriptionTitle}>Content XML</div>
                                <div className={Styles.DescriptionValue}>
                                    <Link to={Server.getFileUrl(`document/sepa-payment/${sepaPaymentDetails.file}`)} download reloadDocument target="_blank">{sepaPaymentDetails.file}</Link>
                                </div>
                            </div>
                            <div className={Styles.description}>
                                <div className={Styles.DescriptionTitle}>Content Excel</div>
                                <div className={Styles.DescriptionValue}>--</div>
                            </div>
                        </div>
                        <div className={Styles.fieldsWrapper}>
                            <div className={Styles.description}>
                                <div className={Styles.DescriptionTitle}>Created At</div>
                                <div className={Styles.DescriptionValue}>{formatDate(sepaPaymentDetails.createdAt)}</div>
                            </div>
                            <div className={Styles.description}>
                                <div className={Styles.DescriptionTitle}>Updated At</div>
                                <div className={Styles.DescriptionValue}>{formatDate(sepaPaymentDetails.updatedAt)}</div>
                            </div>
                            <div className={Styles.description}>
                                <div className={Styles.DescriptionTitle}>Entity</div>
                                <div className={Styles.DescriptionValue}>{sepaPaymentDetails?.entity?.name}</div>
                            </div>
                        </div>
                        <div className={Styles.fieldsWrapper}>
                            <div className={Styles.description}>
                                <div className={Styles.DescriptionTitle}>Total Payment</div>
                                <div className={Styles.DescriptionValue}>{formatPrice(sepaPaymentDetails.amount, ERP_CURRENCY)}</div>
                            </div>
                            <div className={Styles.description}>
                                <div className={Styles.DescriptionTitle}>Transactions</div>
                                <div className={Styles.DescriptionValue}>{sepaPaymentDetails.transaction}</div>
                            </div>
                        </div>
                        <InputWrapper name="notes" label="Notes" defaultValue={sepaPaymentDetails.notes} textarea />
                    </div>
                    <div className="actions">
                        <Button
                            type='primary'
                            htmlType='submit'
                            name='action'
                            value="updateNotes"
                        >Update</Button>
                    </div>
                </Form>
            </Panel>
        </Collapse >
    )
}

const SepaPaymentDetails = ({ title }) => {
    const { sepaPaymentDetails, purchaseBills } = useLoaderData()
    const fetcher = useFetcher()

    return (
        <Page className='sepa-payment-create' title={title} >
            {!sepaPaymentDetails.paid && (
                <Collapse defaultActiveKey={['1']} expandIconPosition="end">
                    <Panel header='Action Center' key="1" >
                        <div className='actions align--left'>
                            <fetcher.Form method="post">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    name="action"
                                    value="markAsPaid"
                                    loading={fetch.state === 'submitting' && fetcher.action === 'markAsPaid'}
                                    ghost
                                >Mark as Paid</Button>
                            </fetcher.Form>
                            <Confirm
                                type="primary"
                                content="Are you sure ?"
                                onConfirm={() => {
                                    fetcher.submit({
                                        action: 'delete'
                                    }, {
                                        method: 'post'
                                    })
                                }}
                                danger
                            >Delete</Confirm>
                        </div>
                    </Panel>
                </Collapse>
            )}
            <Details />
            <Collapse defaultActiveKey={['1']} expandIconPosition="end">
                <Panel header='Purchase Bills' key="1" >
                    <React.Suspense>
                        <Await
                            resolve={purchaseBills}
                            errorElement={
                                <p>Error loading purchase bills!</p>
                            }
                        >
                            {(purchaseBills) => <Table
                                columns={getPurchaseBillColumns(ERP_CURRENCY)}
                                rowKey="id"
                                dataSource={purchaseBills}
                                pagination={false}
                            />}
                        </Await>
                    </React.Suspense>
                </Panel>
            </Collapse>
        </Page >
    )
}

SepaPaymentDetails.propTypes = {
    title: PropTypes.string
}

SepaPaymentDetails.Actions = {
    markAsPaid: async ({ params }) => {
        const { sepaPaymentId } = params
        try {
            const msg = await paySepaPayment(sepaPaymentId)
            message.success(msg)
            return true
        }
        catch (error) {
            message.error(error.message)
        }
        return false
    },
    updateNotes: async ({ params, data }) => {
        const { sepaPaymentId } = params
        const { notes } = data
        try {
            const msg = await updateSepaPayment(sepaPaymentId, notes)
            message.success(msg)
            return true
        }
        catch (error) {
            message.error(error.message)
        }
        return false
    },
    delete: async ({ params }) => {
        const { sepaPaymentId } = params
        try {
            const msg = await deleteSepayPayment(sepaPaymentId)
            message.success(msg)
            return redirect(generateRouteUrl('SepaPaymentFiles'))
        }
        catch (error) {
            message.error(error.message)
        }
        return false
    },
}

SepaPaymentDetails.Loader = async ({ params }) => {
    const { sepaPaymentId } = params
    const sepaPaymentDetails = await getSepaPayment(sepaPaymentId)

    if (!sepaPaymentDetails) {
        throw new Error('Invalid Sepa Payment File')
    }

    const purchaseBills = getPurchaseBills({
        filter: {
            sepa_payment_id: sepaPaymentDetails.id
        }
    }).then(({ data }) => data)

    return defer({
        sepaPaymentDetails,
        purchaseBills
    })
}

export default SepaPaymentDetails
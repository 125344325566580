import { Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './statcard.module.scss';

const Types = {
    BLUE: 'blueBox',
    GRAY: 'grayBox',
    GRAY2: 'grayBox2',
}

const StatCard = ({ icon, title, data, type = null }) => {
    const getClasses = () => {
        const classes = [styles.container]
        if (type) {
            classes.push(styles[type])
        }
        return classes.join(' ')
    }
    return (
        <div className={getClasses()}>
            {(!!title || !!icon) && (
                <div className={styles.header}>
                    <div className={styles.title}>{title}</div>
                    {!!icon && <div className={styles.icon}>{icon}</div>}
                </div>
            )}
            <div className={styles.content}>{data}</div>
        </div>
    )
}
StatCard.propTypes = {
    title: PropTypes.node.isRequired,
    data: PropTypes.node.isRequired,
    icon: PropTypes.node,
    type: PropTypes.oneOf(Object.values(Types)),
}
StatCard.Types = Types

const StatCardSkeleton = ({ icon, title, type = null }) => {
    const getClasses = () => {
        const classes = [styles.container]
        if (type) {
            classes.push(styles[type])
        }
        return classes.join(' ')
    }
    return (
        <div className={getClasses()}>
            {(!!title || !!icon) && (
                <div className={styles.header}>
                    <div className={styles.title}>{title}</div>
                    {!!icon && <div className={styles.icon}>{icon}</div>}
                </div>
            )}
            <div className={styles.content}>
                {/* <Skeleton title={true} paragraph={false} size="large" active /> */}
                <Skeleton.Button size="large" shape="round" active  />
            </div>
        </div>
    )
}
StatCardSkeleton.propTypes = {
    title: PropTypes.node.isRequired,
    icon: PropTypes.node,
    type: PropTypes.oneOf(Object.values(Types)),
}

export { StatCard, StatCardSkeleton };

import { Entities } from "../helpers/entity.js";
import { PurchaseBillStatuses } from "./purchase-bill-statuses.js";

const PurchaseBillFilters = [
    {
        label: 'All',
        key: '1',
        filters: {},
        children: Entities.map(entity => ({
            key: `1-${entity.id}`,
            label: entity.name,
            filters: { entity_id: entity.id }
        }))
    },
    {
        label: 'To Do',
        key: '2',
        filters: {},
        children: Entities.map(entity => ({
            key: `2-${entity.id}`,
            label: entity.name,
            filters: { entity_id: entity.id, status: PurchaseBillStatuses.NEW }
        }))
    },
    {
        label: 'Not Approved',
        key: '3',
        filters: {},
        children: Entities.map(entity => ({
            key: `3-${entity.id}`,
            label: entity.name,
            filters: { entity_id: entity.id, status: PurchaseBillStatuses.NEW }
        }))
    },
    {
        label: 'Approved',
        key: '4',
        filters: {},
        children: Entities.map(entity => ({
            key: `4-${entity.id}`,
            label: entity.name,
            filters: { entity_id: entity.id, status: PurchaseBillStatuses.APPROVED }
        }))
    },
    {
        label: 'On Hold',
        key: '5',
        filters: {},
        children: Entities.map(entity => ({
            key: `5-${entity.id}`,
            label: entity.name,
            filters: { entity_id: entity.id, status: PurchaseBillStatuses.ON_HOLD }
        }))
    },
    {
        label: 'Overdue',
        key: '6',
        filters: {},
        children: Entities.map(entity => ({
            key: `6-${entity.id}`,
            label: entity.name,
            filters: { entity_id: entity.id }
        }))
    },
    {
        label: 'To pass on',
        key: '7',
        filters: {},
        children: Entities.map(entity => ({
            key: `7-${entity.id}`,
            label: entity.name,
            filters: { entity_id: entity.id }
        }))
    },
];

PurchaseBillFilters.findFiltersByKey = keys => PurchaseBillFilters.flatMap(item => item.children ? [item, ...item.children] : item).filter(item => keys.includes(item.key)).reduce((acc, curr) => ({ ...acc, ...curr?.filters }), {})
export { PurchaseBillFilters };

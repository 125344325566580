// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_ROLE = 'customer/:customerId/param/:action/:addressId';
const ENDPOINT_PRIMARY = '/customer/address/:addressId/setprimary';


export const setCustomerPrimaryAddress = (addressId) => {
    return axiosInstance.post(generatePath(ENDPOINT_PRIMARY, {
        addressId
    }), {}).then(({ message }) => message)
}

export const setAddressDelivery = (customerId, addressId) => {
    return axiosInstance.post(generatePath(ENDPOINT_ROLE, {
        customerId,
        addressId,
        action: 'delivery_address'
    }), {}).then(({ message }) => message)
}

export const removeAddressDelivery = (customerId, addressId) => {
    return axiosInstance.delete(generatePath(ENDPOINT_ROLE, {
        customerId,
        addressId,
        action: 'delivery_address'
    }), {})
}

export const setAddressInvoice = (customerId, addressId) => {
    return axiosInstance.post(generatePath(ENDPOINT_ROLE, {
        customerId,
        addressId,
        action: 'billing_address'
    }), {}).then(({ message }) => message)
}

export const removeAddressInvoice = (customerId, addressId) => {
    return axiosInstance.delete(generatePath(ENDPOINT_ROLE, {
        customerId,
        addressId,
        action: 'billing_address'
    }), {})
}
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { searchUsers, searchUsersByRole } from '../../api/customer/account-manager.js';
import { UserRoles } from '../../library/constants/userRoles.js';

function OrderManagerSelect({ value = null, onChange, currentSelection = {} }) {
    const debounceTimeout = 800
    const [fetching, setFetching] = useState(false);
    const selectedOption = currentSelection?.value && currentSelection.value === value ? [{ value: currentSelection?.value, label: currentSelection?.label }] : []
    const [options, setOptions] = useState([selectedOption]);
    const fetchRef = useRef(0);

    useEffect(() => {
        debounceFetcher()
    }, [])

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            searchUsersByRole(value, [UserRoles.ORDER_MANAGER]).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                if (!selectedOption.some(({ value }) => value === currentSelection.value)) {
                    setOptions([...selectedOption, ...newOptions]);
                }
                else {
                    setOptions([...newOptions]);
                }
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [searchUsers, debounceTimeout]);

    return (
        <Select
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            options={options}
            onChange={onChange}
            value={value}
            placeholder="Select Order Manager"
            showSearch
            allowClear
        />
    );
}

OrderManagerSelect.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    currentSelection: PropTypes.shape({
        id: PropTypes.number.isRequired,
        company: PropTypes.string.isRequired
    })
}

export default OrderManagerSelect;
import {
    CheckOutlined,
    CloseOutlined
} from '@ant-design/icons';
import { Button, Modal, message } from 'antd';
import React, { useRef } from 'react';
import { redirect, useNavigate, useRouteLoaderData, useSubmit } from 'react-router-dom';
import { recordOfflinePayment } from '../../api/finance/invoice.js';
import { Currency } from '../../components/inputs/index.js';
import Styles from './manual-payment.module.scss';


const ManualPayment = () => {
    const { invoiceDetails } = useRouteLoaderData('InvoiceDetails')
    const navigate = useNavigate()
    const submit = useSubmit();
    const amount = useRef(null)
    const currency = invoiceDetails.currency.code

    return (
        <Modal
            title="Record Offline Payment"
            open={true}
            onCancel={() => navigate('../', { replace: true })}
            width='40%'
            forceRender
            destroyOnClose
            footer={<>
                <Button type="primary" icon={<CheckOutlined />} onClick={() => {
                    submit({
                        amount: amount.current
                    }, {
                        encType: 'application/json',
                        method: 'post',
                        replace: true
                    })
                }} >Save</Button>
                <Button type="cancel" icon={<CloseOutlined />} onClick={() => navigate('../', { replace: true })}>Cancel</Button>
            </>}>
            <div className={Styles.formWrapper}>
                <div className={Styles.mainWrapper}>
                    <div className={Styles.fieldsWrapper}>
                        <label>Amount Recieved</label>
                        <Currency currency={currency} name="amount" defaultValue={null} onChange={val => amount.current = val} />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

ManualPayment.Action = async ({ params, request }) => {
    const { invoiceId } = params
    const data = await request.json()

    if (!data.amount) {
        message.error('Amount is required')
        return false
    }

    if (data.amount <= 0 || isNaN(data.amount)) {
        message.error('Invalid amount')
        return false
    }
    try {
        const msg = await recordOfflinePayment(invoiceId, data.amount)
        message.success(msg)
        return redirect('../', {
            replace: true
        })
    }
    catch (error) {
        message.error(error.message)
    }
    return false
}


export { ManualPayment };


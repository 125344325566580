import PropTypes from 'prop-types';
import React from 'react';
// import styles from './cms-prices-selectable.module.scss'
import { Table } from 'antd';
import { formatPrice } from '../../../library/utilities/intl.js';

const CmsPricesSelectable = ({ prices, selectedQuantities = [], currency, disabled = false, onSelectionChange }) => {
    const priceList = Object.entries(prices.costs.purchase_price).map(([quantity, price]) => ({
        quantity: parseInt(quantity),
        price: price ? formatPrice(price, currency) : 'NA'
    }))

    return (
        <Table
            columns={[
                {
                    title: 'Quantity',
                    dataIndex: 'quantity'
                },
                {
                    title: 'Price',
                    dataIndex: 'price'
                },
            ]}
            dataSource={priceList}
            rowKey="quantity"
            pagination={false}
            rowSelection={{
                selectedRowKeys: selectedQuantities,
                getCheckboxProps: () => ({
                    disabled: disabled,
                }),
                onChange: (selectedRowKeys) => {
                    onSelectionChange(selectedRowKeys)
                }
            }}
            onRow={(record) => ({
                onClick: event => {
                    event.preventDefault()
                    if (disabled)
                        return
                    if (selectedQuantities.includes(record.quantity)) {
                        onSelectionChange(selectedQuantities.filter(a => a !== record.quantity))
                    }
                    else {
                        onSelectionChange([...selectedQuantities, record.quantity])
                    }
                }
            })}
        />
    )
    // return (
    //     <div className={styles.wrapper}>
    //         {Object.entries(prices).map(([qty, price]) => (
    //             <div className={`${styles.item} ${selectedQuantities.includes(Number(qty)) ? styles.active : ''}`} key={qty} onClick={() => handleItemClick(String(qty))} >
    //                 <span className={styles.quantity}>{qty}</span>
    //                 <span className={styles.price}>{price || 'NA'}</span>
    //             </div>
    //         ))}
    //         {children}
    //     </div>
    // )
}

CmsPricesSelectable.propTypes = {
    prices: PropTypes.object.isRequired,
    onSelectionChange: PropTypes.func,
    selectedQuantities: PropTypes.arrayOf(Number),
    currency: PropTypes.oneOf(['USD', 'EUR', 'GBP']).isRequired,
    disabled: PropTypes.bool,
}

export default CmsPricesSelectable
import { Checkbox, Collapse, message, Modal, Rate } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useFetcher } from 'react-router-dom';
import { GeneralFieldWrapper, InputWrapper } from '../../../components/inputs/input-wrappers.jsx';
// import { generateRouteUrl } from '../../../library/constants/routes.js';
import { getVendorDetails } from '../../../api/vendor/vendor.js';
import { Address } from '../../../components/data/address-view.jsx';
import FinisherSelect from '../../../components/inputs/FinisherSelect.jsx';

const { Panel } = Collapse
const PrintFinisherInvolved = ({ selectedFinisher, selectedAddress, finisherComment, nonUpdatable }) => {
    const fetcher = useFetcher()
    const modalFinisherAddressSelect = useRef(null)
    const [finisherInvolved, setFinisherInvolved] = useState(!!selectedFinisher)
    const [selectedFinisherId, setSelectedFinisherId] = useState(selectedFinisher?.id)

    useEffect(() => {
        setSelectedFinisherId(selectedFinisher?.id)
        setFinisherInvolved(!!selectedFinisher?.id)
    }, [selectedFinisher])

    const handleSelectFinisher = (val) => {
        if (!val) {
            Modal.confirm({
                content: 'The will remove the finisher data.Do you want to continue ?',
                onOk() {
                    handleFinisherChnage(null)
                    setFinisherInvolved(!!val)
                }
            })
        }
        else {
            handleFinisherChnage(selectedFinisher?.id)
            setSelectedFinisherId(null)
            setFinisherInvolved(!!val)
        }
    }

    const changeFinisher = (finisherId, addressId) => {
        fetcher.submit({
            finisherId,
            addressId,
            action: 'assignFinisher'
        }, {
            method: 'post',
            encType: 'application/json',
        })
        setSelectedFinisherId(finisherId)
    }
    const handleFinisherChnage = async (finisherId) => {
        if (!finisherId && selectedFinisher?.id) {
            fetcher.submit({
                finisherId: selectedFinisher.id,
                action: 'removeFinisher'
            }, {
                method: 'post',
                encType: 'application/json',
            })
            setSelectedFinisherId(null)
            return
        }
        const { addresses = [] } = await getVendorDetails(finisherId)
        if (addresses?.length === 0) {
            message.error("Finisher doesn't have any address.Please select another finisher")
            return
        }
        else if (addresses.length > 1) {
            modalFinisherAddressSelect.current = Modal.confirm({
                title: "Please select print finisher address",
                width: "720px",
                content: (
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                        {addresses.map(address => <Address key={address.id} address={address} handleItemClick={({ id }) => {
                            changeFinisher(finisherId, id)
                            modalFinisherAddressSelect.current.destroy()
                        }} style={{ flex: 1 }} />)}
                    </div>
                )
            })
            return
        }
        else {
            changeFinisher(finisherId, addresses[0]?.id)
        }
    }
    const updateComment = (comment) => {
        fetcher.submit({
            supplier_comment: comment,
            action: 'updateItem'
        }, {
            method: 'post',
            encType: 'application/json',
        })
    }

    return (
        <Collapse activeKey={finisherInvolved ? '1' : null} expandIconPosition="end">
            <Panel header={
                <>
                    <Checkbox checked={fetcher.json?.finisher || finisherInvolved} onChange={(e) => { handleSelectFinisher(e.target.checked) }} disabled={nonUpdatable} >Print Finisher Involved</Checkbox>
                    {!!selectedFinisher && (
                        <>
                            {selectedFinisher?.company}
                            <Rate value={selectedFinisher?.rating} allowHalf disabled />
                        </>
                    )}
                </>
            } key="1">
                <div className="form-fields-wrapper" >
                    <GeneralFieldWrapper label="Select Finisher" style={{ gridColumn: 1, gridRow: 1, justifyContent: 'flex-start' }}>
                        <FinisherSelect
                            value={selectedFinisherId}
                            currentSelection={selectedFinisher ? { value: selectedFinisher?.id, label: selectedFinisher?.company } : undefined}
                            onChange={handleFinisherChnage}
                            disabled={nonUpdatable}
                            allowClear
                        />
                    </GeneralFieldWrapper>
                    <InputWrapper name="supplier_comment" inputKey="finisher_comment" label="Finisher Comment" defaultValue={finisherComment} delay={true} onChange={e => updateComment(e.target.value)} containerStyle={{ gridColumn: 1, gridRow: 2, justifyContent: 'flex-start' }} textarea disabled={nonUpdatable} />
                    {!!selectedAddress && (
                        <GeneralFieldWrapper label="Finisher Address" style={{ gridColumn: 2, gridRow: 'span 2' }}>
                            <Address address={selectedAddress} selectable={false} disabled={nonUpdatable} />
                        </GeneralFieldWrapper>

                    )}
                </div>
            </Panel>
        </Collapse>
    )
}
PrintFinisherInvolved.propTypes = {
    selectedFinisher: PropTypes.object,
    selectedAddress: PropTypes.object,
    finisherComment: PropTypes.string,
    nonUpdatable: PropTypes.bool
}

export { PrintFinisherInvolved };

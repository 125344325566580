import { Alert, Button } from 'antd';
import React from "react";
import { Link, useRouteLoaderData } from 'react-router-dom';
import { generateRouteUrl } from '../../../library/constants';
import style from './template-ready.module.scss';



const TemplateReady = () => {

    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')

    return (
        <div className={style.container}>
            <h3>Task for you</h3>
            {orderDetails.order_status == 7 && <Alert message="Template is ready, please send it to customer" type="info"
                action={
                    <Link to={generateRouteUrl('SendReadyTemplate', {
                        itemId: orderDetails.id,
                    })}>
                        <Button className={style.greenButton}>View & Send to client</Button>
                    </Link>

                } />}
        </div>
    )
}

export default TemplateReady ;
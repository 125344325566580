import { Form } from 'antd';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getFieldErrors, getFieldStatus } from '../../library/helpers/forms';
import { FormProvider, useValidationForm } from '../../providers/form';


const ValidationForm = ({ form, entityId, name, children, validationRules, errors, onFinish, onValidationStatusChange, ...otherProps }) => {
	const [isValid, setIsValid] = useState(false)

	useEffect(() => {
		setLocalErrors(errors || {})
	}, [errors])

	const [localErrors, setLocalErrors] = useState(errors || {});

	const handleChange = async (changedFields) => {
		const newErrors = { ...localErrors };

		changedFields.forEach(({ name, errors: fieldErrors }) => {
			const fieldName = name[0];

			if (!fieldErrors.length) {
				// Remove error for the field if it's valid now
				delete newErrors[fieldName];
				// Clear error for this field in the form instance
				// form.setFields([{ name: fieldName, fieldErrors: [] }]);
				newErrors[fieldName] = [];
			} else {
				// console.log({ name, fieldErrors })
				// Update the error message for the field
				newErrors[fieldName] = fieldErrors;
			}
		});

		const checkValidation =
			(form.isFieldsTouched(Object.keys(validationRules), true)
				|| !Object.values(form.getFieldsValue(Object.keys(validationRules))).some(v => (v === undefined || v?.length === 0)))
			&& !form.getFieldsError().some(({ errors }) => errors.length > 0);

		setLocalErrors(newErrors);
		setIsValid(checkValidation);
	};

	useEffect(() => {
		if (onValidationStatusChange)
			onValidationStatusChange(isValid)
	}, [])

	useEffect(() => {
		if (onValidationStatusChange)
			onValidationStatusChange(isValid)
	}, [isValid])

	const handleFormFinish = (data) => {

		if (onFinish) {
			onFinish(data, entityId, (_exception) => {
				if (_exception.type === 'ValidationError' && _exception?.errors) {
					handleServerErrors(form, _exception.errors)
					return true
				}
				else {
					return false
				}
			})
		}
	}

	return (
		<FormProvider validationRules={validationRules} errors={localErrors}>
			<Form
				name={name}
				form={form}
				layout="vertical"
				onFieldsChange={handleChange}
				onFinish={handleFormFinish}
				validateTrigger={otherProps.validateTrigger ?? ['onChange']}
				onFinishFailed={(values, errorFields, outOfDate) => console.log(values, errorFields, outOfDate)}
				{...otherProps}
			>
				{children}
			</Form>
		</FormProvider>
	)
}

ValidationForm.propTypes = {
	form: PropTypes.object.isRequired,
	entityId: PropTypes.number,
	name: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	data: PropTypes.object,
	validationRules: PropTypes.object.isRequired,
	errors: PropTypes.object,
	onFinish: PropTypes.func,
	onValidationStatusChange: PropTypes.func,
}

const Item = ({ children, name, ...otherProps }) => {
	const { validationRules, errors } = useValidationForm();
	const fieldErrors = (name && !otherProps.noStyle) ? getFieldErrors(name, errors) : []
	const fieldStatus = getFieldStatus(name, errors)
	const rules = name && validationRules[name] ? validationRules[name] : []
	const mergedRules = [...rules, ...(otherProps?.rules || [])];

	return (
		<Form.Item name={name ?? undefined} {...otherProps} rules={mergedRules} status={fieldStatus} help={fieldErrors} validateStatus={fieldErrors ? 'error' : 'success'} >
			{children}
		</Form.Item >
	)
}

Item.propTypes = {
	children: PropTypes.node.isRequired,
	name: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
}

ValidationForm.Item = Item
ValidationForm.List = Form.List
ValidationForm.ErrorList = Form.ErrorList
ValidationForm.useForm = Form.useForm
ValidationForm.useFormInstance = Form.useFormInstance
ValidationForm.useWatch = Form.useWatch
ValidationForm.Provider = Form.Provider

export default ValidationForm

export const handleServerErrors = (form, errors) => {
	const fields = Object.entries(form.getFieldsValue()).map(([key, value]) => {
		if (!Array.isArray(key)) {
			key = [key]
		}
		return {
			name: key,
			errors: errors.filter(({ path }) => isEqual(path, key))?.map((({ message }) => message)) ?? [],
			touched: true,
			value,
		}
	})
	form.setFields(fields)
}
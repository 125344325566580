import { set } from 'lodash-es';
export * from './country';
export * from './currency';
export * from './entity';
export * from './invoice-status';

export const convertDataTypes = (data, typeSpecs) => {
    if (!data) return {}
    const convertedData = {};

    for (const [key, value] of Object.entries(data)) {
        //Skip empty
        if (value === null || value === undefined) {
            continue
        }
        if (key in typeSpecs) {
            const conversionFn = typeSpecs[key];
            if (typeof conversionFn === 'function') {
                try {
                    convertedData[key] = conversionFn(value);
                    if (typeof convertedData[key] !== 'object' && isNaN(convertedData[key])) {
                        convertedData[key] = null
                    }
                } catch (error) {
                    throw new Error(`Error converting value for key '${key}': ${error.message}`);
                }
            } else {
                throw new Error(`Invalid conversion function specified for key '${key}'`);
            }
        } else {
            convertedData[key] = value; // Preserve non-specified keys
        }
    }

    return convertedData;
}

export const sleep = (ms = 2000) => {
    console.warn('Kindly remember to remove `sleep`');
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export const nestedFormdataToJson = (data) => {
    const obj = {}
    for (const [path, value] of Object.entries(data)) {
        if (path.startsWith('meta')) {
            set(obj, path, value);
        }
    }
    return obj
}

export const removeEmpty = (obj) => Object.fromEntries(Object.entries(obj).filter((([, v]) => v !== null && v !== undefined)))

export const objectToSearchParams = (obj) => {
    const params = new URLSearchParams();

    for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
            const value = obj[key];

            if (typeof value === 'object' && value !== null) {
                for (const subKey in value) {
                    if (Object.hasOwnProperty.call(value, subKey)) {
                        params.append(`${key}[${subKey}]`, value[subKey]);
                    }
                }
            } else {
                params.set(key, value);
            }
        }
    }

    return params.toString();
}

export function searchParamsToObject(searchParams) {
    const obj = {};
    for (const [key, value] of searchParams.entries()) {
        const keys = key.split('[');
        let currentObj = obj;
        for (let i = 0; i < keys.length; i++) {
            const k = keys[i].replace(']', '');
            if (!currentObj[k]) {
                if (i === keys.length - 1) {
                    currentObj[k] = value;
                } else {
                    currentObj[k] = {};
                }
            }
            currentObj = currentObj[k];
        }
    }
    return obj;
}

// export const getFormattedId = (id) => String(id).padStart(6, '0')
export const getFormattedId = (id) => String(id)

export const convertCaseToText = (caseString) => {
    if (!caseString || typeof caseString !== 'string') {
        return ''; // Handle null, undefined, empty values, and non-string values
    }
    // Replace underscores, hyphens, and camel case with spaces
    const text = caseString
        .replace(/_/g, ' ')
        .replace(/-/g, ' ')
        .replace(/([a-z])([A-Z])/g, '$1 $2');
    return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1); // Capitalize the first letter
    // return text.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '); // Capitalize the first letter of each word
}
import { Badge } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styles from './SmallSection.module.scss';
import './SmallSection.scss';

const Styles = {
    FINANCE: {
        styles: { borderLeftWidth: '1px', marginBottom: 0 },
        colorLight: '#F1F1F1',
        colorDark: '#8F8F8F',
    }
}

const SmallSection = ({ children, style, header, actions, ribbon, primary, authority, colorLight, colorDark, containerStyles = {} }) => {
    const inlineStyles = useMemo(() => ({
        ...containerStyles,
        ...(style?.colorLight && { backgroundColor: style?.colorLight }),
        ...(style?.colorDark && { borderColor: style?.colorDark }),
        ...style?.styles,
        ...(colorLight && { backgroundColor: colorLight }),
        ...(colorDark && { borderColor: colorDark, outlineColor: colorDark }),
        ...((ribbon || primary || authority) && { paddingTop: '1.5rem' }),
    }
    ), [colorLight, colorDark, ribbon, primary, authority, containerStyles])

    const headerElement = () => (
        <header className={`${styles.header} ${header ? styles.fullHeader : ''}`}>
            {!!header && <div className={styles.primaryHeader}>{header}</div>}
            <div className="">
                {!!actions && actions.map((action) => (
                    <span className={styles.action} key={action.key}>{action}</span>
                ))}
            </div>
        </header>
    )

    const content = () => (
        <section className={`small-section ${styles.container} ${primary ? styles.primary : ''}`} style={inlineStyles}>
            {(!!actions || !!header) && headerElement()}
            <div className={styles.body}>
                {children}
            </div>
        </section>
    )

    if (ribbon) {
        return (
            <Badge.Ribbon text={ribbon} placement="start">
                {content()}
            </Badge.Ribbon>
        )
    }

    if (primary) {
        return (
            <Badge.Ribbon text="Primary" placement="start">
                {content()}
            </Badge.Ribbon>
        )
    }

    if (authority) {
        return (
            <Badge.Ribbon text="Authority" placement="start" color="purple">
                {content()}
            </Badge.Ribbon>
        )
    }

    return content()
}

SmallSection.Styles = Styles

SmallSection.propTypes = {
    children: PropTypes.node.isRequired,
    actions: PropTypes.arrayOf(
        PropTypes.element
    ),
    ribbon: PropTypes.string,
    primary: PropTypes.bool,
    header: PropTypes.element,
    authority: PropTypes.bool,
    colorLight: PropTypes.string,
    colorDark: PropTypes.string,
    containerStyles: PropTypes.object,
    style: PropTypes.object
}

export default SmallSection
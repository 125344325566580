import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, Space, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { generatePath, useLoaderData, useNavigate } from 'react-router-dom';
import { createBulkProjectsInvoices, getEulerProjects } from '../../api/finance';
import { SelectedFilters } from '../../components/data/selected-filters.jsx';
import EulerFilterForm from '../../components/form/euler-filter/euler-filter.jsx';
import { Page } from '../../components/page';
import { Table } from '../../components/table';
import { useFetch } from '../../hooks';
import { ValidationError } from '../../library/classes/exceptions';
import { EulerCaseColumn } from '../../library/constants/tableColumns/EulerCaseColumn.js';
import { searchParamsToObject } from '../../library/helpers/index.js';

const FILTER_NAMES = {
  customer_id: "Customer",
  case_number: "Case Number",
  invoice_no: "Invoice No.",
  createdAt: "Create At",
}

function EulerCasesList({ title }) {
  const initialData = useLoaderData()
  const navigate = useNavigate()
  const [filtersVisible, setFiltersVisible] = useState(false)
  const [list, hasMore, isLoading, searchParamsParsed, {
    setSort,
    setFilters,
    loadMore
  }] = useFetch({ initialData })

  const handleRowClick = (_, record) => {
    navigate(generatePath('/finance/euler-cases/:id', {
      id: record.id
    }))
  }

  return (
    <Page className='EulerCasesList' backLink={false} title={title} summary={initialData?.meta?.totalCount ? `${initialData?.meta?.totalCount} Records` : 'No Records'} header={
      <>
        <div></div>
        <Space.Compact style={{ width: '100%', maxWidth: '400px' }}>
          <Input.Search placeholder="Search" prefix={<SearchOutlined />} onChange={(e) => setFilters({ 'search': e.target.value })} allowClear />
          <Button onClick={() => setFiltersVisible(v => !v)} icon={<FilterOutlined />}>Filters</Button>
        </Space.Compact>
        <div></div>
      </>
    } subHeader={initialData.meta?.filters && <SelectedFilters filters={initialData.meta.filters} filterNames={FILTER_NAMES} updateFilters={setFilters} />}>


      <Drawer open={filtersVisible} onClose={() => setFiltersVisible(false)} >
        <EulerFilterForm key={JSON.stringify(searchParamsParsed.filter)} data={searchParamsParsed.filter} onFinish={setFilters} />
      </Drawer>
      {!!list && (
        <>
          <Table
            columns={EulerCaseColumn}
            data={list}
            isLoading={isLoading}
            onChange={setSort}
            onRowClick={handleRowClick}
            hasMore={hasMore}
            loadMore={loadMore}

          />
        </>
      )}
    </Page>
  )
}

EulerCasesList.propTypes = {
  title: PropTypes.string,
}

EulerCasesList.Actions = {
  bulkInvoiceGenerate: async ({ data }) => {
    console.log({ data })
    const project_ids = data?.project_ids?.split(',')?.map(v => parseInt(v))?.filter(v => Number.isInteger(v))
    if (!project_ids || project_ids.length === 0) {
      throw new ValidationError("Invalid Project Ids", {
        "project_ids": "Project Ids must be an array of intergers"
      })
    }
    try {
      const msg = await createBulkProjectsInvoices(project_ids)
      message.success(msg)
      return true
    }
    catch (error) {
      message.error(error.message)
      return false
    }
  }
}

EulerCasesList.Loader = async ({ request }) => {
  const url = new URL(request.url)
  const { filter, page, sort } = searchParamsToObject(url.searchParams)
  const { data, meta } = await getEulerProjects({ filter, page, ...sort })
  return { data, meta }
}

export default EulerCasesList

import { Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import Languages from '../../library/dynamics/languages';
// import Icon from '@ant-design/icons';
const { Option } = Select

function LanguageSelect({ value = null, onChange, options, ...otherProps }) {
    const DropOptions = options == undefined ? Languages : Languages.filter((lang) => { return options.includes(lang.id) })
    return (
        <Select
            optionFilterProp="label"
            onChange={onChange}
            value={value}
            //  suffixIcon={<Icon component={() => (<img src="/icons/dropdown.svg" />)} styles={{pointerEvents: 'none'}} />}
            autoComplete="none"
            showSearch
            {...otherProps}
        >
            {DropOptions.map((language) => (
                <Option key={language.id} value={language.id} label={language.name}>
                    {language.name}
                </Option>
            ))}
        </Select>
    );
}

LanguageSelect.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    options: PropTypes.array
}

export default LanguageSelect;
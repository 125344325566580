import PropTypes from 'prop-types';
import React from 'react';
import { formatDateTime } from '../../../library/utilities/intl.js';
import SmallSection from '../../design/small-section.jsx';
import { DocumentsDownloader } from '../../form/index.js';

const FilePropType = PropTypes.shape({
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    comment: PropTypes.string,
    uploaded_by: PropTypes.string,
    uploaded_time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
})

const ORDER_DOCUMENT_TYPES = {
    vendor_template: "Template Recieved from Vendor",
    leoprinting_uploaded_sample: "Template Sent To Custmer",
    client_artwork: "Artwork Recieved",
    vendor_proof: "Proof Recieved from Vendor",
    studio_template: "Template Recived from Studio",
    studio_artwork: "Artwork Recieved from Studio",
    studio_proof: "Proof Recieved from Studio",
    order_template_to_studio: "Template Sent to Studio",
    order_template_to_client: "Template Sent to Customer",
    order_artwork_to_studio: "Artwork sent to Studio",
    order_artwork_to_vendor: "Artwork Sent to Vendor",
    order_proof_to_studio: "Proof Sent to Studio",
    order_proof_to_client: "Proof Sent to Customer",
    // order_uploaded_proof_of_delivery_for_files: "Bulk Delivery proof",
    make_artwork_send_to_studio: "Artwork Create Request Sent to Studio",
    make_artwork_from_studio: "Artwork Created by Studio",
    make_artwork_send_to_client: "Created artwork Sent to Customer",
    client_uploaded_address: "Bulk Delivery Addresses",
    dispatched_date_file: "Bulk Delivery Dispatch Dates",
    add_shipping_details_file: "Bulk Delivery Shipping Details",
    // shipping_details_file_confirm_by_order: "Bulk Delivery proof ",
    supplier_files: "Files Sent to Supplier",
    supplier_delivery_proof: "Supplier Delivery Proof",
    supplier_delivery_proof_via_vendor: "Delivery Proof uploaded by Supplier",
    vendor_purchase_order: "Vendor/Finisher Purchase Order",
    vendor_delivery_notes: "Vendor/Finisher Delivery Note",
    supplier_purchase_order: "Supplier Purchase order",
    supplier_delivey_notes: "Supplier Delivery Note",
    confirm_proof_by_client: "Proof Approved by Customer",
}

const OrderDocumentSection = ({ projectId, title, files, dateTime, comment }) => {
    if (!files || files.length === 0) {
        return <></>
    }
    return (
        <SmallSection header={(<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h3>{title}</h3>
            <span style={{ color: '#3a3a3a' }}>{formatDateTime(dateTime)}</span>
        </div>)}>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}>
                {files && files.filter(file => !!file.url).map(file => (
                    <DocumentsDownloader key={file.url} image={file?.url} name={file?.name} comment={file?.comment} project_id={projectId} containerStyles={{ background: '#ebf2fc', padding: '1rem' }} />
                ))}
            </div>
            {comment?.length > 0 && (
                <>
                    < hr />
                    <strong>Comment:</strong> <i>{comment}</i>
                </>
            )}
        </SmallSection>
    )
}
OrderDocumentSection.propTypes = {
    projectId: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    files: PropTypes.arrayOf(FilePropType),
    dateTime: PropTypes.string,
    comment: PropTypes.string,
}

const OrderDocuments = ({ projectId, documents, finisherTimelineVisible }) => {
    return (
        <>
            {!!documents.supplier_files && documents.supplier_files.length > 0 && <OrderDocumentSection title='Files Sent to Vendor' projectId={projectId} files={documents.supplier_files} />}
            <OrderDocumentSection title={`${finisherTimelineVisible ? 'Finisher' : 'Vendor'} Purchase order`} projectId={projectId} files={documents.vendor_purchase_order} />
            <OrderDocumentSection title='Supplier Purchase order' projectId={projectId} files={documents.finisher_purchase_order} />
            <OrderDocumentSection title='Vendor template' projectId={projectId} files={documents.vendor_template} />
            <OrderDocumentSection title='Template Sent to Studio' projectId={projectId} files={documents.order_template_to_studio} />
            <OrderDocumentSection title='Template Recived from Studio' projectId={projectId} files={documents.studio_template} />
            <OrderDocumentSection title='Template Sent to Customer' projectId={projectId} files={documents.order_template_to_client} />
            <OrderDocumentSection title='Artwork Create Request to Studio' projectId={projectId} files={documents.make_artwork_send_to_studio} />
            <OrderDocumentSection title='Artwork Created by Studio' projectId={projectId} files={documents.make_artwork_from_studio} />
            <OrderDocumentSection title='Created artwork Sent to Customer' projectId={projectId} files={documents.make_artwork_send_to_client} />
            <OrderDocumentSection title='Customer Artwork' projectId={projectId} files={documents.client_artwork} />
            <OrderDocumentSection title='Artwork sent to Studio' projectId={projectId} files={documents.order_artwork_to_studio} />
            <OrderDocumentSection title='Artwork Recieved from Studio' projectId={projectId} files={documents.studio_artwork} />
            <OrderDocumentSection title='Artwork Sent to Vendor' projectId={projectId} files={documents.order_artwork_to_vendor} />
            <OrderDocumentSection title='Proof Recieved from Vendor' projectId={projectId} files={documents.vendor_proof} />
            <OrderDocumentSection title='Proof Sent to Studio' projectId={projectId} files={documents.order_proof_to_studio} />
            <OrderDocumentSection title='Proof Recieved from Studio' projectId={projectId} files={documents.studio_proof} />
            <OrderDocumentSection title='Proof Sent to Customer' projectId={projectId} files={documents.order_proof_to_client} />
            <OrderDocumentSection title={`${finisherTimelineVisible ? 'Finisher' : 'Vendor'} Delivery Note`} projectId={projectId} files={documents.vendor_delivery_notes} />
            <OrderDocumentSection title='Supplier Delivery Note' projectId={projectId} files={documents.finisher_delivery_notes} />
        </>
    )
}

OrderDocuments.propTypes = {
    projectId: PropTypes.number.isRequired,
    finisherTimelineVisible: PropTypes.bool,
    documents: PropTypes.shape({
        supplier_files: PropTypes.arrayOf(FilePropType),
        vendor_template: PropTypes.arrayOf(FilePropType),
        order_template_to_studio: PropTypes.arrayOf(FilePropType),
        studio_template: PropTypes.arrayOf(FilePropType),
        order_template_to_client: PropTypes.arrayOf(FilePropType),
        make_artwork_send_to_studio: PropTypes.arrayOf(FilePropType),
        make_artwork_from_studio: PropTypes.arrayOf(FilePropType),
        make_artwork_send_to_client: PropTypes.arrayOf(FilePropType),
        client_artwork: PropTypes.arrayOf(FilePropType),
        order_artwork_to_studio: PropTypes.arrayOf(FilePropType),
        studio_artwork: PropTypes.arrayOf(FilePropType),
        order_artwork_to_vendor: PropTypes.arrayOf(FilePropType),
        vendor_proof: PropTypes.arrayOf(FilePropType),
        order_proof_to_studio: PropTypes.arrayOf(FilePropType),
        studio_proof: PropTypes.arrayOf(FilePropType),
        order_proof_to_client: PropTypes.arrayOf(FilePropType),
        vendor_purchase_order: PropTypes.arrayOf(FilePropType),
        finisher_purchase_order: PropTypes.arrayOf(FilePropType),
        vendor_delivery_notes: PropTypes.arrayOf(FilePropType),
        finisher_delivery_notes: PropTypes.arrayOf(FilePropType),
    })
}

const OrderDocumentsNew = ({ projectId, documents }) => {
    return documents?.filter(({ type }) => !!ORDER_DOCUMENT_TYPES[type])?.map(({ id, documents: files, type, comments, updatedAt }) => <OrderDocumentSection key={id} projectId={projectId} title={ORDER_DOCUMENT_TYPES[type]} files={files} dateTime={updatedAt} comment={comments} />)
}
OrderDocumentsNew.propTypes = {
    projectId: PropTypes.number.isRequired,
    finisherTimelineVisible: PropTypes.bool,
    documents: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        documents: PropTypes.arrayOf(FilePropType).isRequired,
        updatedAt: PropTypes.string,
        type: PropTypes.string,
    })),
}

export { OrderDocuments, OrderDocumentsNew };

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Badge, Button, Card, Input, Modal } from 'antd';
import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFetcher } from 'react-router-dom';
import { Genders, Languages, Roles } from '../../library/constants/dynamic.js';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { triggerInputChange } from '../../library/helpers/forms/index.js';
import { Box } from '../design/box.js';
import { CustomerContact } from '../form/index.js';
import styles from './contact-select.module.scss';

const SingleContact = ({ item, selected, handleItemClick, disabled }) => (
	<Card key={item.id} bordered={false} onClick={() => !disabled && handleItemClick(item)} className={`${styles.card} ${selected ? styles['card--selected'] : ''} ${disabled ? styles['card--disabled'] : ''}`} title={`${item.first_name} ${item.last_name}`}>
		<div className={styles.item}>
			<div className={styles.itemLabel}>First Name</div>
			<div className={styles.itemValue}>{item.first_name}</div>
		</div>
		<div className={styles.item}>
			<div className={styles.itemLabel}>Last Name</div>
			<div className={styles.itemValue}>{item.last_name}</div>
		</div>
		<div className={styles.item}>
			<div className={styles.itemLabel}>Phone No.</div>
			<div className={styles.itemValue}>
				{!!item.phone_number && `+${item.phone_country_code ?? ''} ${item.phone_number}`}
			</div>
		</div>
		<div className={styles.item}>
			<div className={styles.itemLabel}>Mobile No.</div>
			<div className={styles.itemValue}>{!!item.mobile_number && `+${item.mobile_country_code ?? ''} ${item.mobile_number}`}</div>
		</div>
		<div className={styles.item}>
			<div className={styles.itemLabel}>Email</div>
			<div className={styles.itemValue}>{item.email}</div>
		</div>
		<div className={styles.item}>
			<div className={styles.itemLabel}>Language</div>
			<div className={styles.itemValue}>{Languages.find(({ id }) => id === item.language_id)?.name}</div>
		</div>
		<div className={styles.item}>
			<div className={styles.itemLabel}>Role</div>
			<div className={styles.itemValue}>{Roles.find(({ id }) => id === item.role_id)?.name}</div>
		</div>
		<div className={styles.item}>
			<div className={styles.itemLabel}>Gender</div>
			<div className={styles.itemValue}>{Genders.find(({ id }) => id === item.gender_id)?.name}</div>
		</div>
	</Card>
)
SingleContact.propTypes = {
	item: PropTypes.object.isRequired,
	selected: PropTypes.bool.isRequired,
	handleItemClick: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
}

const BadgedContact = ({ item, selected, isPrimary = false, isAuthority = false, isInvoice = false, disabled = false, handleItemClick }) => {
	const hasRole = isPrimary || isInvoice || isAuthority

	const getContactRoles = () => {
		let badgeText = [];
		let badgeColor = '';

		if (isPrimary) {
			badgeText.push('Primary');
			badgeColor = 'blue';
		}
		else {
			if (isInvoice) {
				badgeText.push('Invoice');
				badgeColor = 'green';
			}
			if (isAuthority) {
				badgeText.push('Authority');
				badgeColor = 'red';
			}
		}

		if (badgeText.length > 0) {
			return { text: badgeText.join(' & '), color: badgeColor };
		}

		return null;
	};

	const contactRoles = getContactRoles();

	return hasRole ? (
		<Badge.Ribbon text={contactRoles.text} color={contactRoles.color}>
			<SingleContact item={item} selected={selected} handleItemClick={handleItemClick} disabled={disabled} />
		</Badge.Ribbon>
	) : (
		<SingleContact item={item} selected={selected} handleItemClick={handleItemClick} />
	);
}
BadgedContact.propTypes = {
	item: PropTypes.object.isRequired,
	selected: PropTypes.bool.isRequired,
	handleItemClick: PropTypes.func.isRequired,
	isPrimary: PropTypes.bool,
	isAuthority: PropTypes.bool,
	isInvoice: PropTypes.bool,
	disabled: PropTypes.bool,
}

const AddModal = ({ customerId, allowedLanguages = [], initialValues, onCancel, onSubmit }) => {
	const fetcher = useFetcher()
	const { errors = {} } = fetcher.data || {}
	console.log({ errors })

	useEffect(() => {
		if (errors && !isEmpty(errors) || !fetcher.data?.id) {
			return
		}
		onSubmit(fetcher.data)
	}, [fetcher.data])

	return (
		<Modal
			title="Add New Contact"
			open={true}
			onCancel={onCancel}
			width='50%'
			forceRender
			destroyOnClose
			footer={<>

			</>}>
			<CustomerContact
				index="new"
				allowedLanguages={allowedLanguages}
				initialValues={initialValues}
				onFinish={(data) => {
					fetcher.submit({
						...data,
						action: 'addContact'
					}, {
						method: 'post',
						action: generateRouteUrl('CustomerDetails', {
							id: customerId,
						}),
						encType: 'application/json'
					})
				}}
				errors={errors}
			>
				<Button
					type="primary"
					htmlType='submit'
					name='action'
					value='addContact'
					loading={fetcher.state !== 'idle'}
					icon={<CheckOutlined />}
				>Add Contact</Button>
				<Button type="cancel" icon={<CloseOutlined />} onClick={onCancel}>Cancel</Button>
			</CustomerContact>
		</Modal>
	)
}

AddModal.propTypes = {
	customerId: PropTypes.number.isRequired,
	allowedLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
	initialValues: PropTypes.object,
	onCancel: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
}

const ContactSelect = ({ name = null, defaultValue = null, customerId, onSelect, excludedContactIds = [], renderSelected }) => {
	const [query, setQuery] = useState('')
	const [isOpen, setIsOpen] = useState(false)
	const [selectedId, setSelectedId] = useState(defaultValue)
	const inputRef = useRef(null)
	const fetcher = useFetcher()
	const [addNewVisible, setAddNewVisible] = useState(false)
	const { contacts = [], customerDetails = {}, } = fetcher.data ?? {}

	const filteredList = useMemo(() => {
		// const _contacts = contacts.filter(a => !excludedContactIds.includes(a.id))
		if (!query.length) {
			return contacts
		}
		return contacts?.filter((contact) => Object.values(contact).some((v) => {
			return (typeof v === 'string') && v.toLowerCase().includes(query.toLowerCase())
		}))

	}, [contacts, query, excludedContactIds])

	const handleItemClick = (contact) => {
		setSelectedId(contact.id)
		onSelect(contact.id, contact)
		if (name) {
			triggerInputChange(inputRef.current, contact.id)
		}
		closePopup()
	}

	useEffect(() => {
		fetcher.load(generateRouteUrl('CustomerDetails', {
			id: customerId
		}))
	}, [customerId])

	const closePopup = () => setIsOpen(false)

	return (
		<>
			<div onClick={() => setIsOpen(true)}>
				{renderSelected(contacts.find(({ id }) => id === selectedId))}
			</div>
			{!!name && (<input ref={inputRef} name={name} style={{ display: 'none' }} defaultValue={defaultValue} />)}
			<Modal title="Add Contact" open={isOpen} onCancel={closePopup} destroyOnClose footer={<Button onClick={() => setAddNewVisible(true)}>Add New Contact</Button>} width={1000} >
				<Box type={Box.BoxTypes.INNERSHADOW}>
					<div className={styles.wrapper}>
						<Input.Search placeholder="Search..." onChange={(e) => setQuery(e.target.value)} />
						<div className={styles.cards}>
							{filteredList?.map((item) => <BadgedContact key={item.id} item={item} selected={item.id === selectedId} isPrimary={item.id === customerDetails.primary_contact_id} isAuthority={!!item.is_authority} isInvoice={item.id === customerDetails.billing_contact_id} handleItemClick={handleItemClick} disabled={excludedContactIds.includes(item.id)} />)}
						</div>
					</div>
				</Box>
				{addNewVisible && <AddModal customerId={customerId} initialValues={{ phone_country_code: customerDetails.contact_country_code, mobile_country_code: customerDetails?.contact_country_code, }} allowedLanguages={customerDetails.entity?.default_language} onSubmit={(_contact) => {
					if (!_contact.id) {
						return
					}
					handleItemClick(_contact)
					setAddNewVisible(false)
				}} onCancel={() => setAddNewVisible(false)} />}
			</Modal >
		</>
	)
}

ContactSelect.propTypes = {
	name: PropTypes.string,
	defaultValue: PropTypes.number,
	customerId: PropTypes.number.isRequired,
	onSelect: PropTypes.func.isRequired,
	renderSelected: PropTypes.func.isRequired,
	excludedContactIds: PropTypes.arrayOf(PropTypes.number)
}

export default ContactSelect
import { GlobalOutlined, MailOutlined, MobileOutlined } from '@ant-design/icons';
import { List } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Server } from '../../../library/constants/server';
// import './studio-kanaban-view.scss';
import { convertDate } from '../../../library/helpers/dateFormat';

const renderOrderKanabanCard = ({ item, onClick }) => {
  const getSourceIcon = () => {
    switch (item.project.source.id) {
      case 'email':
        return <MailOutlined />
      case 'website':
        return <GlobalOutlined color="#6e589d" />
      case 'phone':
        return <MobileOutlined />
    } 
  } 
  return (
    <List.Item className='kanaban-card' onClick={() => onClick(item)}>
      <div className="kanaban-card--header">
        <span className="deadline-delivery-data">Deadline Delivery :  {convertDate(String(item.client_expected_delivery_date))}</span>
      </div>
      <div className="kanaban-card--body">
        <div className="upper">
          <span className="project-number">{String(item.id).padStart(6, '0')}</span>
          <span className="project-source">{getSourceIcon()}</span>
          <span className="project-country">
            <img src={`${Server.BaseUrl}/${item.project.customer.country.flag_url}`} alt={item.project.customer.country.name} title={item.project.customer.country.name} />
          </span>
        </div>
        <br />
        <span className="customer-logo">
          {/* <img src={`https://www.google.com/s2/favicons?sz=64&domain_url=${item.customer.website}`} alt="" /> */}
        </span>
        <span className="customer-name">{item.project.customer.company}</span>
        <table width={'100%'}>
          <tr>
            <th className="order-id">Account Manager</th>
            <th className="recieved-date">Total Price</th>
          </tr>
          <tr>
            <th> {item.project.user.username}</th>
            <th style={{ "float": "right" }}>{item?.item_vendor_price?.total_price ? item.item_vendor_price.total_price : '0'}</th>
          </tr>
        </table>
      </div>
      <div className="kanaban-card--footer">
        <span className='payment'>Payment Status  : <span className='payment_value'> {item.project.payment_term?.id}</span></span>
      </div>
    </List.Item>
  );
};

const StudioKanabanColumn = ({ list, title, onItemClick }) => {
  return (
    <div className='kanaban-column'>
      <h3 className='kanaban-colum-inside'>{title} ({list.length})</h3>
      <List
        className="kanaban-column--list"
        //loading={initLoading}
        itemLayout="horizontal"
        //loadMore={loadMore}
        dataSource={list}
        renderItem={(item) => renderOrderKanabanCard({ item, onClick: onItemClick })} />
    </div>
  );
};
StudioKanabanColumn.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  onItemClick: PropTypes.func.isRequired,
};

const StudioKanaban = ({ data, columns, onItemClick }) => {
  return (
    <div className='kanaban-wrapper'>
      {columns.map(c => <StudioKanabanColumn key={c.key} title={c.title} list={data.filter(({ studio_status }) => studio_status === c.key) ?? []} onItemClick={onItemClick} />)}
    </div>
  );
};
StudioKanaban.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  onItemClick: PropTypes.func.isRequired,
};

export default StudioKanaban
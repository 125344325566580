import PropTypes from 'prop-types';
import React from 'react';
import { Outlet, useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { getTemplateEmails } from '../../api/settings/email-template';
import { Page } from '../../components/page/index.js';
import Table from '../../components/table/table.jsx';
import useFetch from '../../hooks/use-fetch.js';
import { generateRouteUrl } from '../../library/constants/index.js';
import { getTimeAgo } from '../../library/utilities/intl.js';

const EmailHistory = ({ title }) => {
    const initialData = useLoaderData();
    const { id } = useParams()
    const navigate = useNavigate()

    const [list, hasMore, isLoading, , {
        setSort,
        loadMore
    }] = useFetch({ initialData })

    return (
        <Page title={title}>
            <Table
                columns={[
                    {
                        label: 'Subject',
                        dataIndex: 'subject',
                    },
                    {
                        label: 'Email',
                        dataIndex: 'to_email',
                    },
                    {
                        label: 'Name',
                        dataIndex: 'sender_name',
                    },
                    {
                        label: 'Template',
                        dataIndex: 'template',
                    },
                    {
                        label: 'Time',
                        dataIndex: 'createdAt',
                        render: time => getTimeAgo(time),
                    },
                ]}
                dataSource={list}
                rowKey="id"
                onRow={email => ({
                    onClick: () => {
                        navigate(generateRouteUrl('EmailDetails', {
                            id,
                            emailId: email.id
                        }), { replace: true })
                    }
                })}
                isLoading={isLoading}
                onChange={setSort}
                hasMore={hasMore}
                loadMore={loadMore}
                sticky
            />
            <Outlet />
        </Page>
    );
};

EmailHistory.Loader = async ({ params }) => {
    const { id } = params;
    return await getTemplateEmails(id);
};

EmailHistory.propTypes = {
    title: PropTypes.string,
};

export default EmailHistory;

import { useEffect, useState } from 'react';
import { VatTypes } from '../library/constants/dynamic.js';

export const usePurchasebillLineTotals = (initialTotalPriceExclVat, initialVisibleVatId) => {
    const [totalPriceExclVat, setTotalPriceExclVat] = useState(parseFloat(initialTotalPriceExclVat) || 0.00);
    const [visibleVatId, setVisibleVatId] = useState(initialVisibleVatId);
    const [totalPriceInclVat, setTotalPriceInclVat] = useState(0.00);

    const visibleVat = VatTypes.find(({ id }) => id === visibleVatId)?.percentage / 100

    useEffect(() => {
        const totalInclVat = parseFloat(totalPriceExclVat) * (1 + parseFloat(visibleVat));
        setTotalPriceInclVat(isNaN(totalInclVat) ? null : totalInclVat);
    }, [totalPriceExclVat, visibleVatId]);

    return {
        totalPriceExclVat,
        setTotalPriceExclVat: (val) => setTotalPriceExclVat(parseFloat(val) || 0),
        visibleVatId,
        setVisibleVatId,
        totalPriceInclVat,
    };
}
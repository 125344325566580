import { ExportOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Collapse, Descriptions, Drawer, Table, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Await, Form, Link, redirect, useActionData, useLoaderData } from 'react-router-dom';
import { getProjectDetails, getPurchaseBillDetails, linkPurchaseBill, purchaseBillAuditTrail } from '../../api/finance';
import PurcahseBillProjectItemSelect from '../../components/form/purchase-bill-project-item-select.jsx';
import { FieldWrapper, ProjectSelectAdvance, VendorSelect } from '../../components/inputs';
import { Page } from '../../components/page';
import { ValidationError } from '../../library/classes/exceptions/index.js';
import { InvoiceDetailsAuditTrailColumns, generateRouteUrl } from '../../library/constants/index.js';
import { Server } from '../../library/constants/server.js';
import { getTimeAgo } from '../../library/utilities/intl.js';
import Styles from './purchasebills-basic-details.module.scss';
const { Panel } = Collapse

const PurchaseBillLink = ({ title }) => {
	const { purchaseBillId, purchaseBill, email/* , projectDetails */, auditTrail } = useLoaderData()
	const [pdfPreview, setPdfPreview] = useState(null)
	const [projectId, setProjectId] = useState(purchaseBill.project_id)
	const [projectItemIds, setProjectItemIds] = useState(purchaseBill.item_ids ?? [])
	// const [paymentMethodId, setPaymentMethodId] = useState(purchaseBill.payment_method_id)
	const [vendorId, setVendorId] = useState(purchaseBill.vendor_id)

	// const submit = useSubmit();
	const { errors = null } = useActionData() || {}

	// const mainFormRef = useRef(null)

	const getAttatchmentUrl = (path) => Server.getFileUrl(`document/attachment/${path}`)

	// const debounceUpdateHandler = useCallback(debounce((form) => {
	// 	fetcher.submit(form, { method: 'post' });
	// }, 1000), []);

	// const submitForm = () => debounceUpdateHandler(mainFormRef.current)
	return (
		<>
			<Page className='purchase-bill-create' title={title} >
				<Collapse defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header={<><MailOutlined /> Emails</>} key="1" >
						<Descriptions>
							<Descriptions.Item label="From">{email.from}</Descriptions.Item>
							<Descriptions.Item label="Subject">{email.subject}</Descriptions.Item>
							<Descriptions.Item label="Files">{<Button type="link" onClick={() => setPdfPreview(email.attachments[0])}>{email.attachments[0]}</Button>}</Descriptions.Item>
							<Descriptions.Item label="Recieved At">{getTimeAgo(email.createdAt)}</Descriptions.Item>
							<Descriptions.Item label="Content">{email.body.split("\n").slice(0, 5).map((line, i) => <React.Fragment key={i}>{line}<br></br></React.Fragment>)}</Descriptions.Item>
						</Descriptions>
					</Panel>
				</Collapse>
				<Collapse defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header={<><MailOutlined /> Project Search</>} key="1">
						<Form className={Styles['fieldsWrapper--Two']} method="post" >
							<FieldWrapper name="vendor_id" label="Vendor" errors={errors}>
								<VendorSelect
									value={vendorId}
									currentSelection={{ value: purchaseBill.vendor_id, label: purchaseBill.vendor?.company }}
									onChange={v => {
										setVendorId(v)
										setProjectId(null)
										setProjectItemIds([])
									}}
								/>
								<input type="hidden" name="vendor_id" value={vendorId ?? ''} />
							</FieldWrapper>
							<FieldWrapper name="item_ids" label="Project" errors={errors}>
								<ProjectSelectAdvance
									key={projectId}
									filters={{
										vendor_id: vendorId,
									}}
									value={projectId}
									onChange={v => {
										setProjectId(v)
										setProjectItemIds([])
									}}
									disabled={!vendorId}
								/>
							</FieldWrapper>
							<FieldWrapper name="item_ids" label="Items" errors={errors} style={{ gridColumn: 'span 2' }}>
								<PurcahseBillProjectItemSelect
									key={`${vendorId}-${projectId}`}
									filters={{
										vendor_id: vendorId,
										project_id: projectId,
									}}
									value={projectItemIds}
									onChange={(v, o) => {
										if (o?.[0]?.project_id) {
											setProjectId(o?.[0]?.project_id ?? null)
										}
										setProjectItemIds(v)
									}}
									disabled={!projectId}
									mode="multiple"
								/>
								<input type="hidden" name="item_id" value={projectItemIds ?? ''} />
							</FieldWrapper>
							<div className="actions align--left">
								<Button htmlType='submit' type='primary'>Save</Button>
								<Link to={generateRouteUrl('FinancePurchaseBillDetails', {
									purchaseBillId
								})}>
									<Button type='danger'>Cancel</Button>
								</Link>
							</div>
						</Form>
					</Panel>
				</Collapse>
				<React.Suspense>
					<Await
						resolve={auditTrail}
						errorElement={
							<p>Error loading audit trail!</p>
						}
					>
						{(logs) => (
							<Collapse defaultActiveKey={['1']} expandIconPosition="end">
								<Panel header='Audit Trail' key="1">
									<Table
										columns={InvoiceDetailsAuditTrailColumns}
										dataSource={logs}
										rowKey="id"
										pagination={{ hideOnSinglePage: true }}
									/>
								</Panel>
							</Collapse>
						)}
					</Await>
				</React.Suspense>
			</Page >
			<Drawer title={<><Button type="primary" href={getAttatchmentUrl(pdfPreview)} icon={<ExportOutlined />} target='_blank'>View file</Button> </>} placement="right" onClose={() => setPdfPreview(null)} open={!!pdfPreview && pdfPreview.length > 0} size="large" mask={false} getContainer={false} destroyOnClose>
				<object data={getAttatchmentUrl(pdfPreview)} type="application/pdf" width="100%" height="100%">
					<p>Unable to load file.Click on the link below to view the file</p>
					<p><a target="_blank" rel="noreferrer" href={getAttatchmentUrl(pdfPreview)}>{pdfPreview}</a></p>
				</object>
			</Drawer>
		</>
	)
}

PurchaseBillLink.propTypes = {
	title: PropTypes.string
}

PurchaseBillLink.Action = async ({ params, request }) => {
	const { purchaseBillId } = params
	const { ...data } = Object.fromEntries(await request.formData())
	try {
		const { vendor_id, item_id } = data
		const itemIds = item_id.split(',').map(Number)
		const msg = await linkPurchaseBill(purchaseBillId, Number(vendor_id), itemIds)
		message.success(msg)
		return redirect(generateRouteUrl('FinancePurchaseBillDetails', {
			purchaseBillId
		}))
	}
	catch (error) {
		message.error(error.message)
		if (error instanceof ValidationError) {
			return {
				errors: error.errors
			}
		}
	}
	return true
}

PurchaseBillLink.Loader = async ({ params }) => {
	if (isNaN(params.purchaseBillId)) {
		throw new Error('Invalid Purchase Bill ID')
	}

	const { id: purchaseBillId, email_read: email, purchase_bill_items, status, ...purchaseBill } = await getPurchaseBillDetails(params.purchaseBillId)

	// if (status > 0) {
	// 	return redirect(generateRouteUrl('PurchaseBillDetails', {
	// 		purchaseBillId
	// 	}));
	// }

	purchaseBill.item_ids = purchase_bill_items.map(({ item_id }) => item_id)

	let projectDetails = {}
	if (purchaseBill.projectId) {
		projectDetails = await getProjectDetails(purchaseBill.projectId)
	}

	const auditTrail = purchaseBillAuditTrail(params.purchaseBillId)

	return { purchaseBillId, email, status, purchaseBill, projectDetails, auditTrail }
}

export default PurchaseBillLink
import { LoadingOutlined } from '@ant-design/icons';
import { Table as AntdTable } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import useElementReachedBottomListner from '../../hooks/use-element-reached-bottom-listener.js';
import styles from './table.module.scss';

const sortOrders = {
    ascend: 'ASC',
    descend: 'DESC'
}

const Table = ({ columns, data, hasMore, loadMore, isLoading, onChange, onRowClick, ...otherProps }) => {
    const tableRef = useRef()
    const endElementRef = useRef(null)
    useElementReachedBottomListner({
        hasMore, loadMore, element: endElementRef.current
    })

    return (
        <div className={styles.wrapper}>
            <AntdTable
                columns={columns}
                dataSource={data}
                ref={tableRef}
                rowKey="id"
                pagination={false}
                onChange={(...[, , { field, order }]) => {
                    onChange({
                        sortBy: Array.isArray(field) ? field.join('.') : field,
                        sortType: sortOrders[order] ?? undefined,
                    })
                }}
                // style={{ cursor: 'pointer' }}
                loading={isLoading}
                onRow={(record, rowIndex) => ({
                    onClick: event => {
                        if (event.target.querySelector('input[type="checkbox"]')) {
                            return
                        }
                        event.preventDefault()
                        onRowClick(rowIndex, record)
                    }
                })}
                scroll={{ x: 'max-content' }}
                // sticky
                // scroll={{y: 500}}
                style={{ overflow: 'visible' }}
                className='table--fullwidth'
                {...otherProps}
            />
            <div ref={endElementRef} className={styles.scrollToEnd}>
                {hasMore ? <LoadingOutlined /> : 'No more items'}
            </div>
        </div>
    )
}

Table.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    loadMore: PropTypes.func.isRequired,
    hasMore: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoading: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onRowClick: PropTypes.func,
    scroll: PropTypes.object
}

export default Table
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { searchCustomers } from '../../api/customer/customer-list.js';

function CompanySelect({ value = null, onChange, currentSelection = {} ,disabled}) {
    const debounceTimeout = 800
    const selectedOption = currentSelection?.value && currentSelection.value === value ? [{ value: currentSelection?.value, label: currentSelection?.label }] : []
    const [fetching, setFetching] = useState(selectedOption);
    const [options, setOptions] = useState();
    const fetchRef = useRef(0);

    useEffect(() => {
        debounceFetcher()
    }, [])

    const debounceFetcher = useMemo(() => {
        const loadOptions = (query) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions(selectedOption);
            setFetching(true);
            searchCustomers(query).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                setOptions([...selectedOption, ...newOptions]);
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [value, currentSelection, searchCustomers, debounceTimeout]);

    return (
        <Select
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            options={options}
            onChange={onChange}
            value={value}
            placeholder="Select Company"
            showSearch
            allowClear
            disabled={disabled}
        />
    );
}

CompanySelect.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    currentSelection: PropTypes.object,
    disabled: PropTypes.bool
    // currentSelection: PropTypes.shape({
    //     id: PropTypes.number.isRequired,
    //     company: PropTypes.string.isRequired
    // })
}

export default CompanySelect;
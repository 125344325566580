import React from 'react'
import PropTypes from 'prop-types'
import { Page } from '../../../components/page';
import { useLocation,useNavigate } from 'react-router-dom'
//import { sendCombiRequest } from '../../../api/combi';
import styles from './combi-details.module.scss'
import {Button,message } from 'antd';
import { sendCombiRequest } from '../../../api/combi';


const Review = (props) => {
    const { state } = useLocation()
    const navigate = useNavigate()
    const payload = {
        "item_ids" :state?.item_ids ,
        "vendor_ids": state?.vendor_ids,
        "notes":state?.form?.notes,
        "deadline_delivery_date":state?.form?.deadline_delivery_date
}
    const handleSubmitReuqest = async () => {
        try {
            const { message: msg } = await sendCombiRequest(payload)
            message.success(msg)
            navigate(-2)
        }
        catch (e) {
            console.log(e);
        }
    }

    // console.log(location);
    return (
        <Page title={props.title} backLink={false}>
            <div className={styles.reviewWrapper}>
               <div className={styles.row1}>
               <h3>{state?.combiDetails?.product_name}</h3>
                <p className={styles.value}><b className={styles.key}>Combi Project id:</b> CMBI_{state?.combiID}</p>
               </div>
                <div className={styles.innerReviewWrapper}>

                    <div className={styles.row1}>
                        <p className={styles.value}><b className={styles.key}>Quantity:   </b>{state?.combiDetails?.total_quantity}</p>
                        <p className={styles.value}><b className={styles.key}>Requested Date :  </b> {state.form?.createdAt}</p>
                        <p className={styles.value}><b className={styles.key}>Deliery Deadline Date :  </b> {state?.form?.deadline_delivery_date}</p>
                    </div>
                    <div className={styles.row2}> 
                    <p><b className={styles.key}>Selected Vendor : </b></p>
                   <div>
                   {state?.vendor_names.map((v, index) => {
                        return (
                            <p className={styles.value} key={index}>{index+1}  . {v}</p>
                        )
                    })}
                   </div>
                    </div>
                    <p className={styles.value}><b className={styles.key}>Notes :   </b>{state?.form?.notes}</p>
                </div>


            </div>
            <br />
            <Button className={styles.review} type='primary' ghost onClick={()=>{handleSubmitReuqest()}} >Send Request</Button>

        </Page>
    )
}

Review.propTypes = {
    title: PropTypes.string
}

export default Review
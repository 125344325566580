import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';

const Hubspot = ({ style }) => {
    const styles = { width: '20px', height: '20px', ...style }
    return <img src='/icons/hubspot.svg' style={styles} />
}
Hubspot.propTypes = {
    style: PropTypes.object
}
export const HubspotIcon = ({ style = {}, ...props }) => (
    <Icon component={Hubspot} style={style} {...props} />
)
HubspotIcon.propTypes = {
    style: PropTypes.object
}

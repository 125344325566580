import { Alert, Button } from 'antd';
import React from "react";
import { useRouteLoaderData, Link } from 'react-router-dom';
import { generateRouteUrl } from '../../../library/constants';
import style from './proof-ready.module.scss';

const ProofReady = () => {
    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')

    return (
        <div className={style.container}>
            <h3>Task for you</h3>
            <Alert message="Proof is ready, please send it to customer." type="info"
                action={
                    <Link to={generateRouteUrl('SendReadyProof', {
                        itemId: orderDetails.id,
                    })}>
                 
                        <Button className={style.greenButton}>View & Send Proof</Button>
                    </Link>

                } />
        </div>
    )
}

export default ProofReady ;
import { SearchOutlined, UserAddOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Outlet, useLoaderData, useNavigate } from 'react-router-dom';
import { getUsers } from '../../api/user';
import { Page } from '../../components/page';
import { useFetch } from '../../hooks';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { UserColumns } from '../../library/constants/tableColumns';
import { objectToSearchParams, searchParamsToObject } from '../../library/helpers/index.js';

function Users(props) {
  const { title } = props;
  const initialData = useLoaderData()
  const navigate = useNavigate()

  const [list, hasMore, isLoading, searchParamsParsed, {
    setSort,
    setFilters,
    loadMore
  }] = useFetch({ initialData })
  return (
    <Page className='users' title={title} header={(
      <>
        <div></div>
        <Space.Compact style={{ width: '100%', maxWidth: '400px' }}>
          <Input.Search placeholder="Search" defaultValue={searchParamsParsed?.filter?.search ?? ''} prefix={<SearchOutlined />} onChange={(e) => setFilters({ 'search': e.target.value })} allowClear />
        </Space.Compact>
        <Button type="primary" onClick={() => { navigate(generateRouteUrl('UserCreate')) }} icon={<UserAddOutlined />}>Add new User</Button>
      </>
    )} >

      <Table
        columns={UserColumns}
        dataSource={list}
        rowKey="id"
        onRow={user => ({
          onClick: () => {
            navigate(`${generateRouteUrl('UserDetails', { userId: user.id })}?${objectToSearchParams(searchParamsParsed)}`);
          }
        })}
        isLoading={isLoading}
        onChange={setSort}
        hasMore={hasMore}
        loadMore={loadMore}
        sticky
      />
      <Outlet />
    </Page>
  )
}

Users.Loader = async ({ request }) => {
  const url = new URL(request.url)
  const { filter, page, sort } = searchParamsToObject(url.searchParams)
  const { data, meta } = await getUsers({ filter, page, ...sort })
  return { data, meta }
}

Users.propTypes = {
  title: PropTypes.string,
}

export default Users

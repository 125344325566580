import { FilterOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, message, Space } from 'antd';
import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { generatePath, useFetcher, useLoaderData, useNavigate } from 'react-router-dom';
import { createBulkProjectsInvoices, getFinanceProjects } from '../../api/finance';
import { SelectedFilters } from '../../components/data/selected-filters.jsx';
import ProjectFilterForm from '../../components/form/financeProjectFilter/project-filter.jsx';
import { Page } from '../../components/page';
import { Table } from '../../components/table';
import { useFetch } from '../../hooks';
import { useUpdateQueryStringValueWithoutNavigation } from '../../hooks/use-update-query-string-without-navigation.js';
import { ValidationError } from '../../library/classes/exceptions';
import { FinanceProjectColumns } from '../../library/constants';
import { searchParamsToObject } from '../../library/helpers/index.js';


const FILTER_NAMES = {
  customer_id: "Customer",
  account_manager_id: "Account Manager",
  country_id: "Country",
  language_id: "Language",
}

function FinanceProjects({ title }) {
  const initialData = useLoaderData()
  const navigate = useNavigate()
  const [filtersVisible, setFiltersVisible] = useState(false)
  const fetcher = useFetcher()
  const [selectedRowsString, setSelectedRows] = useUpdateQueryStringValueWithoutNavigation('selectedRows')
  const selectedRows = !selectedRowsString ? [] : selectedRowsString.split(',').map(Number)
  //const [filtersVisible, setFiltersVisible] = useState(false)
  const [list, hasMore, isLoading, searchParamsParsed, {
    setSort,
    setFilters,
    loadMore
  }] = useFetch({ initialData })

  const handleRowClick = (_, record) => {
    navigate(generatePath('/finance/projects/:id', {
      id: record.id
    }))
  }

  const debouncedChangeHandler = debounce(q => setFilters({ search: q }), 500)

  return (
    <Page className='FinanceProjects' backLink={false} title={title} summary={initialData?.meta?.totalCount ? `${initialData?.meta?.totalCount} Records` : 'No Records'} header={
      <>
        <div> </div>
        <Space.Compact style={{ width: '100%', maxWidth: '400px' }}>
          <Input.Search placeholder="Search" onChange={(e) => debouncedChangeHandler(e.target.value)} defaultValue={searchParamsParsed?.filter?.search ?? ''} allowClear /> <Button onClick={() => setFiltersVisible(v => !v)} icon={<FilterOutlined />}>Filters</Button>
        </Space.Compact>


        <div>
          {selectedRows.length > 0 && (
            <Button onClick={() => {
              fetcher.submit({
                project_ids: selectedRows,
                action: "bulkInvoiceGenerate"
              }, { method: "post", encType: 'application/json' })
            }}>Create Invoice (Multiple)</Button>
          )}
        </div>
      </>
    } footer={(
      <>

      </>
    )} subHeader={initialData.meta?.filters && <SelectedFilters filters={initialData.meta.filters} filterNames={FILTER_NAMES} updateFilters={setFilters} />}>
      <Drawer open={filtersVisible} onClose={() => setFiltersVisible(false)} >
        <ProjectFilterForm key={JSON.stringify(searchParamsParsed.filter)} data={searchParamsParsed.filter} onFinish={setFilters} />
      </Drawer>
      {!!list && (
        <>
          <Table
            columns={FinanceProjectColumns}
            data={list}
            isLoading={isLoading}
            onChange={setSort}
            onRowClick={handleRowClick}
            hasMore={hasMore}
            loadMore={loadMore}
            rowSelection={{
              type: "checkbox",
              columnWidth: 50,
              fixed: true,
              selectedRowKeys: selectedRows,
              onChange: (selectedRowKeys) => {
                setSelectedRows(selectedRowKeys.join(','))
              },
            }}
          />
        </>
      )}
    </Page>
  )
}

FinanceProjects.propTypes = {
  title: PropTypes.string,
}

FinanceProjects.Actions = {
  bulkInvoiceGenerate: async ({ data }) => {
    const { project_ids = [] } = data
    try {
      if (!project_ids || project_ids.length === 0) {
        throw new ValidationError("Invalid Project Ids", {
          "project_ids": "Please select atleast one project"
        })
      }
      const msg = await createBulkProjectsInvoices(project_ids)
      message.success(msg)
      return true
    }
    catch (error) {
      message.error(error.message)
      return false
    }
  }
}

FinanceProjects.Loader = async ({ request }) => {
  const url = new URL(request.url)
  const { filter, page, sort } = searchParamsToObject(url.searchParams)
  const { data, meta } = await getFinanceProjects({ filter, page, ...sort })
  return { data, meta }
}

export default FinanceProjects

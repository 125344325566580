import {
	// MailOutlined,
	DeleteOutlined,
	FilePdfOutlined,
	FileSearchOutlined,
	SendOutlined,
	UserAddOutlined
} from '@ant-design/icons';
import { Button, Checkbox, Collapse, DatePicker, Drawer, Input, Space, Statistic, Table, message } from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Await, Link, Outlet, defer, matchPath, redirect, replace, useFetcher, useLoaderData, useLocation } from 'react-router-dom';
import { addInvoiceLine, checkSingleInvoicePayments, createCreditNote, deleteInvoice, deleteInvoiceLine, getInvoiceDetails, getInvoicePreviewURL, getInvoiceReminders, getPaymentLinks, invoiceAuditTrail, rescheduleInvoiceReminder, sendInvoiceReminder, sendInvoiceToClient, sendInvoiceToTwinfield, sendToEuler, setInvoicePaidOnline, unsetInvoicePaidOnline, updateInvoiceDetails, updateInvoiceLine, updateInvoicePaymentNotes } from '../../api/finance';
import { InvoiceBasicDetails, InvoiceLines } from '../../components/data';
import { InvoiceReminder } from '../../components/data/invoice/invoice-reminder.jsx';
import QuotationsDownload from '../../components/data/invoice/quotations-download-button.js';
import { ActionCenter } from '../../components/design/action-center.jsx';
import { Box } from '../../components/design/box.js';
import { GeneralFieldWrapper } from '../../components/inputs/input-wrappers.jsx';
import Confirm from '../../components/message/Confirm.jsx';
import { Page } from '../../components/page';
import { useUpdateQueryStringValueWithoutNavigation } from '../../hooks/use-update-query-string-without-navigation.js';
import { ValidationError } from '../../library/classes/exceptions/index.js';
import { InvoiceDetailsAuditTrailColumns, InvoiceDetailsPaymentLinksColumns, formats, getFilePath, getInvoiceDetailsPaymentsColumns } from '../../library/constants';
import { ERP_CURRENCY } from '../../library/constants/dynamic.js';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { InvoiceStatuses, ReminderTypes, findInvoiceStatusById } from '../../library/helpers';
import { textToClassName } from '../../library/utilities/common.js';
import { formatPrice } from '../../library/utilities/intl.js';
import { PaymentLinkDetails } from './payment-link.jsx';
const { Panel } = Collapse
dayjs.locale('nl')

const InvoiceDetails = ({ title }) => {
	const { invoiceId, invoiceLines, invoiceDetails, paymentLinks, auditTrail, invoiceReminders } = useLoaderData()
	const [showInvoicePreview, setShowInvoicePreview] = useUpdateQueryStringValueWithoutNavigation('invoicepreview')
	const [selectedPaymentLink, setSelectedPaymentLink] = useState(null)
	const fetcher = useFetcher()
	const location = useLocation()
	const { editableSection = null, ['*']: sectionId } = matchPath('/finance/invoices/:invoiceId/edit/:editableSection/*', location.pathname)?.params ?? {}

	const invoicePreviewURL = useMemo(() => {
		return getInvoicePreviewURL(invoiceId)
	}, [invoiceId, invoiceDetails])
	const invoiceLineId = Number(sectionId) ? Number(sectionId) : null
	const invoiceTypeName = invoiceDetails.credit_note ? 'Credit Note' : 'Invoice'

	const reminderOnHold = invoiceReminders?.hold_on_date && dayjs(invoiceReminders.hold_on_date) >= dayjs().startOf('day')

	const debounceWrapper = (callback) => {
		const debouncedCallback = debounce(callback, 500);

		return (args) => {
			debouncedCallback(args);
		};
	};

	const canSendReminder = () => {
		if (invoiceDetails.credit_note) {
			return false
		}
		if (invoiceDetails.isSentToEuler) {
			return false
		}
		if (invoiceDetails.isPaid) {
			return false
		}
		//Invoice should be sent 
		if (!invoiceDetails.isSentToClient) {
			return false
		}
		//Reminders should not be on hold
		if (reminderOnHold) {
			return false
		}
		//No reminder sent earlier
		if (!invoiceDetails.reminderStatus) {
			return true
		}
		//Check if all reminders are not sent already
		if ([ReminderTypes.FIRST, ReminderTypes.SECOND, ReminderTypes.THIRD].includes(invoiceDetails.reminderStatus)) {
			return true
		}
	}

	const canSendEuler = () => {
		if (invoiceDetails.credit_note) {
			return false
		}
		if (invoiceDetails.isSentToEuler) {
			return false
		}
		if (invoiceDetails.isPaid) {
			return false
		}
		//Invoice should be sent 
		if (!invoiceDetails.isSentToClient) {
			return false
		}
		return true
	}

	return (
		<Page className='invoice-create' title={title} >
			<div className='headerStats'>
				<Link to={generateRouteUrl('FinanceProjectDetails', {
					projectId: invoiceDetails.project_id
				})}>
					<Statistic
						title="Project ID"
						value={invoiceDetails.project_id}
						formatter={v => v}
					/>
				</Link>
				<Statistic
					title={`${invoiceTypeName} No.`}
					value={invoiceDetails.invoice_number}
				/>
				{!!invoiceDetails.customer.bad_payment_history && (
					<Statistic
						title="Bad Payment History"
						value="Yes"
						valueStyle={{ backgroundColor: 'red', color: 'white' }}
					/>
				)}
				<Statistic
					title={`${invoiceTypeName} Status`}
					className={`status--${textToClassName(invoiceDetails.status?.name)}`}
					value={invoiceDetails.status?.name}
				/>
				<Link to={generateRouteUrl('CustomerDetails', {
					id: invoiceDetails.customer_id
				})}>
					<Statistic
						title="Company Name"
						value={invoiceDetails.customer.company}
					/>
				</Link>
				<Statistic
					title="Payment Condition"
					value={invoiceDetails.payment_condition?.name}
				/>
				<Statistic
					title={`${invoiceTypeName} Total`}
					value={formatPrice(invoiceDetails.total_amount, invoiceDetails.currency.code)}
				/>
				<Statistic
					title="Project Invoiced %"
					suffix='%'
					precision={2}
					value={parseFloat(invoiceDetails.invoiced_percentage ?? 0)}
				/>
				<Statistic
					title="Amount Paid"
					value={formatPrice(invoiceDetails.amount_paid, invoiceDetails.currency.code)}
				/>
				<Statistic
					title="Acc. Manager"
					value={invoiceDetails.sales_manager?.name}
				/>
				{invoiceDetails.isSentToEuler && invoiceDetails.euler?.id && (
					<Link to={generateRouteUrl('EulerCaseDetails', {
						id: invoiceDetails.euler.id
					})}>
						<Statistic
							title="Euler Case ID"
							value={invoiceDetails.euler.id}
						/>
					</Link>
				)}
			</div>
			<ActionCenter actions={[
				{
					action: (
						<Confirm
							icon={<SendOutlined />}
							type="primary"
							content={`This will send the ${invoiceTypeName} to client. Continue ?`}
							okText='Send'
							onConfirm={() => {
								fetcher.submit({
									action: 'sendToClient'
								}, {
									method: 'post',
									encType: 'application/json'
								})
							}}
							loading={fetcher.json?.action === 'sendToClient'}
							ghost
						>
							{invoiceDetails.isSentToClient ? `Resend ${invoiceTypeName} To Client` : `Send ${invoiceTypeName} To Client`}
						</Confirm>
					),
					isVisible: (!invoiceDetails.isPaid && !invoiceDetails.isSentToEuler)
				},
				{
					action: (
						<Button
							icon={<SendOutlined />}
							type="primary"
							onClick={() => {
								fetcher.submit({
									action: 'createCreditNote'
								}, {
									method: 'post',
									encType: 'application/json'
								})
							}}
							loading={fetcher.json?.action === 'createCreditNote'}
							ghost
						>
							Create Credit Note
						</Button>
					),
					isVisible: (!invoiceDetails.credit_note && invoiceDetails.isSentToClient)
				},
				{
					action: (
						<Button
							icon={<FileSearchOutlined />}
							type="primary"
							htmlType="submit"
							onClick={() => setShowInvoicePreview(true)}
							ghost
						>Preview {invoiceTypeName}</Button>
					),
					isVisible: !invoiceDetails.isPaid
				},
				{
					action: (
						<Link to={generateRouteUrl('FinanceInvoiceDetailsCreatePaylink', {
							invoiceId
						})
						} replace>
							<Button
								icon={<UserAddOutlined />}
								type="primary" ghost>Create Paylink</Button>
						</Link>
					),
					isVisible: !invoiceDetails.credit_note && invoiceDetails.isSentToClient && !invoiceDetails.isPaid && !invoiceDetails.isSentToEuler
				},
				{
					action: (
						<Link to={
							generateRouteUrl('ManualPayment', {
								invoiceId
							})
						} replace>
							<Button
								icon={<UserAddOutlined />}
								type="primary" ghost>Offline Payment</Button>
						</Link>
					),
					isVisible: !invoiceDetails.credit_note && invoiceDetails.isSentToClient && !invoiceDetails.isSentToEuler
				},
				{
					action: (
						<fetcher.Form method="post">
							<Button
								name="action"
								value="sendReminder"
								type="primary"
								htmlType="submit"
								loading={fetcher.formData?.get('action') === 'sendReminder'}
								ghost
							>
								{
									invoiceDetails.reminderStatus === ReminderTypes.THIRD ? 'Send final Reminder' :
										invoiceDetails.reminderStatus === ReminderTypes.SECOND ? 'Send 3rd Reminder' :
											invoiceDetails.reminderStatus === ReminderTypes.FIRST ? 'Send 2nd Reminder' : 'Send 1st Reminder'
								}
							</Button>
						</fetcher.Form>
					),
					isVisible: canSendReminder()
				},
				{
					action: (
						<Confirm
							content={!invoiceDetails.status.id < ReminderTypes.FIRST ? 'Reminders are not yet sent. Do you want to send invoice to Euler directly ?' : 'Send invoice to Euler ?'}
							onConfirm={() => {
								fetcher.submit({
									action: 'sendEuler',

								}, {
									method: "post",
									encType: 'application/json'
								})
							}}
							type="primary"
							loading={fetcher.json?.action === 'sendEuler'}
							ghost
						>Send to Euler</Confirm>
					),
					isVisible: (canSendEuler() && invoiceDetails.reminderStatus === ReminderTypes.FINAL)
				},
				{
					action: (
						<Confirm
							icon={<DeleteOutlined />}
							type="primary"
							content={`Are you sure you want to remove ths ${invoiceTypeName} ?`}
							onConfirm={() => {
								fetcher.submit({
									action: 'deleteInvoice'
								}, {
									method: 'post',
									encType: 'application/json'
								})
							}}
							loading={fetcher.json?.action === 'deleteInvoice'}
							danger
						>
							Remove {invoiceTypeName}
						</Confirm>
					),
					isVisible: invoiceDetails.status?.id === InvoiceStatuses.NEW
				},
				{
					action: (
						<Button
							// icon={<SendOutlined />}
							type="primary"
							onClick={() => {
								fetcher.submit({
									action: 'checkNewPayments'
								}, {
									method: 'post',
									encType: 'application/json'
								})
							}}
							loading={fetcher.json?.action === 'checkNewPayments'}
							ghost
						>
							Check for new Payments
						</Button>
					),
					isVisible: (!invoiceDetails.credit_note && invoiceDetails.isSentToClient)
				},
				{
					action: (
						<Button
							// icon={<SendOutlined />}
							type="primary"
							onClick={() => {
								fetcher.submit({
									action: 'sendInvoiceToTwinfield'
								}, {
									method: 'post',
									encType: 'application/json'
								})
							}}
							loading={fetcher.json?.action === 'sendInvoiceToTwinfield'}
							ghost
						>
							Send to Twinfield
						</Button>
					),
					isVisible: !!(invoiceDetails.isSentToClient && invoiceDetails.twinfield_id)
				},
			]} />
			<Collapse defaultActiveKey={['1']} expandIconPosition="end">
				<Panel header='Payment Info' key="1">
					<Input.TextArea onChange={debounceWrapper(({ target }) => {
						fetcher.submit({
							payment_notes: target.value,
							action: "updatePaymentNotes"
						}, {
							method: "post"
						})
					})} defaultValue={invoiceDetails.payment_notes} />
				</Panel>
			</Collapse>
			<InvoiceBasicDetails invoiceId={invoiceId} data={invoiceDetails} />
			<InvoiceLines
				invoiceId={invoiceId}
				invoiceLines={invoiceLines}
				editableInvoiceLineId={invoiceLineId}
				currency={invoiceDetails.currency}
				invoiceStatus={invoiceDetails.status}
				entity={invoiceDetails.entity}
				totals={{
					total_amount_converted: invoiceDetails.total_amount_converted,
					total_amount_excl: invoiceDetails.total_amount_excl,
					total_amount_excl_converted: invoiceDetails.total_amount_excl_converted,
					total_amount: invoiceDetails.total_amount
				}}
				isCreditNote={!!invoiceDetails.credit_note}
			/>

			{editableSection === 'addinvoiceline' && <Outlet context={{ currency: invoiceDetails.currency.symbol, entity: invoiceDetails.entity, invoiceId }} />}
			{!invoiceDetails.credit_note && invoiceDetails.isSentToClient && !invoiceDetails.isSentToEuler && !invoiceDetails.isPaid && (
				<Collapse defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header='Invoice' key="1">
						<Box type={Box.BoxTypes.GRAY}>
							<div className="form-fields-wrapper">
								<GeneralFieldWrapper label="Paid Online" labelStyle={{ fontWeight: 'bold' }} horizontal>
									<Checkbox key={invoiceDetails.paid_online} defaultChecked={invoiceDetails.paid_online} onChange={(e) => {
										fetcher.submit({
											action: 'changePaidOnline'
										}, {
											method: e.target.checked ? 'post' : 'delete'
										})
									}} />
								</GeneralFieldWrapper>
								<GeneralFieldWrapper label="1st Reminder" labelStyle={{ fontWeight: 'bold' }} horizontal>
									<InvoiceReminder
										status={invoiceReminders?.first}
										sentAt={invoiceReminders?.first_reminder_createdAt}
										scheduledAt={invoiceReminders?.schedule_first}
										onHold={reminderOnHold}
										onScheduleChange={date => {
											fetcher.submit({
												action: 'reminderReschedule',
												reminderType: ReminderTypes.FIRST,
												date,
											}, {
												method: "post",
											})
										}} />
								</GeneralFieldWrapper>
								<GeneralFieldWrapper label="2nd Reminder" labelStyle={{ fontWeight: 'bold' }} horizontal>
									<InvoiceReminder
										status={invoiceReminders?.second}
										sentAt={invoiceReminders?.second_reminder_createdAt}
										scheduledAt={invoiceReminders?.schedule_second}
										minDate={invoiceReminders?.schedule_first}
										onHold={reminderOnHold}
										onScheduleChange={date => {
											fetcher.submit({
												action: 'reminderReschedule',
												reminderType: ReminderTypes.SECOND,
												date,
											}, {
												method: "post",
											})
										}} />
								</GeneralFieldWrapper>
								<GeneralFieldWrapper label="3rd Reminder" labelStyle={{ fontWeight: 'bold' }} horizontal>
									<InvoiceReminder
										status={invoiceReminders?.third}
										sentAt={invoiceReminders?.third_reminder_createdAt}
										scheduledAt={invoiceReminders?.schedule_third}
										minDate={invoiceReminders?.schedule_second}
										onHold={reminderOnHold}
										onScheduleChange={date => {
											fetcher.submit({
												action: 'reminderReschedule',
												reminderType: ReminderTypes.THIRD,
												date,
											}, {
												method: "post",
											})
										}} />
								</GeneralFieldWrapper>
								<GeneralFieldWrapper label="Final Reminder" labelStyle={{ fontWeight: 'bold' }} horizontal>
									<InvoiceReminder
										status={invoiceReminders?.fourth}
										sentAt={invoiceReminders?.fourth_reminder_createdAt}
										scheduledAt={invoiceReminders?.schedule_fourth}
										minDate={invoiceReminders?.schedule_third}
										onHold={reminderOnHold}
										onScheduleChange={date => {
											fetcher.submit({
												action: 'reminderReschedule',
												reminderType: ReminderTypes.FINAL,
												date,
											}, {
												method: "post",
											})
										}} />
								</GeneralFieldWrapper>
								<GeneralFieldWrapper label="Reminder on hold till" labelStyle={{ fontWeight: 'bold' }} horizontal>
									<DatePicker
										defaultValue={invoiceReminders?.hold_on_date ? dayjs(invoiceReminders?.hold_on_date, formats.backendDateFormat) : null}
										format={formats.frontendDateFormat}
										onChange={(val) => {
											fetcher.submit({
												action: 'reminderReschedule',
												reminderType: 'hold_on_date',
												date: val ? val.format(formats.backendDateFormat) : '',
											}, {
												method: "post",
											})
										}}
										disabledDate={(current) => {
											return current && current <= dayjs().startOf('day')
										}}
										disabled={invoiceDetails.reminderStatus === ReminderTypes.FINAL}
									/>
								</GeneralFieldWrapper>
								{canSendEuler() && (
									<GeneralFieldWrapper label="Send to Euler" labelStyle={{ fontWeight: 'bold' }} horizontal>
										<Confirm
											content={!invoiceDetails.status.id < ReminderTypes.FIRST ? 'Reminders are not yet sent. Do you want to send invoice to Euler directly ?' : 'Send invoice to Euler ?'}
											onConfirm={() => {
												fetcher.submit({
													action: 'sendEuler',

												}, {
													method: "post",
													encType: 'application/json'
												})
											}}
											type="primary"
											loading={fetcher.json?.action === 'sendEuler'}
										>Send</Confirm>
									</GeneralFieldWrapper>
								)}
							</div>
						</Box>
					</Panel>
				</Collapse>
			)}
			<Collapse defaultActiveKey={['1']} expandIconPosition="end">
				<Panel header='Documents' key="1">
					<Space size="large">
						{!!invoiceDetails?.client_quotations && <QuotationsDownload quotations={invoiceDetails.client_quotations} projectID={invoiceDetails.project_id} />}
						{!!invoiceDetails.file && (
							<Button
								href={getFilePath(invoiceDetails.file, invoiceDetails.project_id)}
								icon={<FilePdfOutlined />}
								type="primary"
								target="_blank"
								download="invoice.pdf"
							>Invoice</Button>
						)}
					</Space>
				</Panel>
			</Collapse>
			{!invoiceDetails.credit_note && (
				<>
					<Collapse defaultActiveKey={['1']} expandIconPosition="end">
						<Panel header='Payment History' key="1">
							<Table
								columns={getInvoiceDetailsPaymentsColumns(invoiceDetails.currency.code)}
								dataSource={invoiceDetails.invoice_payments}
								rowKey="createdAt"
								pagination={false}
							/>
						</Panel>
					</Collapse>
					<React.Suspense>
						<Await
							resolve={paymentLinks}
							errorElement={
								<p>Error loading payment links!</p>
							}
						>
							{(paymentLinks) => (
								<>
									<Collapse defaultActiveKey={['1']} expandIconPosition="end">
										<Panel header='Payment Links' key="1">
											<Table
												columns={InvoiceDetailsPaymentLinksColumns}
												dataSource={paymentLinks}
												rowKey="id"
												onRow={(record) => {
													return {
														onClick: (event) => {
															if (event.target.closest('a')) {
																return
															}
															setSelectedPaymentLink(record.id)
														},
													}
												}}
												pagination={false}
											/>
										</Panel>
									</Collapse>
									{(selectedPaymentLink && paymentLinks?.find(({ id }) => id === selectedPaymentLink)) && <PaymentLinkDetails data={paymentLinks.find(({ id }) => id === selectedPaymentLink)} onClose={() => setSelectedPaymentLink(null)} />}
								</>
							)}
						</Await>
					</React.Suspense>
				</>
			)}
			<React.Suspense>
				<Await
					resolve={auditTrail}
					errorElement={
						<p>Error loading audit trail!</p>
					}
				>
					{(logs) => (
						<Collapse defaultActiveKey={['1']} expandIconPosition="end">
							<Panel header='Audit Trail' key="1">
								<Table
									columns={InvoiceDetailsAuditTrailColumns}
									dataSource={logs}
									rowKey="id"
									pagination={{ hideOnSinglePage: true }}
								/>
							</Panel>
						</Collapse>
					)}
				</Await>
			</React.Suspense>
			<Drawer
				open={showInvoicePreview}
				width="70%"
				onClose={() => setShowInvoicePreview(false)}
				title="Invoice Preview"
				destroyOnClose
				// getContainer='#sidebar'
				mask={false}
				// rootStyle={{ position: 'absolute' }}
				extra={(
					<fetcher.Form method="post" action={generateRouteUrl('InvoiceDetails', { invoiceId })} replace>
						<Button
							icon={<SendOutlined />}
							type="primary"
							htmlType="submit"
							name="action"
							value="sendToClient"
							loading={fetcher.formData?.get('action') === 'sendToClient'}
							ghost
						>{invoiceDetails.isSentToClient ? 'Resend' : 'Send'} to Client</Button>
					</fetcher.Form>
				)}
			>
				<object data={invoicePreviewURL} type="application/pdf" width="100%" height="100%">
					<p>Unable to load file.Click on the link below to view the file</p>
					<Link to={invoicePreviewURL}>View Invoice</Link>
				</object>
			</Drawer>
			<Outlet />
		</Page >
	)
}

InvoiceDetails.propTypes = {
	title: PropTypes.string
}

InvoiceDetails.Actions = {
	sendToClient: async ({ params }) => {
		const { invoiceId } = params
		try {
			const msg = await sendInvoiceToClient(invoiceId)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	updatePaymentNotes: async ({ params, data }) => {
		const { invoiceId } = params
		try {
			const { payment_notes = "" } = data
			await updateInvoicePaymentNotes(invoiceId, payment_notes)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	checkNewPayments: async ({ params }) => {
		const { invoiceId } = params
		try {
			await checkSingleInvoicePayments(invoiceId)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	sendInvoiceToTwinfield: async ({ params }) => {
		const { invoiceId } = params
		try {
			const msg = await sendInvoiceToTwinfield(invoiceId)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	reminderReschedule: async ({ params, data: formData }) => {
		const { invoiceId } = params
		const { reminderType, date } = formData
		let payload = {}
		if (Object.values(ReminderTypes).includes(reminderType)) {
			payload[`schedule_${reminderType}`] = date
		}
		else if (reminderType === 'hold_on_date') {
			payload[reminderType] = date
		}
		else {
			message.error('Invalid reminder type')
			return null
		}

		try {
			const { message: msg } = await rescheduleInvoiceReminder(invoiceId, payload)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	sendReminder: async ({ params }) => {
		const { invoiceId } = params
		try {
			const { message: msg } = await sendInvoiceReminder(invoiceId)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	sendEuler: async ({ params }) => {
		const { invoiceId } = params
		try {
			const msg = await sendToEuler(invoiceId)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	changePaidOnline: async ({ params, method }) => {
		const { invoiceId } = params
		try {
			await (method === 'DELETE' ? unsetInvoicePaidOnline(invoiceId) : setInvoicePaidOnline(invoiceId))
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	deleteInvoiceLineItem: async ({ data }) => {
		const { lineId } = data
		try {
			const msg = await deleteInvoiceLine(lineId)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return null
	},
	updateBasicDetails: async ({ params, data }) => {
		try {
			const msg = await updateInvoiceDetails(params.invoiceId, data)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
			if (error instanceof ValidationError) {
				return {
					errors: error.errors
				}
			}
		}
		return false
	},
	updateInvoiceLine: async ({ params, data }) => {
		const { invoiceId } = params
		const { invoiceLineId, ...payload } = data
		try {
			await updateInvoiceLine(invoiceId, invoiceLineId, payload)
			message.success('Invoice Line updated successfully')
			return true
		}
		catch (error) {
			message.error(error.message)
			if (error instanceof ValidationError) {
				// Handle the specific exception type
				return {
					errors: error.errors
				}
			}
		}
		return false
	},
	addInvoiceLine: async ({ params, data }) => {
		try {
			await addInvoiceLine(params.invoiceId, data)
			message.success('Invoice Line Added')
			return replace('./')
		}
		catch (error) {
			message.error(error.message)
			if (error instanceof ValidationError) {
				// Handle the specific exception type
				return {
					errors: error.errors
				}
			}
		}
		return false
	},
	deleteInvoice: async ({ params }) => {
		const { invoiceId } = params
		try {
			const msg = await deleteInvoice(invoiceId)
			message.success(msg)
			return redirect(generateRouteUrl('Invoices'))
		}
		catch (error) {
			message.error(error.message)
		}
		return null
	},
	createCreditNote: async ({ params }) => {
		const { invoiceId } = params
		try {
			const { data: creditId, message: msg } = await createCreditNote(invoiceId)
			if (!creditId) {
				throw new Error('Unable to create credit not')
			}
			message.success(msg)
			return redirect(generateRouteUrl('InvoiceDetails', {
				invoiceId: creditId
			}))
		}
		catch (error) {
			message.error(error.message)
		}
		return null
	},
	// finalizeInvoice: async ({ params }) => {
	// 	const { invoiceId } = params
	// 	message.info(invoiceId)
	// 	// const result = await createProjectInvoice(invoiceId)
	// 	// message.error(result.message)
	// 	// if (result.status === true && result?.data?.invoiceId) {
	// 	// 	redirect(generateRouteUrl('InvoiceDetails', {
	// 	// 		invoiceId: result.data.invoiceId
	// 	// 	}))
	// 	// }
	// }
}

InvoiceDetails.Loader = async ({ params }) => {
	if (isNaN(params.invoiceId)) {
		throw new Error('Invalid Invoice ID')
	}
	const { id: invoiceId, invoice_lines, ...invoiceDetails } = await getInvoiceDetails(params.invoiceId)
	if (!invoiceDetails) {
		throw new Error('Invalid Invoice')
	}

	const isErpCurrency = invoiceDetails.currency.code === ERP_CURRENCY

	const invoiceLines = invoice_lines.map(line => ({
		...line,
		unit_price: isErpCurrency ? line.unit_price : line.unit_price_converted?.[invoiceDetails.currency.code],
		unit_price_erp: !isErpCurrency ? line.unit_price : null,
		total_price_excl_vat: isErpCurrency ? line.total_price_excl_vat : line.total_price_excl_vat_converted?.[invoiceDetails.currency.code],
		total_price_excl_vat_erp: !isErpCurrency ? line.total_price_excl_vat : null,
		total_vat: isErpCurrency ? line.total_vat : line.total_vat_converted?.[invoiceDetails.currency.code],
		total_vat_erp: !isErpCurrency ? line.total_vat : null,
		total_price_incl_vat: isErpCurrency ? line.total_price_incl_vat : line.total_price_incl_vat_converted?.[invoiceDetails.currency.code],
		total_price_incl_vat_erp: !isErpCurrency ? line.total_price_incl_vat : null,
	}))

	invoiceDetails.total_amount = isErpCurrency ? invoiceDetails.total_amount : invoiceDetails.total_amount_converted?.[invoiceDetails.currency.code]
	invoiceDetails.total_amount_erp = !isErpCurrency ? invoiceDetails.total_amount : null
	invoiceDetails.amount_paid = (isErpCurrency ? invoiceDetails.amount_paid : invoiceDetails.amount_paid_converted?.[invoiceDetails.currency.code]) ?? 0
	invoiceDetails.total_amount_erp = !isErpCurrency ? invoiceDetails.total_amount : null
	invoiceDetails.amount_open = invoiceDetails.total_amount - invoiceDetails.amount_paid
	invoiceDetails.amount_open_erp = invoiceDetails.total_amount_erp ? invoiceDetails.total_amount_erp - invoiceDetails.amount_paid_erp : null

	invoiceDetails.isSentToClient = invoiceDetails.status >= 4
	invoiceDetails.isSentToEuler = invoiceDetails.status === 6
	invoiceDetails.isPaid = invoiceDetails.status === 7

	const invoiceStatus = invoiceDetails.status
	invoiceDetails.reminderStatus = ((status) => {
		switch (status) {
			case InvoiceStatuses.FIRST_REMINDER: return ReminderTypes.FIRST
			case InvoiceStatuses.SECOND_REMINDER: return ReminderTypes.SECOND
			case InvoiceStatuses.THIRD_REMINDER: return ReminderTypes.THIRD
			case InvoiceStatuses.FOURTH_REMINDER: return ReminderTypes.FINAL
			default: return null
		}
	})(invoiceStatus)

	invoiceDetails.status = {
		id: invoiceStatus,
		name: findInvoiceStatusById(invoiceStatus)
	}

	// const items = await Promise.all(invoiceDetails.items.map(async ({ id }) => {
	// 	return await getProjectItem(id)
	// }))

	const auditTrail = invoiceAuditTrail(params.invoiceId)
	const paymentLinks = getPaymentLinks(params.invoiceId)
	return defer({
		invoiceId,
		invoiceLines,
		invoiceDetails,
		paymentLinks,
		auditTrail,
		invoiceReminders: !invoiceDetails.credit_note ? (await getInvoiceReminders(params.invoiceId).catch(() => ({}))) : null,
	})
}

export default InvoiceDetails

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
// import { Link } from "react-router-dom";
import { LeftOutlined, RightOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Menu } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { Link, useLocation, useMatches, useRouteLoaderData } from 'react-router-dom';
import { Server } from '../../library/constants/server.js';
import { useApp } from '../../providers/app/app-context.js';
import './sidebar.scss';
import styles from './sider.module.scss';

const getMenuItems = (items, parent = '') => {
  if (!items || items.filter((item) => item.showOnMenu).length == 0) return undefined

  return items.filter((item) => item.showOnMenu).map((item) => {
    const { url, nolink = false, label, icon = undefined, children = null } = item
    const relativeUrl = url?.length ? url : parent
    return {
      label: nolink ? label : <Link to={relativeUrl} className='nav-text' title={label}>{label}</Link>,
      key: (!url && relativeUrl === parent) ? `${relativeUrl}?index` : relativeUrl,
      icon: icon,
      children: getMenuItems(children, relativeUrl),
      id: url + parent,
    }
  })
}

export default function Sidebar(props) {
  const { routes } = props;
  const { theme } = useApp()
  const location = useLocation();
  const { user } = useRouteLoaderData('Root')
  // const { user } = useAuth()
  const [collapsed, setCollapsed] = useState(false);
  const menuItems = useMemo(() => getMenuItems(routes), [routes])
  const matches = useMatches()
  const pathnames = (matches?.map(({ pathname }) => pathname) || []).filter(Boolean);
  const currentPathWithIndex = `${location?.pathname || ''}?index`;
  const [selectedKeys, setSelectedKeys] = useState([...pathnames, currentPathWithIndex])
  const [openKeys, setOpenKeys] = useState([...pathnames])

  useEffect(() => {
    setSelectedKeys([...pathnames, currentPathWithIndex])
    setOpenKeys([...pathnames])
  }, [location])

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  return (
    <Sider width={250} theme={theme} trigger={null} className={styles.sider} style={{ position: 'sticky', 'top': 0, zIndex: 9, height: '100vh' }} collapsible collapsed={collapsed}>
      {!collapsed ? <div className="logo-wraper"></div> : <div className="small-logo-wraper"></div>}
      <Button
        onClick={toggleCollapsed}
        style={{
          position: 'absolute',
          right: '0',
          top: '200px',
          transform: 'translateX(50%)',
          zIndex: '1',
        }}
        size={30}
        icon={collapsed ? <RightOutlined /> : < LeftOutlined />}
        shape='circle'
      />
      <div className="user-info">
        <div className="text">
          {user.profile == null ? <Avatar size={80} icon={<UserOutlined />} /> : <Avatar size={!collapsed ? 80 : 50} src={`${Server.BaseUrl}/api/v1/user/profile/${user.profile}`} />}
          <h4><b>{user.name}</b></h4>
          <p>{user.role}</p>
        </div>
      </div>

      <Menu
        // key={location.pathname}
        // defaultSelectedKeys={[...pathnames, ...pathnames.map(path => `${path}?index`), currentPathWithIndex]}
        selectedKeys={selectedKeys}
        defaultOpenKeys={openKeys}
        mode="inline"
        theme={theme}
        className={styles.menu}
        inlineCollapsed={collapsed}
        items={menuItems}
      />
      {/* <Menu theme={theme} mode="inline" items={getMenuItems(routes)} defaultSelectedKeys={[location]} /> */}


      <a href={process.env.REACT_APP_BUILD_LOGS} target='blank'> <p style={{
        fontSize: '11px',
        fontWeight: '400',
        lineHeight: "11px",
        letterSpacing: '0.03em',
        textAlign: 'center',
        color: '#8F8F8F',
        marginTop: '20px'
      }
      }>Version {process.env.REACT_APP_CURRENT_BUILD}</p></a>
    </Sider>
  )
}


Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
}

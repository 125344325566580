import { Alert, Button } from 'antd';
import React from "react";
import { Link, useRouteLoaderData } from 'react-router-dom';
import { generateRouteUrl } from '../../../library/constants';
import style from './request-received.module.scss';


const TemplateRecievedFromVendor = () => {
    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')

    return (
        <div className={style.container}>
            <h3>Task for you</h3>
            { <Alert message="Template has been receivied from vemdor, Please share it with customer" type="info"
                action={
                    <Link to={generateRouteUrl('UploadSampleTemplate', {
                        itemId: orderDetails.id,
                    })}>
                        <Button className={style.greenButton}>Send Template to Customer</Button>
                    </Link>

                } />}
        </div>
    )
}
export default TemplateRecievedFromVendor;
export const UserPermissions = {
  DASHBOARD: 'dashboard',
  CREATE_PROJECT: 'create_project',
  WAIVEOFREQUEST: 'waiveofrequest',
  PROJECT_LIST: 'project_list',
  CUSTOMER: 'customer',
  CUSTOMER_DETAIL: 'customer_detail',
  CUSTOMER_UPDATE: 'customer_update',
  CUSTOMER_CREATE: 'customer_create',
  CUSTOMER_DELETE: 'customer_delete',
  COMPANY: 'company',
  CONTACT: 'contact',
  CONTACT_DETAIL: 'contact_detail',
  CONTACT_UPDATE: 'contact_update',
  CONTACT_CREATE: 'contact_create',
  CONTACT_DELETE: 'contact_delete',
  STUDIO_LIST: 'studio_list',
  ORDER_MANAGER_LIST: 'order_manager_list',
  COMBI_DEALS: 'combi_deals',
  SENT_COMBI_DEALS: 'sent_combi_deals',
  ADD_VENDOR: 'add_vendor',
  VENDOR_LIST: 'vendor_list',
  VENDOR_DETAIL: 'vendor_detail',
  UPDATE_VENDOR: 'update_vendor',
  DELETE_VENDOR: 'delete_vendor',
  CREATE_VENDOR_USER: 'create_vendor_user',
  UPDATE_VENDOR_USER: 'update_vendor_user',
  DELETE_VENDOR_USER: 'delete_vendor_user',
  DETAIL_VENDOR_USER: 'detail_vendor_user',
  FINANCE_PROJECT: 'finance_project',
  FINANCE_INVOICE: 'finance_invoice',
  PURCHASE_BILLS: 'purchase_bills',
  EULER: 'euler',
  USER: 'user',
  SETTINGS: 'settings',
}
import { Input } from "antd";
import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import { getFieldErrors, getFieldStatus } from '../../library/helpers/forms';

const getClassName = (span, horizontal = false, required = false) => {
    const baseClassname = 'form-field'
    let classname = baseClassname
    if (horizontal) {
        classname = `${classname} ${baseClassname}--horizontal`
    }
    if (required) {
        classname = `${classname} ${baseClassname}--required`
    }
    if (span <= 1 || span > 4) {
        return classname
    }
    if (span === '0.5') {
        return `${classname} ${baseClassname}--span-half`
    }
    return `${classname} ${baseClassname}--span${span}`
}

const InputWrapper = ({ name, errorKey = name, defaultValue, value, label = null, delay = false, onChange, errors = {}, textarea = false, span = 1, horizontal = false, inputKey, containerStyle, ...otherProps }) => {
    const changeHandler = delay ? debounce(onChange, 1000) : onChange
    const InputComponent = textarea ? Input.TextArea : Input

    return (
        <FieldWrapper name={name} label={label} errors={errors} errorKey={errorKey} span={span} horizontal={horizontal} style={containerStyle}>
            <InputComponent name={name ?? undefined} defaultValue={defaultValue} value={!defaultValue ? value : undefined} onChange={onChange ? changeHandler : undefined} {...otherProps} status={getFieldStatus(errorKey, errors)} key={inputKey ?? defaultValue} />
        </FieldWrapper>
    )
}

InputWrapper.propTypes = {
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    delay: PropTypes.bool,
    containerStyle: PropTypes.object,
    onChange: PropTypes.func,
    errors: PropTypes.object,
    span: PropTypes.oneOf([0.5, 1, 2, 3, 4]),
    textarea: PropTypes.bool,
    inputKey: PropTypes.string,
    horizontal: PropTypes.bool,
    errorKey: PropTypes.string
}

const FieldWrapper = ({ name, errorKey = name, label = null, errors = null, span = 1, children, horizontal = false, showRequired = false, containerStyle, ...otherProps }) => {
    return (
        <div className={getClassName(span, horizontal, showRequired)} style={containerStyle} {...otherProps}>
            {!!label && <label>{label}</label>}
            {children}
            {!!errors && getFieldErrors(errorKey, errors)}
        </div>
    )
}

FieldWrapper.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    errors: PropTypes.object,
    span: PropTypes.oneOf([1, 2, 3, 4]),
    containerStyle: PropTypes.object,
    children: PropTypes.node.isRequired,
    horizontal: PropTypes.bool,
    showRequired: PropTypes.bool,
    errorKey: PropTypes.string
}

const GeneralFieldWrapper = ({ label = null, labelStyle = {}, span = 1, children, horizontal = false, ...otherProps }) => {
    return (
        <div className={getClassName(span, horizontal)} {...otherProps}>
            {!!label && <label style={labelStyle}>{label}</label>}
            {children}
        </div>
    )
}

GeneralFieldWrapper.propTypes = {
    label: PropTypes.string,
    span: PropTypes.oneOf([1, 2, 3, 4]),
    children: PropTypes.node,
    horizontal: PropTypes.bool,
    labelStyle: PropTypes.object,
}

export { FieldWrapper, GeneralFieldWrapper, InputWrapper };


import { Button, Modal } from "antd";
import PropTypes from 'prop-types';
import React from "react";

const Confirm = ({ title, content = '', onConfirm, okText = 'Yes', cancelText = 'No', onCancel = null, modalIcon, width, children, reverseButtons, ...otherProps }) => {
    // eslint-disable-next-line no-unused-vars
    const { onClick = null, ...buttonProps } = otherProps

    return (
        <>
            <Button onClick={() => {
                Modal.confirm({
                    content,
                    width,
                    title,
                    okText: okText,
                    cancelText: cancelText,
                    onOk: onConfirm,
                    closable: true,
                    maskClosable: true,
                    icon: modalIcon,
                    wrapClassName: reverseButtons ? 'ant-modal-wrap--reverse' : '',
                    onCancel: (close) => {
                        if (onCancel) {
                            onCancel()
                        }
                        return close()
                    },
                })
            }} {...buttonProps}>{children}</Button>
        </>
    )
}

Confirm.propTypes = {
    title: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    reverseButtons: PropTypes.bool,
    okText: PropTypes.node,
    cancelText: PropTypes.node,
    modalIcon: PropTypes.node,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    children: PropTypes.node,
}

export default Confirm
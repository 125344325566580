import React from 'react';
import PropTypes from 'prop-types'
import { Select } from 'antd'
// import Icon from '@ant-design/icons';
const { Option } = Select

const nameOptions =
    [
        { name: "Delivery", id: 1 },
        { name: "Import Fees", id: 2 },
        { name: "Specs Change", id: 3 },
        { name: "Studio Involvement", id: 4 },
        { name: "Others", id: 5 }
    ]

export const ReasonStatus = {
    1: "Delivery",
    2: "Import Fees",
    3: "Specs Change",
    4: "Studio Involvement",
    5: "Others"
}

function NameSelect({ value = null, onChange }) {
    return (
        <Select
            optionFilterProp="label"
            onChange={onChange}
            value={value}
            //  suffixIcon={<Icon component={() => (<img src="/icons/dropdown.svg" />)} styles={{pointerEvents: 'none'}} />}
            autoComplete="none"
            showSearch>
            {nameOptions.map((name) => (
                <Option key={name.id} value={name.id} label={name.name}>
                    {name.name}
                </Option>
            ))}
        </Select>
    );
}

NameSelect.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    options: PropTypes.array
}

export default NameSelect;
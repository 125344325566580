import { Steps as AntdSteps } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styles from './step.module.scss';

const Steps = ({ items, current = 0, onChange, debug = false, ...otherProps }) => {
    const indexeditems = useMemo(() => items.map((item, index) => ({
        title: item.title,
        icon: (
            <div className={current === index ? styles.stepIconActive : styles.stepIcon}>
                {item.icon}
            </div>
        ),
        index
    })), [items, current])

    const visibleItems = useMemo(() => {
        if (indexeditems.length <= 9) {
            return indexeditems
        }
        if (current <= 4) {
            const vi = [...indexeditems.slice(0, 6), ...indexeditems.slice(-3)]
            vi[5].dotted = 'yes'
            return vi
        }
        if (current > (indexeditems.length - 4)) {
            const vi = [...indexeditems.slice(0, 3), ...indexeditems.slice(-6)]
            vi[2].dotted = 'yes'
            return vi
        }
        const vi = [...indexeditems.slice(0, 2), ...indexeditems.slice(current - 2, current + 3), ...indexeditems.slice(-2)]
        vi[1].dotted = 'yes'
        vi[6].dotted = 'yes'
        return vi
    }, [items, current])

    const selectedStep = useMemo(() => visibleItems.findIndex(({ index }) => index === current), [items, current])

    // useEffect(() => {
    //     onChange && onChange(visibleItems[selectedStep].index, selectedStep)
    // }, [items, current])

    return (
        <>
            {debug && indexeditems.map(({ title }, index) => (
                <span key={title} style={{
                    background: current === index ? 'red' : 'white',
                    border: '1px black solid',
                    padding: '3px',
                    margin: '3px',
                    borderRadius: '3px'
                }}>{title}</span>
            ))}
            <AntdSteps
                items={visibleItems}
                current={selectedStep}
                onChange={(i) => {
                    console.log({ i })
                    onChange && onChange(visibleItems[i].index, i)
                }}
                {...otherProps}
            />
            <div className={styles.stepsWrapperActiveMarker} />
        </>
    )
}
Steps.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    current: PropTypes.number,
    onChange: PropTypes.func,
    debug: PropTypes.bool
}


export default Steps
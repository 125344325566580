import { SendOutlined, UploadOutlined } from '@ant-design/icons';
import { Alert, Button, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from "react";
import { Link, useFetcher, useOutletContext, useRevalidator, useRouteLoaderData } from 'react-router-dom';
import { fetchArtworkDetails, updateOrderItem } from '../../../api/order/order-manager.js';
import { deleteItemArtwork, uploadItemArtwork } from '../../../api/project-item/project-item.js';
import { ProjectItemSpecifications } from '../../../components/data/index.js';
import { Box } from '../../../components/design/box.js';
import { DatePicker, FieldWrapper, GeneralFieldWrapper, InputWrapper } from "../../../components/inputs";
import FileManager from '../../../components/inputs/file-manager.jsx';
import Confirm from '../../../components/message/Confirm.jsx';
import { ValidationError } from '../../../library/classes/exceptions/index.js';
import { ItemClientStatuses } from '../../../library/constants/ItemClientStatuses.js';
import { CLIENT_ARTWORK_UPLOAD_OFFSET_DAYS, CLIENT_PROOF_APPROVAL_OFFSET_DAYS } from '../../../library/constants/dynamic.js';
import { generateRouteUrl } from '../../../library/constants/routes.js';
import { subtractDays } from '../../../library/utilities/intl.js';
import style from './order-confifrmation.module.scss';

const TemplateAlert = ({ itemId, status }) => {
    switch (status) {
        case 2:
            return (
                <Alert message="Template sent to customer" type="info" />
            )

        case 1:
            return (
                <Alert message="Customer has requested for product template. Please share it" type="info"
                    action={
                        <Link to={generateRouteUrl('UploadSampleTemplate', {
                            itemId
                        })}>
                            <Button className={style.greenButton}>Send Template to Customer</Button>
                        </Link>
                    }
                />
            )

        default:
            return (
                <Alert message="" type="info"
                    action={
                        <Link to={generateRouteUrl('UploadSampleTemplate', {
                            itemId
                        })}>
                            <Button className={style.greenButton}>Send Template to Customer</Button>
                        </Link>
                    }
                />
            )
    }
}
TemplateAlert.propTypes = {
    itemId: PropTypes.number.isRequired,
    status: PropTypes.oneOf([0, 1, 2]).isRequired
}


const OrderConfirmation = () => {
    const { orderDetails, itemDetails, productDetails } = useRouteLoaderData('OrderManagerDetailsPage')
    const [isArtkworkUploadVisible, setIsArtkworkUploadVisible] = useState(false)
    const [artworkFiles, setArtworkFiles] = useState([])
    const fetcher = useFetcher()
    const { isFinisher, vendorRole } = useOutletContext()
    let revalidator = useRevalidator();
    const { errors = null } = fetcher.data || {}

    const handleItemDetailsChange = (field, value) => {
        const data = {
            [field]: value
        }
        if (field === 'deadline_approval_proof_vendor' && value) {
            itemDetails.deadline_artwork_customer = subtractDays(value, CLIENT_ARTWORK_UPLOAD_OFFSET_DAYS)
            itemDetails.deadline_approval_proof_customer = subtractDays(value, CLIENT_PROOF_APPROVAL_OFFSET_DAYS)
            data.deadline_artwork_customer = itemDetails.deadline_artwork_customer
            data.deadline_approval_proof_customer = itemDetails.deadline_approval_proof_customer
        }
        fetcher.submit({
            ...data,
            action: 'updateOrderItem'
        }, {
            method: 'post',
            encType: 'application/json'
        })
    }

    const getClientArtworks = async () => {
        try {
            setIsArtkworkUploadVisible(true)
            const { client_artwork = [] } = await fetchArtworkDetails(itemDetails.id)
            setArtworkFiles(client_artwork)
        }
        catch (e) {
            message.error(e.message)
        }
    }

    const handleUploadArtwork = async (imageData) => {
        try {
            const { message: msg } = await uploadItemArtwork(itemDetails.id, { image_ids: imageData })
            message.success(msg);
            getClientArtworks()
            revalidator.revalidate()
        }
        catch (error) {
            message.error(message.error);
        }
    }

    const handleUploadDelete = async (url) => {
        try {
            const msg = await deleteItemArtwork(itemDetails.id, url)
            message.success(msg);
            getClientArtworks()
            revalidator.revalidate()
        }
        catch (error) {
            message.error(message.error);
        }
    }

    return (
        <div className={style.container}>
            <h3 style={{ 'textAlign': 'start' }}>Tasks For You</h3>
            <TemplateAlert itemId={orderDetails.id} status={orderDetails.request_for_template} />
            <Alert message="Verify details" description="Verify all the order details before sending to vendor" type="info" showIcon />
            <Box type={Box.BoxTypes.BLUE}>
                <h3>Item Details</h3>
                <div style={{ display: 'flex', gap: '1rem', flexDirection: 'row' }}>
                    <div style={{ width: '50%', minWidth: '500px' }}>
                        <h4>Specifications</h4>
                        <ProjectItemSpecifications
                            itemId={itemDetails.itemId}
                            inbuiltSpecifications={productDetails.specifications}
                            max_print_area_size={itemDetails.max_print_area_size}
                            print_position={itemDetails.print_position}
                            customSpecifications={itemDetails.item_custom_specifications || []}
                            specificationValues={Object.values(itemDetails.specifications)}
                            readonly={true}
                        />
                    </div>
                    <fetcher.Form method="post">
                        <h4>Review</h4>
                        <div className="form-fields-wrapper--horizontal">
                            <FieldWrapper name="deadline_artwork_customer" label="Deadline Artwork Customer" errors={errors} horizontal>
                                <DatePicker disabledAfter={itemDetails?.deadline_approval_proof_vendor} key={itemDetails.deadline_artwork_customer} name="deadline_artwork_customer" value={itemDetails.deadline_artwork_customer} onChange={val => handleItemDetailsChange('deadline_artwork_customer', val)} />
                            </FieldWrapper>
                            <FieldWrapper name="deadline_approval_proof_customer" label="Deadline Approval proof Customer" errors={errors} horizontal>
                                <DatePicker disabledAfter={itemDetails?.deadline_artwork_customer} disabledBefore={itemDetails.deadline_artwork_customer} key={itemDetails.deadline_approval_proof_customer} name="deadline_approval_proof_customer" value={itemDetails.deadline_approval_proof_customer} onChange={val => handleItemDetailsChange('deadline_approval_proof_customer', val)} />
                            </FieldWrapper>
                            <FieldWrapper name="deadline_approval_proof_vendor" label="Deadline Approval proof Vendor" errors={errors} horizontal>
                                <DatePicker disabledAfter={itemDetails?.client_expected_delivery_date} disabledBefore={itemDetails.deadline_approval_proof_customer} key={itemDetails.deadline_approval_proof_vendor} name="deadline_approval_proof_vendor" value={itemDetails.deadline_approval_proof_vendor} onChange={val => handleItemDetailsChange('deadline_approval_proof_vendor', val)} />
                            </FieldWrapper>
                            <InputWrapper name="artwork_details" label="Artwork Details" defaultValue={itemDetails.artwork_details} placeholder="Artwork Details..." onChange={e => handleItemDetailsChange('artwork_details', e.target.value)} textarea allowClear delay errors={errors} horizontal />
                            <GeneralFieldWrapper label=" " horizontal>
                                <Button onClick={async () => {
                                    setIsArtkworkUploadVisible(true)
                                    getClientArtworks()
                                }} type='secondary' icon={<UploadOutlined />}>{itemDetails.client_status >= ItemClientStatuses.UPLOAD_ARTWORK ? 'View Artwork' : 'Upload ArtWork'}</Button>
                            </GeneralFieldWrapper>
                            <GeneralFieldWrapper label="Comment for supplier">
                                {isFinisher ? orderDetails.supplier_comment : orderDetails.vendor_comment}
                            </GeneralFieldWrapper>
                        </div>
                    </fetcher.Form>
                    <FileManager
                        title="Upload Customer Artworks"
                        projectId={orderDetails.project_id}
                        files={artworkFiles}
                        isOpen={isArtkworkUploadVisible}
                        onDelete={(url) => handleUploadDelete(url)}
                        onClose={() => setIsArtkworkUploadVisible(false)}
                        onUpload={(imageData) => {
                            handleUploadArtwork(imageData)
                        }}
                        deleteOwnOnly
                    />
                </div>
            </Box>
            <div className="actions align--right">
                <Confirm
                    type='primary'
                    content={`Please check all the details before sending order to ${vendorRole}`}
                    onConfirm={() => {
                        fetcher.submit({
                            ...itemDetails.selected_vendor,
                            action: 'sendOrderToVendor'
                        }, {
                            method: 'post',
                            action: generateRouteUrl('OrderManagerDetailsPage', {
                                itemId: orderDetails.id
                            })
                        })
                    }}
                    icon={<SendOutlined />}
                    loading={fetcher.formData?.get('action') === 'sendOrderToVendor'}
                >Send Order to {vendorRole}</Confirm>
            </div>
        </div>
    )
}

OrderConfirmation.Actions = {
    updateOrderItem: async ({ params, data }) => {
        const { itemId } = params
        try {
            //todo: Validate only specific fields can be updated by order manager
            await updateOrderItem(itemId, data)
            return true
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
            return false
        }
    },
}

export default OrderConfirmation;
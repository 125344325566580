import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { getPreview } from '../library/constants';
import styles from './addFile.module.scss';
const { TextArea } = Input

const fileTypeMapping = {
    jpg: {
        'image/jpg': ['.jpg'],
        'image/jpeg': ['.jpeg'],
        'image/png': ['.png']
    },
    pdf: {
        'application/pdf': ['.pdf'],
    },
    tiff: {
        'image/tiff': ['.tiff', '.tif'],
    },
    svg: {
        'image/svg+xml': ['.svg'],
    },
    psd: {
        'image/vnd.adobe.photoshop': ['.psd'],
    },
    eps: {
        'application/postscript': ['.eps'],
        'application/eps': ['.eps'],
        'application/x-eps': ['.eps'],
        'image/eps': ['.eps'],
        'image/x-eps': ['.eps'],
    }
}
const getAllowedTypes = (fileTypes) => {
    if (!fileTypes || fileTypes.length === 0) {
        return Object.values(fileTypeMapping).reduce((prev, curr) => ({
            ...prev,
            ...curr
        }), {})
    }
    return fileTypes.reduce((prev, curr) => fileTypeMapping[curr] ? {
        ...prev,
        ...fileTypeMapping[curr]
    } : prev, {})
}

const formattedSupportedFileTypes = (fileTypes) => {
    let types = []
    if (!fileTypes || fileTypes.length === 0) {
        types = Object.keys(fileTypeMapping).map(type => type.toUpperCase())
    }
    else {
        types = fileTypes.map(type => type.toUpperCase())
    }
    return types.join(', ')
}

const AddFileUpload = forwardRef(function AddFileUpload({ acceptedFileTypes = null }, ref) {
    const [, setFiles] = useState([])
    const [reload, setReload] = useState()

    useEffect(() => {
        if (!ref.current) {
            ref.current = []
        }
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => ref.current.forEach(file => URL.revokeObjectURL(file.file.preview));
    }, [reload]);

    const { getRootProps, getInputProps } = useDropzone({
        multiple: true,
        maxSize: 10 * 1024 * 1024,
        maxFiles: 10,
        accept: getAllowedTypes(acceptedFileTypes),
        onDropRejected: rejections => {
            alert(rejections.map(({ errors }) => errors.map(({ message }) => message)).join("\n"))
        },
        onDrop: acceptedFiles => {
            acceptedFiles.forEach(file => {
                addFile(file);
            });
        }
    })
    const addFile = (file) => {
        setFiles(files => {
            return [...files, Object.assign(file, {
                preview: URL.createObjectURL(file)
            })]
        })
        ref.current.push({ file, comment: null })
    }
    const updateComment = (index, comment) => {
        // ref.commentData.push(cmnt)
        if (!ref.current?.[index]) {
            ref.current[index] = {}
        }
        ref.current[index].comment = comment
    }
    const handleDeleteFile = (index) => {
        ref.current.splice(index, 1)
        setReload(!reload)
    }
    const handleViewFile = (file) => {
        const fileType = file.type;
        console.log(fileType);
        var blob = new Blob([file], { type: fileType });
        var blobURL = window.URL.createObjectURL(blob);
        window.open(blobURL);
    }

    return (
        <>
            <div className={styles.files}>
                {ref.current.length > 0 && (
                    ref.current.map(({ file, comment }, index) => {
                        return (
                            <div className={styles.file} key={file.name}>
                                <div className={styles.filePreview}>
                                    <img
                                        src={getPreview(file)}
                                        // Revoke data uri after image is loaded
                                        onLoad={() => { URL.revokeObjectURL(file.preview) }}
                                    />
                                </div>

                                <div className={styles.fileTitle}>
                                    <span className="label" style={{ wordBreak: 'break-word' }}>{file.name}</span>
                                </div>

                                <div className={styles.fileComment}>
                                    <TextArea onBlur={(e) => {
                                        updateComment(index, e.target.value)
                                    }} defaultValue={comment} name={index} label="Add a comment" placeholder="type your message here.." />

                                </div>

                                <div className={`actions align--left ${styles.fileActions}`}>
                                    <Space.Compact>
                                        <Button onClick={() => { handleDeleteFile(index) }} icon={<DeleteOutlined />} >Delete</Button>
                                        <Button onClick={() => { handleViewFile(file) }} icon={<EyeOutlined />}>View</Button>
                                    </Space.Compact>
                                </div>
                            </div>)
                    }
                    )
                )}
            </div>
            <input type="file" {...getInputProps()} style={{ display: "none" }} />
            <div {...getRootProps({ className: styles.dropArea })}>
                <div className={styles.dropIcon}>
                    +
                </div>
                <div className={`button--primary ${styles.uploadButton ?? ''}`}>Add File</div>
            </div>
            <i>Supported formats : {formattedSupportedFileTypes(acceptedFileTypes)}</i>
        </>
    )
})

AddFileUpload.propTypes = {
    inputName: PropTypes.string,
    acceptedFileTypes: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(fileTypeMapping))),
}

export { AddFileUpload };


import { HomeOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './header.module.scss';

import PropTypes from 'prop-types';
import { generateRouteUrl } from '../../library/constants';

const SecondaryHeader = ({ content, backLink = false }) => {
    const navigate = useNavigate()

    return (
        <div className={styles.secondaryheader}>
            {!content && <HomeOutlined onClick={() => { navigate(generateRouteUrl('Dashboard')) }} />}
            {content}
            {backLink && <Button type="primary" onClick={() => navigate(-1)}>Back</Button>}
        </div>
    )
}

SecondaryHeader.propTypes = {
    content: PropTypes.any,
    backLink: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}
export default SecondaryHeader
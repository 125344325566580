import { Alert, Button } from 'antd';
import React from "react";
import { Link, useRouteLoaderData } from 'react-router-dom';
import { generateRouteUrl } from '../../../library/constants';
import style from './send-ready-artwork.module.scss';
const ArtWorkReady = () => {
    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')
    return (
        <div className={style.container}>
            <h3>Task for you</h3>
            <Alert message="Artwork is ready, please send it to vendor" type="info"
                action={
                    <Link to={generateRouteUrl('SendReadyArtwork', {
                        itemId: orderDetails.id,
                    })}>
                        <Button className={style.greenButton}>View & Send Artwork</Button>
                    </Link>
                }
            />
        </div>
    )
}
export default ArtWorkReady;
export const ProjectStatuses = Object.freeze({
    NEW: 1,
    REQUEST_SEND: 3,
    QUOTATION_SENT: 4,
    CONFIRMATION_CLIENT_SENT: 5,
    CLOSED_LOST: 9,
    CANCELED: 10,
})
export const ProjectStatusesDetails = Object.freeze([
    { id: 1, name: 'New Request' },
    { id: 3, name: 'Enquiry request sent' },
    { id: 4, name: 'Quotation sent' },
    { id: 5, name: 'Won - Confirmation client sent' },
    {
        id: 9,
        name: 'Closed Lost',
        reasons: [
            'Different product',
            'Bought at the competition - delivery time',
            'Bought at the competition - price',
            'Bought at the competition - Stick to old supplier',
            `Client doesn't respond`,
        ]
    },
    { id: 10, name: 'Canceled' },
])

export const getProjectStatusByID = (statusId) => ProjectStatusesDetails.find(p => p.id === statusId)

export const hasClientQuotationApproved = (statusId) => statusId >= ProjectStatuses.CONFIRMATION_CLIENT_SENT

export const canSendQuotation = (statusId) => [ProjectStatuses.NEW].includes(statusId)
export const canCloseProject = (statusId) => [ProjectStatuses.NEW, ProjectStatuses.QUOTATION_SENT].includes(statusId)
export const canDeleteProject = (statusId) => [ProjectStatuses.NEW].includes(statusId)
export const canCancelQuotation = (statusId) => [ProjectStatuses.QUOTATION_SENT].includes(statusId)
export const canCancelProject = (statusId) => [ProjectStatuses.CONFIRMATION_CLIENT_SENT].includes(statusId)
export const canUpdateQuotation = (statusId) => [ProjectStatuses.NEW, ProjectStatuses.QUOTATION_SENT].includes(statusId)
export const isQuotationConfirmed = (statusId) => [ProjectStatuses.CONFIRMATION_CLIENT_SENT, ProjectStatuses.CANCELED].includes(statusId)
export const isInactiveProject = (statusId) => [ProjectStatuses.CLOSED_LOST, ProjectStatuses.CANCELED].includes(statusId)
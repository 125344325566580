import { DeleteOutlined, EyeOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Drawer, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useRouteLoaderData } from 'react-router-dom';
import { uploadFileFromObject } from '../../api/common/image-upload.js';
import { getFilePathForView, getPreviewUrl } from '../../library/utilities/urls.js';
import { AddFileUpload } from '../../ui/addFileUpload.jsx';
import styles from './file-manager.module.scss';
import Confirm from '../message/Confirm.jsx';
const { TextArea } = Input;

const FileManager = ({ title = "", projectId, files = [], acceptedFileTypes = null, isOpen = false, loading, deleteOwnOnly, onUpload, onDelete, onClose, ...otherProps }) => {
    // const [isDeleting, setIsDeleting] = useState(null)
    const { user } = useRouteLoaderData('Root')
    const [isUploading, setIsUploading] = useState(loading)
    const fileUploaderRef = useRef([])

    const handleFileUpload = async () => {
        if (fileUploaderRef.current.length === 0) {
            alert('Please select atleast one file')
            return
        }
        setIsUploading(true)
        const promises = fileUploaderRef.current.map(({ file }) => uploadFileFromObject(file))
        try {
            const fileIds = await Promise.all(promises)
            const imageData = fileIds.map((id, index) => ({
                id,
                comment: fileUploaderRef.current[index].comment
            }))
            onUpload(imageData)
            fileUploaderRef.current = []
        }
        catch (error) {
            throw new Response('Unable to upload files', { status: 500 })
        }
        setIsUploading(loading)
    }

    // const handleDeleteFile = async (url) => {
    //     setIsDeleting(url)
    //     try {
    //         const msg = await deleteImage(projectId, url)
    //         message.success(msg)
    //         revalidator.revalidate()
    //     }
    //     catch (error) {
    //         message.error(error.message)
    //     }
    //     setIsDeleting(null)
    // }

    return (
        <Drawer title={title} open={isOpen} onClose={onClose} size='large' footer={
            <div className="actions align--right">
                <Button onClick={() => handleFileUpload()} icon={<UploadOutlined />} loading={isUploading} type="primary">Upload</Button>
                <Button onClick={onClose} disabled={isUploading} type="danger">Close</Button>
            </div>
        } {...otherProps}>
            {!!files && files.length > 0 && (
                <div className={`${styles.files} ${(loading || isUploading) ? 'loading' : ''}`}>
                    {files.map(file => (
                        <div className={styles.file} key={file.url}>
                            <div className={styles.filePreview}>
                                <img src={getPreviewUrl(file.url, projectId)} />
                            </div>
                            <div className={styles.fileComment}>
                                <p className={styles.label}>{file.name}</p>
                                <TextArea label="Add a comment" value={file.comment} disabled />
                            </div>
                            <div className={`actions align--left ${styles.fileActions}`}>
                                {(onDelete && (!deleteOwnOnly || file.user_id === user.id)) && <Confirm title="Delete file" content='Selected file will be deleted permanently' okText='Delete' onConfirm={() => { onDelete(file.url) }} icon={<DeleteOutlined />} >Delete</Confirm>}
                                <Button href={getFilePathForView(file.url, projectId)} icon={<EyeOutlined />} target='_blank'>View</Button>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <AddFileUpload ref={fileUploaderRef} acceptedFileTypes={acceptedFileTypes} />
        </Drawer>
    )
}
FileManager.propTypes = {
    title: PropTypes.string,
    projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    files: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        comment: PropTypes.string,
    })),
    acceptedFileTypes: PropTypes.arrayOf(PropTypes.string),
    isOpen: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    deleteOwnOnly: PropTypes.bool,
    onUpload: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClose: PropTypes.func.isRequired
}

export default FileManager
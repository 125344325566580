import { Alert, Button, message } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useFetcher, useSubmit } from 'react-router-dom';
import { InputWrapper } from '../../../components/inputs/input-wrappers.jsx';
import { Prompt } from '../../../components/message/prompt.jsx';
import { generateRouteUrl } from '../../../library/constants/routes.js';

const SaleItemAlertTypes = {
    SPECS: 1,
    DELIVERY: 2,
    STUDIO: 3,
    FINANCIAL: 4,
    ADDITIONALQUOTATION: 5,
}

const ItemAlert = ({ projectId, itemId, id, title, description, notification_type }) => {
    const fetcher = useFetcher()
    const submit = useSubmit()
    const actions = notification_type === SaleItemAlertTypes.FINANCIAL ? (
        <Button.Group>
            <Prompt
                title="Continue without Payment"
                buttonText="Continue without Payment"
                onConfirm={({ comment = '' }) => {
                    if (comment.length === 0) {
                        message.error('Please enter a comment')
                        return false
                    }
                    fetcher.submit({
                        alertId: id,
                        comment,
                        action: 'declineAlert'
                    }, {
                        method: 'post',
                        encType: 'application/json'
                    })
                }}
            >
                <InputWrapper name="comment" label="Comment" textarea />
            </Prompt>
        </Button.Group>
    ) : notification_type === SaleItemAlertTypes.ADDITIONALQUOTATION ? (
        <Button.Group>
            <Prompt
                title="Continue without Confirmation"
                buttonText="Continue without Confirmation"
                onConfirm={({ comment = '' }) => {
                    if (comment.length === 0) {
                        message.error('Please enter a comment')
                        return false
                    }
                    fetcher.submit({
                        alertId: id,
                        comment,
                        action: 'declineAlert'
                    }, {
                        method: 'post',
                        encType: 'application/json'
                    })
                }}
            >
                <InputWrapper name="comment" label="Comment" textarea />
            </Prompt>
        </Button.Group>
    ) : (
        <Button.Group>
            <Button onClick={() => {
                submit({
                    fromAlerts: true,
                }, {
                    action: generateRouteUrl('AdditionalQuotation', { projectId, itemId }),
                    method: 'get'
                })
            }}>
                Create Additional Quotation
            </Button>
            <Prompt
                title="Decline alert"
                buttonText="Decline"
                onConfirm={({ comment = '' }) => {
                    if (comment.length === 0) {
                        message.error('Please enter a comment')
                        return false
                    }
                    fetcher.submit({
                        alertId: id,
                        comment,
                        action: 'declineAlert'
                    }, {
                        method: 'post',
                        encType: 'application/json'
                    })
                }}
            >
                <InputWrapper name="comment" label="Comment" textarea />
            </Prompt>
        </Button.Group>
    )

    return (
        <Alert
            type="warning"
            message={title}
            description={description}
            action={actions}
            showIcon
        />
    )
}
ItemAlert.propTypes = {
    projectId: PropTypes.number.isRequired,
    itemId: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    notification_type: PropTypes.number.isRequired,
}

const ItemAlerts = ({ projectId, itemId, alerts = [] }) => (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', marginBottom: '0.5rem' }}>
        {alerts?.map(alert => <ItemAlert key={alert.id} projectId={projectId} itemId={itemId} {...alert} />)}
    </div>
)
ItemAlerts.propTypes = {
    projectId: PropTypes.number.isRequired,
    itemId: PropTypes.number.isRequired,
    alerts: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        type: PropTypes.number.isRequired,
    }))
}

export { ItemAlerts, SaleItemAlertTypes };

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT = '/notify/:action';
const ENDPOINT_NOTIFICATION = '/notification';
const ENDPOINT_NOTIFICATION_SINGLE = 'notification/:id/read';


// Define all api functions here
export const subscribe = (token) => axiosInstance.post(generatePath(ENDPOINT, {
    action: 'subscribe'
}), {
    token
});

export const getNotifications = () => axiosInstance.get(ENDPOINT_NOTIFICATION).then(({ data }) => data)

export const readNotification = (id) => axiosInstance.patch(generatePath(ENDPOINT_NOTIFICATION_SINGLE, {
    id
})).then(({ data }) => data)
import { Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import Gender from '../../library/dynamics/genders';
// import Icon from '@ant-design/icons';
const { Option } = Select

function GenderSelect({ value = null, onChange, ...otherProps }) {
    return (
        <Select
            optionFilterProp="label"
            onChange={onChange}
            value={value}
            //  suffixIcon={<Icon component={() => (<img src="/icons/dropdown.svg" />)} styles={{pointerEvents: 'none'}} />}
            autoComplete="none"
            {...otherProps}
            showSearch
        >
            {Gender.map((gender) => (
                <Option key={gender.id} value={gender.id} label={gender.name}>
                    {gender.name}
                </Option>
            ))}
        </Select>
    );
}

GenderSelect.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    options: PropTypes.array
}

export default GenderSelect;
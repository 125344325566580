// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/vendor';
const ENDPOINT_SINGLE = '/vendor/:id';
const ENDPOINT_FINISHER_SEARCH = '/vendor/finisher';
const ENDPOINT_SINGLE_ACTION = '/vendor/:id/:action';
const ENDPOINT_SINGLE_ACTION_SUBACTION = '/vendor/:id/:action/:subaction';

export const getAllVendors = (payload) => {
    return axiosInstance.get(ENDPOINT_GENERAL, { params: payload })
}

export const searchVendors = (query) => {
    return axiosInstance.get('/vendor/search', { params: { q: query } })
        .then(({ data }) =>
            data.map(({ id, company }) => ({
                label: company,
                value: id,
            })),
        )
}

export const createVendorUser = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE + '/user', {
        id
    }), payload)
}

export const updateVendorUser = (id, uId, payload) => {
    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE + '/user/:uId', {
        id,
        uId
    }), payload)
}
export const getVendorUser = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE + '/user', {
        id
    }))
}

export const getVendorUserDetails = (id, uId) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE + '/user/:uId', {
        id,
        uId
    }))
}

export const getVendorDetails = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE, {
        id
    })).then(({ data }) => data)
}

export const updateVendorDetails = (id, payload) => {
    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE, {
        id
    }), payload)
}

export async function searchFinishers(query) {
    return axiosInstance.get(ENDPOINT_FINISHER_SEARCH, { params: { q: query } })
        .then(({ data }) =>
            data.map(({ id, company }) => ({
                label: company,
                value: id,
            })),
        )
}

export const getVendorBasicStats = (id) => {
    console.warn('REPLACE WITH REAL API')
    return new Promise(function (resolve) {
        setTimeout(() => {
            resolve({
                id,
                completed_projects: 100,
                cancelled_projects: 120,
                rating: 3.6,
            })
        }, 1000);
    });
}

export const getVendorRunningProjects = (id, page) => {
    const params = {}
    if (page) {
        params.page = page
    }
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE_ACTION_SUBACTION, {
        id,
        action: 'orders',
        subaction: 'running',
    }), { params })
}
export const getVendorCompletedProjects = (id, page) => {
    const params = {}
    if (page) {
        params.page = page
    }
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE_ACTION_SUBACTION, {
        id,
        action: 'orders',
        subaction: 'previous',
    }), { params })
}
export const getVendorProducts = (id, page) => {
    const params = {}
    if (page) {
        params.page = page
    }
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE_ACTION, {
        id,
        action: 'product-associated',
    }), { params })
}
export const getVendorPurchaseBills = (id, page) => {
    const params = {}
    if (page) {
        params.page = page
    }
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE_ACTION, {
        id,
        action: 'purchase-bill',
    }), { params })
}
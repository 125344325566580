import { Card, Input, Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFetcher } from 'react-router-dom';
import { generateRouteUrl } from '../../library/constants/routes.js';
import styles from './selectable-text-template.module.scss';


const renderItem = (item, selected, handleItemClick) => (
    <Card key={item.id} onClick={() => handleItemClick(item)} className={`${styles.card} ${selected ? styles['card--selected'] : ''}`} title={item.name}>
        <div className={styles.item}>
            <div className={styles.itemLabel}>Name.</div>
            <div className={styles.itemValue}>{item.name}</div>
        </div>
        <div className={styles.item}>
            <div className={styles.itemLabel}>Text</div>
            <div className={styles.itemValue}>{item.value}</div>
        </div>
    </Card>
)

const SelectableTextTemplate = ({ children, handleClose, onSelectionChanged, fieldTypeId }) => {
    const [query, setQuery] = useState('')
    const [selectedId, setSelectedId] = useState()

    const fetcher = useFetcher()

    useEffect(() => {
        fetcher.submit({
            filter_type_id: fieldTypeId //todo: Replace with footer texts type id //field_type_id
        }, {
            method: "get",
            action: generateRouteUrl('TextTemplates')
        })
    }, [])

    const filteredList = fetcher?.data?.templates?.filter(item => {
        return item.name.toLowerCase().includes(query.toLowerCase()) ||
            item.value.toLowerCase().includes(query.toLowerCase())
    }) ?? []

    const handleItemClick = (template) => {
        setSelectedId(template.id)
        if (onSelectionChanged) {
            onSelectionChanged(template)
        }
    }
    return (
        <Modal title="Add Contact" open={true} onCancel={handleClose ?? false} width={1000} footer={false} >
            <div className={styles.wrapper}>
                <Input.Search placeholder="Search..." onChange={(e) => setQuery(e.target.value)} />
                <div className={styles.cards}>
                    {filteredList.map((item) => renderItem(item, (item.id === selectedId), handleItemClick))}
                </div>
                {children}
            </div>
        </Modal >
    )
}

SelectableTextTemplate.propTypes = {
    children: PropTypes.node,
    handleClose: PropTypes.func,
    onSelectionChanged: PropTypes.func,
    fieldTypeId: PropTypes.number.isRequired,
}

export default SelectableTextTemplate
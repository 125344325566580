import { Layout } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useNavigation } from 'react-router-dom';
import { Header } from "../header";
import Styles from './projectPage.module.scss';
const { Content } = Layout;

function ProjectPage({ title, className, children, header, footer, loading = false }) {
  const navigation = useNavigation()

  return (
    <>
      <Header title={title} />
      <div className={Styles.header}>
        {header}
      </div>
      <Content className={`page ${className}`} style={{ opacity: loading || navigation.state === 'loading' ? 0.6 : 1 }}>
        <div className={Styles.innerWrapper}>

          <div className={Styles.contentWrapper}>
            {children}
          </div>
          <div className={Styles.footer} style={{ display: 'flex', justifyContent: 'space-between' }}>
            {footer}
          </div>
        </div>
      </Content>
    </>
  );
}

ProjectPage.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  header: PropTypes.node,
  footer: PropTypes.node,
  loading: PropTypes.bool,
};

export default ProjectPage;

import PropTypes from 'prop-types';
import React from 'react';
import { Languages, Roles, Genders } from '../../../library/constants/dynamic.js';
import styles from './contact-detail.module.scss';

const ContactDescription = ({ children, contact }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.item}>
				<div className={styles.itemLabel}>First Name</div>
				<div className={styles.itemValue}>{contact.first_name}</div>
			</div>
			<div className={styles.item}>
				<div className={styles.itemLabel}>Last Name</div>
				<div className={styles.itemValue}>{contact.last_name}</div>
			</div>
			<div className={styles.item}>
				<div className={styles.itemLabel}>Phone No.</div>
				<div className={styles.itemValue}>
					{!!contact.phone_number && `+${contact.phone_country_code ?? ''} ${contact.phone_number}`}
				</div>
			</div>
			<div className={styles.item}>
				<div className={styles.itemLabel}>Mobile No.</div>
				<div className={styles.itemValue}>{!!contact.mobile_number && `+${contact.mobile_country_code ?? ''} ${contact.mobile_number}`}</div>
			</div>
			<div className={styles.item}>
				<div className={styles.itemLabel}>Email</div>
				<div className={styles.itemValue}>{contact.email}</div>
			</div>
			<div className={styles.item}>
				<div className={styles.itemLabel}>Language</div>
				<div className={styles.itemValue}>{Languages.find(({ id }) => id === contact.language_id)?.name}</div>
			</div>
			<div className={styles.item}>
				<div className={styles.itemLabel}>Role</div>
				<div className={styles.itemValue}>{Roles.find(({ id }) => id === contact.role_id)?.name}</div>
			</div>
			<div className={styles.item}>
				<div className={styles.itemLabel}>Gender</div>
				<div className={styles.itemValue}>{Genders.find(({ id }) => id === contact.gender_id)?.name}</div>
			</div>
			{children}
		</div>
	)
}

ContactDescription.propTypes = {
	children: PropTypes.node,
	contact: PropTypes.object,
}

export default ContactDescription
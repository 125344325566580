import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Switch } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { cmsLanguageName, supportedLanguages } from '../../helpers/language.js';
import { generateRouteUrl } from '../routes.js';

export const TemplateListColumns = (fetcher) => ([
    {
        title: 'Edit',
        key: 'edit',
        render: (_, record) => record.multilingual ? (
            <Dropdown
                menu={{
                    items: supportedLanguages.map(language => ({
                        key: language,
                        label: (
                            <Link key={language} to={generateRouteUrl('EmailTemplateDetails', {
                                id: record.id,
                                language
                            })}>{cmsLanguageName(language)}</Link>
                        )
                    })),
                }}
            >
                <Button size="small" type="primary" icon={<DownOutlined />}>Edit</Button>
            </Dropdown>
        ) : (
            <Link to={generateRouteUrl('EmailTemplateDetails', {
                id: record.id,
                language: 'default'
            })}>
                <Button size="small" type="primary">Edit</Button>
            </Link>
        ),
    },
    {
        title: 'History',
        dataIndex: 'id',
        render: id => <Link to={generateRouteUrl('EmailHistory', {
            id
        })}><Button>History</Button></Link>
    },
    {
        title: 'Status',
        dataIndex: 'active',
        render: active => active ? 'Active' : 'Not Active'
    },
    {
        title: 'Multilingual',
        dataIndex: 'multilingual',
        // render: multilingual => multilingual ? 'Yes' : 'No'
        render: (multilingual, record) => (
            <Switch checked={!!multilingual} onChange={(checked) => {
                fetcher.submit({
                    id: record.id,
                    action: 'multilingual'
                }, {
                    replace: true,
                    method: checked ? 'POST' : 'DELETE',
                    encType: 'application/json'
                })
            }} />
        )
    },
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Description',
        dataIndex: 'description',
    },
    {
        title: 'Last Updated',
        dataIndex: 'updatedAt',
    },
    {
        title: 'Updated By',
        dataIndex: 'updatedBy',
    },
])
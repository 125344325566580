import { useEffect, useState } from 'react';
import { GeneralLedgers, VatTypes } from '../library/constants/dynamic.js';

export const useInvoiceLineTotals = (initialQuantity, initialTotalPriceExclVat, initialVisibleVatId, initialGeneralLedger = null) => {
    const [quantity, setQuantity] = useState(parseInt(initialQuantity) || null);
    const [totalPriceExclVat, setTotalPriceExclVat] = useState(parseFloat(initialTotalPriceExclVat) || null);
    const [visibleVatId, setVisibleVatId] = useState(initialVisibleVatId);
    const [totalPriceInclVat, setTotalPriceInclVat] = useState(null);
    const [unitPrice, setUnitPrice] = useState(null);
    const [totalVat, setTotalVat] = useState(null);
    const [generalLedger, setGeneralLedger] = useState(initialGeneralLedger || GeneralLedgers.find(({ vat_type_id }) => vat_type_id === visibleVatId)?.id)

    const visibleVat = VatTypes.find(({ id }) => id === visibleVatId)?.percentage / 100

    useEffect(() => {
        const totalInclVat = parseFloat(totalPriceExclVat) * (1 + parseFloat(visibleVat));
        setTotalPriceInclVat(totalInclVat);

        const calculatedTotalVat = parseFloat(totalPriceExclVat) * parseFloat(visibleVat);
        setTotalVat(calculatedTotalVat);

        const calculatedUnitPrice = parseFloat(totalPriceExclVat) / parseFloat(quantity);
        setUnitPrice(calculatedUnitPrice);
    }, [quantity, totalPriceExclVat, visibleVatId]);

    useEffect(() => {
        setGeneralLedger(GeneralLedgers.find(({ vat_type_id }) => vat_type_id === visibleVatId)?.id)
    }, [visibleVatId])

    return {
        quantity,
        setQuantity,
        totalPriceExclVat,
        setTotalPriceExclVat: (val) => setTotalPriceExclVat(parseFloat(val) || null),
        visibleVatId,
        setVisibleVatId,
        totalPriceInclVat,
        totalVat,
        unitPrice,
        generalLedger
    };
}
import { Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { getOrderStatusName } from '../../library/constants/steps/index.js';
import { getTimeAgo } from '../../library/utilities/intl.js';

const AuditTrail = ({ logs }) => {
    return (
        <Table
            columns={[
                {
                    title: 'Date',
                    dataIndex: 'createdAt',
                    render: getTimeAgo
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    render: status => getOrderStatusName(status)
                },
                {
                    title: 'User',
                    dataIndex: 'user',
                    render: user => user?.name,

                },
                {
                    title: 'Message',
                    dataIndex: 'message',
                },
            ]}
            dataSource={logs}
            rowKey="id"
            pagination={{ hideOnSinglePage: true }}
        />
    )
}
AuditTrail.propTypes = {
    logs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        createdAt: PropTypes.string.isRequired,
        status: PropTypes.number,
        message: PropTypes.string.isRequired,
        user: PropTypes.object,
    }))
}
export default AuditTrail
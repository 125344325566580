import { Input, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Countries } from '../../library/constants';
import { getFieldErrors } from '../../library/helpers/forms';
import { useValidationForm } from '../../providers/form';
import { ValidationForm as Form } from '../form';
import styles from './PhoneInput.module.scss';
import './PhoneInput.scss';
// import Icon from '@ant-design/icons';

function PhoneInput({ placeholder, valuePropName = { code: 'code', number: 'number' }, label = "Contact", dependencies = [], required, /* onChange */ }) {
    const form = Form.useFormInstance();
    const { errors } = useValidationForm();
    // const fieldErrors = [getFieldErrors(valuePropName.code, errors), getFieldErrors(valuePropName.number, errors)]
    // const fieldStatus = getFieldStatus(name, errors)
    const [number, setNumber] = useState(form.getFieldValue(valuePropName.number));
    const country_code = Form.useWatch(valuePropName.code, form);
    const onNumberChange = (event) => {
        setNumber(event.target.value)
    };

    // useEffect(() => {
    //     // const phone = {}
    //     // phone[valuePropName.code] = code
    //     // phone[valuePropName.number] = number
    //     // onChange?.(phone)
    // }, [code, number])
    const normalizeValue = (value) => {
        const filteredValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
        return filteredValue;
    };

    async function countryCodeValidator(_, value) {
        if ((required || form.getFieldValue(valuePropName.number) > 0) && !value?.length) {
            return Promise.reject('Country code is required')
        }
        return Promise.resolve()
    }

    const fieldErrors = () => {
        const codeErrors = getFieldErrors(valuePropName.code, errors)
        const numberErrors = getFieldErrors(valuePropName.number, errors)

        if (required) {
            return numberErrors?.length ? numberErrors : codeErrors?.length ? codeErrors : null;
        }

        // For non-required fields, return null if neither error exists
        if (!numberErrors?.length && !codeErrors?.length) {
            return null;
        }

        // Return numberErrors if they exist, otherwise return codeErrors
        return numberErrors?.length ? numberErrors : codeErrors;
    }

    return (
        <span className={`${styles.wrapper} phone-input`}>
            <div className={`${styles.innerWrapper} ant-input`}>
                <Form.Item name={valuePropName.code} rules={[{ validator: countryCodeValidator }]} dependencies={[valuePropName.number]} noStyle>
                    <Select
                        value={country_code}
                        optionLabelProp="title"
                        dropdownMatchSelectWidth={false}
                        autoComplete="none"
                        className={styles.countryCode}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={
                            Countries.map(({ dial_code, code, name, emoji }) => ({
                                label: [emoji, name, '+' + dial_code].join(' '),
                                value: dial_code,
                                key: dial_code + code,
                                title: emoji,
                            }))}
                        showSearch
                        bordered={false}
                    />
                </Form.Item>
                <Form.Item name={valuePropName.number} rules={required ? [{ required: true, message: `${label} is required` }] : false} normalize={normalizeValue} dependencies={[...dependencies, valuePropName.code]} noStyle>
                    <Input
                        maxLength={15}
                        className={styles.phone}
                        value={number}
                        prefix={<>{country_code ? "+ " + country_code : ''}</>}
                        onChange={onNumberChange}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        placeholder={placeholder}
                        bordered={false}
                    />
                </Form.Item>
            </div>
            <div className="ant-form-item-explain" role="alert">
                {fieldErrors()?.map((error, i) => (
                    <div key={i}><span className="error">{error}</span></div>
                ))}
            </div>
        </span>
    );
}

PhoneInput.propTypes = {
    placeholder: PropTypes.string,
    valuePropName: PropTypes.exact({
        code: PropTypes.string,
        number: PropTypes.string,
    }),
    dependencies: PropTypes.arrayOf(PropTypes.string),
    required: PropTypes.bool,
    label: PropTypes.string,
    //onChange: PropTypes.func
}

export default PhoneInput;
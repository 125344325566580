import React from 'react';
import { Server } from '../../constants/server'
import { fetchSmallWebsiteLogo } from '../../../components/design';

export const CustomerColumns = [
    {
        title: 'Company',
        dataIndex: ['website', 'company'],
        sorter: true,
        width:'20%' ,
        fixed: 'left',
        render: (website, company) => {
            return fetchSmallWebsiteLogo(company.website, company.company)
        }
    },

    {
        title: 'Email',
        dataIndex: 'email',
        sorter: true,
    },
    {
        title: 'Contact Number',
        dataIndex: ['contact_number', 'company'],
        sorter: true,
        render: (contact_number,company) => {return `+ ${company.contact_country_code} ${company.contact_number}`}
    },
    {
        title: 'Industry',
        dataIndex: 'industry',
        sorter: true,
        render: industry => industry?.name
    },
    {
        title: 'Country',
        dataIndex: 'country',
        sorter: true,
        render: (country) => {
            return <img src={`${Server.BaseUrl}/${country.flag_url}`} alt={country.name} title={country.name} />
        }
    },
    {
        title: 'Language',
        dataIndex: 'language',
        sorter: true,
        render: language => language.name
    },
    {
        title: 'Executive Entity',
        dataIndex: 'entity',
        sorter: true,
        render: entity => entity?.name
    },
    // {
    //     title: 'Website',
    //     dataIndex: 'website',
    //     sorter: true,
    // },
    // {
    //   title: 'Action',
    //   key: 'operation',
    //   fixed: 'right',
    //   width: 100,
    //   render: () => <a>action</a>,
    // },
]
export const textToClassName = (text) => {
    if (!text) {
        return ''
    }
    return text.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase();
};

String.prototype.interpolate = function (params) {
    return this.replace(/\$\{(\w+)\}/g, (match, p1) => {
        const value = params[p1];
        // Check if the value is a string before interpolating
        return typeof value === 'string' ? value : match;
    });
};

export const getEnvVariable = (key, isRequired = true) => {
    const actualKey = `REACT_APP_${key}`
    if (!process.env[actualKey]) {
        if (isRequired) {
            console.error(`Please configure ${actualKey} in environment`)
        }
        else {
            console.warn(`Please configure ${actualKey} in environment`)
        }
        return ''
    }
    return process.env[actualKey]
}

export const arrayIntersection = (array1, array2) => array1.filter(value => array2.includes(value))

export const getHistoryState = () => history?.state?.usr

export const isObjectEmpty = (obj) => Object.keys(obj).length === 0

export const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;

    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    return sortedArr1.every((element, index) => element === sortedArr2[index]);
}
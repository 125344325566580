import { Alert } from 'antd';
import React from "react";
import style from './artwork-sent-to-studio.module.scss';


const MakeArtworkSenttoStudio = () => {
    return <div className={style.container}>
        <h3>Task for you</h3>
        <Alert message="Studio has been tasked with generating the customer artwork. Awaiting the studio department's response." type="info" />
    </div>
}

export default MakeArtworkSenttoStudio;
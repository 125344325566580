import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Empty, message, Timeline } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useFetcher, useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { getVendorProjectItemQuotationRequest } from '../../../api/project-item/project-item-vendor.js';
import { sendVisualByAdmin } from '../../../api/vendor/vendorQuotation.js';
import { Box } from '../../../components/design/box.js';
import SmallSection from '../../../components/design/small-section.jsx';
import { DocumentsDownloader } from '../../../components/form/index.js';
import FileManager from '../../../components/inputs/file-manager.jsx';
import { ERP_CURRENCY } from '../../../library/constants/dynamic.js';
import { VendorQuotationStatuses, VendorQuotationStatusText } from '../../../library/constants/vendor-quotation-statuses.js';
import { getCurrencyById } from '../../../library/helpers/currency.js';
import { convertFromERPCurrency, formatDate, formatDateTime, formatPrice, getTimeAgo } from '../../../library/utilities/intl.js';
import styles from './project-item-update.delivery.module.scss';
const statusName = (status) => VendorQuotationStatusText?.[status]

const getStatus = (status) => {
    return <div className={styles.status} >{statusName(status)}</div>
}

const Price = ({ price, currency, conversion }) => {
    if (!price) {
        return 'NA'
    }
    if (!conversion || currency === ERP_CURRENCY) {
        return formatPrice(price, currency)
    }
    return (
        <>
            <div>{formatPrice(price, ERP_CURRENCY)}</div>
            <div>{formatPrice(convertFromERPCurrency(price, conversion), currency)}</div>
        </>
    )
}
Price.propTypes = {
    price: PropTypes.number,
    currency: PropTypes.oneOf(['EUR', 'GBP', 'USD']).isRequired,
    conversion: PropTypes.number,
}

const Quotation = ({ projectId, quotation }) => {
    const [fileUploadVisible, setFileUploadVisible] = useState(false)
    const fetcher = useFetcher()
    const currency = getCurrencyById(quotation.currency_id)?.code ?? ERP_CURRENCY
    const convertionRate = quotation.conversion_rate?.[currency] ?? 1
    const quotationItemStatus = quotation.item_vendor_quotation_statuses?.filter(({ status }) => status === quotation.status)?.reduce((recent, current) => {
        return (!recent.id || current.id > recent.id) ? current : recent;
    }, {})

    if (quotation.file) {
        return (
            <div>
                <p>Vendor has sent quotation file <i>{getTimeAgo(quotation.updatedAt, true)}</i></p>
                <DocumentsDownloader project_id={projectId} image={quotation.file.quotation.url} name={quotation.file.quotation.name} comment={quotationItemStatus?.comment} />
            </div>
        )
    }
    if (quotation.status === VendorQuotationStatuses.VISUAL_SENT && quotation.request_files) {
        return (
            <>
                <p>Visual sent to Vendor <i>{getTimeAgo(quotation.updatedAt, true)}</i></p>
                {quotation.request_files.map((file) => <DocumentsDownloader key={file.url} image={file.url} name={file.name} comment={file.comment} project_id={projectId} />)}
            </>
        )
    }
    if (quotation.status === VendorQuotationStatuses.VISUAL_REQUESTED) {
        return (
            <>
                <p>
                    Vendor has requested for Visual <i>{getTimeAgo(quotation.updatedAt, true)}</i>
                    <br /><strong>Comment from Vendor: </strong><br />
                    {quotationItemStatus?.comment}
                </p>
                <Button onClick={() => {
                    setFileUploadVisible(true)
                }}> Send Visual</Button>
                <FileManager
                    isOpen={fileUploadVisible}
                    title="Send Visual to Vendor"
                    files={[]}
                    projectId={projectId}
                    onUpload={(imageData) => {
                        fetcher.submit({
                            imageData: imageData,
                            quotationId: quotation.id,
                            action: 'sendVisual'
                        }, {
                            method: 'post',
                            encType: 'application/json'
                        })
                    }}
                    onClose={() => setFileUploadVisible(false)}
                    width={500}
                />
            </>
        )
    }
    if (quotation.status === VendorQuotationStatuses.ACTIVE || quotation.status === VendorQuotationStatuses.SENT || quotation.status === VendorQuotationStatuses.CANCELED) {
        return (
            <div>
                <Box type={Box.BoxTypes.GRAY} header={
                    <div className={styles.formGrid}>
                        <h4>Quantity</h4>
                        <h4>Artowrk Approval Date</h4>
                        <h4>Purchase Price</h4>
                        <h4>Shipping Price</h4>
                        <h4>Current Status</h4>
                        <h4>Comment</h4>
                    </div>

                } footer={
                    <div>
                        <p>Created at : {formatDateTime(quotation.createdAt)}</p>
                    </div>
                } >{quotation.item_vendor_quotation_prices.map((value) => {
                    return (<SmallSection colorLight={'#F1F1F1'} colorDark={'#BABABA'} key={value?.id}>
                        <div className={styles.gridValues}>
                            <div>{value.quantity}</div>
                            <div>{formatDate(value.expected_artwork_received_date)}</div>
                            <div>{<Price price={value.price_unit} currency={currency} conversion={convertionRate} />}</div>
                            <div> <Price price={value.transport_price} currency={currency} conversion={convertionRate} /></div>
                            <div>{getStatus(quotation.status)}</div>
                            <div>{quotation.comment}</div>
                        </div>
                    </SmallSection>)
                })}

                </Box>
                <br />
                {/* <Timeline>
                    {quotation.item_vendor_quotation_statuses.map((data) => (
                        <div key={data.createdAt}>
                            <p>Comment : {data.comment}</p>
                            <TimelineItem>{statusName(data.status)} at {formatDateTime(data.createdAt)}</TimelineItem>
                        </div>
                    ))}
                </Timeline> */}
            </div>
        )
    }
}

Quotation.propTypes = {
    projectId: PropTypes.number.isRequired,
    quotation: PropTypes.object.isRequired,
}

const VendorQuotation = () => {
    const { quotations } = useLoaderData()
    const { projectId } = useParams()
    const navigate = useNavigate()

    const handleCancel = () => {
        navigate('../', {
            replace: true
        })
    }

    if (!quotations || quotations.length === 0) {
        <Drawer>Quotataion Not Avaiable Yet</Drawer>
    }

    const activeQuotation = quotations.filter(item => item.inactive === 0);
    const inActiveQuotation = quotations.filter(item => item.inactive === 1);

    if (!activeQuotation) {
        return (
            <Drawer onClose={handleCancel} title="Quotation Details" open={true} closable footer={<Button icon={<CloseCircleOutlined />} type='primary' onClick={handleCancel}> Close</Button>} size="large" >
                <Empty description="There are currently no quotations." />
            </Drawer>
        )
    }

    return (
        <Drawer width={'60%'} mask={false} onClose={handleCancel} title="Quotation Details" open={true} closable footer={<Button icon={<CloseCircleOutlined />} type='primary' onClick={handleCancel}> Close</Button>} size="large" >
            <h3>Active Quotation</h3>
            {activeQuotation && activeQuotation.map((quotation) => {
                return (
                    <SmallSection key={quotation.id}>
                        <Quotation quotation={quotation} projectId={Number(projectId)} />
                        <Timeline mode='alternate'>
                            {quotation.item_vendor_quotation_statuses.map((data) => (
                                <div key={data.createdAt}>
                                    <Timeline.Item>
                                        {statusName(data.status)} <i>{getTimeAgo(data.createdAt)}</i>
                                        {data.comment && <p>Comment : {data.comment}</p>}
                                    </Timeline.Item>
                                </div>
                            ))}
                        </Timeline>
                    </SmallSection>
                )
            })}
            {!!inActiveQuotation && inActiveQuotation.length > 0 && (
                <>
                    <h3>Quotation History</h3>
                    {inActiveQuotation.map((quotation) => {
                        return (
                            <SmallSection key={quotation.id}>
                                <Quotation quotation={quotation} projectId={Number(projectId)} />
                            </SmallSection>
                        )
                    })}
                </>
            )}

        </Drawer>
    )
}
VendorQuotation.Actions = {
    sendVisual: async ({ data }) => {
        try {
            const { quotationId, imageData, comment } = data
            const { message: msg } = await sendVisualByAdmin(quotationId, { image_ids: imageData, comment })
            message.success(msg)
            return true
        }
        catch (error) {
            message.error(error.message)
            return false
        }
    }
}
VendorQuotation.Loader = async ({ params }) => {
    const { itemId, vendorId } = params
    const quotations = await getVendorProjectItemQuotationRequest(itemId, vendorId)
    return {
        quotations
    }
}
export default VendorQuotation
import { Alert, Button } from 'antd';
import React from "react";
import { Link, useRouteLoaderData } from 'react-router-dom';
import { generateRouteUrl } from '../../../library/constants/routes.js';
import style from './proof-requested.module.scss';

const ProofRequested = () => {
    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')
    return <div className={style.container}>
        <h3>Task for you</h3>
        <Alert message="Proof has been requested." type="info" />
         <Alert message="" type="info" action={
            <Link to={generateRouteUrl('SendProofToCustomer', {
                itemId: orderDetails.id,
            })}>
                <Button className={style.greenButton}>Send Proof To Customer</Button>
            </Link>

        } />

    </div>
}

export default ProofRequested;
import { Alert } from 'antd';
import React from "react";
import style from './proof-sent-to-studio.module.scss';

const ProofSentToStudio = () => {
    return <div className={style.container}>
        <h3>Task for you</h3>
        {<Alert message="Proof has been sent to studio. Waiting from studio department" type="info" />}
    </div>
}

export default ProofSentToStudio;


export const vatListColumns = [
    {
        title: 'Entity',
        dataIndex: 'entity',
        render: entity => entity?.name
    },
    {
        title: 'Percentage',
        dataIndex: 'percentage',
    },
    {
        title: 'Description',
        dataIndex: 'description',
    },
    {
        title: 'Btw Code',
        dataIndex: 'btw_code',
    },
   
]
import { Button, Empty } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import { getCombiSuggestions } from '../../../api/combi';
import { Box } from '../../../components/design/box.js';
import SmallSection from '../../../components/design/small-section.jsx';
import { Page } from '../../../components/page';
import { generateRouteUrl } from '../../../library/constants/index.js';
import { formatDate } from '../../../library/utilities/intl';
import styles from './combi-deals.module.scss';

const CombiSuggestionPreview = ({ groupId, productName, totalItems, totalQuantity, items }) => (
    <Box header={(
        <div className={styles.boxHeader}>
            <div className={styles.boxTitle}>
                <h3>{productName}</h3>
                {!!totalItems && (
                    <Link to={generateRouteUrl('CombiDealsDetails', {
                        productId: groupId
                    })}><Button>View</Button></Link>
                )}
            </div>
            <div className={styles.boxTitle}>
                <div>Total Projects: {totalItems}</div>
                <div>Total Quantity: {totalQuantity}</div>
            </div>
        </div>
    )} containerStyles={{
        margin: '0rem'
    }}>
        {items?.length ? (
            <>
                <div className={styles.rowHeading}>
                    <div>Project ID</div>
                    <div>Quantity</div>
                    <div>Order Date</div>
                    <div>Delivery Deadline Date</div>
                </div>
                {items.map(item => (
                    <SmallSection key={item.id} containerStyles={{ margin: '5px 0' }}>
                        <div className={styles.row}>
                            <div>{item.project_id}</div>
                            <div>{item.approved_quantity}</div>
                            <div>{formatDate(item.createdAt)}</div>
                            <div>{formatDate(item.client_expected_delivery_date)}</div>
                        </div>
                    </SmallSection>
                ))}
            </>
        ) : (<Empty description={`Last Combination deals was sent on : ------`} />)}
    </Box>
)
CombiSuggestionPreview.propTypes = {
    groupId: PropTypes.number.isRequired,
    productName: PropTypes.string.isRequired,
    totalItems: PropTypes.number.isRequired,
    totalQuantity: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const CombiDealsSuggestion = (props) => {
    const { combiSuggestions } = useLoaderData()

    return (
        <Page title={props.title} backLink={false}>
            <div className={styles.combiGrid}>
                {combiSuggestions && combiSuggestions.map(combiData => (
                    <CombiSuggestionPreview
                        key={combiData.product_id}
                        groupId={combiData.product_id}
                        productName={combiData.product_name}
                        totalItems={combiData.total_count}
                        totalQuantity={combiData.total_quantity}
                        items={combiData.data}
                    />
                ))}
            </div>
        </Page>
    )
}


CombiDealsSuggestion.propTypes = {
    title: PropTypes.string
}


CombiDealsSuggestion.Loader = async () => {

    const combiSuggestions = await getCombiSuggestions()
    return { combiSuggestions }


}
export default CombiDealsSuggestion
import { Alert } from 'antd';
import React from "react";
import style from './make-artwork.module.scss';


const ArtworkSentToCustomer = () => {
    return (
        <div className={style.container}>
            <h3>Task for you</h3>
            <Alert message="Artwork is sent to customer. Waiting for customers approval." type="info" />
        </div>
    )

}

export default ArtworkSentToCustomer;
import { Alert, Button, Modal, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from "react";
import { useFetcher, useRouteLoaderData } from 'react-router-dom';
import { uploadFileFromObject } from "../../../api/common/index.js";
import { supplierConfirmOrderDelivery } from '../../../api/order/supplier-finisher-flow.js';
import { Box } from "../../../components/design/box.js";
import { InputWrapper } from "../../../components/inputs/input-wrappers.jsx";
import { useUpdateQueryStringValueWithoutNavigation } from "../../../hooks/use-update-query-string-without-navigation.js";
import { ValidationError } from "../../../library/classes/exceptions/index.js";
import { formatDate } from "../../../library/utilities/intl.js";
import MultifileUpload from "../../../ui/multifile-upload.jsx";
import styles from './supplier-product-dispatch.module.scss';

const getFormattedAddress = (address) => <>{address.street_number},{address.street},{address.city}</>

const DeliveryConfirm = ({ delivery, onConfirm }) => {
    return (
        <div className={styles.deliveryRow}>
            <div className="quantity">{delivery.quantity}</div>
            <div className="address">{getFormattedAddress(delivery)}</div>
            <div className="field-quantity">{delivery.ship_quantity}</div>
            <div className="field-expected_date">
                {formatDate(delivery.expected_dispatch_date)}
            </div>
            <div className="field-trackingurl">{delivery.tracking_url && <Button href={delivery.tracking_url} type="link" target="_blank">View</Button>}</div>
            <div className="field-expected_date">{formatDate(delivery.expected_delivery_date)}</div>
            {delivery.status === 4 ? (
                <Button
                    type="primary"
                    name="action"
                    disabled
                >Proof Sent</Button>
            ) : (
                <Button
                    type="primary"
                    onClick={onConfirm}
                >Confirm</Button>
            )}

        </div>
    )
}
DeliveryConfirm.propTypes = {
    delivery: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
}

export const SupplierProductDispatch = () => {
    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')
    const fetcher = useFetcher();
    const { errors = {} } = fetcher.data || {}
    const [isUploading, setIsuploading] = useState(false)
    const fileUploaderRef = useRef([])
    const inputComment = useRef('')
    const [showDeliveryConfirmPopup, setShowDeliveryConfirmPopup] = useUpdateQueryStringValueWithoutNavigation('confirmdelivery')

    const closeConfirmDeliveryPopup = () => {
        setShowDeliveryConfirmPopup(false)
        inputComment.current = ''
        fileUploaderRef.current = []
    }

    useEffect(() => {
        setIsuploading(fetcher.state === 'submitting')
        if (fetcher.state !== 'submitting' && fetcher.data === true) {
            closeConfirmDeliveryPopup()
        }
    }, [fetcher])

    const handleFilesUpload = async () => {
        if (fileUploaderRef.current.length === 0) {
            alert('Please select atleast one file')
            return
        }
        setIsuploading(true)
        const promises = fileUploaderRef.current.map(file => uploadFileFromObject(file))
        try {
            const fileIds = await Promise.all(promises)
            fetcher.submit({
                image_ids: fileIds,
                delivery_proof_comment: inputComment.current,
                action: 'supplierConfirmDelivery'
            }, {
                method: 'post',
                encType: 'application/json'
            })
        }
        catch (error) {
            message.error(error.message)
        }
        finally {
            setIsuploading(false)
        }
    }

    return (
        <div className={styles.container}>
            <h3>Task For You</h3>
            <Alert description="The Vendor has shipped the order, the delivery will take 5-6 days." type="info" showIcon />
            <Box header={
                <div className={styles.deliveryRowHead}>
                    <div>Quantity</div>
                    <div>Delivery Address</div>
                    <div>Ship Quantity</div>
                    <div>Expected Dispatch Date</div>
                    <div>Tracking URL</div>
                    <div>Expected Delivery Date</div>
                    <div></div>
                </div>
            }>
                <DeliveryConfirm
                    projectId={orderDetails.project_id}
                    delivery={orderDetails.item_finisher_address ?? {}}
                    onConfirm={() => {
                        setShowDeliveryConfirmPopup(true)
                    }}
                />
            </Box>
            <Modal
                title="Upload Proof of Delivery"
                open={showDeliveryConfirmPopup}
                onOk={handleFilesUpload}
                okButtonProps={{ loading: isUploading }}
                okText="Continue"
                onCancel={closeConfirmDeliveryPopup}
            >
                <div className={styles.uploadBox}>
                    <p>The uploaded printscreen should not contain track and trace code, country of origin and sender information</p>
                    <div className="main">
                        <div className="box--white">
                            <MultifileUpload ref={fileUploaderRef} />
                        </div>
                    </div>
                    <br />
                    <InputWrapper name="delivery_proof_comment" label="comment" defaultValue="" textarea allowClear errors={errors} onChange={e => inputComment.current = e.target.value} />
                </div>
            </Modal>
        </div>
    )
}

export const SupplierProductDispatchActions = {
    // notifyCustomer: async ({ params }) => {
    //     console.log(params);

    //     const { itemId } = params
    //     try {
    //         const msg = await clientTransit(itemId)
    //         message.success(msg);
    //         return true
    //     }
    //     catch (error) {
    //         message.error(error.message)
    //     }
    //     return false
    // },
    supplierConfirmDelivery: async ({ params, data }) => {
        const { itemId } = params
        try {
            const { image_ids, delivery_proof_comment } = data
            const msg = await supplierConfirmOrderDelivery(itemId, image_ids, delivery_proof_comment)
            message.success(msg);
            return true
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
        }
        return false
    }
}

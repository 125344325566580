export const websiteUrls = Object.freeze({
    "at": "https://www.leoprinting.at",
    "be_fr": "https://www.leoprinting.be/fr",
    "be": "https://www.leoprinting.be",
    "ch": "https://www.leoprinting.ch",
    "de": "https://www.leoprinting.de",
    "dk": "https://www.leoprinting.dk",
    "en": "https://www.leoprinting.co.uk",
    "es": "https://www.leoprinting.es",
    "fr": "https://www.leoprinting.fr",
    "nl": "https://www.leoprinting.nl",
    "lu_de": "https://www.leoprinting.lu",
    "lu_fr": "https://www.leoprinting.lu/fr",
    "it": "https://www.leoprinting.it"
})